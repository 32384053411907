import base from "../baseurl";
import token from "../getTk";

const Delete = async (goodDeliveryEventId, states) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("good-delivery-participant-states/showall", {
      json: {
        goodDeliveryEventId,
        states,
      },
    })
    .json();
  console.log(Create);
  return Create;
};

export default Delete;
