import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import Update from "../../../API/Companies/Update";

const styles = (theme) => ({
  PaperDialog: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  Radio: {
    margin: "2%",
  },
});

class EditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { info: { company: {} } };
  }

  componentDidMount = async () => {
    try {
      if (this.props.Info) {
        this.setState({ info: this.props.Info });
      } else {
        toastType.error("No hay datos");
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
    }
  };

  onChange = (event) => {
    var info = { ...this.state.info };
    info.company.Benefit[event.target.name] = event.target.value;
    this.setState({ info });
  };
  Save = async () => {
    try {
      const save = await Update(this.state.info.company);
      if (save.status === "success") {
        this.props.Refresh();
        toastType.success("Guardado");
      } else {
        toastType.error("No fue posible Guardar");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Editar Beneficios</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Paper className={classes.PaperDialog}>
                <TextField
                  label="Aguinaldo"
                  margin="normal"
                  size="small"
                  name="christmasBox"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.christmasBox
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Bono por Asistencia"
                  margin="normal"
                  size="small"
                  name="attendanceBonus"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.attendanceBonus
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Prima Vacacional"
                  margin="normal"
                  size="small"
                  name="holydayBonus"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.holydayBonus
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Fondo de Ahorro"
                  margin="normal"
                  size="small"
                  name="savingFund"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.savingFund
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Utilidades"
                  margin="normal"
                  size="small"
                  name="utilities"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.utilities
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Vales de despensa"
                  margin="normal"
                  size="small"
                  name="vouchers"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.vouchers
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Servicio de comedor"
                  margin="normal"
                  size="small"
                  name="lunchBonus"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.lunchBonus
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Servicio de transporte"
                  margin="normal"
                  size="small"
                  name="transportBonus"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.transportBonus
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
                <TextField
                  label="Beneficio Adicionales"
                  margin="normal"
                  size="small"
                  name="other"
                  onChange={this.onChange}
                  multiline
                  rowsMax={4}
                  fullWidth
                  value={
                    this.state.info.company.Benefit
                      ? this.state.info.company.Benefit.other
                      : "Sin Información"
                  }
                  //variant="outlined"
                />
              </Paper>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.Save} color="primary">
              Guardar
            </Button>
            ,
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(EditDialog);
