import base from "../baseurl";
import token from "../getTk";

const Create = async (State) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("users/create", {
      json: {
        name: State.name,
        lastNames: State.lastNames,
        email: State.email,
        pass: State.pass,
        userType: State.userType,
        dateOfBird: State.dateOfBird,
        sex: State.sex,
        mobilePhone: State.mobilePhone,
        headquartersUuid: State.HQid,
      },
    })
    .json();

  return Create;
};

export default Create;
