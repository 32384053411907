import base from "../baseurl";
import token from "../getTk"

const showAll = async () => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const SectionsShow = await api.get("sections/index",{timeout:false,}).json();

    console.log('====================================');
    console.log(SectionsShow);
    console.log('====================================');
    
    return SectionsShow.data.sections;
    
  };
  
  export default  showAll ;