import api from "../baseurl";

const Logout = async (data) => {
    
    const Logout = await api.post("login",{data}).json();
    //console.log(data);
    return Logout;
    
  };
  
  export default Logout ;