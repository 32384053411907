import base from "../../baseurl";

const showAll = async () => {
  const CompaniesShow = await base
    .post("bulletins-general/sport", {
      timeout: false,
    })
    .json();
  console.log(CompaniesShow);
  return CompaniesShow;
};

export default showAll;
