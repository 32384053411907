import api from "../../baseurl";

const Create = async () => {
    const Create = await api.get("frequentqas/showall").json(); 
   // console.log(Create.data.frequentQAs)
    return Create.data.frequentQAs;

    
  };
  
  export default  Create ;