import axios from "axios";
const Report = async (CompanyId) => {
  const re = await axios
    .post(`https://api.sitimm.org/api/reports/show`, {
      reportNumber: 9,
      params: {
        CompanyId,
      },
    })
    .then((res) => {
      return res;
    });
  return re;
};

export default Report;
