import base from "../baseurl";
import token from "../getTk"

const showAll = async () => {
  const tokenA = token.get()

  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const headquartersShow = await api.get(`headquarters/index`).json();
    //console.log(headquartersShow.data.headquarters)
    return headquartersShow.data.headquarters;
    
  };
  
  export default  showAll ;
  