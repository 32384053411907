import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import SportEvent from "../../../API/SportEvent";
import CircularProgress from "@material-ui/core/CircularProgress";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import UploadFile from "../../../Components/Header/UploadFiles";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
    padding: "1%",
  },
  Text: {
    width: "30%",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      title: "",
      editorState: EditorState.createEmpty(),
      url: "",
      urlForPage: "",
    };
  }
  componentDidMount = async () => {
    console.log(this.props.objetINDET);
    if (this.props.objetINDET) {
      const contentBlock = htmlToDraft(this.props.objetINDET.body);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        console.log("Editor", contentState);
        this.setState({ editorState });
      }
      this.setState({
        title: this.props.objetINDET.title,
        url: this.props.objetINDET.img,
        urlForPage: this.props.objetINDET.url,
      });
    }
  };
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });

      const Deli = await SportEvent.INDETUpdate(
        this.state.title,
        this.state.url,
        draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        this.state.urlForPage,
        this.props.objetINDET.id
      );
      console.log(Deli);
      if (Deli.status === "success") {
        this.setState({
          Oncreate: false,
        });
        toastType.success("Se ha Editado con éxito");
        await this.props.Refresh("EditDialog");
      } else {
        this.setState({
          Oncreate: false,
        });
        toastType.error("SSE No fue Posible Editar el elemento");
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Editar el evento");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  handleCallback = (urlFromChild, stateName) => {
    this.setState({
      [stateName]: urlFromChild,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Nuevo Elemento INDET</DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            <TextField
              label="Nombre"
              margin="normal"
              size="small"
              name="title"
              onChange={this.onChange}
              value={this.state.title}
              className={classes.Text}
            />
            <UploadFile
              Type="Imagen"
              Place="SITIMM_INDET_SPORTEVENT_"
              parentCallback={this.handleCallback}
              stateNameOnParent="url"
              PrevImg={this.state.url}
            />
            <Editor
              editorState={this.state.editorState}
              editorClassName={classes.tool}
              onEditorStateChange={this.onEditorStateChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.title.length > 0
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
