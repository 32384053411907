import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles, Grid } from "@material-ui/core";
//import toasType from "../../API/CallUtils/tostyNotification";
//import SelectCompany from "./Components/SelectCompany";
//import SelecType from "./Components/SelecType";
//import Events from "./List/Events";
//import Fitlist from "./List/Fitlist";
//import Verify from "./List/VerifyList";
//import Unfit from "./List/UnFitlist";
import CompaniesList from "./Components/CompaniesTableList";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
    marginTop: "5px",
  },
  Table: {
    //maxHeight: "400px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompanySelected: null,
      Type: null,
    };
  }

  componentDidMount = async () => {};

  selectCompany = async (uuid) => {
    this.setState({ CompanySelected: uuid });
  };
  selectType = async (id) => {
    this.setState({ Type: id });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.paperFilter}>
          <Grid container item xs={12} className={classes.Initial} spacing={1}>
            <CompaniesList />
          </Grid>
        </Paper>
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
