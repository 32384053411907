import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Select: null, Oncreate: false, name: "", url: "" };
  }
  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Documentos</DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            {this.props.docs.length > 0 &&
              this.props.docs.map((doc) => {
                return (
                  <Paper className={classes.DocConteiner}>
                    <Typography className={classes.DocTitle}>
                      {doc.PersonalDocumentType.name}
                    </Typography>
                    <Paper
                      className={classes.DocImg}
                      component="a"
                      href={doc.url}
                    >
                      <img
                        src={doc.url}
                        className={classes.ImgPre}
                        alt={doc.url.split("/")[4]}
                      />
                    </Paper>
                  </Paper>
                );
              })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
