import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete"
import ShowAll from "./ShowAll";



const Companies = {
    Update,
    ShowAll,
    Delete,
    Create,
     };

export default Companies;

export {
    Create,
    Update,
    ShowAll,
    Delete
}