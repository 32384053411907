import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import List from "../../../API/CompaniesForms";
import { OnLoadSelect } from "../../../Components/StatesForComponets";

const styles = (theme) => ({
  formControl: {
    width: "80%",
    margin: "10px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompaniesList: [],
      SelectedCompanie: "",
    };
  }
  componentDidMount = async () => {
    await this.fetchCompanyList();
  };
  fetchCompanyList = async () => {
    try {
      const CompanyList = await List.ShowAll();
      if (CompanyList) {
        //console.log("Lista", CompanyList);
        this.setState({
          CompaniesList: CompanyList.data.companyForms,
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.CompaniesList && this.state.CompaniesList.length > 0 ? (
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Listado de Formularios
            </InputLabel>
            <Select
              value={this.props.formId}
              onChange={(event) => {
                this.props.Select("formId", event.target.value);
              }}
            >
              {this.state.CompaniesList.map((companie, index) => {
                return (
                  <MenuItem value={companie.id} key={companie.id}>
                    {companie.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <div className={classes.formControl}>
            <OnLoadSelect />
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
