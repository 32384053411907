import base from "../baseurl";
import token from "../getTk";

const Delete = async (id) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Delete = await api
    .post("user-permissions/delete", {
      json: {
        id,
      },
    })
    .json();

  return Delete;
};

export default Delete;
