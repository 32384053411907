import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Blog from "./blog";
import Magazine from "./magazine";
import Events from "./events";
import Home from "./home";
import Benefits from "./Benefits";
import FAQ from "./FAQ";
import NewProgrmas from "./NewPrograms";

function TabContainer(props) {
  return <div>{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "100%",
    height: "80vh",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: 150,
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "100% !important",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
});

class SITIMM extends React.Component {
  state = {
    value: 0,
    Blog: [],
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  ArticleD = (Uuid, title, img, text, url, e) => {
    this.setState({
      UuidE: Uuid,
      Title: title,
      img: img,
      TextArticle: text,
      urlArticle: url,
      DialogArticle: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="Inicio" />
            <Tab label="Programas" />
            <Tab label="Beneficios" />
            <Tab label="Eventos" />
            <Tab label="Revista" />
            <Tab label="Blog" />
            <Tab label="FAQ" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <Home />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <NewProgrmas />
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <Benefits />
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            <Events />
          </TabContainer>
        )}
        {value === 4 && (
          <TabContainer>
            <Magazine />
          </TabContainer>
        )}
        {value === 5 && (
          <TabContainer>
            <Blog />
          </TabContainer>
        )}
        {value === 6 && (
          <TabContainer>
            <FAQ />
          </TabContainer>
        )}
      </div>
    );
  }
}

SITIMM.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SITIMM);
