import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Estadistica from "@material-ui/icons/Equalizer";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },

  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    textAlign: "center",
    color: "#2f3542",
  },
}));

export default function Statistics() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Estadistica />
        </ListItemIcon>
        <ListItemText primary="Estadística" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link className={classes.Link} to="/Estadistica/Empresas">
            <ListItem button className={classes.nested}>
              <ListItemText>Empresas</ListItemText>
            </ListItem>
          </Link>
          <Link className={classes.Link} to="/Estadistica/Empleados">
            <ListItem button className={classes.nested}>
              <ListItemText>Empleados</ListItemText>
            </ListItem>
          </Link>
          <Link className={classes.Link} to="/Estadistica/Reportes">
            <ListItem button className={classes.nested}>
              <ListItemText>Reportes</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </div>
  );
}
