import base from "../baseurl";
import token from "../getTk";

const showAllEmployees = async (censusUuid) => {
  const tokenA = token.get();

  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  //console.log("llego a llamda de fecth")
  const CompaniesShow = await api.get("census/show/" + censusUuid).json();
  //console.log(CompaniesShow)
  return CompaniesShow;
};

export default showAllEmployees;
