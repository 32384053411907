import React from "react";

import {
  Typography,
  Button,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VotingCall from "../../../API/Voting";
import Skeleton from "@material-ui/lab/Skeleton";
import toastType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoad: true,
      onError: false,
      ErrorCase: "",
      label: "",
      DialogDelete: false,
      delete: null,
      iteams: [],
      path: "",
    };
  }
  componentDidMount = async () => {
    if (this.props.companieUuid) {
      await this.initialFetch(this.props.Type);
    }
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.companieUuid !== this.props.companieUuid) {
      await this.initialFetch(this.props.Type);
    }
  };
  initialFetch = async (type) => {
    switch (type) {
      case "EDIT":
        //console.log("edit");
        this.setState({
          label: "En preparación",
          path: "/Votaciones/Nueva",
        });
        await this.fetchVoting(this.props.companieUuid, 1);
        break;
      case "PROGRAMED":
        //console.log("progr");
        this.setState({ label: "Programadas", path: "/Votaciones/Programada" });
        await this.fetchVoting(this.props.companieUuid, 2);
        break;
      case "DEPLOYED":
        //console.log("diplo");
        this.setState({ label: "En curso", path: "/Votaciones/EnProceso" });
        await this.fetchVoting(this.props.companieUuid, 3);
        break;
      case "FINISHED":
        //console.log("end");
        this.setState({ label: "Finalizadas", path: "/Votaciones/Terminada" });
        await this.fetchVoting(this.props.companieUuid, 4);
        break;
      default:
        this.setState({ iteams: [] });
        break;
    }
  };
  fetchVoting = async (UuidCompanie, Type) => {
    // Llamada para votaciones Terminadas
    try {
      this.setState({
        onLoad: true,
      });

      const FinishCall = await VotingCall.showAll(UuidCompanie, Type);
      console.log("Llamada", FinishCall);

      if (FinishCall.status === "success") {
        this.setState({
          iteams: FinishCall.data,
          onLoad: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  DialogClose = async (Name) => {
    this.setState({ [Name]: false });
  };
  Alertdelete = async (comp, e) => {
    //console.log("id comp a borrar ", comp);
    this.setState({
      delete: comp,
      DialogDelete: true,
    });
  };
  trueDelete = async () => {
    try {
      if (this.state.delete === null) {
      } else {
        const DeleteCall = await VotingCall.Delete(this.state.delete);
        console.log(DeleteCall);

        if (DeleteCall.status === "success") {
          toastType.success("Borrado exitoso");
          this.setState({
            DialogDelete: false,
            delete: null,
          });
          this.initialFetch(this.props.Type);
        } else {
          toastType.error("Error en Borrado");
          this.setState({
            DialogDelete: false,
            delete: null,
          });
        }
      }
    } catch (error) {
      toastType.error("Error en la conexion");
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            {this.state.label}
          </Typography>
          <Skeleton variant="text" animation="wave" height="40px" />
          <Skeleton variant="text" animation="wave" height="40px" />
          <Skeleton variant="text" animation="wave" height="40px" />
          <Skeleton variant="text" animation="wave" height="40px" />
        </>
      );
    } else if (this.state.onError) {
      return (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            {this.state.label}
          </Typography>
          <Paper className={classes.NoItemPaper}>
            <Typography variant="body1" color="textPrimary" component="p">
              {this.state.ErrorCase}
            </Typography>
          </Paper>
          ,
        </>
      );
    } else {
      return (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            {this.state.label}
          </Typography>

          {this.state.iteams.length > 0 ? (
            <Paper className={classes.Table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Detalles</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.iteams.map((Article) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article.uuid}>
                          <TableCell component="th" scope="row">
                            {Article.title}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Link
                              className={classes.Link}
                              to={{
                                pathname: this.state.path,
                                state: {
                                  UuidVoting: Article.uuid,
                                  UuidCompanie: this.props.companieUuid,
                                },
                              }}
                            >
                              <Button color="primary">
                                <EditIcon />
                              </Button>
                            </Link>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="secondary"
                              onClick={this.Alertdelete.bind(
                                this,
                                Article.uuid
                              )}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <Paper className={classes.NoItemPaper}>
              <Typography variant="body1" color="textPrimary" component="p">
                Sin elementos disponibles
              </Typography>
            </Paper>
          )}
          <Dialog
            open={this.state.DialogDelete}
            onClose={this.DialogClose.bind(this, "DialogDelete")}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">Borrar</DialogTitle>
            <DialogContent>
              <Typography
                variant="body1"
                color="textPrimary"
                component="p"
                className={classes.Title}
              >
                Esta acción es permanente y sera registrada
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.DialogClose.bind(this, "DialogDelete")}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                onClick={this.trueDelete}
                variant="contained"
                color="primary"
              >
                Borrar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
}

export default withStyles(styles)(Edit);
