import base from "../baseurl";
import token from "../getTk"


const showAll = async (State) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
  //console.log("llamda para los logs",State);
    const LogsShow = await api.post(`logs/show-all`,{json: {
      quantity:State.quantity,
      page: State.page,
      userTypes:[],
      userUuid:State.userUuid
    }}).json();
    console.log(LogsShow)
    return LogsShow;
    
  };
  
  export default  showAll ; 