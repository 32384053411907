import Update from "./Update";
import showAll from "./showAll";



const Home = { 
    Update,
    showAll,

 };

export default Home;

export {   
    Update,
    showAll,

}