import base from "../baseurl";
import token from "../getTk";

const Create = async (EmployedUuid, ElectionUuid, ImgURL, PlaceTo, WSUuid) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log("Uuid enviado a creacion", PlaceTo);
  const Create = await api
    .post("candidate/create", {
      json: {
        employeeUuid: EmployedUuid,
        img: ImgURL,
        electionUuid: ElectionUuid,
        employment: PlaceTo,
        candidateWorkSheetUuid: WSUuid ? WSUuid : null,
      },
    })
    .json();
  console.log("crear un candidato", Create);
  return Create;
};

export default Create;
