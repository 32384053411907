import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Paper, Typography, Grid, Button } from "@material-ui/core";
import Api from "../../../API/Companies";
import { OnLoadSection } from "../../../Components/StatesForComponets";
import toastType from "../../../API/CallUtils/tostyNotification";
import EmployeeData from "./EmployeeData";
import ProfileData from "./ProfileData";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
moment.locale("es");
const RFC = new RegExp(/^[A-ZÑ]{3,4}(\d{6})((\D|\d){1,3})?$/);
const styles = (theme) => ({
  formControl: {
    margin: "10px",
  },
  Skeleton: {
    width: "100%",
    height: "20px",
    borderRadius: "10px 10px 10px 10px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    //backgroundColor: '',
    "&:hover": {
      backgroundColor: "#f2efeb",
    },
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "30%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  Subtitle: {
    alignItems: "center",
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  searchFild: {
    width: "100%",
  },
  End: {
    margin: "10px",
    justifyContent: "flex-end",
  },
  InitialConteiner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40vh",
  },
});
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Conection: "OnSearch",
      CheckErrors: false,
      userData: {
        userType: 63974,
      },
      employeData: {
        payroll: "",
        job: "",
        daySalary: "",
        entry: moment(),
        address: "",
        City: "",
      },
      userProfileData: {
        name: "",
        lastNames: "",
        dateOfBirth: moment(),
        sex: "",
        nss: "",
        charge: "",
        CivilStateId: "",
        ScholarshipId: "",
        isFatherAlive: true,
        isMotherAlive: true,
        profilePic: "",
        rfc: "",
        title: "",
        City: "",
        titleType: "",
        affiliationDate: moment(),
        personalPhone: "",
        mobilePhone: "",
        address: "",
      },
    };
  }
  componentDidMount = () => {
    console.log(this.props.location.state.company);
  };
  SendToCreate = async () => {
    try {
      if (this.Validations()) {
        this.setState({ Conection: "Onload" });
        const userDataNew = this.state.userData;
        userDataNew.email = this.state.userProfileData.rfc;
        userDataNew.pass = this.state.userProfileData.rfc;
        userDataNew.dateOfBirth = moment(
          new Date(this.state.userData.dateOfBirth)
        ).toISOString();
        const UserProfile = this.state.userProfileData;
        UserProfile.affiliationDate = moment(
          new Date(this.state.userProfileData.affiliationDate)
        ).toISOString();
        const UserEmployee = this.state.employeData;
        UserEmployee.entry = moment(
          new Date(this.state.employeData.entry)
        ).toISOString();

        const Create = await Api.NewUser(
          this.props.location.state.company,
          userDataNew,
          UserProfile,
          UserEmployee
        );
        if (Create.status === "success") {
          this.Clean();
          toastType.success("Éxito al crear");
        } else {
          this.setState({ Conection: "OnSearch" });
          toastType.error("SSE No fue posible agregar " + Create.data.message);
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({ Conection: "OnSearch" });
      toastType.error(" CSE No fue posible agregar");
    }
  };
  SendToparent = (nameObj, field, value) => {
    this.setState({ [nameObj]: { ...this.state[nameObj], [field]: value } });
  };
  Clean = () => {
    this.setState({
      Conection: "OnSearch",
      CheckErrors: false,
      userData: {
        userType: 63974,

        personalPhone: "",
        mobilePhone: "",
      },
      employeData: {
        payroll: "",
        job: "",
        daySalary: "",
        entry: moment(),
        address: "",
      },
      userProfileData: {
        dateOfBirth: moment(),
        sex: "",
        name: "",
        lastNames: "",
        nss: "",
        charge: "",
        CivilStateId: "",
        ScholarshipId: "",
        isFatherAlive: true,
        isMotherAlive: true,
        profilePic: "",
        rfc: "",
        title: "",
        city: "",
        titleType: "",
        affiliationDate: moment(),
      },
    });
  };
  Validations = () => {
    if (RFC.test(this.state.userProfileData.rfc)) {
      if (
        this.state.employeData.payroll &&
        this.state.employeData.job &&
        this.state.employeData.address &&
        this.state.employeData.daySalary
      ) {
        return true;
      } else {
        toastType.error(
          "Todos los valores de datos de empleados son obligatorios "
        );
        this.setState({ CheckErrors: true });
      }
    } else {
      toastType.error("El RFC no tiene un formato valido ");
    }
  };
  render() {
    const { classes } = this.props;
    switch (this.state.Conection) {
      case "Onload":
        return (
          <div className={classes.InitialConteiner}>
            <CircularProgress />
          </div>
        );
      case "OnError":
        return (
          <Paper>
            <OnLoadSection />
          </Paper>
        );
      case "OnNull":
        return (
          <Paper>
            <OnLoadSection />
          </Paper>
        );
      case "OnSearch":
        return (
          <>
            <Grid container row spacing={1}>
              <Grid
                item
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={6}
              >
                <Typography
                  className={classes.Subtitle}
                  align="center"
                  variant="h5"
                >
                  Datos de Perfil
                </Typography>
                <ProfileData
                  SendToparent={this.SendToparent}
                  ParentState={this.state.userProfileData}
                />
              </Grid>
              <Grid
                item
                justifyContent="center"
                alignItems="center"
                direction="row"
                xs={6}
              >
                <Typography
                  className={classes.Subtitle}
                  align="center"
                  variant="h5"
                >
                  Datos de Empleado
                </Typography>
                <EmployeeData
                  SendToparent={this.SendToparent}
                  ParentState={this.state.employeData}
                  errors={this.state.CheckErrors}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                className={classes.End}
              >
                <Grid item>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={this.SendToCreate}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      default:
        return (
          <Paper>
            <OnLoadSection />
          </Paper>
        );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(index));
