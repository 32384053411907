import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  textField: {
    marginLeft: "12px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Select: null, Oncreate: false, name: "" };
  }
  componentDidMount = async () => {
    console.log(this.props.UserData);
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Detalles de{" "}
          {" " + this.props.UserData.name + this.props.UserData.lastNames}
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <TextField
            label="Nombre"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.name + this.props.UserData.lastNames}
            variant="outlined"
          />
          <TextField
            label="Fecha de nacimiento"
            className={classes.textField}
            margin="normal"
            value={
              this.props.UserData.dateOfBirth
                ? moment(this.props.UserData.dateOfBirth).format("ll")
                : ""
            }
            variant="outlined"
          />
          <TextField
            label="Edad"
            className={classes.textField}
            margin="normal"
            value={
              this.props.UserData.dateOfBirth
                ? Math.floor(
                    moment().diff(
                      moment(this.props.UserData.dateOfBirth),
                      "years",
                      true
                    )
                  )
                : ""
            }
            variant="outlined"
          />
          <TextField
            label="Ciudad"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.city}
            variant="outlined"
          />
          <TextField
            label="Dirección"
            className={classes.textField}
            margin="normal"
            value={
              this.props.UserData.User.Employees.length > 0
                ? this.props.UserData.User.Employees[0].address
                : "No disponible"
            }
            variant="outlined"
          />
          <TextField
            label="Fecha de admisión a Sitimm"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.affiliationDate}
            variant="outlined"
          />
          <TextField
            label="Numero de seguro social"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.nss}
            variant="outlined"
          />
          <TextField
            label="Email de contacto"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.otherMail}
            variant="outlined"
          />
          <TextField
            label="Teléfono de contacto"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.personalPhone}
            variant="outlined"
          />
          <TextField
            label="RFC"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.rfc}
            variant="outlined"
          />
          <TextField
            label="Genero"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.sex}
            variant="outlined"
          />
          <TextField
            label="Titulo dentro de sitimm"
            className={classes.textField}
            margin="normal"
            value={
              this.props.UserData.title ? this.props.UserData.title : "Ninguno"
            }
            variant="outlined"
          />
          <TextField
            label="Empresa donde labora"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.User.Employees[0].Company.name}
            variant="outlined"
          />
          <TextField
            label="Fecha de ingreso a la empresa"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.User.Employees[0].entry}
            variant="outlined"
          />
          <TextField
            label="Salario Diario"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.User.Employees[0].daySalary}
            variant="outlined"
          />
          <TextField
            label="Puesto"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.User.Employees[0].job}
            variant="outlined"
          />
          <TextField
            label="Numero de nomina"
            className={classes.textField}
            margin="normal"
            value={this.props.UserData.User.Employees[0].payroll}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
