import React from "react";
import { Grid, withStyles, TextField } from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridSectionItem: {
    //marginTop:"15px"
  },
});
class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Election: {},
    };
  }
  componentDidMount = async () => {
    try {
      console.log("El autor", this.props);

      if (this.props.ElectionInfo) {
        this.setState({ Election: this.props.ElectionInfo });
      } else {
        console.log("El Uuid es perdido");
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        justify="start"
        alignItems="start"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className={classes.GridSectionItem}
        >
          <TextField
            label="Titulo"
            value={this.state.Election.title}
            className={classes.Text}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Titulo Publico"
            value={this.state.Election.publicTitle}
            className={classes.Text}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Puesto"
            value={
              this.state.Election.publicTitle
                ? this.state.Election.publicTitle.split(
                    "Asamblea Electiva para "
                  )[1]
                : ""
            }
            className={classes.Text}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className={classes.GridSectionItem}
        >
          <TextField
            label="Empresa donde se realiza"
            value={
              this.state.Election.Company
                ? this.state.Election.Company.name
                : ""
            }
            className={classes.Text}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Autor de la votación"
            value={this.props.ElectionInfo.User.email}
            className={classes.Text}
            variant="outlined"
            size="small"
          />
          {this.state.Election.Editor ? (
            this.state.Election.Editor.map((autor, index) => {
              return (
                <TextField
                  label={"Registro de Edición " + (index + 1)}
                  value={autor.User ? autor.User.email : ""}
                  className={classes.Text}
                  variant="outlined"
                  size="small"
                />
              );
            })
          ) : (
            <></>
          )}
          <TextField
            label="Tipo de votación"
            value={this.state.Election.type === 1 ? "Singular" : "Plantillas"}
            className={classes.Text}
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(BasicInfo);
