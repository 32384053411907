import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import apiCall from "../../../API/Permissions";
import { OnLoadSelect } from "../../../Components/StatesForComponets";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
class createNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { List: [] };
  }
  componentDidMount = async () => {
    try {
      const getEntities = await apiCall.Entities();
      console.log(getEntities);
      if (getEntities.status === "success") {
        this.setState({
          List: getEntities.data.entities,
        });
      } else {
        console.log("server error");
      }
    } catch (e) {
      console.log("error, no city's for you");
    }
  };
  onChange = (event) => {
    this.props.SendToParent(event);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.Table}>
        {this.state.List.length > 0
          ? [
              <FormControl className={classes.Table}>
                <InputLabel id="demo-simple-select-label">
                  Listado de Entidades
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.props.CitySelected}
                  name="CitySelected"
                  onChange={this.onChange}
                >
                  {this.state.List.map((Entity, index) => {
                    return (
                      <MenuItem value={Entity.url} key={Entity.id}>
                        {Entity.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>,
            ]
          : [<OnLoadSelect />]}
      </Grid>
    );
  }
}

export default withStyles(styles)(createNewEvent);
