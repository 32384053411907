import base from "../baseurl";
import token from "../getTk";

const Delete = async (companiesId, goodDeliveryTypeId, state) => {
  console.log(companiesId, goodDeliveryTypeId, state);
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("good-delivery-events/create", {
      json: {
        companiesId,
        goodDeliveryTypeId,
        basics: state.basics,
        registryEndDate: state.registryEndDate,
        registryStartDate: state.registryStartDate,
        startDate: state.startDate,
        endDate: state.endDate,
      },
    })
    .json();
  console.log(Create);
  return Create;
};

export default Delete;
