import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFile from "../../../../Components/Header/UploadFiles";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      title: "",
      url: "",
      text: "",
      img: "",
    };
  }
  componentDidMount = async () => {};
  create = async () => {
    let newElemen = {
      title: this.state.title,
      text: this.state.text,
      url: this.state.url,
      img: this.state.img,
    };
    this.props.Send(newElemen);
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleCallback = (urlFromChild, stateName) => {
    this.setState({
      [stateName]: urlFromChild,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Nueva elemento del carrusel
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <TextField
              label="Titulo Principal"
              margin="normal"
              size="small"
              name="title"
              onChange={this.onChange}
              fullWidth
            />
            <TextField
              label="Resumen"
              margin="normal"
              size="small"
              name="text"
              onChange={this.onChange}
              fullWidth
              multiline
              rows="1"
              variant="outlined"
            />
            <TextField
              label="Link"
              margin="normal"
              size="small"
              name="url"
              onChange={this.onChange}
              fullWidth
            />
            <UploadFile
              Type="Imagen"
              Place="SITIMM_GALLERIES_CARRUSEL"
              parentCallback={this.handleCallback}
              stateNameOnParent="img"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.title && this.state.img
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
