import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CapCatalogoCall from "../../../API/Ceforma/Capacitacion/Catalogo";
import CallUtils from "../../../API/CallUtils";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "90%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
  input: {
    display: "none",
  },
  Actividad: {
    width: "100%",
  },
  MOD: {
    width: "100%",
  },
});

class catalogo extends React.Component {
  state = {
    title: "",
    welcomeText: "",
    TemasLaborales: [],
    TemasSindicales: [],
    TemasRH: [],
    Costos: [],
    costoText: "",

    DialogTemasLaborales: false,
    DialogTemasSindicales: false,
    DialogTemasRH: false,
    DialogCostos: false,

    TopicTitle: "",
    TopicHour: "",
    TopicObjetive: "",
    modulo: [],
    IMGCurso: null,
    DialogEdit: false,
    typeE: "",
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    const Update = await CapCatalogoCall.Update(this.state);
    console.log(Update);
    await this.showAll();
  };

  showAll = async () => {
    const polute = await CapCatalogoCall.showAll();
    console.log(polute);
    this.setState({
      title: polute.title,
      welcomeText: polute.welcomeText,
      TemasLaborales: polute.TemasLaborales,
      TemasSindicales: polute.TemasSindicales,
      TemasRH: polute.TemasRH,
      Costos: polute.Costos,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addTemasLaborales = async (e) => {
    var newelement = {
      title: this.state.TopicTitle,
      hous: this.state.TopicHour,
      objetive: this.state.TopicObjetive,
      modules: this.state.modulo,
      img: this.state.IMGCurso,
    };

    this.setState({
      TemasLaborales: [...this.state.TemasLaborales, newelement],
    });
    this.setState({
      DialogTemasLaborales: false,
      TopicTitle: "",
      TopicHour: "",
      TopicObjetive: "",
      modulo: [],
      IMGCurso: null,
    });
  };

  addTemasRH = async (e) => {
    var newelement = {
      title: this.state.TopicTitle,
      hous: this.state.TopicHour,
      objetive: this.state.TopicObjetive,
      modules: this.state.modulo,
      img: this.state.IMGCurso,
    };

    this.setState({
      TemasRH: [...this.state.TemasRH, newelement],
    });
    this.setState({
      DialogTemasRH: false,
      TopicTitle: "",
      TopicHour: "",
      TopicObjetive: "",
      modulo: [],
      IMGCurso: null,
    });
  };

  addTemasSindicales = async (e) => {
    var newelement = {
      title: this.state.TopicTitle,
      hous: this.state.TopicHour,
      objetive: this.state.TopicObjetive,
      modules: this.state.modulo,
      img: this.state.IMGCurso,
    };

    this.setState({
      TemasSindicales: [...this.state.TemasSindicales, newelement],
    });
    this.setState({
      DialogTemasSindicales: false,
      TopicTitle: "",
      TopicHour: "",
      TopicObjetive: "",
      modulo: [],
      IMGCurso: null,
    });
  };
  addCostos = async (e) => {
    var newelement = {
      Punto: this.state.costoText,
    };

    this.setState({
      Costos: [...this.state.Costos, newelement],
    });
    this.setState({
      DialogCostos: false,
      costoText: "",
    });
  };

  addModuo = async (e) => {
    var newelement = {
      modulo: this.state.ModuloText,
    };

    this.setState({
      modulo: [...this.state.modulo, newelement],
    });
    this.setState({
      ModuloText: "",
    });
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  addIMGPrincipal = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_CATALOGO_PRINCIPAL_"
    );
    this.setState({
      IMGCurso: principal,
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    return upload.data.location;
  };

  Editar = (ind, type, title, horas, text, module, img, e) => {
    this.setState({
      index: 0,
      typeE: "",
      TopicTitle: "",
      TopicHour: "",
      TopicObjetive: "",
      modulo: "",
      IMGCurso: null,
    });

    this.setState({
      DialogEdit: true,
      index: ind,
      typeE: type,
      TopicTitle: title,
      TopicHour: horas,
      TopicObjetive: text,
      modulo: module,
      IMGCurso: img,
    });
  };

  addIMGPrincipal = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_CONVENIOS_PRINCIPAL_"
    );
    this.setState({
      IMGCurso: principal,
    });
  };

  EditConvenio = () => {
    var newelement = {
      title: this.state.TopicTitle,
      hous: this.state.TopicHour,
      objetive: this.state.TopicObjetive,
      modules: this.state.modulo,
      img: this.state.IMGCurso,
    };

    switch (this.state.typeE) {
      case "TemasLaborales":
        let TemasL = [...this.state.TemasLaborales];
        TemasL[this.state.index] = newelement;

        this.setState({
          TemasLaborales: TemasL,
        });
        break;
      case "TemasSindicales":
        let TemasS = [...this.state.TemasSindicales];
        TemasS[this.state.index] = newelement;

        this.setState({
          TemasSindicales: TemasS,
        });
        break;
      case "TemasRH":
        let NPrograms = [...this.state.TemasRH];
        NPrograms[this.state.index] = newelement;

        this.setState({
          TemasRH: NPrograms,
        });
        break;
      default: {
        this.setState({
          DialogEdit: false,
          Titulo: "",
          Texto: "",
          imgPrincipal: null,
          Imgs: [],
          index: 0,
        });
      }
    }

    this.setState({
      DialogEdit: false,
      Titulo: "",
      Texto: "",
      imgPrincipal: null,
      Imgs: [],
      index: 0,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography className={classes.Section}>Catalogo</Typography>
            <Grid
              item
              xs={7}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo de la pagina"
                name="title"
                value={this.state.title}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto Principal"
                className={classes.text}
                margin="normal"
                name="welcomeText"
                multiline
                rows="8"
                onChange={this.onChange}
                value={this.state.welcomeText}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Temas Laborales</Typography>
            {this.state.TemasLaborales.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tema</TableCell>
                      <TableCell>Horas</TableCell>
                      <TableCell>Objetivo</TableCell>
                      <TableCell>Módulos</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Editar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.TemasLaborales.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.hous}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.objetive}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.modules.length}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(
                                  this,
                                  index,
                                  "TemasLaborales"
                                )}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "TemasLaborales"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Editar.bind(
                                  this,
                                  index,
                                  "TemasLaborales",
                                  Article.title,
                                  Article.hous,
                                  Article.objetive,
                                  Article.modules,
                                  Article.img
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "TemasLaborales"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogTemasLaborales")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>
              Temas Sindicales
            </Typography>
            {this.state.TemasSindicales.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tema</TableCell>
                      <TableCell>Horas</TableCell>
                      <TableCell>Objetivo</TableCell>
                      <TableCell>Módulos</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Editar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.TemasSindicales.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.hous}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.objetive}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.modules.length}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(
                                  this,
                                  index,
                                  "TemasSindicales"
                                )}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "TemasSindicales"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Editar.bind(
                                  this,
                                  index,
                                  "TemasSindicales",
                                  Article.title,
                                  Article.hous,
                                  Article.objetive,
                                  Article.modules,
                                  Article.img
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "TemasSindicales"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogTemasSindicales")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>
              Temas de Desarrollo Humano
            </Typography>
            {this.state.TemasRH.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tema</TableCell>
                      <TableCell>Horas</TableCell>
                      <TableCell>Objetivo</TableCell>
                      <TableCell>Módulos</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Editar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.TemasRH.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.hous}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.objetive}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.modules.length}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "TemasRH")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(this, index, "TemasRH")}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Editar.bind(
                                  this,
                                  index,
                                  "TemasRH",
                                  Article.title,
                                  Article.hous,
                                  Article.objetive,
                                  Article.modules,
                                  Article.img
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "TemasRH"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogTemasRH")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>
          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Costos</Typography>
            {this.state.Costos.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Punto</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Costos.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.Punto}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "Costos")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(this, index, "Costos")}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "Costos"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogCostos")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>
          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.DialogTemasLaborales}
          onClose={this.DialogClose.bind(this, "DialogTemasLaborales")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Temas Laborales</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="TopicTitle"
                  value={this.state.TopicTitle}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Horas"
                  name="TopicHour"
                  value={this.state.TopicHour}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Objetivo"
                  name="TopicObjetive"
                  value={this.state.TopicObjetive}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <div className={classes.imgDiv}>
                  <Typography>Imagen de portada</Typography>
                </div>

                {this.state.IMGCurso !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.IMGCurso}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGPrincipal}
                      className={classes.input}
                      id="Laborales"
                      required
                    />
                    <label htmlFor="Laborales">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>

                <div className={classes.SectionTable}>
                  <Typography className={classes.Section}>
                    Lista de Módulos
                  </Typography>
                  {this.state.modulo.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Subir</TableCell>
                            <TableCell>Bajar</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.modulo.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    {Article.modulo}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="primary"
                                      onClick={this.Up.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowUp />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.Down.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowDown />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}></Paper>
                  )}
                  <TextField
                    id="standard-basic"
                    label="Nuevo modulo"
                    name="ModuloText"
                    value={this.state.ModuloText}
                    className={classes.Actividad}
                    onChange={this.onChange}
                    multiline
                    rows="2"
                    variant="outlined"
                  />
                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.addModuo}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogTemasLaborales")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addTemasLaborales} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogTemasSindicales}
          onClose={this.DialogClose.bind(this, "DialogTemasSindicales")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Temas Sindicales</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="TopicTitle"
                  value={this.state.TopicTitle}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Horas"
                  name="TopicHour"
                  value={this.state.TopicHour}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Objetivo"
                  name="TopicObjetive"
                  value={this.state.TopicObjetive}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />

                <div className={classes.imgDiv}>
                  <Typography>Imagen de portada</Typography>
                </div>

                {this.state.IMGCurso !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.IMGCurso}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGPrincipal}
                      className={classes.input}
                      id="Sindicales"
                      required
                    />
                    <label htmlFor="Sindicales">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>

                <div className={classes.SectionTable}>
                  <Typography className={classes.Section}>
                    Lista de Módulos
                  </Typography>
                  {this.state.modulo.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Subir</TableCell>
                            <TableCell>Bajar</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.modulo.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    {Article.modulo}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="primary"
                                      onClick={this.Up.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowUp />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.Down.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowDown />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}></Paper>
                  )}
                  <TextField
                    id="standard-basic"
                    label="Nuevo modulo"
                    name="ModuloText"
                    value={this.state.ModuloText}
                    className={classes.Actividad}
                    onChange={this.onChange}
                    multiline
                    rows="2"
                    variant="outlined"
                  />
                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.addModuo}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogTemasSindicales")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addTemasSindicales} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogTemasRH}
          onClose={this.DialogClose.bind(this, "DialogTemasRH")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Temas de RH</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="TopicTitle"
                  value={this.state.TopicTitle}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Horas"
                  name="TopicHour"
                  value={this.state.TopicHour}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Objetivo"
                  name="TopicObjetive"
                  value={this.state.TopicObjetive}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />

                <div className={classes.imgDiv}>
                  <Typography>Imagen de portada</Typography>
                </div>

                {this.state.IMGCurso !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.IMGCurso}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGPrincipal}
                      className={classes.input}
                      id="RH"
                      required
                    />
                    <label htmlFor="RH">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>

                <div className={classes.SectionTable}>
                  <Typography className={classes.Section}>
                    Lista de Módulos
                  </Typography>
                  {this.state.modulo.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Subir</TableCell>
                            <TableCell>Bajar</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.modulo.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    {Article.modulo}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="primary"
                                      onClick={this.Up.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowUp />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.Down.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowDown />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}></Paper>
                  )}
                  <TextField
                    id="standard-basic"
                    label="Nuevo modulo"
                    name="ModuloText"
                    value={this.state.ModuloText}
                    className={classes.MOD}
                    onChange={this.onChange}
                    multiline
                    rows="6"
                    variant="outlined"
                  />
                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.addModuo}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogTemasRH")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addTemasRH} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogCostos}
          onClose={this.DialogClose.bind(this, "DialogCostos")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Costo</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Punto en costos"
                  name="costoText"
                  value={this.state.costoText}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogCostos")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addCostos} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogEdit}
          onClose={this.DialogClose.bind(this, "DialogEdit")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">EDITAR</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="TopicTitle"
                  value={this.state.TopicTitle}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Horas"
                  name="TopicHour"
                  value={this.state.TopicHour}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Objetivo"
                  name="TopicObjetive"
                  value={this.state.TopicObjetive}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />

                <div className={classes.imgDiv}>
                  <Typography>Imagen de portada</Typography>
                </div>

                {this.state.IMGCurso !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.IMGCurso}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGPrincipal}
                      className={classes.input}
                      id="RH"
                      required
                    />
                    <label htmlFor="RH">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>

                <div className={classes.SectionTable}>
                  <Typography className={classes.Section}>
                    Lista de Módulos
                  </Typography>
                  {this.state.modulo.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Subir</TableCell>
                            <TableCell>Bajar</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.modulo.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    {Article.modulo}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="primary"
                                      onClick={this.Up.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowUp />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.Down.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <ArrowDown />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "modulo"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}></Paper>
                  )}
                  <TextField
                    id="standard-basic"
                    label="Nuevo modulo"
                    name="ModuloText"
                    value={this.state.ModuloText}
                    className={classes.MOD}
                    onChange={this.onChange}
                    multiline
                    rows="6"
                    variant="outlined"
                  />
                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.addModuo}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogEdit")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.EditConvenio} color="primary">
              Modificar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(catalogo);
