import React, { Component } from "react";
import { connect } from "react-redux";
import BulletinsC from "../../API/Bulletins";
import {
  Typography,
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Grid,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBoxIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
import List from "../../API/Companies";
import DeleteIcon from "@material-ui/icons/Delete";
import UploadFile from "../../Components/Header/UploadFiles";
import toasType from "../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  paperFilter: {
    width: "90%",
    padding: "1%",
    marginBottom: "2%",
  },
  formControl: {
    width: "50%",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompaniesList: [],
      Bulletin: [],

      readOnly: true,

      EditTitle: "",
      EditDescrip: "",
      EditPdfName: "",
      EditPdfCont: "",
      EditPdfImg: "",
      EditUuid: "",

      DialogEdit: false,
      DialogCreate: false,

      NewTitle: "",
      NewDescrip: "",
      NewPdfName: "",
      NewPdfImg: null,
      NewPdfCont: "",

      onSave: false,
      CompanieSelected: null,
      SelectedCompanie: "",

      onLoad: false,
      onDelete: false,
      onErrorFetch: false,
      Selected: false,
      links: [],
      DialogNewLink: false,
      NewLink: "",
    };
  }
  fetchBulletins = async (UuidCompanie) => {
    try {
      this.setState({
        onLoad: false,
      });
      const Bulletins = await BulletinsC.ShowAll(UuidCompanie);

      if (Bulletins.status === "success") {
        this.setState({
          Bulletin: Bulletins.data.bulletins,
          onLoad: true,
        });
      } else {
        this.setState({
          onErrorFetch: true,
        });
        console.log(Bulletins);
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchCompanyList = async () => {
    try {
      const CompanyList = await List.showAllNotSta();
      if (CompanyList) {
        console.log("Lista", CompanyList);
        this.setState({
          CompaniesList: CompanyList,
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount = async () => {
    if (this.props.userType === 45298 || this.props.userType === 72468) {
      await this.fetchCompanyList();
    }
  };

  Edit = (bolletin) => {
    this.setState({
      EditTitle: bolletin.title,
      EditDescrip: bolletin.description,
      EditPdfImg: bolletin.img,
      EditPdfCont: bolletin.pdf,
      EditUuid: bolletin.uuid,
      links: bolletin.links.split(","),
      DialogEdit: true,
    });
  };

  NewBolletin = () => {
    this.setState({
      DialogCreate: true,
      NewTitle: "",
      NewDescrip: "",
      NewPdfName: "",
      NewPdfCont: "",
      NewPdfImg: null,
      links: [],
    });
  };

  Delete = async (Uuid) => {
    try {
      const Delete = await BulletinsC.Delete(Uuid);
      if (Delete.status === "success") {
        toasType.success("Boletín Borrado");
        await this.fetchBulletins(this.state.SelectedCompanie);
        this.cleanState();
      } else {
        console.log();
        toasType.error(
          " No fue posible Borrar el comunicado seleccionado, ServerSide Error"
        );
      }
    } catch (e) {
      console.log(e);
      toasType.error(
        " No fue posible Borrar el comunicado seleccionado, FrontSide Error"
      );
    }
  };
  Update = async () => {
    try {
      const Update = await BulletinsC.Update(this.state);
      if (Update.status === "success") {
        toasType.success("Boletín Modificado");
        await this.fetchBulletins(this.state.SelectedCompanie);
        this.cleanState();
      } else {
        toasType.error(
          " No fue posible modificar el comunicado seleccionado, ServerSide Error"
        );
      }
    } catch (e) {
      console.log(e);
      toasType.error(
        " No fue posible modificar el comunicado seleccionado, FrontSide Error"
      );
    }
  };
  Create = async () => {
    if (this.state.NewPdfCont === "") {
      toasType.error("Los comunicados requieren una imagen de portada");
    } else {
      try {
        const Create = await BulletinsC.Create(this.state);
        console.log(Create);
        if (Create.status === "success") {
          toasType.success("Comunicado Creado, Refrescando información");
          await this.fetchBulletins(this.state.SelectedCompanie);
          this.cleanState();
        } else {
          toasType.error("Error en la creación, ServerSide");
        }
      } catch (e) {
        console.log(e);
        toasType.error("Error en la creación, FrontSide");
      }
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  DialogClose = async (Name) => {
    this.setState({ [Name]: false });
  };
  handleChangeState = async (event) => {
    this.setState({ Selected: true });
    this.setState({ SelectedCompanie: event.target.value });
    await this.fetchBulletins(event.target.value);
  };

  NewLink = () => {
    this.setState({
      DialogNewLink: true,
      NewLink: "",
    });
  };

  addLink = () => {
    this.setState({
      links: [...this.state.links, this.state.NewLink],
      DialogNewLink: false,
    });
  };

  deleteLink = (i) => {
    let value = this.state.links.filter((item, j) => i !== j);
    this.setState({ links: value });
  };

  handleCallback = (urlFromChild, stateName) => {
    this.setState({
      [stateName]: urlFromChild,
    });
  };

  cleanState = () => {
    this.setState({
      DialogCreate: false,
      NewTitle: "",
      NewDescrip: "",
      NewPdfName: "",
      NewPdfCont: "",
      links: [],
      EditTitle: "",
      EditDescrip: "",
      EditPdfName: "",
      EditPdfCont: "",
      EditUuid: "",
      DialogEdit: false,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.paperFilter}>
          <Typography
            variant="h5"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            Selección de empresa
          </Typography>

          {this.props.Companies.length > 0
            ? [
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Listado de Empresas
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.SelectedCompanie}
                    name="stateUuid"
                    onChange={this.handleChangeState}
                    //inputProps={{ readOnly: true }}
                  >
                    {this.props.Companies.map((companie, index) => {
                      return (
                        <MenuItem value={companie.uuid}>
                          {companie.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>,
              ]
            : [
                this.state.CompaniesList.length > 0
                  ? [
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Listado de Empresas
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.SelectedCompanie}
                          name="stateUuid"
                          onChange={this.handleChangeState}
                          //inputProps={{ readOnly: true }}
                        >
                          {this.state.CompaniesList.map((companie, index) => {
                            return (
                              <MenuItem value={companie.uuid}>
                                {companie.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>,
                    ]
                  : [
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        component="p"
                        className={classes.Title}
                      >
                        Sin empresas Asignadas
                      </Typography>,
                    ],
              ]}
        </Paper>
        <Paper className={classes.paper}>
          <Grid container className={classes.Grid}>
            <Grid container className={classes.Grid}>
              {this.state.Selected === true ? (
                <Grid
                  item
                  xs={12}
                  className={classes.Grid}
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    component="p"
                    className={classes.Title}
                  >
                    Boletines por Empresa
                  </Typography>

                  {this.state.onLoad
                    ? [
                        this.state.Bulletin.length > 0 ? (
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={3}
                          >
                            {this.state.Bulletin.map((Article, index) => {
                              return (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <Card className={classes.root}>
                                    <CardActionArea>
                                      <CardMedia
                                        className={classes.media}
                                        image={Article.img}
                                        title={Article.pdf.name}
                                      />
                                      <CardContent>
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="h2"
                                        >
                                          {Article.title}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          component="p"
                                        >
                                          {Article.description}
                                        </Typography>
                                        {Article.pdf ? (
                                          <Button size="small" color="primary">
                                            <a href={Article.pdf}>Documento</a>
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                        {Article.links &&
                                        Article.links.length > 0
                                          ? [
                                              <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                              >
                                                links
                                              </Typography>,
                                            ]
                                          : []}

                                        {Article.links &&
                                        Article.links.length > 0
                                          ? [
                                              Article.links
                                                .split(",")
                                                .map((link, index) => {
                                                  return (
                                                    <a href={link}>
                                                      <Button
                                                        size="small"
                                                        color="primary"
                                                      >
                                                        {index + 1}
                                                      </Button>
                                                    </a>
                                                  );
                                                }),
                                            ]
                                          : [<></>]}
                                      </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                      <Button
                                        size="small"
                                        color="primary"
                                        onClick={this.Edit.bind(this, Article)}
                                      >
                                        Editar
                                      </Button>
                                      <Button
                                        size="small"
                                        color="secondary"
                                        onClick={this.Delete.bind(
                                          this,
                                          Article.uuid
                                        )}
                                      >
                                        Eliminar
                                      </Button>
                                    </CardActions>
                                  </Card>
                                </Grid>
                              );
                            })}
                          </Grid>
                        ) : (
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            lassName={classes.SkeletonGrid}
                            spacing={3}
                          >
                            <Typography
                              variant="body"
                              color="textPrimary"
                              component="p"
                              className={classes.load}
                            >
                              No hay Boletines
                            </Typography>
                          </Grid>
                        ),
                      ]
                    : [
                        this.state.onErrorFetch
                          ? [
                              <>
                                <Typography
                                  variant="body"
                                  color="textPrimary"
                                  component="p"
                                  className={classes.load}
                                >
                                  Error en la descarga de la información, este
                                  error puede deberse a su internet
                                </Typography>
                                <Typography
                                  variant="body"
                                  color="textPrimary"
                                  component="p"
                                  className={classes.load}
                                >
                                  O factores externos, intente presionando el
                                  botón de Re-intentar o refrescando la pagina
                                </Typography>
                                <Typography
                                  variant="body"
                                  color="textPrimary"
                                  component="p"
                                  className={classes.load}
                                >
                                  Si los problemas continúan comuníquese a la
                                  oficina principal
                                </Typography>
                                <div className={classes.load}></div>
                              </>,
                            ]
                          : [
                              <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                lassName={classes.SkeletonGrid}
                                spacing={3}
                              >
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <Skeleton variant="text" animation="wave" />
                                  <Skeleton
                                    variant="rect"
                                    width={210}
                                    height={118}
                                    animation="wave"
                                  />
                                  <Skeleton variant="text" animation="wave" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <Skeleton variant="text" animation="wave" />
                                  <Skeleton
                                    variant="rect"
                                    width={210}
                                    height={118}
                                    animation="wave"
                                  />
                                  <Skeleton variant="text" animation="wave" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <Skeleton variant="text" animation="wave" />
                                  <Skeleton
                                    variant="rect"
                                    width={210}
                                    height={118}
                                    animation="wave"
                                  />
                                  <Skeleton variant="text" animation="wave" />
                                </Grid>
                              </Grid>,
                            ],
                      ]}

                  <div className={classes.add}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.addBTN}
                      onClick={this.NewBolletin}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </Grid>
              ) : (
                [
                  <Typography
                    variant="body"
                    color="textPrimary"
                    component="p"
                    className={classes.load}
                  >
                    Seleccione una empresa para continuar
                  </Typography>,
                ]
              )}
            </Grid>

            <Dialog
              open={this.state.DialogCreate}
              onClose={this.DialogClose.bind(this, "DialogCreate")}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              fullWidth="sm"
            >
              <DialogTitle id="form-dialog-title">Nuevo Comunicado</DialogTitle>
              {this.state.onSave === true ? (
                <DialogContent>
                  <div className={classes.load}>
                    <CircularProgress />
                  </div>
                  <div className={classes.load}>Procesando Acción</div>
                </DialogContent>
              ) : (
                <DialogContent>
                  <Grid container className={classes.GridD}>
                    <TextField
                      label="Titulo"
                      name="NewTitle"
                      value={this.state.NewTitle}
                      className={classes.Text}
                      onChange={this.onChange}
                    />
                    <TextField
                      label="Descripción"
                      name="NewDescrip"
                      value={this.state.NewDescrip}
                      className={classes.Text}
                      onChange={this.onChange}
                    />
                    <UploadFile
                      Type="Imagen"
                      Place="SITIMM_BULLETINS_Private_IMG"
                      parentCallback={this.handleCallback}
                      stateNameOnParent="NewPdfImg"
                    />
                    <UploadFile
                      Type="Documento"
                      Place="SITIMM_BULLETINS_Private_DOC"
                      parentCallback={this.handleCallback}
                      stateNameOnParent="NewPdfCont"
                    />
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      component="p"
                      className={classes.Title}
                    >
                      Links complementarios
                    </Typography>

                    {this.state.links.length > 0
                      ? [
                          <Paper className={classes.Table}>
                            <Table size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Link</TableCell>
                                  <TableCell>Eliminar</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.links.map((Article, index) => {
                                  return (
                                    <React.Fragment>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          {Article}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Button
                                            color="secondary"
                                            onClick={this.deleteLink.bind(
                                              this,
                                              index
                                            )}
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Paper>,
                        ]
                      : [
                          <div className={classes.imgDiv}>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              component="p"
                              className={classes.Title}
                            >
                              Sin links
                            </Typography>
                          </div>,
                        ]}
                    <div className={classes.Remp}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                        onClick={this.NewLink}
                      >
                        <AddBoxIcon />
                      </Button>
                    </div>
                  </Grid>
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  onClick={this.DialogClose.bind(this, "DialogCreate")}
                  color="secondary"
                >
                  Cancelar
                </Button>
                <Button onClick={this.Create} color="primary">
                  Crear Comunicado
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.DialogEdit}
              onClose={this.DialogClose.bind(this, "DialogEdit")}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              fullWidth="sm"
            >
              <DialogTitle id="form-dialog-title">
                Editor de Comunicados
              </DialogTitle>
              {this.state.onSave === true ? (
                <DialogContent>
                  <div className={classes.load}>
                    <CircularProgress />
                  </div>
                  <div className={classes.load}>Procesando Acción</div>
                </DialogContent>
              ) : (
                <DialogContent>
                  <Grid container className={classes.GridD}>
                    <TextField
                      label="Titulo"
                      name="EditTitle"
                      value={this.state.EditTitle}
                      className={classes.Text}
                      onChange={this.onChange}
                    />
                    <TextField
                      label="Descripción"
                      name="EditDescrip"
                      value={this.state.EditDescrip}
                      className={classes.Text}
                      onChange={this.onChange}
                    />

                    <UploadFile
                      Type="Imagen"
                      Place="SITIMM_BULLETINS_GENERAL_IMG"
                      parentCallback={this.handleCallback}
                      stateNameOnParent="EditPdfImg"
                      PrevImg={this.state.EditPdfImg}
                    />
                    <UploadFile
                      Type="Documento"
                      Place="SITIMM_BULLETINS_GENERAL_DOC"
                      parentCallback={this.handleCallback}
                      stateNameOnParent="EditPdfCont"
                      PrevImg={this.state.EditPdfCont}
                    />

                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      component="p"
                      className={classes.Title}
                    >
                      Links complementarios
                    </Typography>

                    {this.state.links.length > 0
                      ? [
                          <Paper className={classes.Table}>
                            <Table size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Link</TableCell>
                                  <TableCell>Eliminar</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.links.map((Article, index) => {
                                  return (
                                    <React.Fragment>
                                      <TableRow>
                                        <TableCell component="th" scope="row">
                                          {Article}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          <Button
                                            color="secondary"
                                            onClick={this.deleteLink.bind(
                                              this,
                                              index
                                            )}
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Paper>,
                        ]
                      : [
                          <div className={classes.imgDiv}>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              component="p"
                              className={classes.Title}
                            >
                              Sin links
                            </Typography>
                          </div>,
                        ]}
                    <div className={classes.Remp}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                        onClick={this.NewLink}
                      >
                        <AddBoxIcon />
                      </Button>
                    </div>
                  </Grid>
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  onClick={this.DialogClose.bind(this, "DialogEdit")}
                  color="secondary"
                >
                  Cancelar
                </Button>
                <Button onClick={this.Update} color="primary">
                  Editar Comunicado
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.DialogNewLink}
              onClose={this.DialogClose.bind(this, "DialogNewLink")}
              aria-labelledby="form-dialog-title"
              maxWidth="xs"
              fullWidth="xs"
            >
              <DialogTitle id="form-dialog-title">Nuevo Link</DialogTitle>
              <DialogContent>
                <Grid container className={classes.GridD}>
                  <TextField
                    label="Link"
                    name="NewLink"
                    value={this.state.NewLink}
                    className={classes.Text}
                    onChange={this.onChange}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.DialogClose.bind(this, "DialogNewLink")}
                  color="secondary"
                >
                  Cancelar
                </Button>
                <Button onClick={this.addLink} color="primary">
                  Añadir link
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
