import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import Update from "../../../API/Companies/Update";
import UploadFile from "../../../Components/Header/UploadFiles";

const styles = (theme) => ({
  PaperDialog: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  Radio: {
    margin: "2%",
  },
});

class EditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
      place: false,
      where: "",
      url: "",
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.Info) {
        this.setState({
          info: this.props.Info,
          place: this.props.type,
        });
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
    }
  };

  Save = async () => {
    try {
      const save = await Update(
        this.props.Info,
        null,
        null,
        this.props.type,
        this.state.url
      );
      if (save.status === "success") {
        toastType.success("Guardado");
      } else {
        toastType.error("No fue posible Guardar");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log(error);
    }
  };
  ups = (urlFromChild) => {
    this.setState({ url: urlFromChild });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Subir Documento</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Paper className={classes.PaperDialog}>
                <UploadFile
                  Type="Documento"
                  Place={"SITIMM_COMPANY_OFDOC_" + this.props.Info.uuid}
                  parentCallback={this.ups}
                  stateNameOnParent={"99"}
                />
              </Paper>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.Save} color="primary">
              Guardar
            </Button>
            ,
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(EditDialog);
