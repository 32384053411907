import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const styles = (theme) => ({
  Table: {
    width: "100%",
    marginBottom: "8px",
    marginTop: "8px",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function SelectRegis(props) {
  return (
    <FormControl className={props.classes.Table}>
      <InputLabel>Genero de la Búsqueda</InputLabel>
      <Select
        value={props.value}
        onChange={(ev) => {
          props.ParentSet("sex", ev.target.value);
        }}
      >
        <MenuItem value={"AMBOS"} key={1}>
          Ambos
        </MenuItem>
        <MenuItem value={"HOMBRE"} key={2}>
          Hombres
        </MenuItem>
        <MenuItem value={"MUJER"} key={3}>
          Mujeres
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default withStyles(styles)(SelectRegis);
