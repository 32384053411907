import base from "../baseurl";
import token from "../getTk";

const showAll = async (rfc, name, lastNames) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("user-profiles/search", {
      json: {
        rfc,
        name,
        lastNames,
      },
    })
    .json();
  return Create;
};

export default showAll;
