import base from "../../baseurl";
import token from "../../getTk";

const Create = async (disciplineId, documentTypeId, url) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log(disciplineId, documentTypeId, url);
  const newS = await api
    .post("sport-documents/create", {
      json: { disciplineId, documentTypeId, url },
      timeout: false,
    })
    .json();
  return newS;
};

export default Create;
