import States from "./States";
import Citys from "./Citys";
import Country from "./Country";
import localplaces from "./localplaces";
import Comunication from "./Comunication";
import Types from "./Types";
import UploadNewImg from "./UploadNewImg";
import Get_Img from "./Get_Imgs";
import DeleteImg from "./DeleteImg";
import Reports from "./Reports";
import GtoCitys from "./GtoCitys";
import CivilStatesTypes from "./CivilStatesTypes";
import SchoolarTypes from "./SchoolarTypes";

const Utils = {
  States,
  Citys,
  Country,
  localplaces,
  Comunication,
  Types,
  UploadNewImg,
  Get_Img,
  DeleteImg,
  Reports,
  GtoCitys,
  SchoolarTypes,
  CivilStatesTypes,
};

export default Utils;

export {
  States,
  Citys,
  Country,
  localplaces,
  Comunication,
  Types,
  UploadNewImg,
  Get_Img,
  DeleteImg,
  Reports,
  GtoCitys,
  SchoolarTypes,
  CivilStatesTypes,
};
