import React from "react";

import {
  Typography,
  Button,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  Grid,
} from "@material-ui/core";
///import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import toastType from "../../API/CallUtils/tostyNotification";
import Api from "../../API/CompaniesFormsQuestions";
import AddIcon from "@material-ui/icons/Add";
import DialogCreateEvent from "./Dialogs/Create";
import DialogDetailsEvent from "./Dialogs/DetailsDialog";
import AddBoxIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Skeleton: {
    width: "100%",
    height: "400px",
    borderRadius: "10px 10px 10px 10px",
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListEvents: [],
      onLoad: true,
      Answer: null,
    };
  }
  componentDidMount = async () => {
    this.fetchlist();
  };

  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.ShowAll();
      console.log("lo rico", Deli);
      if (Deli.status === "success") {
        this.setState({
          ListEvents: Deli.data.companyFormQuestions,
          onLoad: false,
          onError: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };

  delete = async (id) => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.Delete(id);
      console.log(Deli);
      if (Deli.status === "success") {
        toastType.success("Se borro con éxito");
        await this.fetchlist();
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "SSE No fue Posible Eliminar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Dialog = (a, b) => {
    this.setState({ [a]: !this.state[a], Answer: b ? b : null });
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <>
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        </>
      );
    } else if (this.state.onError) {
      return (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            Error
          </Typography>
          <Paper className={classes.NoItemPaper}>
            <Typography variant="body1" color="textPrimary" component="p">
              Error
            </Typography>
          </Paper>
          ,
        </>
      );
    } else {
      return (
        <div className={classes.paper}>
          <Typography
            align="Center"
            variant="h5"
            component="p"
            className={classes.Subtitle}
          >
            Listado de Preguntas
          </Typography>

          {this.state.ListEvents.length > 0 ? (
            <Paper className={classes.Table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Pregunta</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Respuestas</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.ListEvents.map((Article) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article.uuid}>
                          <TableCell component="th" scope="row">
                            {Article.content}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Article.CompanyFormQuestionType.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Article.createdAt.split("T")[0]}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.btn}
                              onClick={this.Dialog.bind(
                                this,
                                "EditDialog",
                                Article
                              )}
                            >
                              <EditIcon />
                            </Button>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={this.delete.bind(this, Article.id)}
                              className={classes.btn}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.Margin}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.Dialog.bind(this, "CreateDialog")}
                >
                  <AddIcon />
                </Button>
              </Grid>
              {this.state.CreateDialog
                ? [
                    <DialogCreateEvent
                      DialogOpen={this.state.CreateDialog}
                      DialogClose={this.Dialog.bind(this, "CreateDialog")}
                      Refresh={this.Refresh}
                    />,
                  ]
                : [<></>]}
              {this.state.EditDialog
                ? [
                    <DialogDetailsEvent
                      DialogOpen={this.state.EditDialog}
                      DialogClose={this.Dialog.bind(this, "EditDialog")}
                      Answer={this.state.Answer}
                      Refresh={this.Refresh}
                    />,
                  ]
                : [<></>]}
            </Paper>
          ) : (
            <Paper className={classes.NoItemPaper}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.Dialog.bind(this, "CreateDialog")}
                className={classes.AddCandidate}
              >
                <AddBoxIcon />
              </Button>
              {this.state.CreateDialog
                ? [
                    <DialogCreateEvent
                      DialogOpen={this.state.CreateDialog}
                      DialogClose={this.Dialog.bind(this, "CreateDialog")}
                      Refresh={this.Refresh}
                    />,
                  ]
                : [<></>]}
            </Paper>
          )}
        </div>
      );
    }
  }
}

export default withStyles(styles)(Edit);
