import base from "../baseurl";
import token from "../getTk"

const Citys = async (Stateid) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const Citys= await api.get("cities/index/"+Stateid).json();
    return Citys.data.city ;
    
  };
  
  export default  Citys ;