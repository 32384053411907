import base from "../baseurl";
import token from "../getTk";

const Show = async (
  goodDeliveryParticipantId,
  goodDeliveryParticipantStateId
) => {
  console.log(goodDeliveryParticipantId, goodDeliveryParticipantStateId);
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const status = await api
    .post("good-delivery-participants/update", {
      json: { goodDeliveryParticipantId, goodDeliveryParticipantStateId },
    })
    .json();
  return status;
};

export default Show;
