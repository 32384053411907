import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SeccionsCall from "../../../API/Sections";
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: "#2980b9",
  },
  paper: {
    width: "100%",
    height: "50vh",
  },
  RootGrid: {
    width: "100%",
    height: "80%",
  },
  FootGrid: {
    width: "100%",
    height: "15%",
  },
  SubmitDiv: {
    width: "99%",
    display: "flex",
    justifyContent: "end",
    maxHeight: "9%",
    paddingBottom: "1%",
  },
  Grid: {
    padding: "2%",
    overflow: "auto",
  },
  Name: {
    width: "100%",
    marginTop: "3%",
  },
  btnAsesor: {
    width: "90%",
    marginTop: "3%",
    marginLeft: "5%",
    marginRight: "5%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  GridCompanies: {
    maxHeight: "100%",
    overflow: "auto",
  },
  TableCompany: {
    marginTop: "5%",
    maxHeight: "90%",
  },
  List: {
    width: "100%",
    maxHeight: "50vh",
    overflow: "auto",
  },
  ListIteam: {
    width: "90%",
    margin: "2%",
  },
  text: {
    width: "70%",
    maxWidth: "70%",
  },
  title: {
    marginBottom: "3%",
  },
  TableArea: {
    maxHeight: "450px",
    overflow: "auto",
  },
};

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { AsesorCompanies: [], NotAsesorCompanies: [] };
  }
  componentDidMount = async () => {
    this.setState({
      NotAsesorCompanies: this.props.not,
      AsesorCompanies: this.props.add,
    });
  };
  DeleteCompany = (index, Uuid, name, e) => {
    var Nname = name;
    var NUuid = Uuid;
    console.log(Uuid, name);

    const Companies = Object.assign([], this.state.AsesorCompanies);
    Companies.splice(index, 1);
    this.setState({ AsesorCompanies: Companies });

    var notCompanies = { uuid: NUuid, name: Nname };
    this.setState((state) => {
      const NotAsesorCompanies = [...state.NotAsesorCompanies, notCompanies];
      return {
        NotAsesorCompanies,
      };
    });
  };
  NotDeleteCompany = (index, Uuid, name, e) => {
    var Nname = name;
    var NUuid = Uuid;
    console.log(Uuid, name);
    const Companies = Object.assign([], this.state.NotAsesorCompanies);
    Companies.splice(index, 1);
    this.setState({ NotAsesorCompanies: Companies });

    var notCompanies = { uuid: NUuid, name: Nname };
    this.setState((state) => {
      const AsesorCompanies = [...state.AsesorCompanies, notCompanies];
      return {
        AsesorCompanies,
      };
    });
  };
  SaveChanges = async () => {
    console.log("after send", this.state.AsesorCompanies);
    try {
      const SaveCompanies = await SeccionsCall.SaveCompanies(
        this.props.info.Uuid,
        this.state.AsesorCompanies.map((x) => x.uuid)
      );

      if (SaveCompanies) {
        toasType.success("Cambios Guardados");
        this.props.Refresh();
      } else {
        toasType.error("Error Guardando los cambios");
      }
    } catch (e) {
      console.log(e);
      toasType.error("Error en la carga de datos");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Agregar/Eliminar empresas
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.RootGrid}>
            <Grid
              item
              xs={6}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              <Typography variant="button" className={classes.title}>
                Eliminar empresas
              </Typography>
              {this.state.AsesorCompanies.length > 0 ? (
                <Paper className={classes.TableArea}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.AsesorCompanies.map((companie, index) => {
                        return (
                          <TableRow key={companie.uuid}>
                            <TableCell component="th" scope="row">
                              {companie.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                className={classes.button}
                                onClick={this.DeleteCompany.bind(
                                  this,
                                  index,
                                  companie.uuid,
                                  companie.name
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              ) : (
                <div className={classes.load}>
                  <p>No hay empresas</p>
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={6}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              <Typography variant="button" className={classes.title}>
                Agregar empresas
              </Typography>
              {this.state.NotAsesorCompanies.length > 0 ? (
                <Paper className={classes.TableArea}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Agregar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.NotAsesorCompanies.map((companie, index) => {
                        return (
                          <TableRow key={companie.uuid}>
                            <TableCell component="th" scope="row">
                              {companie.name}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                className={classes.button}
                                onClick={this.NotDeleteCompany.bind(
                                  this,
                                  index,
                                  companie.uuid,
                                  companie.name
                                )}
                              >
                                <AddIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              ) : (
                <div className={classes.load}>
                  <p>No hay empresas</p>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="Secondary">
            Cancelar
          </Button>
          <Button onClick={this.SaveChanges} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
