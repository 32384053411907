import React from "react";
import { withStyles } from "@material-ui/core/styles";
import OfficeCall from "../../../API/Ceforma/Oficinas";
import CallUtils from "../../../API/CallUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "90%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
    height: "10%",
  },
  input: {
    display: "none",
  },
  Actividad: {
    width: "100%",
  },
});

class Oficinas extends React.Component {
  state = {
    Oficinas: [],
    Imgs: [],
    Url: "",
    Name: "",
    Address: "",
    Phone: "",
    DialogOficina: false,
    Editar: false,
    index: 0,
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    const Update = await OfficeCall.Update(this.state);
    console.log(Update);
    await this.showAll();
  };

  showAll = async () => {
    const polute = await OfficeCall.showAll();
    console.log(polute);
    this.setState({
      Oficinas: polute.Oficinas,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addOficina = () => {
    var newelement = {
      Nombre: this.state.Name,
      Direccion: this.state.Address,
      Telefono: this.state.Phone,
      Imagenes: this.state.Imgs,
    };
    this.setState({
      Oficinas: [...this.state.Oficinas, newelement],
    });

    let NPrograms = [...this.state.Oficinas];

    NPrograms[this.state.index] = newelement;

    this.setState({
      Oficinas: NPrograms,
    });

    this.setState({
      DialogOficina: false,
      Name: "",
      Address: "",
      Phone: "",
      Imgs: [],
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };

  OficineIMG = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_OFFICE_IMGS"
    );

    var newelement = {
      Url: principal,
    };
    this.setState({
      Imgs: [...this.state.Imgs, newelement],
    });
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    console.log("entro close ", name);
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  edit = async (name, add, tel, img, ind) => {
    this.setState({
      DialogOficina: true,
      Name: name,
      Address: add,
      Phone: tel,
      Imgs: img,
      index: ind,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Oficinas</Typography>
            {this.state.Oficinas.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Dirección</TableCell>
                      <TableCell>Teléfono</TableCell>
                      <TableCell>Numero de Imágenes</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Editar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Oficinas.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.Nombre}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.Direccion}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.Telefono}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.Imagenes.length}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "Oficinas")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "Oficinas"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.edit.bind(
                                  this,
                                  Article.Nombre,
                                  Article.Direccion,
                                  Article.Telefono,
                                  Article.Imagenes,
                                  index
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            {/*<TableCell component="th" scope="row">
                                          <Button color="secondary"
                                            onClick={this.remove.bind(this,index,"Oficinas")}
                                          >
                                              <DeleteIcon />
                                                </Button>
                                            </TableCell> */}
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Dirección</TableCell>
                      <TableCell>Teléfono</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <React.Fragment>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {" "}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {" "}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  </TableBody>
                </Table>
              </Paper>
            )}
          </div>

          <div className={classes.Remp}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.DialogOficina}
          onClose={this.DialogClose.bind(this, "DialogOficina")}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Oficina</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Nombre de la Oficina"
                  name="Name"
                  value={this.state.Name}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Dirección de la Oficina"
                  name="Address"
                  value={this.state.Address}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Teléfono de la oficina"
                  name="Phone"
                  value={this.state.Phone}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <div className={classes.SectionTable2}>
                  <Typography className={classes.Section}>
                    Imágenes de la Oficina
                  </Typography>
                  {this.state.Imgs.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.Imgs.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    <img
                                      src={Article.Url}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      alt=""
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <React.Fragment>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {" "}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {" "}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        </TableBody>
                      </Table>
                    </Paper>
                  )}

                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.OficineIMG}
                      className={classes.input}
                      id="files2"
                      required
                    />
                    <label htmlFor="files2">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogOficina")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addOficina} color="primary">
              Modificar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Oficinas);
