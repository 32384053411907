import base from "../../baseurl";
import token from "../../getTk";

const Create = async (state) => {
  console.log(state.isPrivate);
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const Update = await api
    .post("events/create", {
      timeout: false,
      json: {
        title: state.title,
        eventDate: state.eventDate,
        content: state.description,
        description: state.description,
        img: state.img,
        files: state.Files.toString(),
        place: state.place,
        register: state.register,
        isPrivate: state.isPrivate,
        offersMobility: state.offersMobility,
        eventTypeId: state.eventTypeId,
        enabled: true,
        daysBeforeAnouncment: state.hasConfirmation
          ? state.daysBeforeAnouncment
          : 0,
        busStopsId: state.offersMobility
          ? state.busStopsId.filter(function (item, pos, self) {
              return self.indexOf(item) === pos;
            })
          : [],
        campusesId:
          state.eventTypeId === 3
            ? state.campusesId.filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
              })
            : [],
        virtualUrl: state.eventTypeId === 1 ? [] : state.virtualUrl,
        audience: [state.audience],
        audienceTitles: [""],
      },
    })
    .json();

  return Update;
};

export default Create;
