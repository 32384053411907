import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import Update from "../../../API/Companies/Update";
import IP from "../../../API/Companies/IndustrialPark";

const styles = (theme) => ({
  PaperDialog: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  Radio: {
    margin: "2%",
    width: "51%",
  },
});

class EditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: { company: { hasCommittee: false } },
      commite: false,
      park: [],
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.Info) {
        this.setState({
          info: this.props.Info,
          commite: this.props.Info.company.hasCommittee,
        });
        const park = await IP();
        this.setState({ park: park.industrialParks });
        console.log(park.industrialParks);
      } else {
        toastType.error("No hay datos");
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
    }
  };

  onChange = (event) => {
    var info = { ...this.state.info };
    info.company[event.target.name] = event.target.value;
    this.setState({ info });
  };

  onChangeRadio = (event) => {
    var info = { ...this.state.info };
    info.company.hasCommittee = !this.state.commite;
    this.setState({ info });
    this.setState({ commite: !this.state.commite });
  };

  Save = async () => {
    try {
      const save = await Update(this.state.info.company);
      if (save.status === "success") {
        this.props.Refresh();
        toastType.success("Guardado");
      } else {
        toastType.error("No fue posible Guardar");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Editar</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Paper className={classes.PaperDialog}>
                <TextField
                  label="Nombre"
                  margin="normal"
                  size="small"
                  name="name"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company ? this.state.info.company.name : ""
                  }
                  variant="outlined"
                />
                <TextField
                  label="Teléfono"
                  margin="normal"
                  size="small"
                  name="phone"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company ? this.state.info.company.phone : ""
                  }
                  variant="outlined"
                />
                <TextField
                  label="Contraseña"
                  margin="normal"
                  size="small"
                  name="rhPassword"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company
                      ? this.state.info.company.rhPassword
                      : ""
                  }
                  variant="outlined"
                />
                <TextField
                  label="Fecha de aceptación"
                  margin="normal"
                  size="small"
                  name="acceptanceDate"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company
                      ? this.state.info.company.acceptanceDate
                      : ""
                  }
                  variant="outlined"
                />
                {this.state.park
                  ? [
                      <FormControl className={classes.Radio}>
                        <InputLabel id="demo-simple-select-label">
                          Parques Industriales
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.info.company.industrialPark}
                          name="industrialPark"
                          onChange={this.onChange}
                        >
                          {this.state.park.map((Estado, index) => {
                            return (
                              <MenuItem value={Estado.name} key={Estado.name}>
                                {Estado.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>,
                    ]
                  : [<></>]}

                <FormControl component="fieldset" className={classes.Radio}>
                  <FormLabel component="legend">Tipo de empresa</FormLabel>
                  <RadioGroup
                    margin="normal"
                    aria-label="position"
                    name="hasCommittee"
                    onChange={this.onChangeRadio}
                    value={this.state.commite}
                    row
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label="REGIONAL"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="secondary" />}
                      label="DE INDUSTRIA"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.Save} color="primary">
              Guardar
            </Button>
            ,
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(EditDialog);
