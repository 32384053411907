const initSt = {
  user: {
    token: "",
    accessToken: "",
    ttl: "",
    lastTokenStamp: "",
    auth_in: false,
    firstSetup: false,
    userType: "",
    userUuid: "",
    CompaniesList: [],
    permissions: {},
    TotalCompaniesList: [],
  },
};

const loginReducer = (state = initSt, action) => {
  switch (action.type) {
    case "ADD_AUTH": {
      return {
        ...state,
        user: {
          ...state.user,
          accessToken: action.payload.accessToken,
          userType: action.payload.userType,
          userUuid: action.payload.userUuid,
          auth_in: true,
          CompaniesList: action.payload.companies,
        },
      };
    }
    case "ADD_PERMISSIONS": {
      return {
        ...state,
        user: {
          ...state.user,
          permissions: action.payload,
        },
      };
    }
    case "ADD_LTS": {
      return {
        ...state,
        user: {
          ...state.user,
          lastTokenStamp: action.payload,
        },
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        user: {
          ...state.user,
          token: "",
          accessToken: "",
          ttl: "",
          lastTokenStamp: "",
          auth_in: false,
          firstSetup: false,
          userType: "",
          userUuid: "",
          CompaniesList: [],
          permissions: {},
          TotalCompaniesList: [],
        },
      };
    }

    case "REFRESH_TOKEN": {
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload,
        },
      };
    }

    case "REFRESH_TOKEN_FAIL": {
      return {
        ...state,
        user: {
          ...state.user,
          token: "",
          accessToken: "",
          ttl: "",
          auth_in: false,
        },
      };
    }
    case "ADD_COMPANIES_LIST": {
      return {
        ...state,
        user: {
          ...state.user,
          TotalCompaniesList: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
