import base from "../../baseurl";
import token from "../../getTk"

const Update = async (state) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
  // console.log(state)
    const Update = await api.post("pages/update",{json: {
        pageId:1,
        name:"home",
        content:{
            coverPage:{
                ctaText: state.CTAtext,
                img:"",
                mainText: state.principalText,
                mainTitle: state.principalTitle,
                urlCta: state.CTAurl,
            },
            whoWeAre:{
                title:state.secondaryTitle,
                description:state.secondaryText,
                img:state.secondaryIMG,
                motto:state.motto,
                lemma:{
                    mision:{
                        content: state.MisionText,
                        img: state.MisionIMG,
                        title: state.MisionTitle,
                    },
                    values:{
                        content: state.ValoresText,
                        img: state.ValoresIMG,
                        title: state.ValoresTitle,
                    },
                    vision:{
                        content: state.VisionText,
                        img: state.VisionIMG,
                        title: state.VisionTitle,
                    }
                }        
            },
           Consultation:{
                title:state.ConsultationTitle,
                description:state.ConsultationText,
                link:state.ConsultationLink,
                img:state.ConsultationIMG
           },
           Bonus:{
            title:state.BonusTitle,
            description:state.BonusText,
            link:state.BonusLink,
            img:state.BonusIMG
            },
            Banner:{
                title:state.AppTitle,
                description:state.AppText,
                link:state.AppLink,
                img:state.BannerIMG
            },
            Org:{
                OrgTitle:state.OrgTitle,
                OrgText:state.OrgText,
                OrgImg:state.OrgImg,
                OrgDoc:state.OrgDoc
            },
           Programs:
           [
               {
                title:state.programTitle1,
                text:state.programResumen1,
                img:state.programIMG1,
               },
               {
                title:state.programTitle2,
                text:state.programResumen2,
                img:state.programIMG2,
               },
               {
                title:state.programTitle3,
                text:state.programResumen3,
                img:state.programIMG3,
               },
               {
                title:state.programTitle4,
                text:state.programResumen4,
                img:state.programIMG4,
               },
               {
                title:state.programTitle5,
                text:state.programResumen5,
                img:state.programIMG5,
               },
           ]         
        }
    }
    ,
        timeout:800000
}).json();
   //console.log(Update)
    return Update ;
    
  };
  
  export default  Update ;