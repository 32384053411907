import React from "react";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Call from "../../../API/CandidatePositions";
import toastType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "50%",
    padding: "1%",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  btn: {
    width: "100%",
  },
});

class SeletCandidatePosition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Positions: [],
      DialogNewPosition: false,
      titleNewPosition: "",
    };
  }
  componentDidMount = async () => {
    await this.initialFetch(this.props.type);
  };
  onChange = (event) => {
    //console.log("nombre", event.target.name, "valor", event.target.value);
    this.setState({ [event.target.name]: event.target.value, Selected: true });
  };
  initialFetch = async () => {
    try {
      const SeletPositions = await Call.showAll(this.props.type);
      console.log("Llamada SeletPositions", SeletPositions);

      if (SeletPositions.status === "success") {
        this.setState({ Positions: SeletPositions.data.candidatePositions });
      } else {
        toastType.error("Error llamando las candidaturas");
      }
    } catch (error) {
      toastType.error("Error llamado las candidaturas");
    }
  };
  createNewPosition = async () => {
    if (this.state.titleNewPosition !== "") {
      try {
        const NewPosition = await Call.Create(this.state.titleNewPosition);
        this.DialogControl("DialogNewPosition", false);
        console.log("NewPosition", NewPosition);

        if (NewPosition.status === "success") {
          toastType.success("Candidatura creada exitosamente");
          this.initialFetch(this.props.type);
        } else {
          toastType.error("Error Creando la Candidatura");
        }
      } catch (error) {
        toastType.error("Error Creando la Candidatura");
      }
    } else {
      toastType.error("ingrese un nombre valido");
    }
  };
  DialogControl = (DIALOG, VALUE) => {
    if (VALUE) {
      this.setState({ [DIALOG]: true });
    } else {
      this.setState({ [DIALOG]: false });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <FormControl className={classes.paper}>
          <InputLabel id="demo-simple-select-label">Candidaturas</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.props.value}
            name="candidatePosition"
            onChange={this.props.onChange}
          >
            {this.state.Positions.length > 0
              ? [
                  this.state.Positions.map((companie, index) => {
                    return (
                      <MenuItem key={companie.uuid} value={companie}>
                        {companie.name}
                      </MenuItem>
                    );
                  }),
                  <MenuItem value="">
                    <Button
                      onClick={this.DialogControl.bind(
                        this,
                        "DialogNewPosition",
                        true
                      )}
                      className={classes.btn}
                      color="primary"
                    >
                      Agregar Candidatura
                    </Button>
                  </MenuItem>,
                ]
              : [
                  <MenuItem value="">Sin posiciones a mostrar</MenuItem>,
                  <MenuItem value="">
                    <Button
                      onClick={this.DialogControl.bind(
                        this,
                        "DialogNewPosition",
                        true
                      )}
                      className={classes.btn}
                      color="primary"
                    >
                      Agregar Candidatura
                    </Button>
                  </MenuItem>,
                ]}
          </Select>
        </FormControl>
        <Dialog
          open={this.state.DialogNewPosition}
          onClose={this.DialogControl.bind(this, "DialogNewPosition", false)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Nueva Candidatura</DialogTitle>
          <DialogContent>
            <TextField
              label="Titulo"
              name="titleNewPosition"
              value={this.state.titleNewPosition}
              className={classes.btn}
              onChange={this.onChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogControl.bind(
                this,
                "DialogNewPosition",
                false
              )}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              onClick={this.createNewPosition}
              variant="contained"
              color="primary"
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(SeletCandidatePosition);
