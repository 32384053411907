import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import Api from "../../../API/WeeklyFeeds";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  text: {
    width: "80%",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      sitimmFee: null,
      feeAmount: null,
    };
  }
  componentDidMount = async () => {
    this.setState({
      sitimmFee: this.props.data.CompanyAccountingDetail.feeAmount * 100,
      feeAmount: this.props.data.CompanyAccountingDetail.sitimmFee * 100,
    });
  };
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });

      if (
        this.state.feeAmount.length > 0 &&
        !Number.isNaN(this.state.feeAmount) &&
        this.state.sitimmFee.length > 0 &&
        !Number.isNaN(this.state.sitimmFee)
      ) {
        const Deli = await Api.UpdateCompaniesFeeds(
          this.props.data.id,
          this.state.sitimmFee / 100,
          this.state.feeAmount / 100
        );
        console.log(Deli);
        if (Deli.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se cambiado con éxito");
          await this.props.Refresh("EditDialog");
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible cambiar");
        }
      } else {
        if (
          !this.state.Payment.length > 0 ||
          Number.isNaN(this.state.Payment)
        ) {
          toastType.error("No se puede crear una aportación en 0 o con letras");
        }
        this.setState({
          Oncreate: false,
        });
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue Posible Agregar");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Valores</DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <TextField
              label="Cuota"
              margin="normal"
              size="small"
              name="sitimmFee"
              onChange={this.onChange}
              className={classes.text}
              value={this.state.sitimmFee}
            />
            <TextField
              label="Porcentaje"
              margin="normal"
              size="small"
              name="feeAmount"
              onChange={this.onChange}
              className={classes.text}
              value={this.state.feeAmount}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.feeAmount && this.state.sitimmFee
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
