import React, { useState, useEffect } from "react";
import {
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import SeccionsCall from "../../../../API/Sections";
import Skeleton from "@material-ui/lab/Skeleton";
import Utils from "../../../../API/Reports";
import CircularProgress from "@material-ui/core/CircularProgress";
import toasType from "../../../../API/CallUtils/tostyNotification";

const Classes = (theme) => ({
  Paper: {
    width: "100%",
    minHeight: "20vh",
    padding: "12px",
  },
  title: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    padding: "8px",
    fontWeight: "bold",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  formControl: {
    width: "100%",
  },
  Skeleton: {
    width: "100%",
    minHeight: "200px",
  },
});

function AdvisorSons({ classes }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [advisor, setAdvisor] = useState([]);
  const [report, setReport] = useState(undefined);
  const [selectedAdvisor, setSelectedAdvisor] = useState(undefined);
  useEffect(() => {
    fetchPark();
  }, []);
  const fetchPark = async () => {
    const Advisors = await SeccionsCall.showAllNotStat();
    if (Advisors && Advisors.length > 0) {
      const listOfAdvisors = Advisors.map((Advisor) => {
        return {
          name: `${Advisor.section.UserProfile.name} ${Advisor.section.UserProfile.lastNames}`,
          id: Advisor.section.id,
        };
      });
      setAdvisor(listOfAdvisors);
    }
  };
  const getAdvisorReport = async (park) => {
    try {
      setLoading(true);
      const doc = await Utils.AdvisorMoms(park);
      console.log(doc);
      if (doc.data) {
        setLoading(false);
        toasType.success("Su reporte esta listo para Descargar");
        const blob = new Blob(["\ufeff", doc.data]);
        setReport(blob);
      } else {
        toasType.error("No fue posible generar su reporte SSE");
        setLoading(false);
        setError(true);
      }
    } catch (e) {
      toasType.error("No fue posible generar su reporte");
    }
  };
  return (
    <>
      {advisor.length > 0 ? (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Asesores</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedAdvisor}
              name="park"
              onChange={(e) => {
                setReport(false, setSelectedAdvisor(e.target.value));
              }}
            >
              {advisor.map((Estado, index) => {
                return (
                  <MenuItem value={Estado.id} key={Estado.id}>
                    {Estado.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "12px",
            }}
          >
            {report ? (
              <Button
                variant="contained"
                color="primary"
                href={window.URL.createObjectURL(report)}
                disabled={error}
                download="Reporte.csv"
              >
                {error ? "No fue posible Generar su reporte" : "Descargar"}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={selectedAdvisor === undefined}
                onClick={() => {
                  getAdvisorReport(selectedAdvisor);
                }}
              >
                {loading ? (
                  <CircularProgress color="white" />
                ) : (
                  "Generar Reporte"
                )}
              </Button>
            )}
          </div>
        </>
      ) : (
        <Skeleton className={classes.Skeleton} animation="wave" />
      )}
    </>
  );
}

export default withStyles(Classes)(AdvisorSons);
