import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import Update from "../../../API/Companies/Update";
import Places from "../../../API/CallUtils";

const styles = (theme) => ({
  PaperDialog: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  Radio: {
    margin: "2%",
  },
});

class EditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Estados: [],
      Ciudades: [],
      cityUuid: "",
      stateUuid: "",
      info: { company: {} },
    };
  }

  componentDidMount = async () => {
    try {
      if (this.props.Info) {
        this.setState({
          info: this.props.Info,
        });
        await this.ShowAllStates();
        if (this.props.Info.company.State) {
          await this.ShowAllCitys(this.props.Info.company.State.uuid);
        }
        this.setState({
          stateUuid: this.props.Info.company.State
            ? this.props.Info.company.State.uuid
            : "",
          cityUuid: this.props.Info.company.City
            ? this.props.Info.company.City.uuid
            : "",
        });
      } else {
        toastType.error("No hay datos");
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
    }
  };

  onChange = (event) => {
    var info = { ...this.state.info };
    info.company[event.target.name] = event.target.value;
    this.setState({ info });
  };
  onChangeTecnical = (event) => {
    var info = { ...this.state.info };
    info.company.TechnicalDetail[event.target.name] = event.target.value;
    this.setState({ info });
  };

  Save = async () => {
    try {
      const save = await Update(
        this.state.info.company,
        this.state.cityUuid,
        this.state.stateUuid
      );
      if (save.status === "success") {
        toastType.success("Guardado");
        this.props.Refresh();
      } else {
        toastType.error("No fue posible Guardar");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log(error);
    }
  };
  handleChangeState = async (event) => {
    this.setState({ stateUuid: event.target.value, cityUuid: "" });
    await this.ShowAllCitys(event.target.value);
  };

  handleChangeCity = (name) => (event) => {
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  };

  ShowAllStates = async () => {
    const ShowStates = await Places.States();
    this.setState({ Estados: ShowStates });
  };

  ShowAllCitys = async (uuid) => {
    const ShowCitys = await Places.Citys(uuid);
    this.setState({ Ciudades: ShowCitys });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Editar</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Paper className={classes.PaperDialog}>
                <FormControl className={classes.PaperDialog}>
                  <InputLabel id="demo-simple-select-label">Estados</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.stateUuid}
                    name="stateUuid"
                    onChange={this.handleChangeState}
                  >
                    {this.state.Estados.map((Estado, index) => {
                      return (
                        <MenuItem value={Estado.uuid} key={Estado.uuid}>
                          {Estado.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.PaperDialog}>
                  <InputLabel id="demo-simple-select-label">
                    Municipio
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.cityUuid}
                    onChange={this.handleChangeCity("cityUuid")}
                  >
                    <MenuItem value={" "} key={" "}>
                      {" Sin Ciudad "}
                    </MenuItem>
                    {this.state.Ciudades.map((ciudad, index) => {
                      return (
                        <MenuItem value={ciudad.uuid} key={ciudad.uuid}>
                          {ciudad.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  label="País de Origen"
                  margin="normal"
                  size="small"
                  name="country"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company
                      ? this.state.info.company.country
                      : " "
                  }
                  variant="outlined"
                />
                <TextField
                  label="Dirección"
                  margin="normal"
                  size="small"
                  name="address"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company
                      ? this.state.info.company.address
                      : " "
                  }
                  variant="outlined"
                />{" "}
                <TextField
                  label="Inicio de operaciones"
                  margin="normal"
                  size="small"
                  name="operationStart"
                  onChange={this.onChangeTecnical}
                  fullWidth
                  value={
                    this.state.info.company.TechnicalDetail
                      ? this.state.info.company.TechnicalDetail.operationStart
                      : " "
                  }
                  variant="outlined"
                />
                <TextField
                  label="Producto que elabora"
                  margin="normal"
                  size="small"
                  name="product"
                  onChange={this.onChangeTecnical}
                  fullWidth
                  value={
                    this.state.info.company.TechnicalDetail
                      ? this.state.info.company.TechnicalDetail.product
                      : " "
                  }
                  variant="outlined"
                />
                <TextField
                  label="Principales Clientes"
                  margin="normal"
                  size="small"
                  name="clients"
                  onChange={this.onChangeTecnical}
                  fullWidth
                  value={
                    this.state.info.company.TechnicalDetail
                      ? this.state.info.company.TechnicalDetail.clients
                      : " "
                  }
                  variant="outlined"
                />
                <TextField
                  label="Vigencia del contrato"
                  margin="normal"
                  size="small"
                  name="contract"
                  onChange={this.onChangeTecnical}
                  fullWidth
                  value={
                    this.state.info.company.TechnicalDetail
                      ? this.state.info.company.TechnicalDetail.contract
                      : " "
                  }
                  variant="outlined"
                />
                <TextField
                  label="Fecha de Afiliación"
                  margin="normal"
                  size="small"
                  name="acceptanceDate"
                  onChange={this.onChange}
                  fullWidth
                  value={
                    this.state.info.company
                      ? this.state.info.company.acceptanceDate
                      : " "
                  }
                  variant="outlined"
                />
              </Paper>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.Save} color="primary">
              Guardar
            </Button>
            ,
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(EditDialog);
