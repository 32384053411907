import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import EmployeesCall from "../../../API/Companies";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { isNullOrUndefined } from "util";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography } from "@material-ui/core";
//import {Link} from 'react-router-dom';

const styles = (theme) => ({
  card: {
    minWidth: 275,
    height: "100%",
    overflowY: "visible",
    maxWidth: "70wv",
    overflow: "auto",
  },
  paper: {
    height: "82vh",
    width: "94%",
    maxWidth: "84vw",
    overflow: "auto",
  },

  Grid: {
    width: "100%",
    height: "100%",
  },

  log: {
    marginTop: "20px",
  },

  Btn: {
    //width: '80%',
    //marginLeft: '10%',
    marginTop: "5px",
    padding: "5px",
  },
  BtnDownload: {
    //width: '80%',
    display: "flex",
    marginLeft: "10%",
    marginTop: "5px",
    padding: "5px",
    justifyContent: "end",
  },

  paperTable: {
    height: "100%",
    maxHeight: "100%",
    overflow: "auto",
  },
  table: {
    maxWidth: "100%",
  },
  Skeleton: {
    width: "100%",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};
class EmpresaLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Uuid: "",
      Date: "",
      Employees: [],
      CensusDates: [],
      fileUrl: "",
      Onload: true,
      OnError: false,
      OnNull: false,
    };
  }

  fetchCensus = async (Uuid) => {
    const dates = await EmployeesCall.CensusDates(Uuid);
    console.log(dates);
    let datecensus;
    if (!isNullOrUndefined(dates))
      datecensus = dates.map((census) => [
        census.createdAt.substring(0, 10),
        census.uuid,
        census.fileUrl,
      ]);
    else datecensus = [];
    this.setState({ CensusDates: datecensus });
    return dates;
  };

  fetchEmployees = async (censusUuid) => {
    const EmployeesShow = await EmployeesCall.ShowAllEmployees(censusUuid);
    console.log(EmployeesShow);
    this.setState({ Employees: EmployeesShow });
  };

  componentDidMount = async () => {
    try {
      if (this.props.location.state) {
        this.setState({
          Uuid: this.props.location.state.companieUuid,
          Date: this.props.location.state.companieName,
        });
        const census = await this.fetchCensus(
          this.props.location.state.companieUuid
        );
        if (census.length > 0) {
          await this.fetchEmployees(census[0].uuid);
          this.setState({
            Date: census[0].updatedAt.substring(0, 10),
            fileUrl: census[0].fileUrl,
            Onload: false,
          });
        } else {
          this.setState({
            Onload: false,
            OnNull: true,
          });
        }
      } else {
        this.props.history.push("/Empresas");
      }
    } catch (e) {
      this.setState({
        Onload: false,
        OnError: true,
      });
      console.log(e);
    }
  };

  changeDate = async (census) => {
    console.log(census);

    await this.fetchEmployees(census[1]);
    this.setState({ Date: census[0], fileUrl: census[2] });
  };
  downloadFile = () => {
    console.log(this.state.fileUrl);
    if (this.state.fileUrl && this.state.fileUrl !== "") {
      //alert('Descargando archivo')
      window.open(this.state.fileUrl);
    } else {
      alert("Archivo no disponible");
    }
  };
  render() {
    const { classes } = this.props;
    const data = [];
    let counter = 0;
    this.state.Employees.map((employees) => {
      data.push([
        (counter += 1),
        employees.payroll,
        employees.User.UserProfile.name,
        employees.job,
        employees.User.UserProfile.rfc,
        employees.entry,
        employees.daySalary,
        employees.City !== null ? employees.City.name : "",
        employees.Localplace !== null ? employees.Localplace.name : "",
        employees.address,
        employees.User.UserProfile.sex,
        moment().diff(employees.User.UserProfile.dateOfBirth, "years"),
        employees.User.UserProfile.dateOfBirth,
      ]);
      return data;
    });
    const columns = [
      "No.",
      "No. Nomina",
      "Nombre",
      "Puesto",
      "RFC",
      "Fecha de ingreso",
      "Salario",
      "Municipio",
      "Colonia",
      "Dirección",
      "Sexo",
      "Edad",
      "Fecha de Nacimiento",
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin datos",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };

    if (this.state.Onload) {
      return (
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.Skeleton}
        />
      );
    } else {
      if (this.state.OnError) {
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error en la carga
              </Typography>
            </Grid>
          </Paper>
        );
      } else if (this.state.OnNull) {
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                No hay padrones disponibles
              </Typography>
            </Grid>
          </Paper>
        );
      } else {
        return (
          <Paper className={classes.paper}>
            <Card className={classes.card}>
              <Grid container className={classes.Grid}>
                <Grid item xs={2} className={classes.Grid}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.log}
                  >
                    <div>
                      {this.state.CensusDates !== [] ? (
                        <div>
                          {this.state.CensusDates.slice(0)
                            .reverse()
                            .map((census) => (
                              <React.Fragment>
                                <Button
                                  variant="contained"
                                  //color="primary"
                                  className={classes.Btn}
                                  id={census[1]}
                                  onClick={() => {
                                    this.changeDate(census);
                                  }}
                                >
                                  {census[0]}
                                </Button>
                                <br />
                              </React.Fragment>
                            ))}
                        </div>
                      ) : (
                        <div className={classes.load}>
                          Sin padrones disponibles
                        </div>
                      )}
                    </div>
                  </Grid>

                  <div className={classes.log}></div>
                </Grid>
                <Grid item xs={10} className={classes.Grid}>
                  <Paper className={classes.paperTable}>
                    <Grid xs={12} className={classes.BtnDownload}>
                      <Button
                        variant="contained"
                        color="primary"
                        //className={classes.BtnDownload}
                        id="fileURL"
                        onClick={() => {
                          this.downloadFile();
                        }}
                      >
                        {"Excel Original"}
                      </Button>
                    </Grid>
                    <div>
                      <MUIDataTable
                        // eslint-disable-next-line no-useless-concat
                        title={"Padrón del " + `${this.state.Date}`}
                        data={data}
                        columns={columns}
                        options={options}
                        className={classes.table}
                        selectableRows="none"
                      />
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Card>
          </Paper>
        );
      }
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(EmpresaLog));
