import base from "../baseurl";
import token from "../getTk";

const Create = async (Worker, phone, mail) => {
  console.log("Llego al cambio");
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  //console.log( ElectionUuid)
  const Create = await api
    .post("employee-management/update", {
      json: {
        userProfileData: {
          personalPhone: phone,
          otherMail: mail,
          uuid: Worker.User.UserProfile.uuid,
        },
      },
    })
    .json();
  console.log(Create);
  return Create;
};
export default Create;
