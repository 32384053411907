import React, { Component } from "react";
import {
  Typography,
  Grid,
  Paper,
  withStyles,
  Avatar,
  Box,
  Button,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import LinearProgress from "@material-ui/core/LinearProgress";
import PeopleIcon from "@material-ui/icons/People";
import VotingCall from "../../../API/Voting";
import { ListCandidates } from "./index";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  largeA: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    //display:"flex",
    //justifyContent:"end",
    //marginBottom:"10px"
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    marginTop: "10px",
    //paddingTop:"5px"
  },
  paperFilter: {
    width: "90%",
    padding: "2%",
  },
  formControl: {
    width: "50%",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  SectionPaper: {
    width: "100%",
    padding: "1%",
    //marginTop:"1%",
    marginBottom: "2%",
  },
  actionBtn: {
    margin: "5px",
  },
  Skeleton: {
    width: "100%",
    height: "200px",
    //marginTop:"20px"
    borderRadius: "0px 0px 10px 10px",
  },
  Subtitle: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  GridSectionItem: {
    //marginTop:"15px"
  },
  Subtitle2: {
    borderRadius: "5px 5px 5px 5px",
    backgroundColor: "#7f8c8d",
    color: "rgb(247, 244, 244)",
    marginTop: "5px",
  },
  ImgPlaceholder: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  ImgProp: {
    width: "100%",
    height: "100%",
    //padding:"1px",
    borderRadius: "2px 2px 2px 2px",
  },
  btn: {
    width: "100%",
  },
  Table: {
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "150px",
  },
  TimePicker: {
    marginTop: "10px",
    width: "100%",
    minHeight: "140px",
    padding: "2%",
  },
  DataTimePicker: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  BtnSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "5px",
  },
  PaperDialogNewCandidate: {
    padding: "5px",
  },
  SubSection: {
    width: "100%",
    marginTop: "10px",
    minHeight: "200px",
    maxHeight: "300px",
  },
  SubSectionBottom: {
    width: "100%",
    marginTop: "2px",
    minHeight: "100px",
    maxHeight: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  SubSectionTop: {
    width: "100%",
    marginTop: "2px",
    minHeight: "100px",
    maxHeight: "50%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  TextShow: {
    width: "100%",
    marginTop: "10px",
    marginLeft: "10px",
    minHeight: "50px",
    maxHeight: "33%",
  },
  largeAvatar: {
    width: "100%",
    minHeight: "50px",
    maxHeight: "200px",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  large: {
    width: "100%",
    minHeight: "50px",
    //maxHeight: "200px",
    margin: "5px",
  },
  full: {
    width: "100%",
    padding: "3px",
  },
  peopleButton: {
    alignItems: "center",
    margin: "5px",
  },
});

function CircularProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="95%" mr={3}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${props.value.toFixed(2)}%`}
          {props.title}
        </Typography>
      </Box>
    </Box>
  );
}

class CandidateOnElection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: [],
      dialogInfo: false,
      VoteInfo: {
        TotalVoter: 127,
        Voted: 0,
        NonVoted: 0,
        VotedPercent: 0,
      },
      List: [],
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.UuidElection) {
        this.GetVotes(this.props.UuidElection);
        this.interval = setInterval(() => {
          this.GetVotes(this.props.UuidElection);
        }, 6000);
      } else {
        //toastType.error("Información perdida en los tiempos de votación");
        //this.props.history.push("/Votaciones");
      }
    } catch (error) {
      console.log(error);
      //toastType.error("Información perdida en los tiempos de votación");
      //this.props.history.push("/Votaciones");
    }
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  openMoreInfo = (i, t) => {
    console.log(i);
    const reverse = !this.state.dialogInfo;
    this.setState({
      List: i,
      dialogInfo: reverse,
    });
  };
  GetVotes = async (uuid) => {
    try {
      const Votes = await VotingCall.GetVotes(uuid);
      if (Votes.status === "success") {
        //console.log("get votes from Candidates on eleccinton", Votes);
        var VotesC = Votes.data.votes.votes.map((type) => {
          return type.amount;
        });
        VotesC = VotesC.reduce((a, b) => a + b, 0);
        const TotalVotes = this.props.ElectionInfo.Employees.length;
        let PercentVotes = (VotesC / TotalVotes) * 100;
        this.setState({
          VoteInfo: {
            TotalVoter: TotalVotes,
            Voted: VotesC,
            NonVoted: TotalVotes - VotesC,
            VotedPercent: PercentVotes,
            nonVotedPercent: 100 - PercentVotes,
            Total: Votes.data.votes.totalPossibleVotants,
          },
        });
        this.setState({ candidates: Votes.data.votes.votes });
      } else {
        toastType.error("Error en el servidor");
      }
    } catch (error) {
      console.log("El error", error);
    }
  };
  totalPercent = (votes) => {
    return (votes / this.state.VoteInfo.TotalVoter) * 100;
  };
  electionPercent = (votes) => {
    return (votes / this.state.VoteInfo.Total) * 100;
  };
  componentWillUnmount = async () => {
    clearInterval(this.interval);
  };
  render() {
    const { classes } = this.props;

    if (this.props.Type === 1) {
      return (
        <Grid direction="row" xs={12} sm={12} container spacing={1}>
          {this.state.candidates.length > 0
            ? [
                this.state.candidates.map((candidate, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Paper className={classes.full}>
                        <div className={classes.largeAvatar}>
                          <Avatar
                            className={classes.largeA}
                            alt={"1"}
                            src={
                              candidate.candidate.img
                                ? candidate.candidate.img
                                : "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255634-stock-illustration-avatar-icon-male-profile-gray.jpg"
                            }
                          />
                        </div>
                        <div className={classes.large}>
                          <Typography
                            align="Center"
                            variant="body2"
                            color="textPrimary"
                            component="p"
                          >
                            {candidate.candidate.Employee.User.UserProfile.name}
                          </Typography>
                          <Typography
                            align="Center"
                            variant="body2"
                            color="textPrimary"
                            component="p"
                          >
                            {candidate.candidate.Employee.job}
                          </Typography>
                          <Typography
                            align="Center"
                            variant="body2"
                            color="textPrimary"
                            component="p"
                          >
                            {candidate.candidate.Employee.payroll}
                          </Typography>
                          <Typography
                            align="Center"
                            variant="body2"
                            color="textPrimary"
                            component="p"
                          >
                            Total de votos: {candidate.amount}
                            <CircularProgressWithLabel
                              value={this.totalPercent(candidate.amount)}
                              className={classes.TextShow}
                              title={" En base Votos"}
                            />
                            <CircularProgressWithLabel
                              value={this.electionPercent(candidate.amount)}
                              color="Secondary"
                              className={classes.TextShow}
                              title={" En base al Total"}
                            />
                          </Typography>
                        </div>
                      </Paper>
                    </Grid>
                  );
                }),
              ]
            : []}
        </Grid>
      );
    } else {
      return (
        <Paper>
          {this.state.candidates.length > 0
            ? [
                this.state.candidates.map((candidate, index) => {
                  return (
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Paper className={classes.full}>
                            <div className={classes.large}>
                              <Typography
                                align="Center"
                                variant="body2"
                                color="textPrimary"
                                component="p"
                              >
                                Plantilla
                              </Typography>
                              <Typography
                                align="Center"
                                variant="body2"
                                color="textPrimary"
                                component="p"
                              >
                                {candidate.candidate.name}
                              </Typography>
                              <Typography
                                align="Center"
                                variant="body2"
                                color="textPrimary"
                                component="p"
                              >
                                Total de votos: {candidate.amount}
                                <CircularProgressWithLabel
                                  value={this.totalPercent(candidate.amount)}
                                  className={classes.TextShow}
                                  title={" En base Votos"}
                                />
                                <CircularProgressWithLabel
                                  value={this.electionPercent(candidate.amount)}
                                  color="Secondary"
                                  className={classes.TextShow}
                                  title={" En base al Total"}
                                />
                              </Typography>
                              <Button
                                color="primary"
                                variant="contained"
                                className={classes.peopleButton}
                                onClick={this.openMoreInfo.bind(
                                  this,
                                  candidate.candidate.Candidates
                                )}
                              >
                                <PeopleIcon />
                              </Button>
                            </div>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }),
              ]
            : []}
          {this.state.dialogInfo
            ? [
                <ListCandidates
                  DialogOpen={this.state.dialogInfo}
                  DialogClose={this.openMoreInfo}
                  List={this.state.List}
                />,
              ]
            : []}
        </Paper>
      );
    }
  }
}

export default withStyles(styles)(CandidateOnElection);
