import base from "../../baseurl";
import token from "../../getTk"

const Update = async (State) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Update = await api.post("bonuses/update",{json: {
      bonusUuid:State.Uuid,
      business:State.bussiness,
      address:State.address,
      discount:State.off,
      city:State.city
    }}).json();
   
    console.log (Update)
    return Update ;
    
  };
  
  export default  Update ;