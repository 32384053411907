import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  AppBar,
  Toolbar,
  Tab,
  Tabs,
} from "@material-ui/core";
import INDET from "../Sections/INDET";
import Releases from "../Sections/Releases";
import Galleries from "../Sections/Galleries";
import Sports from "../Sections/Sports";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import ListSport from "../AddNewSport/ListSport";
import DocsType from "../AddNewDocType/ListDocsType";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 1 };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">
          {" "}
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.props.DialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Tabs value={this.state.value} onChange={this.handleChange}>
                <Tab value={0} label="Deportes" />
                <Tab value={1} label="Galerías" />
                <Tab value={2} label="Comunicados" />
                <Tab value={3} label="INDET" />
              </Tabs>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={classes.paper}>
          {this.state.value === 0 && (
            <TabContainer>
              <Sports id={this.props.EventId} />
            </TabContainer>
          )}
          {this.state.value === 1 && (
            <TabContainer>
              <Galleries id={this.props.EventId} />
            </TabContainer>
          )}
          {this.state.value === 2 && (
            <TabContainer>
              <Releases id={this.props.EventId} />
            </TabContainer>
          )}
          {this.state.value === 3 && (
            <TabContainer>
              <INDET id={this.props.EventId} />
            </TabContainer>
          )}
          {this.state.value === 4 && (
            <TabContainer>
              <ListSport id={this.props.EventId} />
            </TabContainer>
          )}
          {this.state.value === 5 && (
            <TabContainer>
              <DocsType id={this.props.EventId} />
            </TabContainer>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
