import base from "../../baseurl";
import token from "../../getTk";

const showAll = async (name, disciplineId, disciplineTypeId) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("disciplines/update", {
      json: { name, disciplineId, disciplineTypeId },
      timeout: false,
    })
    .json();
  console.log(Create);
  return Create;
};

export default showAll;
