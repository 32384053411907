import api from "../../base";


const Showall = async () => {

    const showall = await api.get("pages/showall").json(); 
    //console.log(showall.data)
    return showall.data.pages[3].content ;
    
  };
  
  export default  Showall ;