import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import toasType from "../../../API/CallUtils/tostyNotification";
const styles = (theme) => ({
  FormControl: {
    width: "100%",
    marginBottom: "8px",
    marginTop: "8px",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function HasGraphics(props) {
  return (
    <FormControl component="fieldset" className={props.classes.FormControl}>
      <FormLabel component="legend">Gráficos</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.hasGraphics}
              onChange={(e) => {
                if (props.sex === "AMBOS") {
                  props.sentoParent("hasGraphics", !props.hasGraphics);
                } else {
                  toasType.info(
                    "Los gráficos solo están disponibles con la opción de ambos sexos "
                  );
                }
              }}
            />
          }
          label="Solicitar Gráficas comparativas"
        />
      </FormGroup>
    </FormControl>
  );
}

export default withStyles(styles)(HasGraphics);
