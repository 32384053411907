import base from "../baseurl";
import token from "../getTk"

const showAllCompany = async (Uuid,State,fecha) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
  console.log("compo",Uuid)
    const CompaniesShow = await api.post("consultations/index",{json: {
      searchType:"COMPANY",
      companyUuid:Uuid,
      consultationStates:[State],
      consultationTypes:[],
      bottomBoundDate:fecha
    }}).json();

    return CompaniesShow.data;
    
    
    
  };
  
  export default  showAllCompany ;