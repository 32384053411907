import React from "react";
import { toast } from "react-toastify";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import CancelIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";

const notify = {
  error(payload) {
    toast.error(
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CancelIcon style={{ marginRight: "5px" }} /> {payload}
      </div>
    );
  },
  success(payload) {
    toast.success(
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CheckCircleIcon style={{ marginRight: "5px" }} /> {payload}
      </div>
    );
  },
  info(payload) {
    toast.info(
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <InfoIcon style={{ marginRight: "5px" }} /> {payload}
      </div>
    );
  },
  warn(payload) {
    toast.warn(
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <WarningIcon style={{ marginRight: "5px" }} /> {payload}
      </div>
    );
  },
  dark(payload) {
    toast.dark(
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <LabelImportantIcon style={{ marginRight: "5px" }} /> {payload}
      </div>
    );
  },
};
export default notify;
