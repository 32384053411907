import base from "../baseurl";
import token from "../getTk";

const ImgPrincipal = async (place, name1, imgToSend) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const dataForm = new FormData();
  dataForm.append("file", imgToSend);
  dataForm.append("name", name1);
  dataForm.append("location", place);

  const res = await api.post("imgs/create", { body: dataForm }).json();

  return res;
};

export default ImgPrincipal;
