import base from "../../baseurl";
import token from "../../getTk"


const Update = async (state) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
   console.log("estado en la llamada",state)
    const Update = await api.post("pages/update",{json: {
        pageId:3,
        name:"home",
        content:{
            title:state.principalTitle,
            welcomeText:state.principalText,
            ImgText:state.ImgText,
            lema:state.motto,
            principalIMG:state.principalIMG,
            footerCarusel:state.files,
            CEFORMATitle:state.CEFORMATitle,
            CEFORMAText:state.CEFORMAText,
            CeformaIMG:state.CeformaIMG,
            CursoTitle:state.CursoTitle,
            CursoText:state.CursoText,
            CursoIMG:state.CursoIMG,
            Banners:state.files2

        }
    }
    ,
        timeout:false
}).json();
   console.log(Update)
    return Update ;
    
  };
  
  export default  Update ;