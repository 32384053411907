import React from "react";
import { Paper, withStyles, Grid, Typography, Button } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Skeleton from "@material-ui/lab/Skeleton";
import PersonIcon from "@material-ui/icons/Person";
import TimeLine from "./Timeline";

const styles = (theme) => ({
  paper: {
    width: "90%",
    padding: "1%",
  },
  Skeleton: {
    width: "400px",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
  Red: {
    color: "red",
  },
  Green: {
    color: "green",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      StateTransaction: "OnLoad",
      Employees: [],
      TimeLineDialog: false,
      rfc: "",
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.date.length) {
        this.setState({
          Employees: this.props.date,
          StateTransaction: "OnSuccess",
        });
      } else {
        this.setState({
          StateTransaction: "OnNull",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  Dialog = (a, SelectedRfc) => {
    console.log(SelectedRfc);
    this.setState({ [a]: !this.state[a], rfc: SelectedRfc });
  };

  render() {
    const { classes } = this.props;
    const data = [];
    let counter = 0;
    this.state.Employees.map((employees) => {
      data.push([
        (counter += 1),
        employees.UserProfile.name,
        employees.UserProfile.rfc,
        <Button
          color="primary"
          align="center"
          onClick={this.Dialog.bind(
            this,
            "TimeLineDialog",
            employees.UserProfile.rfc
          )}
        >
          <PersonIcon />
        </Button>,
      ]);
      return data;
    });
    const columns = ["No.", "Nombre", "RFC", "Detalles"];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin datos",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    switch (this.state.StateTransaction) {
      case "OnLoad":
        return (
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        );
      case "OnError":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnNull":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Ningún Cambio Disponible
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnSuccess":
        return (
          <>
            {this.state.TimeLineDialog
              ? [
                  <TimeLine
                    DialogOpen={this.state.TimeLineDialog}
                    DialogClose={this.Dialog.bind(this, "TimeLineDialog")}
                    rfc={this.state.rfc}
                  />,
                ]
              : [
                  <Paper className={classes.FullPaper}>
                    <MUIDataTable
                      title={"Lista de cambios"}
                      data={data}
                      columns={columns}
                      options={options}
                      className={classes.table}
                      selectableRows="none"
                    />
                  </Paper>,
                ]}
          </>
        );
      default:
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
    }
  }
}

export default withStyles(styles)(addCandidateDialog);
