import base from "../baseurl";
import token from "../getTk";

const Create = async (body, AdvisorList) => {
  const tokenA = token.get();

  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const status = await api
    .post("companies/create", {
      json: {
        name: body.name,
        phone: body.phone,
        address: body.address,
        cityUuid: body.cityUuid,
        stateUuid: body.stateUuid,
        country: body.country,
        industrialPark: body.industrialPark,
        acceptanceDate: body.acceptanceDate,
        advisorsUuid: AdvisorList,
        nominationDocument: undefined,
        storyOfBenefits: undefined,
        internalRegulation: undefined,
        colectiveContract: undefined,
        hasCommittee: body.hasCommittee,
        electoralId: body.electoralId,
        rhPassword: body.rhPassword,
        otherDocuments: [],
        technicalDetails: {
          section: "",
          contract: body.contract,
          product: body.product,
          operationStart: body.operationStart,
          clients: body.clients,
          lastRevision: body.lastRevision,
        },
        benefits: {
          christmasBox: body.christmasBox,
          holydayBonus: body.holidayBonus,
          savingFund: body.savingFund,
          utilities: body.utilities,
          vouchers: body.vouchers,
          attendanceBonus: body.attendanceBonus,
          lunchBonus: body.lunchBonus,
          transportBonus: body.transportBonus,
          other: body.other,
        },
        rh: { name: "", charge: "", phone: "", email: "" },
      },
    })
    .json();

  return status;
};

export default Create;
