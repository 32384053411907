import base from "../baseurl";
import token from "../getTk";
import Redux from "../../StoreConfig";
import { addPermissions } from "../../Accions/actions";

const Create = async (id, tokenFromLogin) => {
  console.log(id, tokenFromLogin);
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set(
            "jwt",
            `${tokenFromLogin ? tokenFromLogin : tokenA}`
          );
        },
      ],
    },
  });
  const create = await api
    .post("user-permissions/show", {
      timeout: false,
      json: { id },
    })
    .json();
  if (create.status === "success" && tokenFromLogin) {
    Redux.dispatch(addPermissions(create.data));
  }

  return create;
};

export default Create;
