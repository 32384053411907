import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
    marginBottom: "8px",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
class SelectedState extends React.Component {
  constructor(props) {
    super(props);
    this.state = { List: [] };
  }
  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.Table}>
        <FormControl component="fieldset" className={classes.Radio}>
          <FormLabel component="legend">Tipo de empresa</FormLabel>
          <RadioGroup
            margin="normal"
            aria-label="position"
            name="hasCommittee"
            onChange={(event) => {
              this.props.sentoParent("hasCommittee", !this.props.hasCommittee);
            }}
            value={this.props.hasCommittee}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="REGIONAL"
              labelPlacement="start"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="secondary" />}
              label="DE INDUSTRIA"
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    );
  }
}

export default withStyles(styles)(SelectedState);
