import base from "../baseurl";
import token from "../getTk"

const showAll = async () => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const States = await api.get(`consultation-states/index`).json();
    return States.data.consultationStates;
    
  };
  
  export default  showAll ;
  