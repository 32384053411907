import base from "../baseurl";
import token from "../getTk";

const showAll = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const show = await api
    .post("company-form-questions/create", {
      json: {
        questionTypeId: state.type,
        content: state.question,
        answers: state.answers.length > 1 ? state.answers : [""],
      },
    })
    .json();
  return show;
};

export default showAll;
