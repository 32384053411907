import axios from "axios";
const Report = async () => {
  const re = await axios
    .post(`https://api.sitimm.org/api/reports/show`, {
      reportNumber: 10,
    })
    .then((res) => {
      return res;
    });
  return re;
};

export default Report;
