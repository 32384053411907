import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import MUIDataTable from "mui-datatables";
import VotingCall from "../../../API/Voting";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    //marginLeft: "10px",
    padding: "1%",
  },
  Select: {
    maxWidth: "100%",
  },
  full: { width: "100%" },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { List: [] };
  }
  componentDidMount = async () => {
    try {
      this.fetch();
    } catch (e) {
      toastType.error("No hay personal");
    }
  };
  fetch = async () => {
    try {
      const Votes = await VotingCall.showDetails(this.props.uuid);
      console.log("votaron", Votes.data);
      if (Votes.status === "success") {
        this.setState({ List: Votes.data.Employees });
      } else {
        toastType.error("Error en el servidor");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log("El error", error);
    }
  };
  render() {
    const data = [];
    this.state.List.map((Worker) => {
      data.push([Worker.User.UserProfile.name]);
      return data;
    });
    const columns = ["Nombre"];

    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 50, 100, 200],
    };

    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">lista Ya votaron</DialogTitle>
        <DialogContent>
          <Grid container className={classes.GridD}>
            <Grid container>
              <Grid
                iteam
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.GridDialog}
              >
                <MUIDataTable
                  title={"Listado"}
                  data={data}
                  columns={columns}
                  options={options}
                  selectableRows="none"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
