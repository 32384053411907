import React, { Component } from "react";
import {
  Grid,
  Typography,
  Button,
  withStyles,
  Box,
  LinearProgress,
} from "@material-ui/core";
import axios from "axios";
import toasType from "../../API/CallUtils/tostyNotification";
import token from "../../API/getTk";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    maxHeight: "80%",
    padding: "2%",
    marginTop: "10px",
    marginBottom: "5px",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  imgDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Remp: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  fullCenter: {
    width: "100%",
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: null,
      setUploadPercentage: 0,
      urlImg: null,
    };
  }
  saveFileInfo = async (e) => {
    try {
      const tokenA = token.get();
      const dataForm = new FormData();
      dataForm.append("file", e.target.files[0]);
      dataForm.append(
        "name",
        e.target.files[0].name.replace(/[^0-9A-Za-z.]/gi, "_")
      );
      dataForm.append("location", this.props.Place);

      const res = await axios.post(
        "https://api.sitimm.org/api/imgs/create",
        dataForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            jwt: `${tokenA}`,
          },
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            this.setState({
              setUploadPercentage: parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
            });
          },
        }
      );
      //console.log(res.data.status)
      if (res.data.status === "success") {
        this.setState({
          urlImg: res.data.data.location,
          setUploadPercentage: 0,
        });
        this.props.parentCallback(
          res.data.data.location,
          this.props.stateNameOnParent
        );
        toasType.success("El Archivo fue cargado exitosamente");
      } else {
        toasType.error("Error en la recepción del archivo");
      }
    } catch (e) {
      console.log(e);
      toasType.error("Error en la carga");
      this.setState({
        setUploadPercentage: 0,
        urlImg: null,
      });
      this.props.parentCallback(null, this.props.stateNameOnParent);
    }
  };
  componentDidMount = async () => {
    try {
      if (this.props.PrevImg) {
        this.setState({ urlImg: this.props.PrevImg });
      } else {
        console.log("Sin imagen Previa");
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <>
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            className={classes.fullCenter}
          >
            {this.props.Type}
          </Typography>
          {this.state.setUploadPercentage > 0
            ? [
                <LinearProgressWithLabel
                  value={this.state.setUploadPercentage}
                />,
              ]
            : [
                <Grid
                  alignContent="center"
                  alignItems="center"
                  className={classes.fullCenter}
                >
                  {this.state.urlImg !== null ? (
                    <div className={classes.imgDiv}>
                      {this.props.Type === "Imagen"
                        ? [
                            <img
                              src={this.state.urlImg}
                              className={classes.ImgPre}
                              alt={this.state.urlImg}
                            />,
                          ]
                        : [
                            <>
                              <a href={this.state.urlImg}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  className={classes.btn}
                                >
                                  <CloudUploadIcon />
                                </Button>
                              </a>
                              <Typography
                                variant="h6"
                                gutterBottom
                                align="center"
                              ></Typography>
                            </>,
                          ]}
                    </div>
                  ) : (
                    <div className={classes.imgDiv}>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        align="center"
                        display="block"
                      >
                        Sin {this.props.Type}
                      </Typography>
                    </div>
                  )}
                </Grid>,
              ]}
        </>
        <div className={classes.Remp}>
          {this.props.Type === "Imagen"
            ? [
                <input
                  type="file"
                  onChange={this.saveFileInfo.bind(this)}
                  className={classes.input}
                  id="NewImg"
                  required
                  accept="image/*"
                />,
              ]
            : [
                <input
                  type="file"
                  onChange={this.saveFileInfo.bind(this)}
                  className={classes.input}
                  id="NewFile"
                  required
                />,
              ]}

          {this.props.Type === "Imagen"
            ? [
                <label htmlFor="NewImg">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                  >
                    <AddAPhotoIcon />
                  </Button>
                </label>,
              ]
            : [
                <label htmlFor="NewFile">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                  >
                    <NoteAddIcon />
                  </Button>
                </label>,
              ]}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(UploadFiles);
