import base from "../baseurl";
import token from "../getTk";

const Create = async (userId, can, cannot) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const create = await api
    .post("user-permissions/create", {
      timeout: false,
      json: {
        userId,
        can,
        cannot,
      },
    })
    .json();
  console.log(create);
  return create;
};

export default Create;
