import React, { Component } from "react";
import { withStyles, Paper, Grid, Typography, Button } from "@material-ui/core";
import StatisticCall from "../../API/Statistic";
import Utils from "../../API/CallUtils";
import Report from "../../API/Reports";
import toasType from "../../API/CallUtils/tostyNotification";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectCompany from "./SelectCompany";

import DialogToken from "./DialogReportToken";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#2980b9",
  },
  Paper: {
    width: "100%",
    height: "90vh",
  },
  title: {
    display: "flex",
    width: "100%",
    justifyContent: "start",
    margin: "20px",
  },
  formControl: {
    width: "100%",
  },
  SubGrid: {
    padding: "20px",
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Parks: [],
      park: null,
      report: "",
      fromDate: moment(),
      toDate: moment(),
      Companyid: null,
      DialogToken: false,
      ReportList: [],
    };
  }

  componentDidMount = async () => {
    await this.fetchPark();
  };

  fetchPark = async () => {
    const StateList = await StatisticCall.GetParks();
    //console.log(StateList)
    this.setState({ Parks: StateList });
  };
  handleChangeState = async (event) => {
    this.setState({ park: event.target.value });
    await this.getParkReport(event.target.value);
  };

  getParkReport = async (park) => {
    console.log(park);
    const doc = await Utils.Reports(park, 0);
    console.log(doc);

    const blob = new Blob(["\ufeff", doc.data]);
    this.setState({ report: blob });
  };

  Report = async (type) => {
    try {
      this.setState({ LoadH: true });
      const doc = await Report.TecnicalAll();
      if (doc.data) {
        const blob = new Blob([doc.data], {
          type: "text/csv;charset=utf-8;",
        });
        this.setState({ History: blob, LoadH: false });
      } else {
        this.setState({ History: "#", LoadH: false });
      }
    } catch (e) {
      console.log(e);
    }
  };
  Report2 = async () => {
    try {
      this.setState({ LoadH: true });
      const doc = await Report.AllWeekFeeds(
        this.state.Companyid,
        moment().year()
      );
      if (doc.data) {
        const blob = new Blob([doc.data], {
          type: "text/csv;charset=utf-8;",
        });
        this.setState({ Payment: blob, LoadH: false });
      } else {
        this.setState({ History: "#", LoadH: false });
      }
    } catch (e) {
      console.log(e);
    }
  };
  MemberToken = async (type) => {
    try {
      this.setState({ LoadH: true });
      const doc = await Report.MembershipTokens(this.state);
      console.log(doc.data);
      if (doc.data) {
        this.setState({
          LoadH: false,
          DialogToken: true,
          ReportList: doc.data,
        });
      } else {
        toasType.error(" CSE No fue posible generar el reporte");
      }
    } catch (e) {
      toasType.error(" CSE No fue posible generar el reporte");
      console.log(e);
    }
  };
  selectCompany = async (uuid) => {
    this.setState({ Companyid: uuid });
  };
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.Paper}>
          <Grid container className={classes.GridSearch}>
            <Typography className={classes.title}>
              Reporte de contribuciones semanales
            </Typography>
            <Grid iteam xs={5} className={classes.SubGrid}>
              <SelectCompany Select={this.selectCompany} />
              {this.state.Payment ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={window.URL.createObjectURL(this.state.Payment)}
                  download="Contibuciones Semanales.csv"
                >
                  Descargar
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                  onClick={this.Report2}
                >
                  {this.state.LoadH2 ? <CircularProgress /> : "Reporte"}
                </Button>
              )}
            </Grid>
            <Grid iteam xs={7} className={classes.SubGrid}></Grid>
          </Grid>
        </Paper>
        {this.state.DialogToken && (
          <DialogToken
            DialogOpen={this.state.DialogToken}
            DialogClose={this.Dialog.bind(this, "DialogToken")}
            List={this.state.ReportList}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
