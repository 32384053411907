import base from "../baseurl";
import token from "../getTk";
const AsesorStatistic = async (Uuid) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const AsesorStatistic = await api
    .get("sections/show/" + Uuid, {
      timeout: false,
    })
    .json();
  console.log(AsesorStatistic, Uuid);
  return AsesorStatistic.status === "error"
    ? null
    : AsesorStatistic.data.section.statistics;
};

export default AsesorStatistic;
