import React, { Component } from "react";
import {
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import CMS from "./CMS";
import Statistics from "./Statistics";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Secciones from "@material-ui/icons/Book";
import Empresa from "@material-ui/icons/Apartment";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import RemoveRedEyeRoundedIcon from "@material-ui/icons/RemoveRedEyeRounded";
import MailIcon from "@material-ui/icons/Mail";
import PersonIcon from "@material-ui/icons/Person";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import SendIcon from "@material-ui/icons/Send";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import ViewListIcon from "@material-ui/icons/ViewList";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SearchIcon from "@material-ui/icons/Search";
import Forms from "./CompanieForms";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Weeklyfeeds from "./WeeklyFees";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalElements: [
        {
          special: true,
          name: "Estadística",
          element: <Statistics />,
        },
        {
          special: true,
          name: "CMS",
          element: <CMS />,
        },
        {
          special: true,
          name: "Encuestas",
          element: <Forms />,
        },
        {
          special: true,
          name: "Cuotas",
          element: <Weeklyfeeds />,
        },
        {
          name: "Cuotas Semanales",
          path: "/CuotasSemanales",
          icono: <MonetizationOnIcon />,
          special: false,
        },
        {
          name: "Buscar Afiliados",
          path: "/NuevoAfiliado",
          icono: <SearchIcon />,
          special: false,
        },
        {
          name: "Asesores",
          path: "/Secciones",
          icono: <Secciones />,
          special: false,
        },
        {
          name: "Empresas",
          path: "/Empresas",
          icono: <Empresa />,
          special: false,
        },
        {
          name: "Votaciones",
          path: "/Votaciones",
          icono: <HowToVoteIcon />,
          special: false,
        },
        {
          name: "Entrega de Bienes",
          path: "/EntregaBienes",
          icono: <AccountBalanceWalletIcon />,
          special: false,
        },
        {
          name: "Comunicados por Empresa",
          path: "/Comunicados",
          icono: <SendIcon />,
          special: false,
        },
        {
          name: "Comunicados Generales",
          path: "/ComunicadosGenerales",
          icono: <SendIcon />,
          special: false,
        },
        {
          name: "Evento Deportivo",
          path: "/EventoDeportivo",
          icono: <SportsSoccerIcon />,
          special: false,
        },
        {
          name: "Oficinas",
          path: "/Oficinas",
          icono: <BusinessIcon />,
          special: false,
        },
        {
          name: "Usuarios",
          path: "/Usuarios",
          icono: <GroupIcon />,
          special: false,
        },
        {
          name: "Comunicación",
          path: "/Comunicacion",
          icono: <MailIcon />,
          special: false,
        },
        {
          name: "Perfil",
          path: "/Perfil",
          icono: <PersonIcon />,
          special: false,
        },
        {
          name: "Registros",
          path: "/Logs",
          icono: <RemoveRedEyeRoundedIcon />,
          special: false,
        },
        {
          name: "Administración de Consultas",
          path: "/ConsultasAdmin",
          icono: <AssignmentLateIcon />,
          special: false,
        },
        {
          name: "Consultas",
          path: "/Consultas",
          icono: <AssignmentLateIcon />,
          special: false,
        },
        {
          name: "Variables del administrador",
          path: "/AdminPanel",
          icono: <ViewListIcon />,
          special: false,
        },
        {
          name: "Verificación de Hijos",
          path: "/Hijos",
          icono: <CheckBoxIcon />,
          special: false,
        },
      ],
      AsesorMenu: [
        "Empresas",
        "Votaciones",
        "Entrega de Bienes",
        "Comunicados por Empresa",
        "Perfil",
        "Consultas",
        "Verificación de Hijos",
      ],
      GeneralMenu: [
        "Asesores",
        "Empresas",
        "Votaciones",
        "Entrega  de Bienes",
        "Comunicados por Empresa",
        "Comunicados Generales",
        "Evento Deportivo",
        "Oficinas",
        "Usuarios",
        "Comunicación",
        "Perfil",
        "Administración de Consultas",
      ],
      AdminMenu: [
        "Buscar Afiliados",
        "Asesores",
        "Empresas",
        "Votaciones",
        "Entrega  de Bienes",
        "Comunicados por Empresa",
        "Comunicados Generales",
        "Evento Deportivo",
        "Oficinas",
        "Usuarios",
        "Comunicación",
        "Perfil",
        "Administración de Consultas",
      ],
      RootMenu: [
        "Buscar Afiliados",
        "Asesores",
        "Empresas",
        "Votaciones",
        "Entrega de Bienes",
        "Comunicados por Empresa",
        "Comunicados Generales",
        "Evento Deportivo",
        "Oficinas",
        "Usuarios",
        "Comunicación",
        "Perfil",
        "Registros",
        "Administración de Consultas",
        "Variables del administrador",
        "Estadística",
        "CMS",
        "Encuestas",
        "Cuotas",
      ],
      Accountant: ["Cuotas"],
      DisplayMenu: [],
    };
  }
  componentDidMount = async () => {
    console.log("Le permissions", this.props.UserPermissions);
    let listCan = [];
    let listCannot = [];

    if (
      this.props.UserPermissions.permissions &&
      this.props.UserPermissions.permissions.can.length > 0
    ) {
      listCan = this.props.UserPermissions.permissions.can.map((url) => {
        return this.indexUrl(url);
      });
      console.log("Lista de can", listCan);
    }
    if (
      this.props.UserPermissions.permissions &&
      this.props.UserPermissions.permissions.cannot.length > 0
    ) {
      listCannot = this.props.UserPermissions.permissions.cannot.map((url) => {
        return this.indexUrl(url);
      });
    }
    console.log("Lista de cannot", listCannot);
    try {
      switch (this.props.userType) {
        case 81493:
          this.filterMenu(
            this.addOrDeleteItems(this.state.AsesorMenu, listCan, listCannot)
          );
          break;
        case 45298:
          this.filterMenu(
            this.addOrDeleteItems(this.state.RootMenu, listCan, listCannot)
          );
          break;
        case 72468:
          this.filterMenu(
            this.addOrDeleteItems(this.state.AdminMenu, listCan, listCannot)
          );
          break;
        case 48235:
          this.filterMenu(
            this.addOrDeleteItems(this.state.GeneralMenu, listCan, listCannot)
          );
          break;
        case 85672:
          this.filterMenu(
            this.addOrDeleteItems(this.state.Accountant, listCan, listCannot)
          );
          break;
        default:
          this.setState({ DisplayMenu: [] });
      }
    } catch (e) {
      console.log(e);
    }
  };
  addOrDeleteItems = (original, can, cannot) => {
    let total = [...new Set(original.concat(can))];
    let cannotPrune = [...new Set(cannot)];
    console.log(total.filter((val) => !cannotPrune.includes(val)));
    return total.filter((val) => !cannotPrune.includes(val));
  };
  indexUrl = (url) => {
    try {
      switch (url.split("/")[2]) {
        case "election":
          return "Votaciones";
        case "blogposts":
          return "CMS";
        case "boletus":
          return "Votaciones";
        case "candidate":
          return "Votaciones";
        case "campuses":
          return "Variables del administrador";
        case "cities":
          return "Variables del administrador";
        case "companies":
          return "Empresas";
        case "bulletins":
          return "Comunicados por Empresa";
        case "bulletins-general":
          return "Comunicados Generales";
        case "bonuses":
          return "CMS";
        case "son-documents":
          return "Verificación de Hijos";
        case "company-form-campaigns":
          return "Encuestas";
        case "company-forms":
          return "Encuestas";
        case "company-form-entries":
          return "Encuestas";
        case "company-form-questions":
          return "Encuestas";
        case "good-delivery-events":
          return "Entrega de Bienes";
        case "good-delivery-participants":
          return "Entrega de Bienes";
        case "states":
          return "Variables del administrador";
        case "sport-events":
          return "Evento Deportivo";
        case "events":
          return "CMS";
        case "sport-documents":
          return "Evento Deportivo";
        case "sport-gallery-element":
          return "Evento Deportivo";
        case "indets":
          return "Evento Deportivo";
        case "event-participants":
          return "Evento Deportivo";
        case "sons":
          return "Verificación de Hijos";
        case "localplaces":
          return "Variables del administrador";
        case "countries":
          return "Variables del administrador";
        case "bus-stops":
          return "Variables del administrador";
        case "candidate-worksheets":
          return "Votaciones";
        case "candidate-positions":
          return "Votaciones";
        case "frequentqas":
          return "CMS";
        case "magazines":
          return "CMS";
        default:
          return "";
      }
    } catch (e) {
      console.log(e);
    }
  };
  filterMenu = (type) => {
    const DisplayMenu = this.state.totalElements.filter((x) =>
      type.find((element) => element === x.name)
    );
    this.setState({ DisplayMenu });
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {this.state.DisplayMenu.map((menuIteam) => {
            if (menuIteam.special) {
              return <>{menuIteam.element}</>;
            } else {
              return (
                <Link
                  className={classes.Link}
                  to={menuIteam.path}
                  key={menuIteam.name}
                >
                  <ListItem button>
                    <ListItemIcon>{menuIteam.icono}</ListItemIcon>
                    <ListItemText>{menuIteam.name}</ListItemText>
                  </ListItem>
                </Link>
              );
            }
          })}
        </List>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userType: state.login.user.userType,
    UserPermissions: state.login.user.permissions,
  };
};

const styles = {
  root: {
    flexGrow: 1,
  },
  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    color: "#2f3542",
  },
};

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
