import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
    justifyContent: "center",
  },
});

class OnloadTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.paper}
      >
        <Skeleton animation="wave" width="60%" />
        <Skeleton animation="wave" width="60%" />
        <Skeleton animation="wave" width="60%" />
        <Skeleton animation="wave" width="60%" />
        <Skeleton animation="wave" width="60%" />
      </Grid>
    );
  }
}

export default withStyles(styles)(OnloadTable);
