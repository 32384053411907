import base from "../baseurl";
import token from "../getTk"

const Close = async (Uuid,notas) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const Close = await api.post("consultations/update",{json: {
      updateType:2,
      consultationUuid:Uuid,
      payload:{
        solution:notas
      }
    }}).json();
    console.log(Close)
    return Close;
    
  };
  
  export default  Close ;