import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import List from "../../../API/Companies";
import { OnLoadSelect } from "../../../Components/StatesForComponets";

const styles = (theme) => ({
  formControl: {
    width: "80%",
    margin: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompaniesList: [],
      SelectedCompanie: "",
    };
  }

  fetchCompanyList = async () => {
    try {
      const CompanyList = await List.showAllNotSta();
      if (CompanyList) {
        //console.log("Lista", CompanyList);
        CompanyList.push({
          id: "ALL",
          name: "TODAS LAS EMPRESAS",
          uuid: "Allcompanies",
        });
        this.setState({
          CompaniesList: CompanyList,
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChangeState = async (event) => {
    console.log(event.target.value);
    this.props.Select(event.target.value);
    this.setState({ SelectedCompanie: event.target.value });
  };

  componentDidMount = async () => {
    if (this.props.userType === 45298 || this.props.userType === 72468) {
      await this.fetchCompanyList();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.CompaniesList && this.state.CompaniesList.length > 0
          ? [
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Listado de Empresas
                </InputLabel>
                <Select
                  value={this.props.companies}
                  onChange={(event) => {
                    this.props.Select("companies", event.target.value);
                  }}
                >
                  {this.state.CompaniesList.map((companie, index) => {
                    return (
                      <MenuItem value={companie} key={companie.id}>
                        {companie.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>,
            ]
          : [
              <div className={classes.formControl}>
                <OnLoadSelect />
              </div>,
            ]}
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
