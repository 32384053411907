import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import List from "../../../API/DeliveryOfGoods";
import { OnLoadSelect } from "../../../Components/StatesForComponets";

const styles = (theme) => ({
  formControl: {
    margin: "10px",
  },
  Skeleton: {
    width: "100%",
    height: "20px",
    borderRadius: "10px 10px 10px 10px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompaniesList: [
        {
          id: 1,
          name: "Suscrito",
        },
        {
          id: 2,
          name: "Apto",
        },
        {
          id: 3,
          name: "No Apto",
        },
        {
          id: 4,
          name: "En revision de documentos",
        },
        {
          id: 5,
          name: "Rechazados",
        },
      ],
      SelectedCompanie: this.props.initial ? this.props.initial : null,
      All: [1, 2, 3, 4, 5],
    };
  }

  fetchCompanyList = async () => {
    try {
      const CompanyList = await List.States();
      console.log(CompanyList);
      if (CompanyList.status === "success") {
        this.setState({
          CompaniesList: CompanyList.data.goodDeliveryParticipantStates,
          All: CompanyList.data.goodDeliveryParticipantStates.map((j) => j.id),
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChangeState = async (event) => {
    this.props.Select([event.target.value], this.props.id);
    this.setState({ SelectedCompanie: event.target.value });
  };

  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.CompaniesList.length > 0
          ? [
              <FormControl
                className={classes.formControl}
                style={{ width: this.props.Width ? this.props.Width : "30%" }}
              >
                <InputLabel id="demo-simple-select-label">
                  {this.props.initial ? "" : "Listado de Estados"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.SelectedCompanie}
                  name="stateUuid"
                  onChange={this.handleChangeState}
                  //inputProps={{ readOnly: true }}
                >
                  {this.state.CompaniesList.map((companie, index) => {
                    return (
                      <MenuItem value={companie.id} key={companie.uuid}>
                        {companie.name}
                      </MenuItem>
                    );
                  })}
                  {!this.props.initial ? (
                    <MenuItem value={this.state.All} key={"init"}>
                      Todos los Estados
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>,
            ]
          : [
              <FormControl>
                <OnLoadSelect />
              </FormControl>,
            ]}
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
