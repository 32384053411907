import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import Api from "../../../API/CompaniesForms";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";
import DialogAddQuestion from "./DialogAddQuestion";
import EditIcon from "@material-ui/icons/Edit";
import DialogQuestionDetails from "../../CompaniesFormsQuestions/Dialogs/DetailsDialog";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Margin: { margin: "10px" },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Oncreate: false,
      name: "",
      questions: [],
      ListOfQuestions: [],
      Answer: null,
    };
  }
  componentDidMount = async () => {
    if (this.props.Questions && this.props.Questions.length > 0) {
      this.setState({ List: this.props.Questions });
    } else {
      console.log("obtener la lista");
    }
  };
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });
      if (this.state.name.length > 0 && this.state.ListOfQuestions.length > 0) {
        const Deli = await Api.Create(this.state);
        console.log(Deli);
        if (Deli.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se creado con éxito");
          await this.props.Refresh("CreateDialog");
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible Crear el elemento");
        }
      } else {
        if (this.state.name.length < 1) {
          toastType.error("El nombre del formulario es obligatorio");
        }
        if (this.state.ListOfQuestions < 1) {
          toastType.error("No se puede Crear un Formulario Sin preguntas");
        }
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  Dialog = (a, b) => {
    this.setState({ [a]: !this.state[a], Answer: b ? b : null });
  };
  AddNewQuestion = (id) => {
    this.setState({ ListOfQuestions: [...this.state.ListOfQuestions, id] });
  };
  deleteQuestions = (index) => {
    let value = this.state.ListOfQuestions.filter((item, j) => index !== j);
    this.setState({ ListOfQuestions: value });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Nuevo Formulario</DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <TextField
              label="Nombre del Formulario"
              margin="normal"
              size="small"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
              fullWidth
            />
            {this.state.ListOfQuestions.length > 0 ? (
              <div className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pregunta</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Respuestas</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.ListOfQuestions.map((Article, index) => {
                      return this.state.List.map((obj) => {
                        if (obj.id === Article) {
                          return (
                            <React.Fragment>
                              <TableRow key={obj.id}>
                                <TableCell component="th" scope="row">
                                  {obj.content}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {obj.CompanyFormQuestionType.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {obj.createdAt.split("T")[0]}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.btn}
                                    onClick={this.Dialog.bind(
                                      this,
                                      "DialogQuestionDetails",
                                      obj
                                    )}
                                  >
                                    <EditIcon />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={this.deleteQuestions.bind(
                                      this,
                                      index
                                    )}
                                    className={classes.btn}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        } else {
                          return <></>;
                        }
                      });
                    })}
                  </TableBody>
                </Table>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.Margin}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.Dialog.bind(this, "DialogAddQuestion")}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
              </div>
            ) : (
              <Paper className={classes.NoItemPaper}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.Dialog.bind(this, "DialogAddQuestion")}
                  className={classes.AddCandidate}
                >
                  <AddBoxIcon />
                </Button>
              </Paper>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.name.length > 0
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
        {this.state.DialogAddQuestion && (
          <DialogAddQuestion
            DialogOpen={this.state.DialogAddQuestion}
            DialogClose={this.Dialog.bind(this, "DialogAddQuestion")}
            Refresh={this.Refresh}
            Questions={this.state.List}
            Send={this.AddNewQuestion}
          />
        )}
        {this.state.DialogQuestionDetails && (
          <DialogQuestionDetails
            DialogOpen={this.state.DialogQuestionDetails}
            DialogClose={this.Dialog.bind(this, "DialogQuestionDetails")}
            Answer={this.state.Answer}
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
