import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import Skeleton from "./SkeletonGrid";
import SeccionsCall from "../../../API/Sections";
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "200px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
    //marginTop:'1%',
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
});

class asesorInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { EditDialog: false, ConectionState: "onLoad", Statistic: {} };
  }

  componentDidMount = async () => {
    try {
      if (this.props.info.Uuid) {
        const AsesorStatistic = await SeccionsCall.AsesorStatistic(
          this.props.info.Uuid
        );
        console.log("blame", AsesorStatistic);
        if (AsesorStatistic) {
          this.setState({
            Statistic: AsesorStatistic,
            ConectionState: "onSuccess",
          });
        } else {
          this.setState({
            ConectionState: "onNull",
          });
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({
        ConectionState: "onError",
      });
      toasType.error("Error en la carga de datos");
    }
  };
  render() {
    const { classes } = this.props;
    switch (this.state.ConectionState) {
      case "onLoad":
        return (
          <Skeleton
            Name="Estadística de conjunto"
            className={classes.Skeleton}
          />
        );
      case "onSuccess":
        return (
          <Grid className={classes.Initial}>
            <Grid container className={classes.Initial} spacing={1}>
              <Grid container item xs={12} className={classes.Initial}>
                <Typography
                  align="Center"
                  variant="h5"
                  component="p"
                  className={classes.Subtitle}
                >
                  Estadística de conjunto
                </Typography>
                <Paper className={classes.Paper}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    iteam
                  >
                    <Grid container item xs={11}>
                      <TextField
                        label="Numero de Trabajadores"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={Math.round(this.state.Statistic.employeesCount)}
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Salario Promedio"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={Math.round(this.state.Statistic.meanWage)}
                        //variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Salario mas Alto"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={Math.round(this.state.Statistic.maxWage)}
                        //variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Salario mas Bajo"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={Math.round(this.state.Statistic.minWage)}
                        //variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Porcentaje de mujeres"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={
                          Math.round(this.state.Statistic.womenPercentage) +
                          " %"
                        }
                        //variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Porcentaje de hombres"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={
                          Math.round(this.state.Statistic.menPercentage) + " %"
                        }
                        //variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Edad Promedio"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={Math.round(this.state.Statistic.meanAge)}
                        //variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        );
      case "onError":
        return (
          <React.Fragment>
            <Grid
              item
              xs={12}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography
                align="Center"
                variant="h5"
                component="p"
                className={classes.Subtitle}
              >
                Listado de Empresas
              </Typography>
              <Paper className={classes.load}>
                <Typography variant="h6" gutterBottom align="center">
                  No fue posible obtener la Información
                </Typography>
              </Paper>
            </Grid>
          </React.Fragment>
        );
      case "onNull":
        return (
          <React.Fragment>
            <Grid
              item
              xs={11}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography
                align="Center"
                variant="h5"
                component="p"
                className={classes.Subtitle}
              >
                Listado de Empresas
              </Typography>
              <Paper className={classes.load}>
                <Typography variant="h6" gutterBottom align="center">
                  Sin Compañías Asignadas
                </Typography>
              </Paper>
            </Grid>
          </React.Fragment>
        );
      default:
        return (
          <Skeleton Name=" Sin Información" className={classes.Skeleton} />
        );
    }
  }
}

export default withStyles(styles)(asesorInfo);
