import base from "../baseurl";
import token from "../getTk"

const localplaces = async (Stateid) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const localplaces = await api.get("localplaces/index/"+Stateid).json();
    
    return localplaces.data.localplace ;
    
  };
  
  export default  localplaces ;