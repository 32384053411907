import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import MUIDataTable from "mui-datatables";
import UsersCall from "../../../API/Users";

const styles = (theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  Head: {
    backgroundColor: "#ad1d21",
  },

  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    // textAlign: 'center',
    color: "#2f3542",
    alignItems: "center",
    justifyContent: "center",
  },

  fab: {
    display: "inlineBlock",
    left: "0",
    color: "#FFFF",
    marginTop: "1%",
    marginRight: "3%",
  },

  add: {
    display: "flex",
    justifyContent: "flex-end",
  },
  color: {
    color: "#FFFF",
  },
  paperTable: {
    overflow: "auto",
    maxHeight: "77vh",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = { Users: [] };
  }

  fetchUsers = async () => {
    const UsersShow = await UsersCall.ShowAll();
    console.log(UsersShow);
    this.setState({ Users: UsersShow });
  };

  componentDidMount = async () => {
    await this.fetchUsers();
  };

  render() {
    const { classes } = this.props;
    const data = [];

    this.state.Users.map((User) => {
      const btn = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Usuarios/Informacion",
            state: {
              UserUuid: User.uuid,
            },
          }}
        >
          <PersonIcon />
        </Link>
      );
      var Type = "";

      switch (User.userType) {
        case 72468:
          Type = "Administrativo";
          break;
        case 48235:
          Type = "General";
          break;

        case 81493:
          Type = "Asesor";
          break;
        default:
          Type = "Auditor";
          break;
      }
      data.push([
        User.UserProfile.name,
        User.UserProfile.lastNames,
        Type,
        User.UserProfile.mobilePhone,
        User.email,
        btn,
      ]);
      return data;
    });

    const columns = [
      "Nombre",
      "Apellidos",
      "Tipo",
      "Teléfono",
      "E-mail",
      "Perfil",
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    return (
      <div>
        <Paper className={classes.paperTable}>
          <MUIDataTable
            title={"Usuarios"}
            data={data}
            columns={columns}
            options={options}
          />
        </Paper>
        <div className={classes.add}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            className={classes.fab}
          >
            <Link className={classes.Link} to="/Usuarios/Crear">
              <AddIcon className={classes.color} />
            </Link>
          </Fab>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Users));
