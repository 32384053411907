import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Home from "./home";
import Nosotros from "./Nosotros";
import Capacitacion from "./Capacitacion";
import Catalogo from "./Catalogo";
import Sitimm from "./Sitimm";
import Oficinas from "./Oficinas";
import Servicios from "./Servicios";
import Eventos from "./Eventos";
import Educacion from "./Educacion";
import Convenios from "./Convenios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "80vw",
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Home" {...a11yProps(0)} />
          <Tab label="Nosotros" {...a11yProps(1)} />
          <Tab label="Cap Empresas" {...a11yProps(2)} />
          <Tab label="Cap Catalogo" {...a11yProps(3)} />
          <Tab label="Cap SITIMM" {...a11yProps(4)} />
          <Tab label="Oficinas" {...a11yProps(5)} />
          <Tab label="Servicios" {...a11yProps(6)} />
          <Tab label="Eventos" {...a11yProps(7)} />
          <Tab label="Educación" {...a11yProps(8)} />
          <Tab label="Convenios" {...a11yProps(9)} />
          {
            //<Tab label="Biblioteca" {...a11yProps(10)} />
          }
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Home />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Nosotros />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Capacitacion />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Catalogo />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Sitimm />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Oficinas />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Servicios />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Eventos />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Educacion />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Convenios />
      </TabPanel>
      {/*<TabPanel value={value} index={10}>
      <Biblioteca />
      </TabPanel> */}
    </div>
  );
}
