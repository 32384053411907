import base from "../baseurl";
import token from "../getTk";

const States = async (Uuid) => {
  const tokenA = token.get();

  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Country = await api.get("countries/index").json();
  const States = await api
    .get("states/index/" + Country.data.country[0].uuid)
    .json();

  return States.data.states;
};

export default States;
