import base from "../baseurl";
import token from "../getTk"

const Profile = async (Uuid) => {   
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
  const Profile = await api.post("users/show",{json: {userUuid:Uuid}}).json();
  return Profile.data.user;
  };
  
  export default Profile ;