import base from "../../baseurl";
import token from "../../getTk";

const Create = async () => {
  //console.log(state)
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Update = await api
    .post("event-types/showall", {
      timeout: false,
      json: {},
    })
    .json();
  console.log("ShowAll event type", Update);
  return Update;
};

export default Create;
