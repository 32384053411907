import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/Add";
import MUIDataTable from "mui-datatables";
import EmployeesCall from "../../../API/Companies";
import toastType from "../../../API/CallUtils/tostyNotification";
import Update from "../../../API/CallUtils/UpdateWorkerInfo";
import CallUtils from "../../../API/CallUtils";
import CandidatesCall from "../../../API/Cadidates";
import SeletPosition from "../componetsUtils/SeletCandidatePosition";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    marginLeft: "10px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateName: "",
      candidateRFC: "",
      candidatePhoto: "",
      candidateLocation: "",
      candidateNumber: "",
      candidateNew: false,
      candidateUuid: "",
      candidateINFO: {},
      Candidate: [],
      fullList: [],
      CandidatePosition: null,
      candidatePosition: "",
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.CompanieUuid) {
        const dates = await EmployeesCall.CensusDates(this.props.CompanieUuid);
        if (dates[0]) {
          await this.fetchEmployees(dates[0].uuid);
        } else {
          toastType.error(
            "Ningún padrón existente, error: ningún candidato posible"
          );
        }
      } else {
        toastType.error("El id de la votación o de la compañía esta perdido");
      }
    } catch (error) {
      toastType.error("Error en la obtención de trabajadores");
      console.log(error);
    }
  };
  DialogBack = () => {
    this.setState({ candidateNew: false });
  };
  CandidateDetails = (candidate, e) => {
    console.log("datos del candidato", candidate);
    this.setState({
      candidateNew: true,
      candidateINFO: candidate,
      candidateName: candidate.User.UserProfile.name,
      candidateRFC: candidate.User.UserProfile.rfc,
      candidateUuid: candidate.uuid,
      candidateLocation: candidate.job ? candidate.job : "",
      candidateNumber: candidate.payroll ? candidate.payroll : "",
    });
  };
  fetchEmployees = async (censusUuid) => {
    try {
      const EmployeesShow = await EmployeesCall.ShowAllEmployees(censusUuid);
      this.setState({ fullList: EmployeesShow });
    } catch (error) {
      toastType.error("Padrón perdido");
    }
  };
  addIMG = async (Name, e) => {
    this.setState({
      onSave: true,
    });
    let principal = await this.SendImg(
      e.target.files[0],
      "SITIMM_VOTING_CANDIDATE_" + this.props.ElectionUuid
    );
    this.setState({
      [Name]: principal,
      onSave: false,
    });
  };
  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    //console.log(upload.data.location);
    return upload.data.location;
  };
  newCandidate = async () => {
    if (this.checkInfoCandidate()) {
      try {
        if (this.checkInfoChange()) {
          const newCan = await CandidatesCall.Create(
            this.state.candidateUuid,
            this.props.ElectionUuid,
            this.state.candidatePhoto,
            this.props.CandiatePosition
              ? this.props.CandiatePosition
              : this.state.candidatePosition.name,
            this.props.WorkSheet ? this.props.WorkSheet : null
          );
          //console.log("qwe", newCan);
          if (newCan.status === "success") {
            this.cleanCandidateInfo();
            this.DialogBack();
          } else {
            console.log(newCan);
            toastType.error("No ha sido posible agregar este candidato");
            this.cleanCandidateInfo();
          }
        } else {
          toastType.error("No ha sido posible agregar este candidato");
          this.cleanCandidateInfo();
        }
      } catch (error) {
        console.log(error);
        this.cleanCandidateInfo();
        toastType.error("No ha sido posible agregar este candidato");
      }
    }
  };
  cleanCandidateInfo = () => {
    this.setState({
      candidateName: "",
      candidateRFC: "",
      candidatePhoto: "",
      candidateUuid: "",
      candidateINFO: {},
      candidateLocation: "",
      candidateNumber: "",
      candidateNew: false,
      candidatePosition: "",
    });
  };
  onChange = (event) => {
    //console.log("nombre", event.target.name, "valor", event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  checkInfoCandidate = () => {
    if (this.props.type === "WORKSHEET") {
      if (
        this.state.candidatePhoto &&
        this.state.candidateLocation &&
        this.state.candidateNumber &&
        this.state.candidatePosition
      ) {
        return true;
      } else {
        if (!this.state.candidatePhoto) {
          toastType.error("El candidato requiere foto");
        }
        if (!this.state.candidateLocation) {
          toastType.error("El candidato Requiere un area de trabajo");
        }
        if (!this.state.candidateNumber) {
          toastType.error("El candidato Requiere un numero de trabajador");
        }
        if (!this.state.candidatePosition) {
          toastType.error("El candidato Requiere una candidatura");
        }
        return false;
      }
    } else {
      if (
        this.state.candidatePhoto &&
        this.state.candidateLocation &&
        this.state.candidateNumber
      ) {
        return true;
      } else {
        if (!this.state.candidatePhoto) {
          toastType.error("El candidato requiere foto");
        }
        if (!this.state.candidateLocation) {
          toastType.error("El candidato Requiere un area de trabajo");
        }
        if (!this.state.candidateNumber) {
          toastType.error("El candidato Requiere un numero de trabajador");
        }
        return false;
      }
    }
  };
  checkInfoChange = async () => {
    try {
      if (
        this.state.candidateINFO.job === this.state.candidateLocation &&
        this.state.candidateINFO.payroll === this.state.candidateNumber
      ) {
        return true;
      } else {
        const UpdateWorker = await Update(
          this.state.candidateINFO,
          this.state.candidateNumber,
          this.state.candidateLocation
        );
        if (UpdateWorker.status === "success") {
          toastType.success("Información Actualizada");
          console.log(UpdateWorker);
          return true;
        } else {
          console.log("no se pudo subir");
          toastType.error("No ha sido posible agregar este candidato");
          return false;
        }
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  render() {
    const { classes } = this.props;
    const data = [];
    const columns = ["Nombre", "RFC", "Seleccionar"];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin información disponible",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 20,
      rowsPerPageOptions: [10, 50, 100, 200],
    };
    if (this.state.fullList.length > 0) {
      this.state.fullList.map((Comp) => {
        const btn2 = (
          <Button
            color="primary"
            onClick={this.CandidateDetails.bind(this, Comp)}
          >
            {" "}
            <AddBoxIcon />{" "}
          </Button>
        );

        data.push([
          Comp.User.UserProfile.name,
          Comp.User.UserProfile.rfc,
          btn2,
        ]);
        //console.log(Comp.User);
        return data;
      });
    }
    switch (this.props.type) {
      case "SINGULAR":
        return (
          <>
            <Dialog
              open={this.props.DialogOpen}
              onClose={this.props.DialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
            >
              <DialogTitle id="form-dialog-title">Nuevo Candidato</DialogTitle>
              <DialogContent>
                <Grid container className={classes.GridD}>
                  <Paper className={classes.PaperDialogNewCandidate}>
                    {this.state.candidateNew
                      ? [
                          <>
                            <Grid container>
                              <Grid
                                iteam
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                xl={4}
                                className={classes.GridDialog}
                              >
                                {this.state.candidatePhoto ? (
                                  <img
                                    src={this.state.candidatePhoto}
                                    alt="placeholder"
                                    className={classes.ImgProp}
                                  />
                                ) : (
                                  <Paper className={classes.ImgPlaceholder}>
                                    <Typography
                                      align="Center"
                                      variant="body2"
                                      color="textPrimary"
                                      component="p"
                                    >
                                      Sin imagen
                                    </Typography>
                                  </Paper>
                                )}
                                <div className={classes.Remp}>
                                  <input
                                    type="file"
                                    onChange={this.addIMG.bind(
                                      this,
                                      "candidatePhoto"
                                    )}
                                    className={classes.input}
                                    id="ElectionCadidate"
                                    required
                                  />
                                  <label htmlFor="ElectionCadidate">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      size="small"
                                      className={classes.btn}
                                    >
                                      <AddBoxIcon />
                                    </Button>
                                  </label>
                                </div>
                              </Grid>

                              <Grid
                                iteam
                                xs={12}
                                sm={12}
                                md={7}
                                lg={7}
                                xl={7}
                                className={classes.GridDialog}
                              >
                                <TextField
                                  label="Nombre"
                                  name="candidateName"
                                  value={this.state.candidateName}
                                  className={classes.Text}
                                  //onChange={this.onChange}
                                  disabled
                                  size="small"
                                />
                                <TextField
                                  label="RFC"
                                  name="candidateRFC"
                                  value={this.state.candidateRFC}
                                  className={classes.Text}
                                  //onChange={this.onChange}
                                  disabled
                                  size="small"
                                />
                                <TextField
                                  label="Area De trabajo"
                                  name="candidateLocation"
                                  value={this.state.candidateLocation}
                                  className={classes.Text}
                                  onChange={this.onChange}
                                  variant="outlined"
                                  size="small"
                                />
                                <TextField
                                  label="Numero de Empleado"
                                  name="candidateNumber"
                                  value={this.state.candidateNumber}
                                  className={classes.Text}
                                  onChange={this.onChange}
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          </>,
                        ]
                      : [
                          this.state.fullList.length > 0
                            ? [
                                <MUIDataTable
                                  title={"Empleados"}
                                  data={data}
                                  columns={columns}
                                  options={options}
                                  selectableRows="none"
                                />,
                              ]
                            : [
                                <Typography
                                  align="Center"
                                  variant="h5"
                                  color="textPrimary"
                                  component="p"
                                  className={classes.Subtitle}
                                >
                                  No hay Empleados
                                </Typography>,
                              ],
                        ]}
                  </Paper>
                </Grid>
              </DialogContent>
              <DialogActions>
                {this.state.candidateNew
                  ? [
                      <>
                        <Button onClick={this.DialogBack} color="secondary">
                          Regresar
                        </Button>
                        <Button
                          onClick={this.props.DialogClose}
                          color="secondary"
                        >
                          Cancelar
                        </Button>
                      </>,
                    ]
                  : [
                      <Button
                        onClick={this.props.DialogClose}
                        color="secondary"
                      >
                        Cancelar
                      </Button>,
                    ]}

                {this.state.candidateNew
                  ? [
                      <Button onClick={this.newCandidate} color="primary">
                        Crear Candidato
                      </Button>,
                    ]
                  : []}
              </DialogActions>
            </Dialog>
          </>
        );
      case "WORKSHEET":
        return (
          <>
            <Dialog
              open={this.props.DialogOpen}
              onClose={this.props.DialogClose}
              aria-labelledby="form-dialog-title"
              maxWidth="md"
            >
              <DialogTitle id="form-dialog-title">Nuevo Candidato</DialogTitle>
              <DialogContent>
                <Grid container className={classes.GridD}>
                  <Paper className={classes.PaperDialogNewCandidate}>
                    {this.state.candidateNew
                      ? [
                          <>
                            <Grid container>
                              <Grid
                                iteam
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                xl={4}
                                className={classes.GridDialog}
                              >
                                {this.state.candidatePhoto ? (
                                  <img
                                    src={this.state.candidatePhoto}
                                    alt="placeholder"
                                    className={classes.ImgProp}
                                  />
                                ) : (
                                  <Paper className={classes.ImgPlaceholder}>
                                    <Typography
                                      align="Center"
                                      variant="body2"
                                      color="textPrimary"
                                      component="p"
                                    >
                                      Sin imagen
                                    </Typography>
                                  </Paper>
                                )}
                                <div className={classes.Remp}>
                                  <input
                                    type="file"
                                    onChange={this.addIMG.bind(
                                      this,
                                      "candidatePhoto"
                                    )}
                                    className={classes.input}
                                    id="ElectionCadidate"
                                    required
                                  />
                                  <label htmlFor="ElectionCadidate">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      size="small"
                                      className={classes.btn}
                                    >
                                      <AddBoxIcon />
                                    </Button>
                                  </label>
                                </div>
                              </Grid>

                              <Grid
                                iteam
                                xs={12}
                                sm={12}
                                md={7}
                                lg={7}
                                xl={7}
                                className={classes.GridDialog}
                              >
                                <TextField
                                  label="Nombre"
                                  name="candidateName"
                                  value={this.state.candidateName}
                                  className={classes.Text}
                                  //onChange={this.onChange}
                                  variant="outlined"
                                  size="small"
                                />
                                <TextField
                                  label="RFC"
                                  name="candidateRFC"
                                  value={this.state.candidateRFC}
                                  className={classes.Text}
                                  //onChange={this.onChange}
                                  variant="outlined"
                                  size="small"
                                />
                                <TextField
                                  label="Area De trabajo"
                                  name="candidateLocation"
                                  value={this.state.candidateLocation}
                                  className={classes.Text}
                                  onChange={this.onChange}
                                  variant="outlined"
                                  size="small"
                                />
                                <TextField
                                  label="Numero de Empleado"
                                  name="candidateNumber"
                                  value={this.state.candidateNumber}
                                  className={classes.Text}
                                  onChange={this.onChange}
                                  variant="outlined"
                                  size="small"
                                />
                                <SeletPosition
                                  type={false}
                                  value={this.state.candidatePosition}
                                  onChange={this.onChange}
                                />
                              </Grid>
                            </Grid>
                          </>,
                        ]
                      : [
                          this.state.fullList.length > 0
                            ? [
                                <MUIDataTable
                                  title={"Empleados"}
                                  data={data}
                                  columns={columns}
                                  options={options}
                                  selectableRows="none"
                                />,
                              ]
                            : [
                                <Typography
                                  align="Center"
                                  variant="h5"
                                  color="textPrimary"
                                  component="p"
                                  className={classes.Subtitle}
                                >
                                  No hay Empleados
                                </Typography>,
                              ],
                        ]}
                  </Paper>
                </Grid>
              </DialogContent>
              <DialogActions>
                {this.state.candidateNew
                  ? [
                      <>
                        <Button onClick={this.DialogBack} color="secondary">
                          Regresar
                        </Button>
                        <Button
                          onClick={this.props.DialogClose}
                          color="secondary"
                        >
                          Cancelar
                        </Button>
                      </>,
                    ]
                  : [
                      <Button
                        onClick={this.props.DialogClose}
                        color="secondary"
                      >
                        Cancelar
                      </Button>,
                    ]}

                {this.state.candidateNew
                  ? [
                      <Button onClick={this.newCandidate} color="primary">
                        Crear Candidato
                      </Button>,
                    ]
                  : []}
              </DialogActions>
            </Dialog>
          </>
        );
      default:
        return (
          <Dialog
            open={this.props.DialogOpen}
            onClose={this.props.DialogClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">Nuevo Candidato</DialogTitle>
            <DialogContent>
              <Typography
                variant="body1"
                color="textPrimary"
                component="p"
                className={classes.Title}
              >
                Error: type election are missing
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.props.DialogClose}
                variant="contained"
                color="primary"
              >
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
        );
    }
  }
}

export default withStyles(styles)(addCandidateDialog);
