import React from "react";
import "babel-polyfill";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import blogCall from "../../../API/CTM/blog";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Img from "../../../API/CallUtils/img";
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "100%",
    height: "80vh",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "cover",
  },
  tool: {
    backgroundColor: "#e6eced",
    maxHeight: "400px",
    overflow: "auto",
  },
});

class Blog extends React.Component {
  state = {
    Blog: [],
    Dialog: false,
    DialogDelete: false,
    Uuid: "",
    Title: "",
    img: null,
    TextArticle: "",
    DialogCreate: false,
    editorState: EditorState.createEmpty(),
    imgRaw: null,
    hoper: null,
    FileName: "",
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  ArticleD = (Uuid, title, img, text, e) => {
    const contentBlock = htmlToDraft(text);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      this.setState({ editorState });
    }

    this.setState({
      Uuid: Uuid,
      Title: title,
      img: img,
      Dialog: true,
    });
  };

  DialogEntrando = async () => {
    this.setState({
      Uuid: "",
      Title: "",
      img: null,
      imgRaw: null,
      editorState: EditorState.createEmpty(),
      Dialog: false,
    });
  };

  DialogDelete = async () => {
    this.setState({ DialogDelete: false });
  };

  DialogCreate = async () => {
    this.setState({ DialogCreate: false });
  };

  create = async () => {
    var value = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    try {
      var create = await blogCall.Create(
        this.state.Title,
        value,
        this.state.imgRaw,
        this.state.FileName
      );
      if (create.status === "success") {
        toasType.success("Blog Creado");
        this.setState({
          DialogCreate: false,
          editorState: EditorState.createEmpty(),
          img: null,
          imgRaw: null,
          FileName: "",
          Title: "",
        });
      } else {
        toasType.error("No fue posible Crear");
      }
    } catch (e) {
      toasType.error("No fue posible Crear");
    }

    //console.log(create)

    await this.showAll();
  };

  update = async () => {
    var value = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    try {
      const Update = await blogCall.Update(this.state, value);
      if (Update.status === "success") {
        toasType.success("Blog actualizado");
        this.setState({
          img: null,
          Uuid: "",
          imgRaw: null,
          Title: "",
          editorState: EditorState.createEmpty(),
          Dialog: false,
          FileName: "",
        });
      } else {
        toasType.error("NO fue posible Actualizar");
      }
    } catch {
      toasType.error("NO fue posible Actualizar");
    }
    await this.showAll();
  };

  delete = async (Uuid, e) => {
    try {
      const Delete = await blogCall.Delete(Uuid);
      if (Delete.status === "success") {
        toasType.success("Blog Eliminado");
      } else {
        toasType.error("No fue posible eliminar");
      }
    } catch (e) {
      toasType.error("No fue posible eliminar");
    }

    await this.showAll();
  };

  showAll = async () => {
    try {
      const polute = await blogCall.showAll();
      if (polute) {
        this.setState({ Blog: polute });
      } else {
        toasType.error("No fue posible cargar la información");
      }
    } catch (e) {
      toasType.error("No fue posible cargar la información");
    }
  };

  Opencreate = async () => {
    this.setState({ DialogCreate: true });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  uploadSingleFile = async (e) => {
    var name = e.target.files[0].name;
    const str = await Img.getBinaryString(e.target.files[0]);
    var imgDone = btoa(str);

    this.setState({
      img: Img.getImg(str),
      imgRaw: imgDone,
      FileName: name,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container>
            {this.state.Blog.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Detalles</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Blog.map((Article) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.createdAt.split("T")[0]}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.ArticleD.bind(
                                  this,
                                  Article.uuid,
                                  Article.title,
                                  Article.img,
                                  Article.body
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.delete.bind(this, Article.uuid)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}
            <div className={classes.btnTable}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addBTN}
                onClick={this.Opencreate}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </Grid>
        </Paper>

        <Dialog
          open={this.state.Dialog}
          onClose={this.DialogEntrando}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          fullWidth="xl"
        >
          <DialogTitle id="form-dialog-title">Articulo</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <div className={classes.text}>
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="Title"
                  value={this.state.Title}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </div>

              <div className={classes.imgDiv}>
                <Typography>Imagen principal</Typography>
              </div>

              {this.state.img !== null ? (
                <div className={classes.imgDiv}>
                  <img src={this.state.img} className={classes.ImgPre} alt="" />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.uploadSingleFile}
                  className={classes.imgDiv}
                />
              </div>

              <div>
                <Editor
                  editorState={this.state.editorState}
                  //wrapperClassName={classes.tool}
                  editorClassName={classes.tool}
                  //toolbarClassName={classes.tool}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogEntrando} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.update} color="primary">
              Guardar Cambios
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogDelete}
          onClose={this.DialogDelete}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Eliminar Articulo</DialogTitle>
          <DialogContent>
            <DialogContentText>Esta acción sera registrada</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogDelete} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.DialogDelete} color="primary">
              Eliminar Articulo
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogCreate}
          onClose={this.DialogCreate}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          fullWidth="xl"
        >
          <DialogTitle id="form-dialog-title">Articulo</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <div className={classes.text}>
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="Title"
                  value={this.state.Title}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </div>

              <div className={classes.imgDiv}>
                <Typography>Imagen principal</Typography>
              </div>

              <div className={classes.imgDiv}>
                <img src={this.state.img} className={classes.ImgPre} alt="" />
              </div>

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.uploadSingleFile}
                  className={classes.imgDiv}
                />
              </div>

              <Grid
                item
                xs={12}
                className={classes.GridC}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div>
                  <Editor
                    editorState={this.state.editorState}
                    editorClassName={classes.tool}
                    onEditorStateChange={this.onEditorStateChange}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogCreate} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.create} color="primary">
              Crear Articulo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(Blog);
