import base from "../../baseurl";
import token from "../../getTk"

const Delete = async (Uuid) => {
  //console.log("eliminar", Uuid)
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Delete = await api.post("blogposts/delete",{json: {
      blogpostUuid:Uuid
    }}).json();
   
    return Delete ;
    
  };
  
  export default  Delete ;