import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import UploadFile from "../../../../Components/Header/UploadFiles";
import ArrayFiles from "../../../../Components/Header/ArrayFiles";
import SelectRegis from "./SelectRegis";
import SelectType from "./SelectType";
import SelectTransport from "./SelectTransport";
import SelectParticipant from "./SelectParticipant";
import SelectConfirmation from "./SelectConfirmation";
import LinksTable from "./LinksTable";
import BusStop from "./BusStop";
import CampusId from "./Campus";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Remp: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});
class createNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.center}>
        <TextField
          id="standard-basic"
          label="Titulo"
          name="title"
          value={this.props.ParentState.title}
          className={classes.text}
          onChange={(ev) => {
            this.props.parentCallback(ev.target.value, "title");
          }}
        />

        <TextField
          id="standard-basic"
          label="Lugar"
          name="place"
          value={this.props.ParentState.place}
          className={classes.text}
          onChange={(ev) => {
            this.props.parentCallback(ev.target.value, "place");
          }}
        />
        <DatePicker
          label="Fecha del evento"
          name="eventDate"
          value={this.props.ParentState.eventDate}
          onChange={(ev) => {
            this.props.changeDate(ev);
          }}
          className={classes.text}
        />
        <div className={classes.text}>
          <SelectRegis
            ParentState={this.props.ParentState}
            ParentSet={this.props.parentCallback}
          />
        </div>
        {this.props.ParentState.register && (
          <div className={classes.text}>
            <SelectConfirmation
              ParentState={this.props.ParentState}
              ParentSet={this.props.parentCallback}
            />
          </div>
        )}
        {this.props.ParentState.hasConfirmation && (
          <TextField
            label=" Días previos para enviar recordatorio"
            value={this.props.ParentState.daysBeforeAnouncment}
            className={classes.text}
            onChange={(ev) => {
              this.props.parentCallback(
                ev.target.value,
                "daysBeforeAnouncment"
              );
            }}
          />
        )}
        <div className={classes.text}>
          <SelectParticipant
            ParentState={this.props.ParentState}
            ParentSet={this.props.parentCallback}
          />
        </div>
        <div className={classes.text}>
          <SelectType
            ParentState={this.props.ParentState}
            ParentSet={this.props.parentCallback}
          />
        </div>
        {this.props.ParentState.eventTypeId !== 1 && (
          <div className={classes.text}>
            <LinksTable
              ParentState={this.props.ParentState}
              ParentSet={this.props.parentCallback}
            />
          </div>
        )}
        {this.props.ParentState.eventTypeId !== 2 && (
          <div className={classes.text}>
            <SelectTransport
              ParentState={this.props.ParentState}
              ParentSet={this.props.parentCallback}
            />
          </div>
        )}
        {this.props.ParentState.offersMobility &&
          this.props.ParentState.eventTypeId !== 2 && (
            <div className={classes.text}>
              <BusStop
                ParentState={this.props.ParentState}
                ParentBusSet={this.props.parentCallback}
              />
            </div>
          )}
        {this.props.ParentState.eventTypeId === 3 && (
          <div className={classes.text}>
            <CampusId
              ParentState={this.props.ParentState}
              ParentBusSet={this.props.parentCallback}
            />
          </div>
        )}

        <div className={classes.text}>
          <UploadFile
            Type="Imagen"
            Place="SITIMM_EVENTS_Private_IMG"
            parentCallback={this.props.parentCallback}
            stateNameOnParent="img"
            PrevImg={this.props.ParentState.img}
          />
        </div>

        <TextField
          id="standard-read-only-input"
          label="Resumen del evento"
          className={classes.text}
          margin="normal"
          name="description"
          multiline
          rows="6"
          onChange={(ev) => {
            this.props.parentCallback(ev.target.value, "description");
          }}
          value={this.props.ParentState.description}
          variant="outlined"
        />
        <div className={classes.text}>
          <ArrayFiles
            Type="Documento"
            Place="SITIMM_EVENTS_Private_Files"
            parentCallback={this.props.parentCallback}
            stateNameOnParent="Files"
            PrevFilesList={this.props.ParentState.Files}
          />
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(createNewEvent);
