import Create from "./Create";
import Delete from "./Delete";
import ShowAll from "./ShowAll";
import SPShowAll from "./SpecilShowAll";

const Companies = {
  ShowAll,
  Delete,
  Create,
  SPShowAll,
};

export default Companies;

export { Create, ShowAll, Delete, SPShowAll };
