import base from "../../baseurl";
import token from "../../getTk"


const Update = async (state) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
   console.log("estado en la llamada",state)
    const Update = await api.post("pages/update",{json: {
        pageId:7,
        name:"eventos",
        content:{
            title:state.title,
            welcomeText:state.welcomeText,
            Events:state.files
        }
    }
    ,
        timeout:false
}).json();
   console.log(Update)
    return Update ;
    
  };
  
  export default  Update ;