import base from "../baseurl";
import token from "../getTk"

const Show = async (Uuid) => {
  const tokenA = token.get()

  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const Show = await api.post("headquarters/show",{json: {headquarterUuid:Uuid,}}).json();
    //console.log("llamada",Uuid)
    return Show.data.headquarter ;
    
  };
  
  export default  Show ;
  