import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListAltIcon from "@material-ui/icons/ListAlt";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },

  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    textAlign: "center",
    color: "#2f3542",
  },
}));

export default function Statistics() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Encuestas de Validación" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link className={classes.Link} to="/Encuestas/Campañas">
            <ListItem button className={classes.nested}>
              <ListItemText>Campañas</ListItemText>
            </ListItem>
          </Link>
          <Link className={classes.Link} to="/Encuestas/Formularios">
            <ListItem button className={classes.nested}>
              <ListItemText>Formularios</ListItemText>
            </ListItem>
          </Link>
          <Link className={classes.Link} to="/Encuestas/Preguntas">
            <ListItem button className={classes.nested}>
              <ListItemText>Preguntas</ListItemText>
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </div>
  );
}
