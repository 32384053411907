import base from "../baseurl";
import token from "../getTk";

const Create = async (newPosition, type) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("candidate-positions/create", {
      json: {
        name: newPosition,
        isSingular: type ? type : true,
      },
    })
    .json();
  return Create;
};
export default Create;
