import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const styles = (theme) => ({
  Table: {
    width: "100%",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function SelectRegis(props) {
  return (
    <Grid container className={props.classes.Table}>
      <FormControl className={props.classes.Table}>
        <InputLabel>Registro de participantes</InputLabel>
        <Select
          value={props.ParentState.register}
          onChange={(ev) => {
            props.ParentSet(ev.target.value, "register");
          }}
        >
          <MenuItem value={true} key={1}>
            Solicitar Registro
          </MenuItem>
          <MenuItem value={false} key={2}>
            No Solicitar Registro
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export default withStyles(styles)(SelectRegis);
