import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { DatePicker } from "material-ui-pickers";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UserCall from "../../../API/Users";
import HQCall from "../../../API/Headquarters";
import toasType from "../../../API/CallUtils/tostyNotification";
import OfficeList from "./OfficeList";

import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "70%",
    padding: "2%",
  },

  Grid: {
    width: "100%",
    height: "100%",
  },

  textField: {
    width: "88%",
  },
  textFieldM: {
    width: "70%",
  },

  button: {
    marginLeft: "20px",
    marginTop: "24px",
    width: "25%",
  },

  div: {
    display: "flex",
    width: "96%",
    justifyContent: "end",
    marginTop: "3%",
  },

  Avatar: {
    width: "75px",
    height: "75px",
  },
  btn: {
    marginRight: "10px",
  },
  AvatarDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  textFieldAvatar: {
    width: "84%",
    marginTop: "4%",
    marginLeft: "2%",
  },
  PersonDiv: {
    width: "100%",
  },
  textFieldPerson: {
    width: "94%",
  },
  DatosDiv: {
    width: "100%",
  },
  ContratoDiv: {
    width: "100%",
  },
  Radio: {
    padding: "18.5px 14px",
  },
  DivAge: {
    width: "100%",
    display: "flex",
  },
  DivAgeC: {
    width: "50%",
  },
  DivSex: {
    marginTop: "19px",
  },
  color: {
    color: "#9e9e9e",
  },
  formControl: {
    width: "85%",
  },
});

const mapStateToProps = (state) => {
  return { Uuid: state.Uuid };
};

class UsersCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      lastNames: "",
      IdateOfBird: "2020-01-04",
      sex: "Hombre",
      stateId: "",
      cityId: "",
      address: "",
      mobilePhone: "",
      email: "",
      pass: "",
      pass1: "",
      Uuid: "",
      userType: "",
      HQid: "",
      HQ: {},
      dateOfBird: moment().toISOString(),
    };
  }
  Create = async (State) => {
    try {
      if (this.state.pass === this.state.pass1) {
        const createNewUser = await UserCall.Create(State);
        if (createNewUser && createNewUser.status === "success") {
          toasType.success("Nuevo Usuario creado");
          this.props.history.push("/Usuarios");
        } else {
          toasType.error("No fue posible Crear el nuevo usuario");
        }
      } else {
        toasType.error("Las contraseñas no coinciden");
      }
    } catch (e) {
      console.log(e);
      toasType.error("No fue posible crear el nuevo usuario");
    }
  };

  ShowAllHQ = async (State) => {
    const ShowHQ = await HQCall.ShowAll();
    this.setState({ HQ: ShowHQ });
  };

  componentDidMount = async () => {
    await this.ShowAllHQ();
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeDate = (dateOfBird) => {
    this.setState({ dateOfBird: new Date(dateOfBird) });
    var fecha = moment(new Date(dateOfBird)).toISOString();
    this.setState({ IdateOfBird: fecha });
  };

  handleChange = async (ev) => {
    this.setState({ sex: ev.target.value });
  };

  CreateUser = async (e) => {
    await this.Create(this.state);
  };
  handleChangePlace = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.Grid}>
          <div className={classes.PersonDiv}>
            <Grid container className={classes.Grid}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Nombre"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  className={classes.textFieldPerson}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Apellidos"
                  name="lastNames"
                  value={this.state.lastNames}
                  onChange={this.onChange}
                  className={classes.textFieldPerson}
                  margin="normal"
                  variant="outlined"
                />
                <div className={classes.DivAge}>
                  <div className={classes.DivAgeC}>
                    <p className={classes.color}>Fecha de nacimiento</p>
                    <DatePicker
                      value={this.state.dateOfBird}
                      onChange={this.changeDate}
                      format="ll"
                      inputVariant="outlined"
                      openTo="year"
                      disableFuture
                    />
                  </div>
                  <div className={classes.DivSex}>
                    <FormControl component="fieldset" className={classes.Radio}>
                      <FormLabel component="legend">Genero</FormLabel>
                      <RadioGroup
                        aria-label="position"
                        name="position"
                        onChange={this.handleChange}
                        value={this.state.sex}
                        row
                      >
                        <FormControlLabel
                          value="Hombre"
                          control={<Radio color="primary" />}
                          label="Hombre"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="Mujer"
                          control={<Radio color="secondary" />}
                          label="Mujer"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.DatosDiv}>
            <Grid container className={classes.Grid}>
              <Grid
                item
                xs={6}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Teléfono"
                  name="mobilePhone"
                  value={this.state.mobilePhone}
                  onChange={this.onChange}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="E-mail"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
                <OfficeList
                  value={this.state.HQid}
                  Send={(value) => {
                    this.setState({ HQid: value });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Contraseña"
                  name="pass"
                  value={this.state.pass}
                  onChange={this.onChange}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  type="password"
                  required
                />
                <TextField
                  id="standard-read-only-input"
                  label="Repetir contraseña"
                  name="pass1"
                  value={this.state.pass1}
                  onChange={this.onChange}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  type="password"
                  required
                />
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Tipo de Usuario
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.userType}
                    onChange={this.handleChangePlace("userType")}
                  >
                    <MenuItem value={72468}>Administrativo</MenuItem>
                    <MenuItem value={48235}>General</MenuItem>
                    <MenuItem value={81493}>Asesor</MenuItem>
                    <MenuItem value={23648}>RH</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.div}>
            <Link className={classes.Link} to="/Usuarios">
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn}
              >
                cancelar
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={this.CreateUser}
            >
              Crear
            </Button>
          </div>
        </Grid>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(UsersCreate));
