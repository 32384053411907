import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HomeCall from "../../../API/Ceforma/Educacion";
import CallUtils from "../../../API/CallUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "70%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
  input: {
    display: "none",
  },
});

class Educacion extends React.Component {
  state = {
    title: "",
    welcomeText: "",
    ImgPrincipal: "",
    SecondaryText: "",
    Banner1: "",
    requirements: [],
    requi: "",
    DialogRequirements: false,
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    const Update = await HomeCall.Update(this.state);
    console.log(Update);
    await this.showAll();
  };

  showAll = async () => {
    const polute = await HomeCall.showAll();
    this.setState({
      title: polute.title,
      welcomeText: polute.welcomeText,
      ImgPrincipal: polute.ImgPrincipal,
      SecondaryText: polute.SecondaryText,
      Banner1: polute.Banner1,
      requirements: polute.requirements,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addrequirements = async (e) => {
    var newelement = {
      requirement: this.state.requi,
    };

    this.setState({
      requirements: [...this.state.requirements, newelement],
    });
    this.setState({
      DialogRequirements: false,
      requi: "",
    });
  };

  principalIMG = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_EDUCATION_PrincipalIMG"
    );
    this.setState({
      ImgPrincipal: principal,
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo Principal"
                name="title"
                value={this.state.title}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto Principal"
                className={classes.text}
                margin="normal"
                name="welcomeText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.welcomeText}
                variant="outlined"
              />
              <TextField
                id="standard-read-only-input"
                label="Texto Secundario"
                className={classes.text}
                margin="normal"
                name="SecondaryText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.SecondaryText}
                variant="outlined"
              />

              <TextField
                id="standard-read-only-input"
                label="Cintillo inferior"
                className={classes.text}
                margin="normal"
                name="Banner1"
                multiline
                rows="2"
                onChange={this.onChange}
                value={this.state.Banner1}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen principal</Typography>
              </div>

              {this.state.ImgPrincipal !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.ImgPrincipal}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.principalIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Requerimientos</Typography>
            {this.state.requirements.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Requerimiento</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.requirements.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.requirement}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(
                                  this,
                                  index,
                                  "requirements"
                                )}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "requirements"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "requirements"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogRequirements")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.DialogRequirements}
          onClose={this.DialogClose.bind(this, "DialogRequirements")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Costo</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Punto en costos"
                  name="requi"
                  value={this.state.requi}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogRequirements")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addrequirements} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Educacion);
