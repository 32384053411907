import base from "../baseurl";
import token from "../getTk"

const showAll = async () => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const SectionsShow = await api.get("sections/showall",{timeout:false,}).json();
    console.log(SectionsShow)
   if(SectionsShow.status === "success"){
    return SectionsShow.data.sections;
   }
   else{
    return false
   }
    
    
  };
  
  export default  showAll ;