import base from "../baseurl";
import token from "../getTk"

const Create = async (State) => {


  const tokenA = token.get()

  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const Create = await api.post("headquarters/create",{json: {
      name:State.name,
      address:State.address,
      stateUuid:State.stateUuid,
      cityUuid:State.cityUuid,
      suburb:State.suburb
    }}).json();
   
    return Create ;
    
  };
  
  export default  Create ;