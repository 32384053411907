import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HomeCall from "../../../API/Ceforma/Convenios";
import CallUtils from "../../../API/CallUtils";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "90%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
  input: {
    display: "none",
  },
  Actividad: {
    width: "100%",
  },
});

class Convenios extends React.Component {
  state = {
    files: [],
    Dialog: false,
    Titulo: "",
    Texto: "",
    Imgs: [],
    ImgUrl: null,
    ImgDescription: "",
    DialogImg: false,
    title: "",
    welcomeText: "",
    imgPrincipal: null,
    DialogEdit: false,
    index: 0,
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    const Update = await HomeCall.Update(this.state);
    console.log(Update);
    await this.showAll();
  };

  showAll = async () => {
    const polute = await HomeCall.showAll();
    this.setState({
      title: polute.title,
      welcomeText: polute.welcomeText,
      files: polute.Convenios,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addActividad = async (e) => {
    var newelement = {
      title: this.state.Titulo,
      description: this.state.Texto,
      imgPrincipal: this.state.imgPrincipal,
      imgs: this.state.Imgs,
    };

    this.setState({
      files: [...this.state.files, newelement],
    });
    this.setState({
      Dialog: false,
      Titulo: "",
      Texto: "",
      Imgs: [],
      imgPrincipal: null,
    });
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  addIMG = async (e) => {
    var newelement = {
      ImgUrl: this.state.ImgUrl,
      ImgDescripcion: this.state.ImgDescription,
    };

    this.setState({
      Imgs: [...this.state.Imgs, newelement],
    });
    this.setState({
      DialogImg: false,
      ImgDescription: "",
      ImgUrl: null,
    });
  };
  addIMGD = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_COVENANTS_LIST"
    );
    this.setState({
      ImgUrl: principal,
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };

  edit = (title, img, text, imgs, ind, e) => {
    this.setState({
      DialogEdit: true,
      Titulo: title,
      Texto: text,
      imgPrincipal: img,
      Imgs: imgs,
      index: ind,
    });
  };

  addIMGPrincipal = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_CONVENIOS_PRINCIPAL_"
    );
    this.setState({
      imgPrincipal: principal,
    });
  };

  EditConvenio = () => {
    var newelement = {
      title: this.state.Titulo,
      description: this.state.Texto,
      imgPrincipal: this.state.imgPrincipal,
      imgs: this.state.Imgs,
    };

    let NPrograms = [...this.state.files];

    NPrograms[this.state.index] = newelement;

    this.setState({
      files: NPrograms,
    });

    this.setState({
      DialogEdit: false,
      Titulo: "",
      Texto: "",
      imgPrincipal: null,
      Imgs: [],
      index: 0,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <TextField
            id="standard-basic"
            label="Titulo Principal"
            name="title"
            value={this.state.title}
            className={classes.A}
            onChange={this.onChange}
          />

          <TextField
            id="standard-read-only-input"
            label="Texto Principal"
            className={classes.text}
            margin="normal"
            name="welcomeText"
            multiline
            rows="6"
            onChange={this.onChange}
            value={this.state.welcomeText}
            variant="outlined"
          />
          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Convenios</Typography>
            {this.state.files.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Titulo</TableCell>
                      <TableCell>Texto</TableCell>
                      <TableCell>Numero de Imágenes</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Editar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.files.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.description}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.imgs.length}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "files")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(this, index, "files")}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.edit.bind(
                                  this,
                                  Article.title,
                                  //"sin imagen",
                                  Article.imgPrincipal,
                                  Article.description,
                                  Article.imgs,
                                  index
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(this, index, "files")}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "Dialog")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.Dialog}
          onClose={this.DialogClose.bind(this, "Dialog")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Nuevo convenio</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Nombre del convenio"
                  name="Titulo"
                  value={this.state.Titulo}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Texto del convenio"
                  name="Texto"
                  value={this.state.Texto}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="8"
                  variant="outlined"
                />
                <div className={classes.imgDiv}>
                  <Typography>Imagen Principal</Typography>
                </div>

                {this.state.imgPrincipal !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.imgPrincipal}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGPrincipal}
                      className={classes.input}
                      id="files3"
                      required
                    />
                    <label htmlFor="files3">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <div className={classes.SectionTable}>
                  <Typography className={classes.Section}>
                    Lista de Imágenes
                  </Typography>
                  {this.state.Imgs.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Pie de imagen</TableCell>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Subir</TableCell>
                            <TableCell>Bajar</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.Imgs.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    {Article.ImgDescripcion}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <img
                                      src={Article.ImgUrl}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      alt=""
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="primary"
                                      onClick={this.Up.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <ArrowUp />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.Down.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <ArrowDown />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}></Paper>
                  )}

                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.DialogOpen.bind(this, "DialogImg")}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "Dialog")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addActividad} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogImg}
          onClose={this.DialogClose.bind(this, "DialogImg")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Imagen</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.imgDiv}>
                  <Typography>Imagen</Typography>
                </div>

                {this.state.ImgUrl !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.ImgUrl}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGD}
                      className={classes.input}
                      id="files"
                      required
                    />
                    <label htmlFor="files">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <TextField
                  id="standard-basic"
                  label="Pie de paginá"
                  name="ImgDescription"
                  value={this.state.ImgDescription}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogImg")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addIMG} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogEdit}
          onClose={this.DialogClose.bind(this, "DialogEdit")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Convenio</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Nombre del convenio"
                  name="Titulo"
                  value={this.state.Titulo}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Texto del convenio"
                  name="Texto"
                  value={this.state.Texto}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="8"
                  variant="outlined"
                />

                <div className={classes.imgDiv}>
                  <Typography>Imagen Principal</Typography>
                </div>

                {this.state.imgPrincipal !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.imgPrincipal}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGPrincipal}
                      className={classes.input}
                      id="files3"
                      required
                    />
                    <label htmlFor="files3">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <div className={classes.SectionTable}>
                  <Typography className={classes.Section}>
                    Lista de Imágenes
                  </Typography>
                  {this.state.Imgs.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Pie de imagen</TableCell>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Subir</TableCell>
                            <TableCell>Bajar</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.Imgs.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    {Article.ImgDescripcion}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <img
                                      src={Article.ImgUrl}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      alt=""
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="primary"
                                      onClick={this.Up.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <ArrowUp />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.Down.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <ArrowDown />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}></Paper>
                  )}

                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.DialogOpen.bind(this, "DialogImg")}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogEdit")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.EditConvenio} color="primary">
              Modificar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Convenios);
