import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const styles = (theme) => ({
  FormControl: {
    width: "100%",
    marginBottom: "8px",
    marginTop: "8px",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function SelectRegis(props) {
  return (
    <FormControl component="fieldset" className={props.classes.FormControl}>
      <FormLabel component="legend">Valores</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.OptionSalary}
              onChange={(e) => {
                props.sentoParent("OptionSalary", !props.OptionSalary);
              }}
            />
          }
          label="Salario"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={props.OptionAntiquity}
              onChange={(e) => {
                props.sentoParent("OptionAntiquity", !props.OptionAntiquity);
              }}
            />
          }
          label="Antigüedad"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={props.OptionAge}
              onChange={(e) => {
                props.sentoParent("OptionAge", !props.OptionAge);
              }}
            />
          }
          label="Edad"
        />
      </FormGroup>
    </FormControl>
  );
}

export default withStyles(styles)(SelectRegis);
