import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Paper,
  InputBase,
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Api from "../../API/Search";
import SearchIcon from "@material-ui/icons/Search";
import toasType from "../../API/CallUtils/tostyNotification";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogEdit from "./details";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const styles = (theme) => ({
  formControl: {
    margin: "10px",
  },
  Skeleton: {
    width: "100%",
    height: "20px",
    borderRadius: "10px 10px 10px 10px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "30%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  Subtitle: {
    alignItems: "center",
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  searchFild: {
    width: "100%",
  },
  Table: {
    margin: "10px",
  },
  InitialConteiner: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40vh",
  },
  SearchConteiner: {
    width: "50%",
    borderRadius: 30,
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  icon: {
    fontSize: "xx-large",
  },
});
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Conection: "Initial",
      search: "",
      List: [],
      DialogEdit: false,
      UserData: undefined,
    };
  }
  SendToSearch = async () => {
    try {
      this.setState({ Conection: "Onload" });
      const Result = await Api.Search(
        this.state.search,
        this.state.search,
        this.state.search
      );
      console.log(Result);
      if (Result.status === "success") {
        if (Result.data.length > 0) {
          this.setState({ Conection: "OnEndSearch", List: Result.data });
          toasType.info("Resultados (" + Result.data.length + ")");
        } else {
          this.setState({ Conection: "Initial" });
          toasType.info("Sin resultados");
        }
      } else {
        toasType.error("Error en la búsqueda");
        this.setState({ Conection: "Initial" });
      }
    } catch (e) {
      console.log(e);
    }
  };
  Dialog = (a, b) => {
    this.setState({
      [a]: !this.state[a],
      UserData: b ? b : null,
    });
  };
  render() {
    const { classes } = this.props;
    switch (this.state.Conection) {
      case "Onload":
        return (
          <div className={classes.InitialConteiner}>
            <CircularProgress />
          </div>
        );
      case "Initial":
        return (
          <div className={classes.InitialConteiner}>
            <Paper className={classes.SearchConteiner}>
              <Grid container row spacing={1} xs={12}>
                <Grid
                  item
                  justifycontent="center"
                  alignItems="center"
                  direction="row"
                >
                  <SearchIcon className={classes.icon} />
                </Grid>
                <Grid
                  item
                  xs={10}
                  direction="row"
                  container
                  justifycontent="center"
                  alignItems="center"
                >
                  <div className={classes.search}>
                    <InputBase
                      placeholder="Nombre Completo o RFC"
                      className={classes.searchFild}
                      inputProps={{ "aria-label": "search" }}
                      value={this.state.search}
                      onChange={(ev) => {
                        this.setState({ search: ev.target.value });
                      }}
                      onKeyPress={(ev) => {
                        if (ev.charCode === 13) {
                          this.SendToSearch();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={1}
                  justifycontent="center"
                  alignItems="center"
                  container
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                    disabled={this.state.search === "" ? true : false}
                    onClick={this.SendToSearch}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      case "OnEndSearch":
        return (
          <>
            <Paper>
              <Typography
                className={classes.Subtitle}
                align="center"
                variant="h5"
              >
                Buscador global de Afiliados
              </Typography>
              <Grid
                container
                spacing={1}
                xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid
                  item
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <SearchIcon />
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.search}>
                    <InputBase
                      placeholder="Nombre Completo o RFC"
                      className={classes.searchFild}
                      inputProps={{ "aria-label": "search" }}
                      value={this.state.search}
                      onChange={(ev) => {
                        this.setState({ search: ev.target.value });
                      }}
                      onKeyPress={(ev) => {
                        if (ev.charCode === 13) {
                          this.SendToSearch();
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.search === "" ? true : false}
                    onClick={this.SendToSearch}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            {this.state.List.length > 0 && (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nombre completo</TableCell>
                      <TableCell align="center">Teléfono</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">RFC</TableCell>
                      <TableCell align="center">Sexo</TableCell>
                      <TableCell align="center">Fecha de nacimiento</TableCell>
                      <TableCell align="center">
                        Ultima Empresa Registrada
                      </TableCell>
                      <TableCell align="center">
                        Fecha de ingreso a empresa registrada
                      </TableCell>
                      <TableCell align="center">Detalles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.List.map((Article, index) => {
                      return (
                        <React.Fragment key={Article.name}>
                          <TableRow hover>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.name + " " + Article.lastNames}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.personalPhone
                                ? Article.personalPhone
                                : "No info"}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.otherMail
                                ? Article.otherMail
                                : "No info"}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.rfc}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.sex}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.dateOfBirth}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.User.Employees.length > 0
                                ? Article.User.Employees[0].Company
                                  ? Article.User.Employees[0].Company.name
                                  : "No disponible"
                                : "No disponible"}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {Article.User.Employees.length > 0
                                ? Article.User.Employees[0].Company
                                  ? Article.User.Employees[0].Company.createdAt.split(
                                      "T"
                                    )[0]
                                  : "No disponible"
                                : "No disponible"}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <Button
                                color="primary"
                                onClick={this.Dialog.bind(
                                  this,
                                  "DialogEdit",
                                  Article
                                )}
                              >
                                <MoreVertIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
                {this.state.DialogEdit && (
                  <DialogEdit
                    DialogOpen={this.state.DialogEdit}
                    DialogClose={this.Dialog.bind(this, "DialogEdit")}
                    UserData={this.state.UserData}
                  />
                )}
              </Paper>
            )}
          </>
        );
      default:
        return (
          <div className={classes.InitialConteiner}>
            <CircularProgress />
          </div>
        );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(index));
