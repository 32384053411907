import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
//import EmployeesCall from "../../../API/Companies";
import toastType from "../../../API/CallUtils/tostyNotification";
import WorkSheetsCall from "../../../API/CandidateWorkSheet";
import DialogNewCandidate from "./addCandidateDialog";
import { CandidatesInWorkSheet } from "./index";
import CallUtils from "../../../API/CallUtils";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    marginLeft: "10px",
  },
  BtnSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "5px",
  },
  Text2: {
    width: "50%",
    marginBottom: "10px",
  },
});

class worksheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DialogCandidateWS: false,
      WorkSheetImg: "",
      candidateName: "",
      candidateRFC: "",
      candidatePhoto: "",
      candidateNew: false,
      candidateUuid: "",
      DialogWorkSheet: false,
      TitleWorkSheet: "",
      CandidateWorkSheets: [],
      fullList: [],
      DialogWSdetails: false,
      WorkSheetUuid: null,
      WorkSheetDetails: {},
      namesForWorkSheets: ["ROJO", "VERDE", "AZUL", "AMARILLO", "NARANJA"],
      listOfnamesForWorkSheets: [
        "ROJO",
        "VERDE",
        "AZUL",
        "AMARILLO",
        "NARANJA",
      ],
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.ElectionUuid) {
        this.showWorkSheet();
      }
    } catch (error) {
      toastType.error("Error en la obtención de las planillas");
      console.log(error);
    }
  };
  AddCandidate = (uuid, e) => {
    //console.log("WorkSheetUuid for new candidate", uuid);
    this.setState({
      DialogCandidateWS: true,
      WorkSheetUuid: uuid,
    });
  };
  AddWorkSheet = () => {
    this.setState({ DialogWorkSheet: true });
  };
  DialogClose = async (NAME) => {
    this.setState({ [NAME]: false });
    this.setState({ candidateNew: false });
    this.showWorkSheet();
  };
  DialogBack = () => {
    this.setState({ candidateNew: false });
  };
  onChange = (event) => {
    //console.log("nombre", event.target.name, "valor", event.target.value);
    this.setState({ [event.target.name]: event.target.value, Selected: true });
  };
  createNewWorkSheet = async () => {
    try {
      if (this.state.TitleWorkSheet !== "") {
        const NewWS = await WorkSheetsCall.Create(
          this.state.TitleWorkSheet,
          this.props.ElectionUuid,
          this.state.WorkSheetImg
        );
        if (NewWS.status === "success") {
          toastType.success("Nueva Plantilla creada");
          this.showWorkSheet();
          this.setState({ WorkSheetImg: null });
        } else {
          toastType.error("Error en la creación, Acción no completada");
          this.setState({ WorkSheetImg: null });
        }
      } else {
        toastType.error("Se requiere un nombre valido");
        console.log("sin nombre");
      }
    } catch (error) {
      toastType.error("Error de conexión");
    }
  };
  showWorkSheet = async () => {
    try {
      const ShowWS = await WorkSheetsCall.showAll(this.props.ElectionUuid);
      if (ShowWS.status === "success") {
        this.setState({ CandidateWorkSheets: ShowWS.data });
        if (ShowWS.data.length > 0) {
          const ColorsNames = ShowWS.data.map((WorkSheet) => {
            return WorkSheet.name;
          });
          if (ColorsNames.length > 0) {
            let filteOfNames = this.state.listOfnamesForWorkSheets.filter(
              (color) => !ColorsNames.includes(color)
            );
            this.setState({ namesForWorkSheets: filteOfNames });
          }
        }
      } else {
        toastType.error("Error buscando planillas");
        //console.log(ShowWS);
      }
    } catch (error) {
      toastType.error("Error de conexión");
    }
  };
  deleteWorkSheet = async (Uuid) => {
    try {
      const DeleteWS = await WorkSheetsCall.Delete(Uuid);
      console.log(DeleteWS);
      if (DeleteWS.status === "success") {
        this.showWorkSheet();
        toastType.success("Plantilla eliminada");
        console.log(DeleteWS);
      } else {
        toastType.error("Error Eliminando planillas");
        console.log(DeleteWS);
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log("El ERROR", error);
    }
  };
  showDetailsOfWorkSheet = async (Uuid) => {
    try {
      const ShowDetailsWS = await WorkSheetsCall.show(Uuid);
      console.log("todos los detalles", ShowDetailsWS);
      if (ShowDetailsWS.status === "success") {
        this.setState({
          DialogWSdetails: true,
          WorkSheetDetails: ShowDetailsWS.data.candidateWorkSheet,
        });
      } else {
        toastType.error("Error Eliminando planillas");
        console.log(ShowDetailsWS);
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log("El ERROR", error);
    }
  };
  addIMG = async (Name, e) => {
    this.setState({
      onSave: true,
    });
    let principal = await this.SendImg(
      e.target.files[0],
      "SITIMM_VOTING_WORKSHEET_" + this.props.ElectionUuid
    );
    this.setState({
      [Name]: principal,
      onSave: false,
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    return upload.data.location;
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.candidate} elevation={3}>
        {this.state.CandidateWorkSheets.length > 0
          ? [
              <>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Numero de integrantes</TableCell>
                      <TableCell>Agregar candidato</TableCell>
                      <TableCell>Resumen</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.CandidateWorkSheets.map((Article) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {/*Nombre*/}
                              {Article.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {/*Numero de integrantes*/}
                              {Article.candidatesNumber}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {/*agregar candidato*/}
                              <Button
                                color="primary"
                                onClick={this.AddCandidate.bind(
                                  this,
                                  Article.uuid
                                )}
                              >
                                <AddBoxIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {/*Detalles*/}
                              <Button
                                color="primary"
                                onClick={this.showDetailsOfWorkSheet.bind(
                                  this,
                                  Article.uuid
                                )}
                              >
                                <ListIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {/*Eliminar*/}
                              <Button
                                color="secondary"
                                onClick={this.deleteWorkSheet.bind(
                                  this,
                                  Article.uuid
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
                <div className={classes.BtnSection}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.AddWorkSheet}
                  >
                    <AddBoxIcon />
                  </Button>
                </div>
              </>,
            ]
          : [
              <Button
                className={classes.AddCandidate}
                onClick={this.AddWorkSheet}
              >
                <AddBoxIcon />
              </Button>,
            ]}
        <Dialog
          open={this.state.DialogWorkSheet}
          onClose={this.DialogClose.bind(this, "DialogWorkSheet")}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Plantilla</DialogTitle>
          <DialogContent>
            <FormControl className={classes.paper}>
              <InputLabel id="demo-simple-select-label">Plantillas</InputLabel>
              <Select
                value={this.state.TitleWorkSheet}
                name="TitleWorkSheet"
                onChange={this.onChange}
              >
                {this.state.namesForWorkSheets.length > 0
                  ? [
                      this.state.namesForWorkSheets.map((name, index) => {
                        return (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        );
                      }),
                    ]
                  : [<MenuItem value="">Sin mas nombres disponibles</MenuItem>]}
              </Select>
            </FormControl>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.GridDialog}
            >
              {this.state.WorkSheetImg ? (
                <img
                  src={this.state.WorkSheetImg}
                  alt="placeholder"
                  className={classes.ImgProp}
                />
              ) : (
                <Paper className={classes.ImgPlaceholder}>
                  <Typography
                    align="Center"
                    variant="body2"
                    color="textPrimary"
                    component="p"
                  >
                    Sin imagen
                  </Typography>
                </Paper>
              )}
              <div className={classes.Remp}>
                <input
                  type="file"
                  onChange={this.addIMG.bind(this, "WorkSheetImg")}
                  className={classes.input}
                  id="addWorkSheetImg"
                  required
                />
                <label htmlFor="addWorkSheetImg">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small"
                    className={classes.btn}
                  >
                    <AddBoxIcon />
                  </Button>
                </label>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogWorkSheet")}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              onClick={this.createNewWorkSheet}
              variant="contained"
              color="primary"
            >
              Crear
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.DialogWSdetails}
          onClose={this.DialogClose.bind(this, "DialogWSdetails")}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Plantilla Detalles</DialogTitle>
          <DialogContent>
            <TextField
              label="Nombre de la plantilla"
              value={this.state.WorkSheetDetails.name}
              className={classes.Text2}
            />
            {this.state.WorkSheetDetails.img && (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.GridDialog}
              >
                <img
                  src={this.state.WorkSheetDetails.img}
                  alt="placeholder"
                  className={classes.ImgProp}
                />
              </Grid>
            )}
            {this.state.WorkSheetDetails.Candidates
              ? [
                  this.state.WorkSheetDetails.Candidates.length > 0
                    ? [
                        <CandidatesInWorkSheet
                          list={this.state.WorkSheetDetails.Candidates}
                          Uuid={this.state.WorkSheetDetails.uuid}
                        />,
                      ]
                    : [
                        <Typography
                          align="Center"
                          variant="h5"
                          color="textPrimary"
                          component="p"
                          className={classes.Subtitle}
                        >
                          Sin Candidatos registrados
                        </Typography>,
                      ],
                ]
              : [<></>]}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogWSdetails")}
              variant="contained"
              color="secondary"
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.CompanieUuid ? (
          <DialogNewCandidate
            CompanieUuid={this.props.CompanieUuid}
            ElectionUuid={this.props.ElectionUuid}
            DialogOpen={this.state.DialogCandidateWS}
            DialogClose={this.DialogClose.bind(this, "DialogCandidateWS")}
            type={"WORKSHEET"}
            WorkSheet={this.state.WorkSheetUuid}
          />
        ) : (
          <></>
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(worksheet);
