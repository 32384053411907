import base from "../baseurl";
import token from "../getTk";

const Update = async (State) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const UserUpdate = await api
    .post("users/update", {
      json: {
        userUuid: State.Uuid,
        userType: State.userType,
        email: State.email,
        headquarterUuid: State.headquartersUuid,
      },
    })
    .json();

  const UserUpdateProfile = await api
    .post("user-profiles/update", {
      json: {
        userProfileUuid: State.UserUuid,
        name: State.name,
        lastNames: State.lastNames,
        sex: State.sex,
        mobilePhone: State.mobilePhone,
        dateOfBirth: State.dateOfBirth,
      },
    })
    .json();

  return { UserUpdate, UserUpdateProfile };
};

export default Update;
