import base from "../baseurl";
import token from "../getTk"
const Update = async (body) => {
  const tokenA = token.get()

  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const status = await api.post("headquarters/update",{json: {
    headquarterUuid:body.Uuid,
    address:body.address,
    cityUuid:body.cityUuid,
    stateUuid:body.stateUuid,
    suburb:body.suburb   
    }}).json();
    
    return status ;
    
  };

  export default  Update ; 