import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  container: {
    width: "100%",
    //height: '100%',
    height: "100vh",
    overFlow: "hidden !important",
    fontFamily: "Source Sans Pro, sans-serif",
  },
  text: {
    position: "relative",
    top: "28%",
    //-webkit - transform: translateY(-50 %)!important;
    //-ms - transform: translateY(-50 %)!important;
    transform: "translateY(-50 %)!important",
    zIndex: "3",
    display: "block",
    "& h1": {
      fontWeight: "bold",
      fontSize: "90px",
      letterSpacing: "20px",
      textTransform: "uppercase",
      textAlign: "center",
      color: "#B5B5B5 !important",
      margin: "0px",
      padding: "0px",
    },
    "& h2": {
      fontSize: "20px",
      fontWeight: 600,
      letterSpacing: "10px",
      /* text-transform: uppercase; */
      textAlign: "center",
      color: "#B5B5B5 !important",
      lineHeight: "40px",
      padding: "0px",
      margin: "0px",
    },
    "& a": {
      color: "#B5B5B5",
      textDecoration: "none",
      borderBottom: "2px solid #B5B5B5",
      margin: 0,
      padding: 0,
      "&:hover": {
        color: "#808080",
        borderBottom: "5px solid #808080",
      },
      "& span": {
        letterSpacing: "0px !important",
        paddingRight: "1px",
      },
    },
  },
});

class Error404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 10,
    };
  }
  render() {
    const { classes } = this.props;
    const { count } = this.state;
    if (count === -1) return <Redirect to="/" />;
    return (
      <div className={classes.container}>
        <div className={classes.text}>
          <h1> ERROR 404</h1>
          <h2>
            Espera {count} segundos para regresar<br></br>a la{" "}
            <a href="/" title="Presione aquí para regresar a inicio">
              pagina principal<span>l</span>
            </a>
          </h2>
        </div>
      </div>
    );
  }
  backHome() {
    if (this.state.count === -1) {
      //console.log('El timer es 0')
      clearInterval(this.myInterval);
    }
  }
  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.setState((prevState) => ({
        count: prevState.count - 1,
      }));
      this.backHome();
    }, 1000);
  }
}
Error404.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Error404);
