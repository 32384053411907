import base from "../baseurl";
import token from "../getTk";

const Companies = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  //console.log(state)
  const status = await api
    .post("statistics/companies", {
      json: {
        country: state.country,
        industrialPark: state.parqueIndustrial,
        stateUuid: state.stateUuid,
        cityUuid: state.cityUuid,
        operationStart: state.inicioOperaciones
          ? state.inicioOperaciones
          : undefined,
        acceptanceDate: state.fechaInicio ? state.fechaInicio : undefined,
      },
    })
    .json();

  return status.data.companies;
};

export default Companies;
