import Create from "./Create";
import Delete from "./Delete";
import ShowAll from "./ShowAll";

const Companies = {
  ShowAll,
  Delete,
  Create,
};

export default Companies;

export { Create, ShowAll, Delete };
