import React from "react";
import { withStyles } from "@material-ui/core/styles";
import NosotrosCall from "../../../API/Ceforma/Nosotros";
import CallUtils from "../../../API/CallUtils";
import DeleteIcon from "@material-ui/icons/Delete";
//import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from "@material-ui/icons/Add";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "90%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
  input: {
    display: "none",
  },
  Actividad: {
    width: "100%",
  },
});

class Us extends React.Component {
  state = {
    pageTitle: "",
    descriptiveText: "",
    PrincipalImg: "",
    Certification1: "",
    Certification2: "",
    CertificationText: "",
    activities: [],
    directiveTable: [],
    companies: [],

    DialogActividades: false,
    actividadesText: "",
    actividadesIMG: "",

    DialogMesa: false,
    TablePuesto: "",
    TableName: "",

    DialogSecciones: false,
    SecctionName: "",
    SecctionCurse: "",
    SecctionNumber: "",

    ValuesIMG: null,
    ValuesDescription: "",

    Nexos: [],
    ImgNexo: null,
    TitleNexo: "",
    DescriptionNexo: "",
    imgListNexo: [],
    ImgDescription: "",
    ImgUrl: null,
    dialogNexo: false,
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    const Update = await NosotrosCall.Update(this.state);
    console.log(Update);
    await this.showAll();
  };

  showAll = async () => {
    const polute = await NosotrosCall.showAll();
    console.log(polute);
    this.setState({
      pageTitle: polute.pageTitle,
      descriptiveText: polute.descriptiveText,
      PrincipalImg: polute.PrincipalImg,
      Certification1: polute.Certification1,
      Certification2: polute.Certification2,
      CertificationText: polute.CertificationText,
      activities: polute.activities,
      directiveTable: polute.directiveTable,
      companies: polute.companies,
      ValuesDescription: polute.ValuesDescription,
      ValuesIMG: polute.ValuesIMG,
      Nexos: polute.Nexos,
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  principalIMG = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_NOSOTROS_PrincipalIMG1"
    );
    this.setState({
      PrincipalImg: principal,
    });
  };

  addIMGNexo = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_NOSOTROS_PrincipalIMGNexo"
    );
    this.setState({
      ImgNexo: principal,
    });
  };

  addValuesIMG = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_NOSOTROS_Valores"
    );
    this.setState({
      ValuesIMG: principal,
    });
  };

  Certification1 = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_NOSOTROS_Certification11"
    );
    this.setState({
      Certification1: principal,
    });
  };
  Certification2 = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_NOSOTROS_Certification22"
    );
    this.setState({
      Certification2: principal,
    });
  };

  addActividad = async (e) => {
    var newelement = {
      Actividad: this.state.actividadesText,
      img: this.state.actividadesIMG,
    };

    this.setState({
      activities: [...this.state.activities, newelement],
    });
    this.setState({
      DialogActividades: false,
      actividadesText: "",
      actividadesIMG: "",
    });
  };

  addMesa = async (e) => {
    var newelement = {
      Nombre: this.state.TableName,
      Puesto: this.state.TablePuesto,
    };

    this.setState({
      directiveTable: [...this.state.directiveTable, newelement],
    });
    this.setState({
      DialogMesa: false,
      TableName: "",
      TablePuesto: "",
    });
  };

  addSeccion = async (e) => {
    var newelement = {
      Empresa: this.state.SecctionName,
      Curso: this.state.SecctionCurse,
      Capacitados: this.state.SecctionNumber,
    };

    this.setState({
      companies: [...this.state.companies, newelement],
    });
    this.setState({
      DialogSecciones: false,
      SecctionName: "",
      SecctionCurse: "",
      SecctionNumber: "",
    });
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  addPrincipal = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_CONOCENOS_ACTIVIDADES"
    );
    this.setState({
      actividadesIMG: principal,
    });
  };
  addNexo = async (e) => {
    var newelement = {
      title: this.state.TitleNexo,
      description: this.state.DescriptionNexo,
      imgPrincipal: this.state.ImgNexo,
      imgs: this.state.imgListNexo,
    };

    this.setState({
      Nexos: [...this.state.Nexos, newelement],
    });
    this.setState({
      ImgNexo: null,
      TitleNexo: "",
      DescriptionNexo: "",
      imgListNexo: [],
      imgSubImg: null,
      footerImg: "",
      dialogNexo: false,
    });
  };

  addIMG = async (e) => {
    var newelement = {
      ImgUrl: this.state.ImgUrl,
      ImgDescripcion: this.state.ImgDescription,
    };

    this.setState({
      imgListNexo: [...this.state.imgListNexo, newelement],
    });
    this.setState({
      DialogImg: false,
      ImgDescription: "",
      ImgUrl: null,
    });
  };
  addIMGD = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_NOSOTROS_LIST_NEXOS_IMGS"
    );
    this.setState({
      ImgUrl: principal,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={7}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo Principal"
                name="pageTitle"
                value={this.state.pageTitle}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto Principal"
                className={classes.text}
                margin="normal"
                name="descriptiveText"
                multiline
                rows="8"
                onChange={this.onChange}
                value={this.state.descriptiveText}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen principal</Typography>
              </div>

              {this.state.principalIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.PrincipalImg}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.principalIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={7}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Texto de valores"
                name="ValuesDescription"
                value={this.state.ValuesDescription}
                className={classes.text}
                multiline
                rows="8"
                variant="outlined"
                onChange={this.onChange}
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen de valores</Typography>
              </div>

              {this.state.ValuesIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.ValuesIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.addValuesIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>
              Actividades del Ceforma
            </Typography>
            {this.state.activities.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Actividades</TableCell>
                      <TableCell>Imagen</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.activities.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.Actividad}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <img
                                src={Article.img}
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(
                                  this,
                                  index,
                                  "activities"
                                )}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "activities"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "activities"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogActividades")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Mesa directiva</Typography>
            {this.state.directiveTable.length > 0 &&
            this.state.remplazar === false ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Actividades</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.directiveTable.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              <a href={Article}>{Article}</a>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Puesto</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.directiveTable.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.Nombre}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.Puesto}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(
                                  this,
                                  index,
                                  "directiveTable"
                                )}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "directiveTable"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "directiveTable"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogMesa")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>
              Secciones Sindicales pertenecientes al SITIMM que ha capacitado el
              CEFORMA A. C.{" "}
            </Typography>
            {this.state.companies.length > 0 &&
            this.state.remplazar === false ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Actividades</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.companies.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              <a href={Article}>{Article}</a>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Empresa</TableCell>
                      <TableCell>Curso</TableCell>
                      <TableCell>Capacitados</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.companies.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.Empresa}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.Curso}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.Capacitados}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "companies")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "companies"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "companies"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogSecciones")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>
          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>
              Nexos Internacionales
            </Typography>
            {this.state.Nexos.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Titulo</TableCell>
                      <TableCell>Descripción</TableCell>
                      <TableCell>Imagen</TableCell>
                      <TableCell>Numero de imágenes</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Nexos.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.description}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <img
                                src={Article.imgPrincipal}
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.imgs.length}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "Nexos")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(this, index, "Nexos")}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(this, index, "Nexos")}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "dialogNexo")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={6}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-read-only-input"
                label="Texto Certificaciones"
                className={classes.text}
                margin="normal"
                name="CertificationText"
                multiline
                rows="8"
                onChange={this.onChange}
                value={this.state.CertificationText}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen</Typography>
              </div>

              {this.state.Certification1 !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.Certification1}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.Certification1}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen</Typography>
              </div>

              {this.state.Certification2 !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.Certification2}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.Certification2}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>

          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.DialogActividades}
          onClose={this.DialogClose.bind(this, "DialogActividades")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Actividad</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Texto"
                  name="actividadesText"
                  value={this.state.actividadesText}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="8"
                  variant="outlined"
                />
                <div className={classes.imgDiv}>
                  <Typography>Imagen</Typography>
                </div>

                {this.state.actividadesIMG !== "" ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.actividadesIMG}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addPrincipal}
                      className={classes.input}
                      id="files3"
                      required
                    />
                    <label htmlFor="files3">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogActividades")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addActividad} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogMesa}
          onClose={this.DialogClose.bind(this, "DialogMesa")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Mesa directiva</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Texto"
                  name="TableName"
                  value={this.state.TableName}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="1"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Texto"
                  name="TablePuesto"
                  value={this.state.TablePuesto}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="1"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogMesa")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addMesa} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogNexo}
          onClose={this.DialogClose.bind(this, "dialogNexos")}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Nuevo Nexo</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="TitleNexo"
                  value={this.state.TitleNexo}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="1"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Descripción"
                  name="DescriptionNexo"
                  value={this.state.DescriptionNexo}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="5"
                  variant="outlined"
                />
                <div className={classes.imgDiv}>
                  <Typography>Imagen Principal</Typography>
                </div>

                {this.state.ImgNexo !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.ImgNexo}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGNexo}
                      className={classes.input}
                      id="files9"
                      required
                    />
                    <label htmlFor="files9">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>

                <div className={classes.SectionTable}>
                  <Typography className={classes.Section}>
                    Lista de Imágenes
                  </Typography>
                  {this.state.imgListNexo.length > 0 ? (
                    <Paper className={classes.Table}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Pie de imagen</TableCell>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Subir</TableCell>
                            <TableCell>Bajar</TableCell>
                            <TableCell>Eliminar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.imgListNexo.map((Article, index) => {
                            return (
                              <React.Fragment>
                                <TableRow key={Article.uuid}>
                                  <TableCell component="th" scope="row">
                                    {Article.ImgDescripcion}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <img
                                      src={Article.ImgUrl}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      alt=""
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="primary"
                                      onClick={this.Up.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <ArrowUp />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.Down.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <ArrowDown />
                                    </Button>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Button
                                      color="secondary"
                                      onClick={this.remove.bind(
                                        this,
                                        index,
                                        "Imgs"
                                      )}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Paper>
                  ) : (
                    <Paper className={classes.Table}></Paper>
                  )}

                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.DialogOpen.bind(this, "DialogImg")}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "dialogNexo")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addNexo} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogSecciones}
          onClose={this.DialogClose.bind(this, "DialogSecciones")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Secciones</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Texto"
                  name="SecctionName"
                  value={this.state.SecctionName}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="1"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Texto"
                  name="SecctionCurse"
                  value={this.state.SecctionCurse}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="1"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Texto"
                  name="SecctionNumber"
                  value={this.state.SecctionNumber}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="1"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogSecciones")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addSeccion} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogImg}
          onClose={this.DialogClose.bind(this, "DialogImg")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Imagen</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.imgDiv}>
                  <Typography>Imagen</Typography>
                </div>

                {this.state.ImgUrl !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.ImgUrl}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGD}
                      className={classes.input}
                      id="files"
                      required
                    />
                    <label htmlFor="files">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <TextField
                  id="standard-basic"
                  label="Pie de paginá "
                  name="ImgDescription"
                  value={this.state.ImgDescription}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogImg")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addIMG} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Us);
