import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./StoreConfig";
import { PersistGate } from "redux-persist/lib/integration/react";
import Private from "./Utils/PrivateRoute";
import Public from "./Utils/PublicRoute";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import {
  Login,
  Companies,
  CompaniesCreate,
  CompaniesUpdate,
  UpdateEmployee,
  EmployeeTable,
  Seccions,
  SeccionsDetails,
  StatisticCompanies,
  StatisticEmployees,
  Headquarters,
  HeadquartersCreate,
  HeadquartersDetails,
  Users,
  UsersCreate,
  UsersDetails,
  Logs,
  Comunication,
  Profile,
  error_404,
  Consultations,
  ConsultationsAdmin,
  SITIMM,
  Ceforma,
  StatisticReports,
  Bulletins,
  BulletinsGeneral,
  electionShowAll,
  NewElection,
  ProgramedElection,
  DeployedElection,
  FinishedElection,
  NewDetails,
  DeliveryOfGoods,
  SportEvents,
  AdminPanel,
  SonsVerification,
  UserAdd,
  NewSingularUser,
  CompaniesForms,
  CompaniesFormsQuestions,
  CompaniesCampaigs,
  WeeklyFees,
  WeeklyFeesStatics,
  WeeklyFeesList,
} from "./Views";

function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ToastContainer />
            <div>
              <Switch>
                <Route path="/" exact component={Public(Login)} />

                <Route path="/Empresas" exact component={Private(Companies)} />
                <Route
                  path="/Empresas/Crear"
                  exact
                  component={Private(CompaniesCreate)}
                />
                <Route
                  path="/Empresas/Empleados"
                  exact
                  component={Private(EmployeeTable)}
                />
                <Route
                  path="/Empresas/Empleados/Actulizar"
                  exact
                  component={Private(UpdateEmployee)}
                />
                <Route
                  path="/Empresas/Informacion"
                  exact
                  component={Private(NewDetails)}
                />
                <Route
                  path="/Empresas/Informacion/Actulizar"
                  exact
                  component={Private(CompaniesUpdate)}
                />
                <Route
                  path="/Empresas/Informacion/Agregar"
                  exact
                  component={Private(NewSingularUser)}
                />

                <Route path="/Secciones" exact component={Private(Seccions)} />
                <Route
                  path="/Secciones/Informacion"
                  exact
                  component={Private(SeccionsDetails)}
                />

                <Route
                  path="/Estadistica/Empleados"
                  exact
                  component={Private(StatisticEmployees)}
                />
                <Route
                  path="/Estadistica/Empresas"
                  exact
                  component={Private(StatisticCompanies)}
                />
                <Route
                  path="/Estadistica/Reportes"
                  exact
                  component={Private(StatisticReports)}
                />

                <Route path="/Usuarios" exact component={Private(Users)} />
                <Route
                  path="/Usuarios/Crear"
                  exact
                  component={Private(UsersCreate)}
                />
                <Route
                  path="/Usuarios/Informacion"
                  exact
                  component={Private(UsersDetails)}
                />

                <Route
                  path="/Oficinas"
                  exact
                  component={Private(Headquarters)}
                />
                <Route
                  path="/Oficinas/Crear"
                  exact
                  component={Private(HeadquartersCreate)}
                />
                <Route
                  path="/Oficinas/Informacion"
                  exact
                  component={Private(HeadquartersDetails)}
                />

                <Route path="/Logs" exact component={Private(Logs)} />

                <Route
                  path="/Comunicacion"
                  exact
                  component={Private(Comunication)}
                />

                <Route path="/Perfil" exact component={Private(Profile)} />

                <Route
                  path="/Consultas"
                  exact
                  component={Private(Consultations)}
                />

                <Route
                  path="/ConsultasAdmin"
                  exact
                  component={Private(ConsultationsAdmin)}
                />

                <Route path="/CMS/SITIMM" exact component={Private(SITIMM)} />
                <Route path="/CMS/Ceforma" exact component={Private(Ceforma)} />

                <Route
                  path="/Comunicados"
                  exact
                  component={Private(Bulletins)}
                />
                <Route
                  path="/ComunicadosGenerales"
                  exact
                  component={Private(BulletinsGeneral)}
                />

                <Route
                  path="/Votaciones"
                  exact
                  component={Private(electionShowAll)}
                />
                <Route
                  path="/Votaciones/Nueva"
                  exact
                  component={Private(NewElection)}
                />
                <Route
                  path="/Votaciones/Programada"
                  exact
                  component={Private(ProgramedElection)}
                />
                <Route
                  path="/Votaciones/EnProceso"
                  exact
                  component={Private(DeployedElection)}
                />
                <Route
                  path="/Votaciones/Terminada"
                  exact
                  component={Private(FinishedElection)}
                />
                <Route
                  path="/EntregaBienes"
                  exact
                  component={Private(DeliveryOfGoods)}
                />
                <Route
                  path="/EventoDeportivo"
                  exact
                  component={Private(SportEvents)}
                />
                <Route
                  path="/AdminPanel"
                  exact
                  component={Private(AdminPanel)}
                />
                <Route
                  path="/Hijos"
                  exact
                  component={Private(SonsVerification)}
                />
                <Route
                  path="/NuevoAfiliado"
                  exact
                  component={Private(UserAdd)}
                />
                <Route
                  path="/Encuestas/Campañas"
                  exact
                  component={Private(CompaniesCampaigs)}
                />
                <Route
                  path="/Encuestas/Formularios"
                  exact
                  component={Private(CompaniesForms)}
                />
                <Route
                  path="/Encuestas/Preguntas"
                  exact
                  component={Private(CompaniesFormsQuestions)}
                />
                <Route
                  path="/CuotasSemanales"
                  exact
                  component={Private(WeeklyFees)}
                />
                <Route
                  path="/CuotasSemanales/Empresas"
                  exact
                  component={Private(WeeklyFeesList)}
                />
                <Route
                  path="/CuotasSemanales/reportes"
                  exact
                  component={Private(WeeklyFeesStatics)}
                />
                <Route component={error_404} />
              </Switch>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
