import React from "react";

import { Typography, Paper, withStyles } from "@material-ui/core";
import UpdateS from "../../../API/DeliveryOfGoods";
import MUIDataTable from "mui-datatables";
const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {};
  UpdateState = async (id) => {
    //console.log("llego a la llamda ", id);
    try {
      const update = await UpdateS.UpdateState(id, 3);
      console.log(update);
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { classes } = this.props;

    const data = this.props.List.map((Article) => [
      Article.User.UserProfile.name + " " + Article.User.UserProfile.lastNames,
      Article.User.UserProfile.rfc,
      Article.updatedAt.split("T")[0],
      Article.GoodDeliveryParticipantState.name,
    ]);

    const columns = ["Nombre", "RFC", "Fecha de aprobación", "Estado"];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin datos",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    return (
      <div className={classes.paper}>
        <Typography
          align="Center"
          variant="h5"
          component="p"
          className={classes.Subtitle}
        >
          Todos
        </Typography>

        {this.props.List.length > 0 ? (
          <Paper className={classes.Table}>
            <MUIDataTable
              title={"Todos"}
              data={data}
              columns={columns}
              options={options}
              className={classes.table}
              selectableRows="none"
            />
          </Paper>
        ) : (
          <Paper className={classes.NoItemPaper}>
            <Typography variant="body1" color="textPrimary" component="p">
              Sin elementos disponibles
            </Typography>
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Edit);
