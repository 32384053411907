import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Api from "../../../API/CompaniesFormsQuestions";
import OnLoadSelect from "../../../Components/StatesForComponets/OnLoadSelect";

const styles = (theme) => ({
  formControl: {
    width: "100%",
    margin: "10px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class QuestionTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompaniesList: [],
    };
  }

  fetchCompanyList = async () => {
    try {
      const CompanyList = await Api.Types();
      if (CompanyList.status === "success") {
        console.log(CompanyList);
        this.setState({
          CompaniesList: CompanyList.data.questionTypes,
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };
  componentDidMount = async () => {
    await this.fetchCompanyList();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.CompaniesList.length > 0 ? (
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Tipo de pregunta
            </InputLabel>
            <Select
              value={this.props.value}
              onChange={(ev) => {
                this.props.Select(ev.target.value, this.props.ParentName);
              }}
            >
              {this.state.CompaniesList.map((companie, index) => {
                return (
                  <MenuItem value={companie.id} key={companie.name}>
                    {companie.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <OnLoadSelect />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(QuestionTypes));
