import Create from "./Create";
import showAll from "./showAll";
import Delete from "./Delete"

const magazine = { showAll,Create,Delete };

export default magazine;

export {
    Create,
    showAll,
    Delete,
}