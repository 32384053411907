import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Switch,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import toastType from "../../../../API/CallUtils/tostyNotification";
import DeleteIcon from "@material-ui/icons/Delete";
import EventCall from "../../../../API/CTM/events";
import UpdateEvent from "./updateEvent";
import ListEvents from "./DialogListParticipant";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    padding: "1%",
  },
});
class eventsPerMonth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Events: null,
      Month: null,
      SingleEvent: null,
      eventInfo: null,
      DialogCreate: false,
      DialogListEvent: false,
    };
  }
  componentDidMount = async () => {
    if (this.props.eventsPerMonth) {
      //console.log("Los Eventos", this.props.eventsPerMonth);
      this.setState({
        Events: this.props.eventsPerMonth,
        Month: this.props.name,
      });
    } else {
      toastType.error("No fue posible cargar los eventos de este mes");
    }
  };
  getName = (name) => {
    switch (name) {
      case "January":
        return "Enero";
      case "February":
        return "Febrero";
      case "March":
        return "Marzo";
      case "April":
        return "Abril";
      case "May":
        return "Mayo";
      case "June":
        return "Junio";
      case "July":
        return "Julio";
      case "August":
        return "Agosto";
      case "September":
        return "Septiembre";
      case "October":
        return "Octubre";
      case "November":
        return "Noviembre";

      case "December":
        return "Diciembre";

      default:
        return "ErrorName";
    }
  };
  delete = async (uuid) => {
    try {
      const del = await EventCall.Delete(uuid);
      if (del.status === "success") {
        this.props.parentCallback();
        toastType.success("Éxito en el borrado");
      } else {
        toastType.error(
          "No fue posible borrar el comunicado, Revise sus permisos"
        );
      }
    } catch (e) {
      console.log(e);
      toastType.error("No fue posible borrar el comunicado");
    }
  };
  DialogCreate = async () => {
    this.setState({
      DialogCreate: false,
      DialogListEvent: false,
      eventInfo: null,
      eventId: null,
    });
  };

  details = (EventDetails) => {
    console.log(EventDetails);
    this.setState({
      eventInfo: EventDetails,
      DialogCreate: true,
    });
  };
  ListEvent = (EventDetails) => {
    this.setState({
      eventId: EventDetails.id,
      DialogListEvent: true,
    });
  };
  handleCallback = () => {
    this.props.parentCallback();
  };
  changeStatus = async (event) => {
    try {
      const create = await EventCall.UpdateEnable(event);
      if (create.status === "success") {
        toastType.success("Exitosamente modificado");
        this.props.parentCallback();
      } else {
        toastType.error("SSE No fue posible realizar el cambio");
      }
    } catch (e) {
      console.log(e);
      toastType.error("CSE No fue posible realizar el cambio");
    }
  };

  render() {
    const { classes } = this.props;
    if (this.props.eventsPerMonth.length > 0) {
      return (
        <Grid
          item
          xs={12}
          className={classes.Grid}
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Typography className={classes.Table} align="center" variant="h6">
            {this.state.Month ? this.getName(this.state.Month) : ""}
          </Typography>
          <Paper className={classes.Table}>
            {this.state.Events ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Detalles</TableCell>
                      <TableCell>Estado del registro</TableCell>
                      <TableCell>Lista de Registro</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Events.map((Article) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.eventDate.split("T")[0]}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.details.bind(this, Article)}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Switch
                                color={"primary"}
                                checked={Article.enabled}
                                disabled={!Article.register}
                                onChange={this.changeStatus.bind(this, Article)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                disabled={!Article.register}
                                onClick={this.ListEvent.bind(this, Article)}
                              >
                                <FormatListBulletedIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.delete.bind(this, Article.uuid)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}
          </Paper>
          {this.state.eventInfo
            ? [
                <UpdateEvent
                  eventInfo={this.state.eventInfo}
                  DialogOpen={this.state.DialogCreate}
                  DialogClose={this.DialogCreate}
                  parentCallback={this.handleCallback}
                />,
              ]
            : [<></>]}
          {this.state.DialogListEvent && (
            <ListEvents
              eventId={this.state.eventId}
              DialogOpen={this.state.DialogListEvent}
              DialogClose={this.DialogCreate}
            />
          )}
        </Grid>
      );
    } else {
      return <></>;
    }
  }
}

export default withStyles(styles)(eventsPerMonth);
