import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const styles = (theme) => ({
  Table: {
    width: "100%",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function SelectType(props) {
  return (
    <Grid container className={props.classes.Table}>
      <FormControl className={props.classes.Table}>
        <InputLabel>Tipo de Evento</InputLabel>
        <Select
          value={props.ParentState.eventTypeId}
          onChange={(ev) => {
            props.ParentSet(ev.target.value, "eventTypeId");
          }}
        >
          <MenuItem value={1} key={1}>
            Presencial
          </MenuItem>
          <MenuItem value={2} key={2}>
            Virtual
          </MenuItem>
          <MenuItem value={3} key={3}>
            Mixto
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
}

export default withStyles(styles)(SelectType);
