import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import ReportList from "./ReportList";
import Secondary from "./SecondaryLogin";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  paperImg: {
    padding: theme.spacing(4),
    margin: "auto",
    maxWidth: 500,
  },
  Grid: {
    marginTop: theme.spacing(2),
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    if (this.props.userType === 45298 || this.props.userType === 72468) {
      //await this.fetchCompanyList();
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCallback = (urlFromChild, stateName) => {
    this.setState({
      [stateName]: urlFromChild,
    });
  };

  render() {
    if (this.props.userType) {
      return (
        <div>
          <ReportList />
        </div>
      );
    } else {
      return (
        <div>
          <Secondary />
        </div>
      );
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
