import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import UsersCall from "../../../API/Users";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import UserCall from "../../../API/Users";
import HQCall from "../../../API/Headquarters";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "70%",
    padding: "2%",
  },

  Grid: {
    width: "100%",
    height: "100%",
  },

  textField: {
    width: "88%",
  },

  div: {
    display: "flex",
    width: "96%",
    justifyContent: "end",
    marginTop: "3%",
  },

  btn: {
    marginRight: "10px",
  },

  DatosDiv: {
    width: "100%",
  },
  DivAgeC: {
    width: "50%",
  },

  color: {
    color: "#9e9e9e",
  },
  formControl: {
    width: "85%",
  },
});

const mapStateToProps = (state) => {
  return { userType: state.login.user.userType };
};

class UsersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      dateOfBirth: "",
      state: "",
      city: "",
      address: "",
      sex: "",
      phone: "",
      email: "",
      userType: "",
      headquartersUuid: "",
      lastNames: "",
      UserUuid: "",
      pass: "",
      pass2: "",

      readOnly: true,

      Uuid: "",
      btn: "Editar",
      HQ: {},
      Dialog: false,
    };
  }
  fetchProfile = async (Uuid) => {
    const UserProfile = await UsersCall.Profile(Uuid);
    this.setState({
      name: UserProfile.UserProfile.name,
      dateOfBirth: UserProfile.UserProfile.dateOfBirth,
      sex: UserProfile.UserProfile.sex,
      phone: UserProfile.UserProfile.mobilePhone,
      email: UserProfile.email,
      lastNames: UserProfile.UserProfile.lastNames,
      userType: UserProfile.userType,
      headquartersUuid:
        UserProfile.Headquarter !== null ? UserProfile.Headquarter.uuid : "",
      UserUuid: UserProfile.UserProfile.uuid,
    });
  };

  componentDidMount = async () => {
    if (this.props.location.state) {
      const { UserUuid } = this.props.location.state;
      this.setState({ Uuid: UserUuid });
      await this.ShowAllHQ();
      await this.fetchProfile(this.props.location.state.UserUuid);
    } else {
      this.props.history.push("/Usuarios");
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  Edit = async (event) => {
    if (this.state.btn === "Editar") {
      this.setState({ btn: "Guardar" });
      this.setState({ readOnly: false });
    } else {
      this.setState({ btn: "Editar" });
      this.setState({ readOnly: true });
      await this.Update(this.state.Profile);
      await this.fetchProfile(this.state.Uuid);
    }
  };
  handleChangePlace = (name) => (event) => {
    const Nname = name;
    const value = event.target.value;
    this.setState((prevState) => ({
      Profile: {
        ...prevState.Profile,
        [Nname]: value,
      },
    }));

    this.setState({ [name]: event.target.value });
  };

  Update = async (State) => {
    // eslint-disable-next-line
    const UserUpdate = await UserCall.Update(this.state);
    //console.log(UserUpdate)
  };

  UpdatePass = async (State) => {
    // eslint-disable-next-line
    const UserUpdatePass = await UserCall.ResetPass(State);
    console.log(UserUpdatePass)
    if(UserUpdatePass.status === "success")
    {
      alert("La contraseña ha sido exitosamente modificada")
    }
    else{
      alert("Error en el cambio de contraseña")
    }
  };

  ShowAllHQ = async () => {
    const ShowHQ = await HQCall.ShowAll();
    //console.log(ShowHQ)
    this.setState({ HQ: ShowHQ });
  };

  DialogOpen = async () => {
    if (this.state.Dialog) {
      if (this.state.pass === this.state.pass2) {
        this.setState({ Dialog: false });
        await this.UpdatePass(this.state);
      } else {
        alert("las contraseñas no coinciden");
      }
    } else {
      this.setState({ Dialog: true });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.Grid}>
          <div className={classes.DatosDiv}>
            <Grid container className={classes.Grid}>
              <Grid
                item
                xs={6}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Nombre"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="name"
                  onChange={this.onChange}
                  value={this.state.name}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Apellidos"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="lastNames"
                  onChange={this.onChange}
                  value={this.state.lastNames}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Fecha de nacimiento"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="dateOfBirth"
                  onChange={this.onChange}
                  value={this.state.dateOfBirth}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
                {/*<TextField
                  id="standard-read-only-input"
                  label="Estado"
                  className={classes.textField} 
                  margin="normal"
                  variant="outlined"
                  name="state"
                  onChange={this.onChange}
                  value = {this.state.state}
                  InputProps={{
                  readOnly: this.state.readOnly,
                  }}
                  />
                  <TextField
                  id="standard-read-only-input"
                  label="Ciudad"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="city"
                  onChange={this.onChange}
                  value = {this.state.city}
                  InputProps={{
                  readOnly: this.state.readOnly,
                  }} 
                  />
                  <TextField
                  id="standard-read-only-input"
                  label="Direccion"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="address"
                  onChange={this.onChange}
                  value = {this.state.address}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }} 
                />*/}
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Sexo"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="sex"
                  onChange={this.onChange}
                  value={this.state.sex}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Teléfono"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="phone"
                  onChange={this.onChange}
                  value={this.state.phone}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="E-mail"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  name="email"
                  onChange={this.onChange}
                  value={this.state.email}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />

                {this.state.btn === "Guardar" ? <div></div> : <div></div>}

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Tipo de Usuario
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.userType}
                    onChange={this.handleChangePlace("userType")}
                    inputProps={{ readOnly: this.state.readOnly }}
                  >
                    <MenuItem value={72468}>Administrativo</MenuItem>
                    <MenuItem value={48235}>General</MenuItem>
                    <MenuItem value={81493}>Asesor</MenuItem>
                    <MenuItem value={23648}>RH</MenuItem>
                  </Select>
                </FormControl>

                {this.state.HQ.length > 0 ? (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Oficina
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.headquartersUuid}
                      onChange={this.handleChangePlace("headquartersUuid")}
                      inputProps={{ readOnly: this.state.readOnly }}
                    >
                      {this.state.HQ.map((head, index) => {
                        return (
                          <MenuItem
                            value={head.headquarter.uuid}
                            key={head.headquarter.uuid}
                          >
                            {head.headquarter.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <div className={classes.load}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Oficina
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.headquartersUuid}
                        onChange={this.handleChangePlace("headquartersUuid")}
                      >
                        <MenuItem value={0}>Sin Oficinas</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
          <div className={classes.div}>
            <Link className={classes.Link} to="/Usuarios">
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn}
              >
                cancelar
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={this.Edit}
            >
              {this.state.btn}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={this.DialogOpen}
            >
              Reset Password
            </Button>
          </div>
          <Dialog
            open={this.state.Dialog}
            onClose={this.DialogOpen}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
            <DialogContent>
              <DialogContentText>Cambiar la contraseña</DialogContentText>
              <TextField
                id="standard-read-only-input"
                label="Nueva Contraseña"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="pass"
                type="password"
                onChange={this.onChange}
                value={this.state.pass}
              />
              <TextField
                id="standard-read-only-input"
                label=" Confirmar Contraseña"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="pass2"
                type="password"
                onChange={this.onChange}
                value={this.state.pass2}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.DialogOpen} color="primary">
                Cancel
              </Button>
              <Button onClick={this.DialogOpen} color="primary">
                Guardar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(UsersDetails));
