import base from "../../baseurl";
import token from "../../getTk";

const Update = async (array) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log("llego con array", array);
  const Update = await api
    .post("carousel/update", {
      json: {
        carousel: array,
      },
      timeout: false,
    })
    .json();
  console.log(Update);
  return Update;
};

export default Update;
