import React, { Component } from "react";
import { connect } from "react-redux";
import Datos from "@material-ui/icons/Launch";
import { Paper, withStyles, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CompaniesCall from "../../../API/Companies";
import MUIDataTable from "mui-datatables";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SeccionsCall from "../../../API/Sections";
import Skeleton from "@material-ui/lab/Skeleton";
import Edit from "../DialogShowCensus";
import { Button } from "@material-ui/core";
import CensusDialog from "../DialogNewCensus";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const mapStateToProps = (state) => {
  return {
    userType: state.login.user.userType,
    Uuid: state.login.user.userUuid,
  };
};

const styles = (theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  Head: {
    backgroundColor: "#ad1d21",
  },

  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    // textAlign: 'center',
    color: "#2f3542",
    alignItems: "center",
    justifyContent: "center",
  },

  fab: {
    display: "inlineBlock",
    left: "0",
    color: "#FFFF",
    marginTop: "1%",
    marginRight: "3%",
  },

  add: {
    display: "flex",
    justifyContent: "flex-end",
  },
  color: {
    color: "#FFFF",
  },
  paperTable: {
    overflow: "auto",
    maxHeight: "77vh",
  },
  Skeleton: {
    width: "100%",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
  Skeleton1: {
    width: "100%",
    height: "20px",
    borderRadius: "10px 10px 10px 10px",
  },
});

class TableEmpresas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Companies: [],
      CompaniesNot: [],
      stats: false,
      Onload: true,
      OnError: false,
      EditDialog: false,
      CensusDialog: false,
      uuid: "",
      CompanyName: "",
    };
  }

  fetchCompanies = async () => {
    const CompaniesShow = await CompaniesCall.ShowAll();

    if (this.props.userType === 81493) {
      const AsesorStatistic = await SeccionsCall.Show(this.props.Uuid);
      //console.log(AsesorStatistic);
      //console.log(CompaniesShow);

      var clean = [];

      for (var b = 0; b < AsesorStatistic.length; b++) {
        for (var a = 0; a < CompaniesShow.length; a++) {
          if (CompaniesShow[a].company.uuid === AsesorStatistic[b].uuid) {
            clean.push(CompaniesShow[a]);
            //console.log(CompaniesShow[a].company.uuid,AsesorStatistic[b].uuid)
          } else {
            //console.log(CompaniesShow[a].company.Uuid,AsesorStatistic[b].Uuid)
          }
        }
      }
      this.setState({ Companies: clean, stats: true });
    } else {
      this.setState({ Companies: CompaniesShow, stats: true });
    }
  };
  fetchCompaniesNotSta = async () => {
    try {
      const CompaniesNotS = await CompaniesCall.showAllNotSta();
      if (this.props.userType === 81493) {
        const AsesorStatistic = await SeccionsCall.Show(this.props.Uuid);
        var clean = [];
        for (var b = 0; b < AsesorStatistic.length; b++) {
          for (var a = 0; a < CompaniesNotS.length; a++) {
            if (CompaniesNotS[a].uuid === AsesorStatistic[b].uuid) {
              clean.push(CompaniesNotS[a]);
            }
          }
        }
        this.setState({ CompaniesNot: clean, Onload: false });
      } else {
        this.setState({ CompaniesNot: CompaniesNotS, Onload: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ Onload: false, OnError: true });
    }
  };

  componentDidMount = async () => {
    await this.fetchCompaniesNotSta();
    await this.fetchCompanies();
  };
  Dialog = (a, Selectuuid, name) => {
    this.setState({ [a]: !this.state[a] });
    if (Selectuuid) {
      this.setState({ uuid: Selectuuid });
    }
    name
      ? this.setState({ CompanyName: name })
      : this.setState({ CompanyName: "" });
  };
  render() {
    const { classes } = this.props;
    const data = [];
    const data2 = [];

    this.state.Companies.map((Comp) => {
      const btn = (
        <Button
          className={classes.Link}
          onClick={this.Dialog.bind(this, "EditDialog", Comp.company.uuid)}
        >
          <Datos />
        </Button>
      );

      const btn1 = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Empresas/Informacion",
            state: {
              companieUuid: Comp.company.uuid,
              employees: Comp.statistics.noOfEmployees,
            },
          }}
        >
          <AssignmentIcon />
        </Link>
      );

      const btn2 = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Empresas/Empleados/Actulizar",
            state: {
              companieUuid: Comp.company.uuid,
              companieName: Comp.company.name,
            },
          }}
        >
          <AddBoxIcon />
        </Link>
      );
      const btn3 = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Empresas/Informacion/Agregar",
            state: {
              company: Comp.company.uuid,
            },
          }}
        >
          <PersonAddIcon />
        </Link>
      );

      var Advisors = Object.keys(Comp.company.Advisors).length;

      data.push([
        Comp.company.name,
        Advisors,
        Comp.statistics.noOfEmployees,
        Math.round(Comp.statistics.percentOfMen),
        Comp.statistics.noOfMen,
        Math.round(Comp.statistics.percentOfWomen),
        Comp.statistics.noOfWomen,
        btn1,
        btn,
        btn2,
        btn3,
      ]);
      return data;
    });

    this.state.CompaniesNot.map((Comp) => {
      const btn = (
        <Button
          className={classes.Link}
          onClick={this.Dialog.bind(this, "EditDialog", Comp.uuid)}
        >
          <Datos />
        </Button>
      );

      const btn1 = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Empresas/Informacion",
            state: {
              companieUuid: Comp.uuid,
              employees: " ",
            },
          }}
        >
          <AssignmentIcon />
        </Link>
      );

      const btn2 = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Empresas/Empleados/Actulizar",
            state: {
              companieUuid: Comp.uuid,
              companieName: Comp.name,
            },
          }}
        >
          <AddBoxIcon />
        </Link>
      );
      const btn3 = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Empresas/Informacion/Agregar",
            state: { company: Comp.uuid },
          }}
        >
          <PersonAddIcon />
        </Link>
      );

      data2.push([
        Comp.name,
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.Skeleton1}
        />,
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.Skeleton1}
        />,
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.Skeleton1}
        />,
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.Skeleton1}
        />,
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.Skeleton1}
        />,
        <Skeleton
          variant="rect"
          animation="wave"
          className={classes.Skeleton1}
        />,
        btn1,
        btn,
        btn2,
        btn3,
      ]);
      return data2;
    });

    const columns = [
      "Empresa",
      "Asesores",
      "Trabajadores",
      " Porcentaje Hombres",
      "Número de hombres",
      " Porcentaje Mujeres",
      "Número de mujeres",
      "Ficha técnica",
      "Histórico padrón",
      "Subir padrón",
      "Agregar Afiliado",
    ];

    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 100,
      rowsPerPageOptions: [10, 50, 100, 200],
    };
    return (
      <>
        {this.state.Onload
          ? [
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />,
            ]
          : [
              this.state.OnError
                ? [
                    <Paper className={classes.FullPaper}>
                      <Grid
                        container
                        className={classes.Full}
                        alignContent="center"
                        justify="center"
                      >
                        <Typography variant="h6" gutterBottom align="center">
                          Error
                        </Typography>
                      </Grid>
                    </Paper>,
                  ]
                : [
                    <>
                      <Paper className={classes.paperTable}>
                        {this.state.stats === true ? (
                          <MUIDataTable
                            title={"Empresas"}
                            data={data}
                            columns={columns}
                            options={options}
                            selectableRows="none"
                          />
                        ) : (
                          <MUIDataTable
                            title={"Empresas"}
                            data={data2}
                            columns={columns}
                            options={options}
                            selectableRows="none"
                          />
                        )}
                      </Paper>
                      <div className={classes.add}>
                        {this.props.userType === 81493 ? (
                          <div></div>
                        ) : (
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            className={classes.fab}
                          >
                            <Link className={classes.Link} to="/Empresas/Crear">
                              <AddIcon className={classes.color} />
                            </Link>
                          </Fab>
                        )}
                      </div>
                      {this.state.EditDialog
                        ? [
                            <Edit
                              DialogOpen={this.state.EditDialog}
                              DialogClose={this.Dialog.bind(this, "EditDialog")}
                              uuid={this.state.uuid}
                            />,
                          ]
                        : [<></>]}
                      {this.state.CensusDialog
                        ? [
                            <CensusDialog
                              DialogOpen={this.state.CensusDialog}
                              DialogClose={this.Dialog.bind(
                                this,
                                "CensusDialog"
                              )}
                              uuid={this.state.uuid}
                              name={this.state.CompanyName}
                            />,
                          ]
                        : [<></>]}
                    </>,
                  ],
            ]}
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(TableEmpresas));
