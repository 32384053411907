import Create from "./Create";
import Delete from "./Delete";
import showAll from "./showAll";

const Voting = {
  Create,
  Delete,
  showAll,
};

export default Voting;

export { Create, Delete, showAll };
