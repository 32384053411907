import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import apiCall from "../../API/CallUtils";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  Head: {
    backgroundColor: "#ad1d21",
  },

  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    // textAlign: 'center',
    color: "#2f3542",
    alignItems: "center",
    justifyContent: "center",
  },

  fab: {
    display: "inlineBlock",
    left: "0",
    color: "#FFFF",
    marginTop: "1%",
    marginRight: "3%",
  },

  add: {
    display: "flex",
    justifyContent: "flex-end",
  },
  color: {
    color: "#FFFF",
  },
  paperTable: {
    height: "79vh",
  },
  Skeleton: {
    width: "100%",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = { Companies: [], onLoad: true };
  }

  fetchUsers = async () => {
    try {
      const CompaniesShow = await apiCall.Comunication();
      this.setState({ Companies: CompaniesShow, onLoad: false });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = async () => {
    await this.fetchUsers();
  };

  filterTitles = (userlist, type) => {
    if (userlist && userlist.length) {
      if (!type) {
        return userlist
          .map((user) => {
            return `${user.UserProfile.title}`;
          })
          .join()
          .replaceAll(",", "\n");
      }
      return userlist
        .map((user) => {
          return `${user.CandidatePosition.name}`;
        })
        .join()
        .replaceAll(",", "\n");
    }
    return " ";
  };
  filterNames = (userlist) => {
    if (userlist && userlist.length) {
      return userlist
        .map((user) => {
          return `${user.UserProfile.name + user.UserProfile.lastNames}`;
        })
        .join()
        .replaceAll(",", "\n");
    }
    return " ";
  };
  filterPhones = (userlist) => {
    if (userlist && userlist.length) {
      return userlist
        .map((user) => {
          return `${user.UserProfile.personalPhone}`;
        })
        .join()
        .replaceAll(",", "\n");
    }
    return " ";
  };
  filterEmails = (userlist) => {
    if (userlist && userlist.length) {
      return userlist
        .map((user) => {
          return `${user.UserProfile.otherMail}`;
        })
        .join()
        .replaceAll(",", "\n");
    }
    return " ";
  };
  filterCity = (userlist) => {
    if (userlist && userlist.length) {
      return userlist
        .map((user) => {
          return `${user.UserProfile.city}`;
        })
        .join()
        .replaceAll(",", "\n");
    }
    return " ";
  };

  render() {
    const { classes } = this.props;
    const data = [];
    this.state.Companies.map((Company) => {
      data.push([
        Company.name,
        Company.hasCommittee ? "Regional" : "De industria",
        this.filterTitles(
          Company.hasCommittee
            ? Company.AssemblyMembers
            : Company.OtherAssemblyMembers,
          Company.hasCommittee
        ),
        this.filterNames(
          Company.hasCommittee
            ? Company.AssemblyMembers
            : Company.OtherAssemblyMembers
        ),
        this.filterPhones(
          Company.hasCommittee
            ? Company.AssemblyMembers
            : Company.OtherAssemblyMembers
        ),
        this.filterEmails(
          Company.hasCommittee
            ? Company.AssemblyMembers
            : Company.OtherAssemblyMembers
        ),
        this.filterCity(
          Company.hasCommittee
            ? Company.AssemblyMembers
            : Company.OtherAssemblyMembers
        ),
        Company.CompanyAdmins && Company.CompanyAdmins.length > 0
          ? Company.CompanyAdmins.map((Admin) => {
              return Admin.title;
            })
              .join()
              .replaceAll(",", "\n")
          : "",
        Company.CompanyAdmins && Company.CompanyAdmins.length > 0
          ? Company.CompanyAdmins.map((Admin) => {
              return Admin.fullName;
            })
              .join()
              .replaceAll(",", "\n")
          : "",
        Company.CompanyAdmins && Company.CompanyAdmins.length > 0
          ? Company.CompanyAdmins.map((Admin) => {
              return Admin.phone;
            })
              .join()
              .replaceAll(",", "\n")
          : "",
        Company.CompanyAdmins && Company.CompanyAdmins.length > 0
          ? Company.CompanyAdmins.map((Admin) => {
              return Admin.email;
            })
              .join()
              .replaceAll(",", "\n")
          : "",
      ]);
      return data;
    });
    const columns = [
      "Empresa",
      "Tipo",
      "Títulos",
      "Nombres",
      "Teléfono",
      "E-mail",
      "Municipio",
      "Títulos",
      "Nombres",
      "Teléfono",
      "E-mail",
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin coincidencias",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      print: false,
      selectableRows: "none",
    };
    return this.state.onLoad ? (
      <Skeleton variant="rect" animation="wave" className={classes.Skeleton} />
    ) : (
      <Paper className={classes.paperTable}>
        <MUIDataTable
          title={"Personas a Informar"}
          data={data}
          columns={columns}
          options={options}
        />
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Users));
