import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import toastType from "../../../API/CallUtils/tostyNotification";
import Deli from "../../../API/DeliveryOfGoods";
import CheckIcon from "@material-ui/icons/Check";
import {
  OnError,
  OnLoadSection,
  OnNull,
} from "../../../Components/StatesForComponets";
import BackspaceIcon from "@material-ui/icons/Backspace";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class DocsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedState: "Onload",
      List: [],
      Sons: [],
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.id) {
        //await this.fetchDocs(this.props.id);
        await this.fetchSons(this.props.id);
      } else {
        toastType.error(" CSE No fue posible buscar los documentos");
      }
    } catch (e) {
      console.log(e);
    }
  };

  fetchDocs = async (id) => {
    try {
      const doc = await Deli.Documents(id);
      console.log(doc);
      if (doc.status === "success") {
        doc.data.personalDocuments.length > 0
          ? this.setState({
              SelectedState: "Success",
              List: doc.data.personalDocuments,
            })
          : this.setState({ SelectedState: "Null" });
      } else {
        this.setState({ SelectedState: "Error" });
      }
    } catch (e) {
      console.log(e);
      this.setState({ SelectedState: "Error" });
    }
  };
  fetchSons = async (id) => {
    try {
      const doc = await Deli.ShowAllSons(id);
      console.log(doc);
      if (doc.status === "success") {
        doc.data.sons.length > 0
          ? this.setState({ Sons: doc.data.sons, SelectedState: "Success" })
          : this.setState({ SelectedState: "Null" });
      } else {
        this.setState({ SelectedState: "Error" });
      }
    } catch (e) {
      console.log(e);
      this.setState({ SelectedState: "Error" });
    }
  };
  fetchSonsDocs = async (id) => {
    try {
      const doc = await Deli.DocFromSons(id);
      console.log(doc);
      if (doc.status === "success") {
        doc.data.sonDocuments.length > 0
          ? this.setState({ SelectedState: "Null" })
          : this.setState({ SelectedState: "Success", Sons: doc.data.sons });
      } else {
        this.setState({ SelectedState: "Error" });
      }
    } catch (e) {
      console.log(e);
      this.setState({ SelectedState: "Error" });
    }
  };
  selectType = async (newType) => {
    if (!this.state.OnLoad) {
      this.setState({
        OnLoad: true,
      });
      try {
        const update = await Deli.UpdateState(this.props.id, newType);
        console.log(update);
        if (update.status === "success") {
          this.setState({
            OnLoad: false,
          });
          toastType.success("Cambio Realizado");
          this.props.DialogClose();
          this.props.Refresh();
        } else {
          this.setState({
            OnLoad: false,
          });
          toastType.error("SSE No fue posible Cambiar de estado");
        }
      } catch (e) {
        console.log(e);
        this.setState({
          OnLoad: false,
        });
        toastType.error("CSE No fue posible Cambiar de estado");
      }
    } else {
      toastType.error("El cambio actual se sigue ejecutando");
    }
  };
  render() {
    var Selected = <></>;
    console.log(this.state.SelectedState);
    switch (this.state.SelectedState) {
      case "Success":
        Selected = (
          <Grid className={this.props.paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Escolaridad</TableCell>
                  <TableCell>Documentos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.Sons.map((Article, index) => {
                  return (
                    <React.Fragment>
                      <TableRow key={Article.uuid}>
                        <TableCell component="th" scope="row">
                          {Article.fullName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {Article.ScholarshipId}
                        </TableCell>
                        {Article.SonDocuments.length > 0
                          ? [
                              Article.SonDocuments.map((Docs) => {
                                return (
                                  <TableCell component="th" scope="row">
                                    <Button
                                      onClick={() => {
                                        window.open(Docs.url, "_blank").focus();
                                      }}
                                    >
                                      {Docs.url.split("/").slice(-1).pop()}
                                    </Button>
                                  </TableCell>
                                );
                              }),
                            ]
                          : [
                              <TableCell component="th" scope="row">
                                Sin Documentos
                              </TableCell>,
                            ]}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        );
        break;
      case "Null":
        Selected = (
          <Grid>
            <OnNull />
          </Grid>
        );
        break;
      case "Error":
        Selected = <OnError />;
        break;
      case "Onload":
        Selected = <OnLoadSection />;
        break;
      default:
        Selected = <OnError />;
        break;
    }
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">Documentos</DialogTitle>
        <DialogContent className={classes.paper}>{Selected}</DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.DialogClose}
            color="secondary"
            startIcon={<BackspaceIcon />}
          >
            Cerrar
          </Button>
          {this.state.SelectedState === "Success"
            ? [
                <>
                  <Button
                    onClick={this.selectType.bind(this, 5)}
                    color="secondary"
                    startIcon={<CloseIcon />}
                  >
                    Rechazar
                  </Button>
                  <Button
                    onClick={this.selectType.bind(this, 2)}
                    color="primary"
                    startIcon={<CheckIcon />}
                  >
                    Aprobar
                  </Button>
                </>,
              ]
            : [<></>]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DocsDetails);
