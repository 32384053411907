import base from "../baseurl";
import token from "../getTk"

const CensusDates = async (Uuid) => {
  const tokenA = token.get()

  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });

    const CensusDates = await api.get("census/index/"+Uuid,{timeout:false}).json();
    console.log(CensusDates)
    return CensusDates;
    
  };
  
  export default  CensusDates ;