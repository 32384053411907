import base from "../baseurl";
import token from "../getTk";

const UpdateEmployees = async (employees, companyUuid, file) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const Census = await api
    .post("uploadcensus/create", { json: { employees, companyUuid, file } })
    .json();
  return Census.data;
};

export default UpdateEmployees;
