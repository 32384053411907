import base from "../baseurl";
import token from "../getTk";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const Update = async (body, city, state, where, url) => {
  if (url) {
    body.nominationDocument
      ? (body.nominationDocument = body.nominationDocument.url)
      : (body.nominationDocument = "");
    body.storyOfBenefits
      ? (body.storyOfBenefits = body.storyOfBenefits.url)
      : (body.storyOfBenefits = "");
    body.internalRegulation
      ? (body.internalRegulation = body.internalRegulation.url)
      : (body.internalRegulation = "");
    body.colectiveContract
      ? (body.colectiveContract = body.colectiveContract.url)
      : (body.colectiveContract = "");
    body[where] = url;
  }
  const tokenA = token.get();

  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log(body, city, state, where, url);
  const status = await api
    .post("companies/update", {
      json: {
        companyUuid: body.uuid,
        name: body.name,
        phone: body.phone,
        address: body.address,
        hasCommittee: body.hasCommittee,
        cityUuid: city ? (city === " " ? "" : city) : "",
        stateUuid: state ? state : "",
        country: body.country,
        rhPassword: body.rhPassword,
        industrialPark: body.industrialPark,
        acceptanceDate: body.acceptanceDate,
        advisorsUuid: "",
        otherDocuments: body.otherDocuments ? body.otherDocuments : "",
        nominationDocument: body.nominationDocument
          ? { url: body.nominationDocument, date: moment() }
          : "",
        storyOfBenefits: body.storyOfBenefits
          ? { url: body.storyOfBenefits, date: moment() }
          : "",
        internalRegulation: body.internalRegulation
          ? { url: body.internalRegulation, date: moment() }
          : "",
        colectiveContract: body.colectiveContract
          ? { url: body.colectiveContract, date: moment() }
          : "",
        technicalDetails: {
          uuid: body.TechnicalDetail.uuid,
          section: "",
          contract: body.TechnicalDetail.contract,

          delegate: {
            delegates: body.TechnicalDetail.delegates,
            commissioners: body.TechnicalDetail.commissioners,
            secretaries: body.TechnicalDetail.secretaries,
          },

          manager: body.TechnicalDetail.manager,
          productionManager: body.TechnicalDetail.productionManager,
          product: body.TechnicalDetail.product,
          operationStart: body.TechnicalDetail.operationStart,
          clients: body.TechnicalDetail.clients,
          lastRevision: body.TechnicalDetail.lastRevision,
        },
        benefits: {
          uuid: body.Benefit.uuid,
          christmasBox: body.Benefit.christmasBox,
          holydayBonus: body.Benefit.holydayBonus,
          savingFund: body.Benefit.savingFund,
          utilities: body.Benefit.utilities,
          vouchers: body.Benefit.vouchers,
          attendanceBonus: body.Benefit.attendanceBonus,
          lunchBonus: body.Benefit.lunchBonus,
          transportBonus: body.Benefit.transportBonus,
          other: body.Benefit.other,
        },
      },
    })
    .json();
  console.log(status);
  return status;
};

export default Update;
