import React, { Component } from "react";
import { connect } from "react-redux";
import SportApi from "../../../API/SportEvent";
import {
  Typography,
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Grid,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBoxIcon from "@material-ui/icons/Add";
import Skeleton from "@material-ui/lab/Skeleton";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import toasType from "../../../API/CallUtils/tostyNotification";
import UploadFile from "../../../Components/Header/UploadFiles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
  };
};

class Releases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Bulletin: [],

      readOnly: true,

      EditTitle: "",
      EditDescrip: "",
      EditPdfName: "",
      EditPdfCont: "",
      EditPdfImg: "",
      EditUuid: "",

      DialogEdit: false,
      DialogCreate: false,

      NewTitle: "",
      NewDescrip: "",
      NewPdfName: "",
      NewPdfImg: null,
      NewPdfCont: "",

      onSave: false,
      onLoad: false,
      onDelete: false,
      onErrorFetch: false,

      links: [],
      DialogNewLink: false,
      NewLink: "",
      TestErro: false,
    };
  }
  fetchBulletins = async () => {
    this.setState({ onLoad: false });

    try {
      const Bulletins = await SportApi.SportBulletinsShowAll();
      if (Bulletins.status === "success") {
        this.setState({
          Bulletin: Bulletins.data.bulletins,
          onLoad: true,
        });
      } else {
        toasType.error(
          "No Fue posible obtener los boletines, ServerSide Error"
        );
        this.setState({
          onErrorFetch: true,
          onLoad: true,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        onErrorFetch: true,
        onLoad: true,
      });
      toasType.error("No Fue posible obtener los boletines, FrontSide Error");
    }
  };

  componentDidMount = async () => {
    await this.fetchBulletins();
  };

  Edit = (state) => {
    this.setState({
      EditTitle: state.title,
      EditDescrip: state.description,
      EditPdfCont: state.pdf,
      EditPdfImg: state.img,
      EditUuid: state.uuid,
      links: state.links.split(","),
      DialogEdit: true,
    });
  };

  NewBolletin = () => {
    this.setState({
      DialogCreate: true,
      NewTitle: "",
      NewDescrip: "",
      NewPdfName: "",
      NewPdfCont: "",
      NewPdfImg: null,
      links: [],
    });
  };

  Delete = async (Uuid) => {
    try {
      const Delete = await SportApi.SportBulletinsDelete(Uuid);
      if (Delete.status === "success") {
        toasType.success("Boletín Borrado");
        await this.fetchBulletins();
        this.cleanState();
      } else {
        console.log();
        toasType.error(
          " No fue posible Borrar el comunicado seleccionado, ServerSide Error"
        );
      }
    } catch (e) {
      console.log(e);
      toasType.error(
        " No fue posible Borrar el comunicado seleccionado, FrontSide Error"
      );
    }
  };
  Update = async () => {
    try {
      const Update = await SportApi.SportBulletinsUpdate(this.state);
      if (Update.status === "success") {
        toasType.success("Boletin Modificado");
        await this.fetchBulletins();
        this.cleanState();
      } else {
        toasType.error(
          " No fue posible modificar el comunicado seleccionado, ServerSide Error"
        );
      }
    } catch (e) {
      console.log(e);
      toasType.error(
        " No fue posible modificar el comunicado seleccionado, FrontSide Error"
      );
    }
  };
  Create = async () => {
    if (this.state.NewPdfCont === "") {
      toasType.error("Los comunicados requieren una imagen de portada");
    } else {
      try {
        const Create = await SportApi.SportBulletinsCreate(this.state);
        console.log(Create);
        if (Create.status === "success") {
          toasType.success("Comunicado Creado, Refrescando información");
          await this.fetchBulletins();
          this.cleanState();
        } else {
          toasType.error("Error en la creación, ServerSide");
        }
      } catch (e) {
        console.log(e);
        toasType.error("Error en la creación, FrontSide");
      }
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  DialogClose = async (Name) => {
    this.setState({ [Name]: false });
  };
  NewLink = () => {
    this.setState({
      DialogNewLink: true,
      NewLink: "",
    });
  };

  addLink = () => {
    this.setState({
      links: [...this.state.links, this.state.NewLink],
      DialogNewLink: false,
    });
  };

  deleteLink = (i) => {
    let value = this.state.links.filter((item, j) => i !== j);
    this.setState({ links: value });
  };

  handleClose = () => {
    this.setState({ TestErro: false });
  };

  handleCallback = (urlFromChild, stateName) => {
    this.setState({
      [stateName]: urlFromChild,
    });
  };

  cleanState = () => {
    this.setState({
      DialogCreate: false,
      NewTitle: "",
      NewDescrip: "",
      NewPdfName: "",
      NewPdfCont: "",
      links: [],
      EditTitle: "",
      EditDescrip: "",
      EditPdfName: "",
      EditPdfCont: "",
      EditUuid: "",
      DialogEdit: false,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.Grid}>
          <Grid container className={classes.Grid}>
            <Grid
              item
              xs={12}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography
                variant="h5"
                color="textPrimary"
                component="p"
                className={classes.Title}
              >
                Boletines Deportivos
              </Typography>

              {this.state.onLoad
                ? [
                    this.state.Bulletin.length > 0 ? (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >
                        {this.state.Bulletin.map((Article, index) => {
                          return (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <Card className={classes.root}>
                                <CardActionArea>
                                  <CardMedia
                                    className={classes.media}
                                    image={Article.img}
                                    title={Article.pdf.name}
                                  />
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="h2"
                                    >
                                      {Article.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                    >
                                      {Article.description}
                                    </Typography>
                                    {Article.pdf
                                      ? [
                                          <Button size="small" color="primary">
                                            <a href={Article.pdf}>Documento</a>
                                          </Button>,
                                        ]
                                      : [<></>]}
                                    {Article.links && Article.links.length > 0
                                      ? [
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                          >
                                            links
                                          </Typography>,
                                        ]
                                      : []}

                                    {Article.links && Article.links.length > 0
                                      ? [
                                          Article.links
                                            .split(",")
                                            .map((link, index) => {
                                              return (
                                                <a href={link}>
                                                  <Button
                                                    size="small"
                                                    color="primary"
                                                  >
                                                    {index + 1}
                                                  </Button>
                                                </a>
                                              );
                                            }),
                                        ]
                                      : [<></>]}
                                  </CardContent>
                                </CardActionArea>
                                <CardActions>
                                  <Button
                                    size="small"
                                    color="primary"
                                    onClick={this.Edit.bind(this, Article)}
                                  >
                                    Editar
                                  </Button>
                                  <Button
                                    size="small"
                                    color="secondary"
                                    onClick={this.Delete.bind(
                                      this,
                                      Article.uuid
                                    )}
                                  >
                                    Eliminar
                                  </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        lassName={classes.SkeletonGrid}
                        spacing={3}
                      >
                        <Typography
                          variant="body"
                          color="textPrimary"
                          component="p"
                          className={classes.load}
                        >
                          No hay Boletines
                        </Typography>
                      </Grid>
                    ),
                  ]
                : [
                    this.state.onErrorFetch
                      ? [
                          <>
                            <Typography
                              variant="body"
                              color="textPrimary"
                              component="p"
                              className={classes.load}
                            >
                              Error en la descarga de la información, este error
                              puede deberse a su internet
                            </Typography>
                            <Typography
                              variant="body"
                              color="textPrimary"
                              component="p"
                              className={classes.load}
                            >
                              O factores externos, intente presionando el botón
                              de Re-intentar o refrescando la pagina
                            </Typography>
                            <Typography
                              variant="body"
                              color="textPrimary"
                              component="p"
                              className={classes.load}
                            >
                              Si los problemas continúan comuníquese a la
                              oficina principal
                            </Typography>
                            <div className={classes.load}>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                className={classes.btn}
                                onClick={this.fetchBulletins}
                              >
                                Re-intentar
                              </Button>
                            </div>
                          </>,
                        ]
                      : [
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            lassName={classes.SkeletonGrid}
                            spacing={3}
                          >
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <Skeleton variant="text" animation="wave" />
                              <Skeleton
                                variant="rect"
                                width={210}
                                height={118}
                                animation="wave"
                              />
                              <Skeleton variant="text" animation="wave" />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <Skeleton variant="text" animation="wave" />
                              <Skeleton
                                variant="rect"
                                width={210}
                                height={118}
                                animation="wave"
                              />
                              <Skeleton variant="text" animation="wave" />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <Skeleton variant="text" animation="wave" />
                              <Skeleton
                                variant="rect"
                                width={210}
                                height={118}
                                animation="wave"
                              />
                              <Skeleton variant="text" animation="wave" />
                            </Grid>
                          </Grid>,
                        ],
                  ]}
              <div className={classes.add}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addBTN}
                  onClick={this.NewBolletin}
                >
                  <AddBoxIcon />
                </Button>
              </div>
            </Grid>
          </Grid>

          <Dialog
            open={this.state.DialogCreate}
            onClose={this.DialogClose.bind(this, "DialogCreate")}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Nuevo Comunicado</DialogTitle>
            {this.state.onSave === true ? (
              <DialogContent>
                <div className={classes.load}>
                  <CircularProgress />
                </div>
                <div className={classes.load}>Cargando la información</div>
              </DialogContent>
            ) : (
              <DialogContent>
                <Grid container className={classes.GridD}>
                  <TextField
                    label="Titulo"
                    name="NewTitle"
                    value={this.state.NewTitle}
                    className={classes.Text}
                    onChange={this.onChange}
                  />
                  <TextField
                    label="Descripción"
                    name="NewDescrip"
                    value={this.state.NewDescrip}
                    className={classes.Text}
                    onChange={this.onChange}
                  />

                  <UploadFile
                    Type="Imagen"
                    Place="SITIMM_BULLETINS_GENERAL_IMG"
                    parentCallback={this.handleCallback}
                    stateNameOnParent="NewPdfImg"
                  />
                  <UploadFile
                    Type="Documento"
                    Place="SITIMM_BULLETINS_GENERAL_DOC"
                    parentCallback={this.handleCallback}
                    stateNameOnParent="NewPdfCont"
                  />

                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    component="p"
                    className={classes.Title}
                  >
                    Links complementarios
                  </Typography>

                  {this.state.links.length > 0
                    ? [
                        <Paper className={classes.Table}>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Link</TableCell>
                                <TableCell>Eliminar</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.links.map((Article, index) => {
                                return (
                                  <React.Fragment>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {Article}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Button
                                          color="secondary"
                                          onClick={this.deleteLink.bind(
                                            this,
                                            index
                                          )}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Paper>,
                      ]
                    : [
                        <div className={classes.imgDiv}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            component="p"
                            className={classes.Title}
                          >
                            Sin links
                          </Typography>
                        </div>,
                      ]}
                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.NewLink}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={this.DialogClose.bind(this, "DialogCreate")}
                color="secondary"
              >
                Cancelar
              </Button>
              <Button onClick={this.Create} color="primary">
                Crear Comunicado
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.DialogNewLink}
            onClose={this.DialogClose.bind(this, "DialogNewLink")}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            fullWidth="xs"
          >
            <DialogTitle id="form-dialog-title">Nuevo Link</DialogTitle>
            <DialogContent>
              <Grid container className={classes.GridD}>
                <TextField
                  label="Link"
                  name="NewLink"
                  value={this.state.NewLink}
                  className={classes.Text}
                  onChange={this.onChange}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.DialogClose.bind(this, "DialogNewLink")}
                color="secondary"
              >
                Cancelar
              </Button>
              <Button onClick={this.addLink} color="primary">
                Añadir link
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.DialogEdit}
            onClose={this.DialogClose.bind(this, "DialogEdit")}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Editor de Comunicados
            </DialogTitle>
            {this.state.onSave === true ? (
              <DialogContent>
                <div className={classes.load}>
                  <CircularProgress />
                </div>
                <div className={classes.load}>Procesando Acción</div>
              </DialogContent>
            ) : (
              <DialogContent>
                <Grid container className={classes.GridD}>
                  <TextField
                    label="Titulo"
                    name="EditTitle"
                    value={this.state.EditTitle}
                    className={classes.Text}
                    onChange={this.onChange}
                  />
                  <TextField
                    label="Descripción"
                    name="EditDescrip"
                    value={this.state.EditDescrip}
                    className={classes.Text}
                    onChange={this.onChange}
                  />

                  <UploadFile
                    Type="Imagen"
                    Place="SITIMM_BULLETINS_GENERAL_IMG"
                    parentCallback={this.handleCallback}
                    stateNameOnParent="EditPdfImg"
                    PrevImg={this.state.EditPdfImg}
                  />
                  <UploadFile
                    Type="Documento"
                    Place="SITIMM_BULLETINS_GENERAL_DOC"
                    parentCallback={this.handleCallback}
                    stateNameOnParent="EditPdfCont"
                    PrevImg={this.state.EditPdfCont}
                  />

                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    component="p"
                    className={classes.Title}
                  >
                    Links complementarios
                  </Typography>

                  {this.state.links.length > 0
                    ? [
                        <Paper className={classes.Table}>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Link</TableCell>
                                <TableCell>Eliminar</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.links.map((Article, index) => {
                                return (
                                  <React.Fragment>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {Article}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Button
                                          color="secondary"
                                          onClick={this.deleteLink.bind(
                                            this,
                                            index
                                          )}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Paper>,
                      ]
                    : [
                        <div className={classes.imgDiv}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            component="p"
                            className={classes.Title}
                          >
                            Sin links
                          </Typography>
                        </div>,
                      ]}
                  <div className={classes.Remp}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                      onClick={this.NewLink}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                onClick={this.DialogClose.bind(this, "DialogEdit")}
                color="secondary"
              >
                Cancelar
              </Button>
              <Button onClick={this.Update} color="primary">
                Editar Comunicado
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={this.state.TestErro}
            autoHideDuration={6000}
            onClose={this.handleClose}
          >
            <Alert onClose={this.handleClose} severity="error">
              Se a producido un Error {this.state.typeError}
            </Alert>
          </Snackbar>
        </Grid>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Releases));
