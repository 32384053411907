import base from "../../baseurl";
import token from "../../getTk";

const Update = async () => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const showA = await api
    .get("carousel/get", {
      timeout: false,
    })
    .json();
  //console.log(showA)
  return showA.data;
};

export default Update;
