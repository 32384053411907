import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import SportEvent from "../../../API/SportEvent";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFile from "../../../Components/Header/UploadFiles";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Select: null, Oncreate: false, name: "", url: "" };
  }
  componentDidMount = async () => {
    if (this.props.info) {
      console.log(this.props.info);
      this.setState({
        name: this.props.info.name,
        url: this.props.info.background,
      });
    }
  };
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });

      const Deli = await SportEvent.DocumentsTypeUpdate(
        this.state.name,
        this.state.url,
        this.props.info.id
      );
      if (Deli.status === "success") {
        this.setState({
          Oncreate: false,
        });
        toastType.success("Se Editado con éxito");
        await this.props.Refresh("DialogEditEvent");
      } else {
        this.setState({
          Oncreate: false,
        });
        toastType.error("SSE No fue Posible Eliminar el elemento");
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Editar tipo de documento
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <TextField
              label="Nombre del tipo de Documento"
              margin="normal"
              size="small"
              name="name"
              onChange={this.onChange}
              fullWidth
              value={this.state.name}
            />
            <UploadFile
              Type="Imagen"
              Place="SITIMM_GALLERIES_SPORTEVENT_DISIPLINE_DOCTYPES"
              parentCallback={(urlFromChild, stateName) => {
                this.setState({
                  [stateName]: urlFromChild,
                });
              }}
              stateNameOnParent="url"
              PrevImg={this.props.info.url}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.name.length > 0 && this.state.url
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
