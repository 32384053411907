import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const styles = (theme) => ({
  Table: {
    width: "100%",
    marginBottom: "8px",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function SelectRegis(props) {
  return (
    <FormControl className={props.classes.Table}>
      <InputLabel>Colonia</InputLabel>
      <Select
        value={props.value}
        onChange={(ev) => {
          props.sentoParent("Colony", ev.target.value);
        }}
      >
        {props.List &&
          props.List.length > 0 &&
          props.List.map((x) => {
            return (
              <MenuItem value={x.uuid} key={x.id}>
                {x.name}
              </MenuItem>
            );
          })}
        {props.List.length < 0 && (
          <MenuItem value={" "} key={0}>
            Seleccione una ciudad
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default withStyles(styles)(SelectRegis);
