import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SelecType from "../Components/SelecType";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import Delivery from "../../../API/DeliveryOfGoods";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadFile from "../../../Components/Header/UploadFiles";
import { DatePicker } from "material-ui-pickers";
import CompaniesInTheEvent from "../Components/CompaniesIntTheEvent";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  text: {
    width: "75%",
    margin: "5px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      basics: null,
      registryEndDate: null,
      registryStartDate: null,
      startDate: null,
      endDate: null,
      CompanySelect: true,
      Companies: [],
    };
  }
  componentDidMount = async () => {
    if (this.props.companiesList) {
      this.setState({
        Companies: this.props.companiesList.map((company) => {
          return {
            ...company,
            enable: true,
          };
        }),
      });
    }
  };
  selectType = async (id) => {
    this.setState({ Select: id });
  };
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });

      const Deli = await Delivery.Create(
        this.state.Companies.filter((company) => company.enable === true).map(
          (x) => x.id
        ),
        this.state.Select,
        this.state
      );
      console.log(Deli);
      if (Deli.status === "success") {
        this.setState({
          Oncreate: false,
        });
        toastType.success("Se creado con éxito");
        await this.props.Refresh("CreateDialog");
      } else {
        this.setState({
          Oncreate: false,
        });
        toastType.error("SSE No fue Posible crear el elemento");
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible crear el evento");
      console.log(e);
    }
  };
  changeDate = (name, acceptanceDate) => {
    var fecha = moment(new Date(acceptanceDate)).toISOString();
    this.setState({ [name]: fecha });
  };
  handleCallback = (urlFromChild, stateName) => {
    this.setState({
      [stateName]: urlFromChild,
    });
  };
  handleChange = (id) => {
    this.setState({
      Companies: this.state.Companies.map((company) => {
        if (company.id === id) {
          return {
            ...company,
            enable: !company.enable,
          };
        } else {
          return company;
        }
      }),
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.DialogOpen} onClose={this.props.DialogClose}>
        <DialogTitle id="form-dialog-title">Nuevo Evento</DialogTitle>
        <DialogContent className={classes.paper}>
          {this.state.CompanySelect ? (
            <div className={classes.paper}>
              <SelecType Select={this.selectType} Width={"75%"} />
              <DatePicker
                label="Inicio del evento"
                value={this.state.startDate}
                onChange={this.changeDate.bind(this, "startDate")}
                className={classes.text}
              />
              <DatePicker
                label="Final del evento"
                value={this.state.endDate}
                onChange={this.changeDate.bind(this, "endDate")}
                className={classes.text}
              />
              <DatePicker
                label="Inicio de inscripciones"
                value={this.state.registryStartDate}
                onChange={this.changeDate.bind(this, "registryStartDate")}
                className={classes.text}
              />
              <DatePicker
                label="Final de inscripciones"
                value={this.state.registryEndDate}
                onChange={this.changeDate.bind(this, "registryEndDate")}
                className={classes.text}
              />
              <UploadFile
                Type="Infomacion del evento"
                Place="SITIMM_DeliveryGoods_Info"
                parentCallback={this.handleCallback}
                stateNameOnParent="basics"
                PrevImg={this.state.basics}
              />
            </div>
          ) : (
            <CompaniesInTheEvent
              List={this.state.Companies}
              handleChange={this.handleChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.CompanySelect ? (
            <Button
              onClick={() => {
                this.setState({ CompanySelect: false });
              }}
              color="primary"
            >
              Continuar
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  this.setState({ CompanySelect: true });
                }}
                color="primary"
              >
                regresar
              </Button>
              {this.state.Select
                ? [
                    !this.state.Oncreate ? (
                      <Button color="Primary" onClick={this.create}>
                        <AddIcon />
                      </Button>
                    ) : (
                      <Button color="Primary" disabled>
                        <CircularProgress />
                      </Button>
                    ),
                  ]
                : [
                    <Button color="Primary" disabled>
                      <AddIcon />
                    </Button>,
                  ]}
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
