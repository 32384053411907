import Create from "./Create";
import Login from "./Login";
import Logout from "./Logout";
import ShowAll from "./ShowAll";
import Profile from "./Profile";
import Update from "./Update";
import ResetPass from "./ResetPass"

const Users = { Create,Login,Logout,ShowAll,Profile,Update,ResetPass };

export default Users

export {
    Create,
    Login,
    Logout,
    ShowAll,
    Profile,
    Update,
    ResetPass
}