import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Edit from "./EditDialogBenefits";
import EditIcon from "@material-ui/icons/Edit";
const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "200px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
    //marginTop:'1%',
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
});

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = { EditDialog: false };
  }
  componentDidMount = async () => {};
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  Refresh = () => {
    this.props.Refresh();
    this.setState({ EditDialog: false });
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.Initial}>
        <Grid container className={classes.Initial} spacing={1}>
          <Grid container item xs={12} className={classes.Initial}>
            <Typography
              align="Center"
              variant="h5"
              component="p"
              className={classes.Subtitle}
            >
              Beneficios
            </Typography>
            <Paper className={classes.Paper}>
              <Grid
                container
                item
                xs={12}
                className={classes.Initial}
                spacing={1}
              >
                <Grid container item xs={6}>
                  <TextField
                    label="Aguinaldo"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit
                        ? this.props.info.company.Benefit.christmasBox
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Bono por Asistencia"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit
                        ? this.props.info.company.Benefit.attendanceBonus
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Prima Vacacional"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit
                        ? this.props.info.company.Benefit.holydayBonus
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>

                <Grid container item xs={6}>
                  <TextField
                    label="Fondo de Ahorro"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit.savingFund
                        ? this.props.info.company.Benefit.savingFund
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Utilidades"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit.utilities
                        ? this.props.info.company.Benefit.utilities
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Vales de despensa"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit.vouchers
                        ? this.props.info.company.Benefit.vouchers
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Servicio de comedor"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit.lunchBonus
                        ? this.props.info.company.Benefit.lunchBonus
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Servicio de transporte"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.Benefit.transportBonus
                        ? this.props.info.company.Benefit.transportBonus
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    label="Beneficio Adicionales"
                    margin="normal"
                    size="small"
                    multiline
                    rowsMax={4}
                    fullWidth
                    value={
                      this.props.info.company.Benefit.other
                        ? this.props.info.company.Benefit.other
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.Margin}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.Dialog.bind(this, "EditDialog")}
                >
                  <EditIcon />
                </Button>
              </Grid>
              {this.state.EditDialog
                ? [
                    <Edit
                      DialogOpen={this.state.EditDialog}
                      DialogClose={this.Dialog.bind(this, "EditDialog")}
                      Info={this.props.info}
                      Refresh={this.Refresh}
                    />,
                  ]
                : [<></>]}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TablePadron);
