import base from "../baseurl";
import token from "../getTk"


const Comunication = async () => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const Country = await api.post("comunications/company-comunications").json();
    return Country.data.companies ;
    
  };
  
  export default  Comunication ;