import ShowAll from "./ShowAll";
import AsesorStatistic from "./AsesorStatistic";
import SaveCompanies from "./SaveCompanies";
import Show from "./Show";
import showAllNotStat from "./showAllNotstat";
import AdvisorList from "./AdvisorList";

const Asesor = {
  ShowAll,
  AsesorStatistic,
  SaveCompanies,
  Show,
  showAllNotStat,
  AdvisorList,
};

export default Asesor;

export {
  ShowAll,
  AsesorStatistic,
  SaveCompanies,
  Show,
  showAllNotStat,
  AdvisorList,
};
