import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
    justifyContent: "center",
  },
});

class OnError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.paper}
      >
        <Typography>Error</Typography>
      </Grid>
    );
  }
}

export default withStyles(styles)(OnError);
