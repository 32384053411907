import React from "react";
import "babel-polyfill";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";
import FAQCall from "../../../API/CTM/FAQ";
import TypesCall from "../../../API/CallUtils";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
//import store from '../../../StoreConfig';
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "100%",
    height: "80vh",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "90%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "cover",
  },
  Select: {
    width: "80%",
    marginTop: "10px",
    marginBottom: "10px",
  },
});

class FAQ extends React.Component {
  state = {
    Blog: [
      {
        uuid: "123",
        Question: "pregunta",
        answer: "respuesta",
        links: [],
        ConsultationType: {
          content: "IMSS",
        },
      },
    ],
    Dialog: false,
    DialogDelete: false,
    DialogCreate: false,
    types: [],

    Uuid: "",
    Question: "",
    answer: "",
    url: "",
    type: "",
  };

  componentDidMount = async () => {
    await this.showAll();
    await this.showTypes();
  };

  ArticleD = (Uuid, pregunta, respuesta, url, tipo, e) => {
    this.setState({
      Uuid: Uuid,
      Question: pregunta,
      answer: respuesta,
      url: url,
      type: tipo.uuid,
      Dialog: true,
    });
  };

  DialogEntrando = async () => {
    this.setState({
      Uuid: "",
      Question: "",
      answer: "",
      url: "",
      Dialog: false,
    });
  };

  DialogDelete = async () => {
    this.setState({ DialogDelete: false });
  };

  DialogCreate = async () => {
    this.setState({
      DialogCreate: false,
      Uuid: "",
      Question: "",
      answer: "",
      url: "",
    });
  };

  create = async () => {
    try {
      const create = await FAQCall.Create(this.state);
      if (create.status === "success") {
        toasType.success("Nueva pregunta creada");
        this.setState({
          DialogCreate: false,
          Uuid: "",
          Question: "",
          answer: "",
          url: "",
        });
      } else {
        toasType.error("No fue posible crear");
      }
    } catch (e) {
      toasType.error("No fue posible crear");
    }

    await this.showAll();
  };

  update = async () => {
    try {
      const update = await FAQCall.Update(this.state);
      if (update.status === "success") {
        toasType.success("Elemento Actualizado");
        this.setState({
          Uuid: "",
          Question: "",
          answer: "",
          url: "",
          Dialog: false,
        });
        await this.showAll();
      } else {
        toasType.error("Error en la actualización");
      }
    } catch (e) {
      toasType.error("Error en la actualización");
    }
  };

  delete = async (Uuid) => {
    try {
      const remove = await FAQCall.Delete(Uuid);
      if (remove.status === "success") {
        toasType.success("Eliminado");
      } else {
        toasType.error("No fue posible Eliminar");
      }
      await this.showAll();
    } catch (e) {
      toasType.error("No fue posible Eliminar");
    }
  };

  showAll = async () => {
    try {
      const polute = await FAQCall.showAll();
      if (polute) {
        this.setState({ Blog: polute });
      } else {
        toasType.error("No fue posible traer la información");
      }
    } catch (e) {
      toasType.error("No fue posible traer la información");
    }
  };

  showTypes = async () => {
    const polute = await TypesCall.Types();
    //console.log(polute)
    this.setState({ types: polute });
  };

  Opencreate = async () => {
    this.setState({ DialogCreate: true });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  test = () => {
    //const state = store.getState();
    //const token = state.login.user.accessToken;
    //console.log(store.getState())
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container>
            {this.state.Blog.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pregunta</TableCell>
                      <TableCell>respuesta</TableCell>
                      <TableCell>urls</TableCell>
                      <TableCell>tipo</TableCell>
                      <TableCell>Editar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Blog.map((Article) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.question}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.answer}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.links[0]===""? "0": Article.links.length}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.ConsultationType.content}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.ArticleD.bind(
                                  this,
                                  Article.uuid,
                                  Article.question,
                                  Article.answer,
                                  Article.links,
                                  Article.ConsultationType
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.delete.bind(this, Article.uuid)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}
            <div className={classes.btnTable}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addBTN}
                onClick={this.Opencreate}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </Grid>
        </Paper>

        <Dialog
          open={this.state.Dialog}
          onClose={this.DialogEntrando}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Pregunta</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={10}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.Select}>
                  {this.state.types.length > 0 ? (
                    <FormControl className={classes.Select}>
                      <InputLabel id="demo-simple-select-label">
                        Tipos
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.type}
                        name="type"
                        onChange={this.onChange}
                      >
                        {this.state.types.map((types, index) => {
                          return (
                            <MenuItem value={types.uuid} key={types.uuid}>
                              {types.content}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl className={classes.Select}>
                      <InputLabel id="demo-simple-select-label">
                        Tipos
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        <MenuItem value={0}>_</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>

                <TextField
                  id="standard-basic"
                  label="pregunta"
                  name="Question"
                  multiline
                  rows="2"
                  variant="outlined"
                  value={this.state.Question}
                  className={classes.Title}
                  onChange={this.onChange}
                />
                <TextField
                  id="standard-basic"
                  label="Respuesta"
                  name="answer"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.answer}
                  className={classes.Title}
                  onChange={this.onChange}
                />
                <TextField
                  id="standard-basic"
                  label="Url"
                  name="url"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.url}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              ></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogEntrando} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.update} color="primary">
              Guardar Cambios
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogDelete}
          onClose={this.DialogDelete}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Eliminar Articulo</DialogTitle>
          <DialogContent>
            <DialogContentText>Esta acción sera registrada</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogDelete} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.DialogDelete} color="primary">
              Eliminar Articulo
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogCreate}
          onClose={this.DialogCreate}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Nuevo Pregunta</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={10}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.Select}>
                  {this.state.types.length > 0 ? (
                    <FormControl className={classes.Select}>
                      <InputLabel id="demo-simple-select-label">
                        Tipos
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.type}
                        name="type"
                        onChange={this.onChange}
                      >
                        {this.state.types.map((types, index) => {
                          return (
                            <MenuItem value={types.uuid} key={types.uuid}>
                              {types.content}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl className={classes.Select}>
                      <InputLabel id="demo-simple-select-label">
                        Tipos
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        <MenuItem value={0}>_</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>

                <TextField
                  id="standard-basic"
                  label="pregunta"
                  name="Question"
                  multiline
                  rows="2"
                  variant="outlined"
                  value={this.state.Question}
                  className={classes.Title}
                  onChange={this.onChange}
                />
                <TextField
                  id="standard-basic"
                  label="Respuesta"
                  name="answer"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.answer}
                  className={classes.Title}
                  onChange={this.onChange}
                />
                <TextField
                  id="standard-basic"
                  label="Url"
                  name="url"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.url}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              ></Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogCreate} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.create} color="primary">
              Crear Pregunta
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(FAQ);
