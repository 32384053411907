import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectCity from "./SelectCity";
import SelectType from "./SelectType";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});
const allShow = ["/showall", "/showAll", "/index", "/show-all", "/show-All"];
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      CitySelected: undefined,
      SelectedType: undefined,
    };
  }
  componentDidMount = async () => {
    console.log(this.props);
  };
  create = async () => {
    if (this.state.SelectedType === "/showall") {
      allShow.forEach((show) => {
        this.props.SendToParent(
          this.props.type,
          this.state.CitySelected.replace(/\s/g, ""),
          show
        );
      });
      this.props.DialogClose();
    } else {
      this.props.SendToParent(
        this.props.type,
        this.state.CitySelected.replace(/\s/g, ""),
        this.state.SelectedType
      );
      this.props.DialogClose();
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.permission === "Permission"
            ? "Nuevo permiso"
            : "Nueva Denegación"}
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <SelectCity
              SendToParent={this.onChange}
              CitySelected={this.state.CitySelected}
            />
            <SelectType
              SendToParent={this.onChange}
              SelectedType={this.state.SelectedType}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.SelectedType && this.state.CitySelected
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
