import base from "../baseurl";
import token from "../getTk";

const showAll = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log(state);
  console.log(
    state.companies.map((comp) => {
      return comp.id;
    })
  );

  const show = await api
    .post("company-form-campaigns/update", {
      json: {
        name: state.name,
        formsData: [
          {
            users: [],
            formId: state.formId,
            companies: state.companies.map((comp) => comp.id),
          },
        ],
        enabled: state.enabled,
        campaignId: state.id,
      },
    })
    .json();
  return show;
};

export default showAll;
