import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, Paper, withStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import VotingCall from "../../../API/Voting";
import toastType from "../../../API/CallUtils/tostyNotification";
import {
  ElectionsInfo,
  BasicInfo,
  CandidateOnElection,
} from "./../componetsUtils";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    //display:"flex",
    //justifyContent:"end",
    //marginBottom:"10px"
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    marginTop: "10px",
    //paddingTop:"5px"
  },
  paperFilter: {
    width: "90%",
    padding: "2%",
  },
  formControl: {
    width: "50%",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  SectionPaper: {
    width: "100%",
    padding: "1%",
    //marginTop:"1%",
    marginBottom: "2%",
  },
  actionBtn: {
    margin: "5px",
  },
  Skeleton: {
    width: "100%",
    height: "200px",
    //marginTop:"20px"
    borderRadius: "0px 0px 10px 10px",
  },
  Subtitle: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  GridSectionItem: {
    //marginTop:"15px"
  },
  Subtitle2: {
    borderRadius: "5px 5px 5px 5px",
    backgroundColor: "#7f8c8d",
    color: "rgb(247, 244, 244)",
    marginTop: "5px",
  },
  ImgPlaceholder: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  ImgProp: {
    width: "100%",
    height: "100%",
    //padding:"1px",
    borderRadius: "2px 2px 2px 2px",
  },
  btn: {
    width: "100%",
  },
  Table: {
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "150px",
  },
  TimePicker: {
    marginTop: "10px",
    width: "100%",
    minHeight: "140px",
    padding: "2%",
  },
  DataTimePicker: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  BtnSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "5px",
  },
  PaperDialogNewCandidate: {
    padding: "5px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Deployed_Election extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoad: true,
      ElectionType: 1,
      candidatePosition: null,
      ElectionInfo: {},
      candidates: [],
      fillOnload: [
        "Datos generales",
        "Tiempos y Votos",
        "Candidatos",
        "Paro de emergencia",
      ],
      VoteInfo: {},
      TimeInfo: {},
    };
  }

  componentDidMount = async () => {
    try {
      if (this.props.location.state.UuidVoting) {
        //console.log("uuid", this.props.location.state.UuidCompanie);
        await this.fetchVotingDetails(this.props.location.state.UuidVoting);
      } else {
        console.log("El Uuid es perdido");
        this.props.history.push("/Votaciones");
      }
    } catch (error) {
      console.log(error);
      this.props.history.push("/Votaciones");
    }
  };
  fetchVotingDetails = async (UuidVoting) => {
    try {
      this.setState({ onLoad: true });
      const Polute = await VotingCall.showDetails(UuidVoting);
      //console.log("datos de la eleccion", Polute);

      if (Polute.status === "success") {
        this.setState({
          ElectionType: Polute.data.type,
          ElectionInfo: Polute.data,
          candidates: Polute.data.Employees,
          onLoad: false,
        });
      } else {
        toastType.error("Error en el servidor");
      }
    } catch (error) {
      //toastType.error("Error de conexión");
      console.log("El error", error);
    }
  };

  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <Paper className={classes.paperFilter}>
          {this.state.fillOnload.map((type) => {
            return (
              <Paper className={classes.SectionPaper}>
                <Typography
                  align="Center"
                  variant="h5"
                  component="p"
                  className={classes.Subtitle}
                >
                  {type}
                </Typography>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  className={classes.Skeleton}
                />
              </Paper>
            );
          })}
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.paperFilter}>
          <Typography
            align="Center"
            variant="h5"
            color="textPrimary"
            component="p"
            className={classes.Subtitle}
          >
            Datos generales
          </Typography>
          <Paper className={classes.SectionPaper} elevation={3}>
            <BasicInfo ElectionInfo={this.state.ElectionInfo} />
          </Paper>
          <Typography
            align="Center"
            variant="h5"
            color="textPrimary"
            component="p"
            className={classes.Subtitle}
          >
            Votos
          </Typography>
          <Paper className={classes.SectionPaper} elevation={3}>
            <ElectionsInfo
              ElectionInfo={this.state.ElectionInfo}
              onlyVotes
              VotesList={this.state.candidates}
              NoVotesList={this.state.candidates}
            />
          </Paper>
          <Typography
            align="Center"
            variant="h5"
            color="textPrimary"
            component="p"
            className={classes.Subtitle}
          >
            Candidatos
          </Typography>
          <Paper className={classes.SectionPaper} elevation={3}>
            <CandidateOnElection
              UuidElection={this.props.location.state.UuidVoting}
              Type={this.state.ElectionInfo.type}
              ElectionInfo={this.state.ElectionInfo}
            />
          </Paper>
        </Paper>
      );
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Deployed_Election));
