import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SelecType from "../Components/SelecType";
import Events from "../List/Events";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  text: {
    width: "75%",
    margin: "5px",
  },
  TypeSelectContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Type: undefined,
    };
  }
  componentDidMount = async () => {};
  componentDidUpdate = async () => {};
  selectType = async (id) => {
    this.setState({ Type: id });
  };

  render() {
    const { classes } = this.props;
    if (this.props.companyInfo.name) {
      return (
        <Dialog open={this.props.DialogOpen} onClose={this.props.DialogClose}>
          <DialogTitle id="form-dialog-title">
            {this.props.companyInfo.name}
          </DialogTitle>
          <DialogContent className={classes.paper}>
            <div className={classes.TypeSelectContainer}>
              <SelecType Select={this.selectType} />
            </div>
            {this.state.Type && this.props.companyInfo.id && (
              <Events
                companieUuid={this.props.companyInfo.id}
                Type={this.state.Type}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return <></>;
  }
}

export default withStyles(styles)(addCandidateDialog);
