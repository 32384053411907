import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import GetInfo from "../../../API/Companies/ShowAllCommite";
import toasType from "../../../API/CallUtils/tostyNotification";
import Add from "./DialogAddCommite";
import DeleteIcon from "@material-ui/icons/Delete";
import Delete from "../../../API/Companies/DeleteCommite";
import AddIcon from "@material-ui/icons/Add";
const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "200px",
    borderRadius: "0px 0px 10px 10px",
    //padding: "1%",
    //marginTop:'1%',
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  PaperTable: {
    width: "100%",
    height: "200px",
    borderRadius: "0px 0px 10px 10px",
    //padding: "1%",
    overflow: "auto",
  },
  Margin: {
    padding: "5px",
  },
});

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = { DialogEdit: false, commitePeople: [] };
  }
  componentDidMount = async () => {
    if (this.props.uuid) {
      this.GetInfo();
    }
  };
  Edit = () => {
    this.setState({ DialogEdit: !this.state.DialogEdit });
  };
  GetInfo = async () => {
    try {
      const SeccionsShow = await GetInfo(this.props.id);
      if (SeccionsShow) {
        console.log("response", SeccionsShow.assemblyMembers);
        this.setState({
          commitePeople: SeccionsShow.assemblyMembers,
        });
      } else {
        //toasType.error("Error en la petición al servidor");
        console.log(SeccionsShow);
      }
    } catch (e) {
      console.log(e);
      toasType.error("Error en la red / Error de conexión");
    }
  };
  Delete = async (uuid) => {
    try {
      const DeleteCommite = await Delete(uuid);
      if (DeleteCommite) {
        this.GetInfo();
        toasType.success("Elemento eliminado");
      } else {
        //toasType.error("Error en la petición al servidor");
        console.log(DeleteCommite);
      }
    } catch (e) {
      console.log(e);
    }
  };
  reload = async () => {
    this.GetInfo();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.Initial}>
        <Grid container className={classes.Initial}>
          <Grid container item xs={12} className={classes.Initial}>
            <Typography
              align="Center"
              variant="h5"
              component="p"
              className={classes.Subtitle}
            >
              Comité
            </Typography>
            <Paper className={classes.PaperTable}>
              {this.state.commitePeople.length > 0 ? (
                <>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    className={classes.TableCompany}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Municipio</TableCell>
                        <TableCell>Puesto</TableCell>
                        <TableCell>Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.TableCompany}>
                      {this.state.commitePeople.map((Asesor) => {
                        return (
                          <React.Fragment>
                            <TableRow key={Asesor.uuid}>
                              <TableCell component="th" scope="row">
                                {Asesor.UserProfile.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Asesor.UserProfile.otherMail}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Asesor.UserProfile.personalPhone}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Asesor.UserProfile.city}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Asesor.CandidatePosition
                                  ? Asesor.CandidatePosition.name
                                  : "No se asigno posición "}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  color="secondary"
                                  onClick={this.Delete.bind(this, Asesor.uuid)}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.Margin}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.Edit}
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                </>
              ) : (
                <Button className={classes.AddCandidate} onClick={this.Edit}>
                  Sin Miembros registrados
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
        {this.state.DialogEdit
          ? [
              <Add
                CompanieUuid={this.props.uuid}
                id={this.props.id}
                DialogOpen={this.state.DialogEdit}
                DialogClose={this.Edit}
                Refresh={this.reload}
              />,
            ]
          : []}
      </Grid>
    );
  }
}

export default withStyles(styles)(TablePadron);
