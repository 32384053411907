import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Paper, Grid, TextField } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExtraData from "../../../API/CallUtils";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");
const styles = (theme) => ({
  formControl: {
    margin: "10px",
  },
  Skeleton: {
    width: "100%",
    height: "20px",
    borderRadius: "10px 10px 10px 10px",
  },
  Container: {
    width: "100%",
    padding: "10px",
  },
  TextField: {
    width: "100%",
  },
});
class index extends Component {
  constructor(props) {
    super(props);
    this.state = { List1: [], List2: [] };
  }
  componentDidMount = async () => {
    this.GetSchoolar();
    this.GetCivi();
  };
  GetSchoolar = async () => {
    const List = await ExtraData.SchoolarTypes();
    //console.log(List);
    if (List.status === "success") {
      this.setState({ List1: List.data.scholarships });
    }
  };
  GetCivi = async () => {
    const ListC = await ExtraData.CivilStatesTypes();
    //console.log(ListC);
    if (ListC.status === "success") {
      this.setState({ List2: ListC.data.civilStates });
    }
  };
  changeDate = (acceptanceDate) => {
    this.props.SendToparent(
      "userProfileData",
      "affiliationDate",
      moment(new Date(acceptanceDate)).toISOString()
    );
  };
  Send = (ev) => {
    this.props.SendToparent("userProfileData", ev.target.name, ev.target.value);
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper>
        <Grid container row spacing={1} className={classes.Container}>
          <TextField
            id="outlined-basic"
            label="RFC"
            variant="outlined"
            value={this.props.ParentState.rfc}
            onChange={this.Send}
            name="rfc"
            className={classes.TextField}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label="Nombre Completo"
            variant="outlined"
            value={this.props.ParentState.name}
            onChange={this.Send}
            name="name"
            className={classes.TextField}
            margin="dense"
          />
          <DatePicker
            label="Fecha de Nacimiento"
            value={this.props.ParentState.dateOfBirth}
            onChange={(ev) => {
              console.log(ev);
              this.props.SendToparent(
                "userProfileData",
                "dateOfBirth",
                moment(new Date(ev)).toISOString()
              );
            }}
            disableFuture
            openTo="year"
            inputVariant="outlined"
            format="ll"
            className={classes.TextField}
          />
          <FormControl className={classes.TextField}>
            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
            <Select
              value={this.props.ParentState.sex}
              onChange={this.Send}
              name="sex"
            >
              <MenuItem value={"HOMBRE"}>Hombre</MenuItem>
              <MenuItem value={"MUJER"}>Mujer</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Teléfono Casa"
            variant="outlined"
            value={this.props.ParentState.personalPhone}
            onChange={this.Send}
            name="personalPhone"
            className={classes.TextField}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label="Teléfono Celular"
            variant="outlined"
            value={this.props.ParentState.mobilePhone}
            onChange={this.Send}
            name="mobilePhone"
            className={classes.TextField}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label="Numero del seguro social"
            variant="outlined"
            value={this.props.ParentState.nss}
            onChange={this.Send}
            name="nss"
            className={classes.TextField}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label="Ciudad"
            variant="outlined"
            value={this.props.ParentState.City}
            onChange={this.Send}
            name="City"
            className={classes.TextField}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label="Dirección"
            variant="outlined"
            value={this.props.ParentState.address}
            onChange={this.Send}
            name="address"
            className={classes.TextField}
            margin="dense"
          />
          <DatePicker
            label="Fecha de Afiliación"
            value={this.props.ParentState.affiliationDate}
            onChange={this.changeDate}
            disableFuture
            openTo="year"
            inputVariant="outlined"
            format="ll"
            className={classes.TextField}
          />
          <FormControl className={classes.TextField}>
            <InputLabel id="demo-simple-select-label">Estado Civil</InputLabel>
            <Select
              value={this.props.ParentState.CivilStateId}
              onChange={this.Send}
              name="CivilStateId"
            >
              {this.state.List2.length > 0 &&
                this.state.List2.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
            </Select>
          </FormControl>
          <FormControl className={classes.TextField}>
            <InputLabel id="demo-simple-select-label">Escolaridad</InputLabel>
            <Select
              value={this.props.ParentState.ScholarshipId}
              onChange={this.Send}
              name="ScholarshipId"
            >
              {this.state.List1.length > 0 &&
                this.state.List1.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(index));
