import base from "../../baseurl";
import token from "../../getTk"

const Update = async (state,body) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });

  if (state.imgRaw !== null){
    const Update = await api.post("blogposts/update",{json: {
      title:state.Title,
     img:{
        name:state.FileName,
        content:state.imgRaw
      },
      body:body,
      blogpostUuid:state.Uuid,
    }}).json();
    console.log(Update,"1",state.imgRaw)
    return Update ;
  }
  else{
    const Update = await api.post("blogposts/update",{json: {
      title:state.Title,
      body:body,
      img:state.tt,
      blogpostUuid:state.Uuid,
    }}).json();
   console.log(Update, "2",state.imgRaw)
    return Update ;
  }
   
    
    
  };
  
  export default  Update ;