import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Logout from '@material-ui/icons/SettingsPower';
import {connect } from 'react-redux';
import logoutCall from '../../API/Users/Logout';
import Fab from '@material-ui/core/Fab';

const styles = {
  Button:{
      backgroundColor: '#FF5252'
  }, 
  Link:{
    color: '#fff',    
  },
};

class LogoutBTN extends Component { 
  constructor(props){
    super(props);
    this.onClick = this.onClick.bind(this);
   }

   onClick(event){ 
    event.preventDefault();
    this.props.Logout()
    //console.log(this.props.token)
    logoutCall(this.props.token)
  }
render()
{
  const { classes } = this.props;
  return (
    <div>
        
        <Fab onClick={this.onClick} className={classes.Button}>
        <Logout />
      </Fab>
  </div>
    );
  } 
}
const mapDispatchToProps = dispatch => {
  return {
    
    Logout: () => dispatch({ type: 'LOGOUT' }),
    
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.user.token,
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(LogoutBTN))