import React, { Component } from "react";
import { withStyles, Paper, Grid, Button, IconButton } from "@material-ui/core";
import StatisticCall from "../../../API/Statistic";
import Places from "../../../API/CallUtils";
import { DatePicker } from "material-ui-pickers";
import SelectIndustrialPark from "../../Companies/Create/selectIndustrialPark";
import SelectContry from "../../Companies/Create/selectContry";
import SelectState from "../../Companies/Create/SelectState";
import SelectCity from "../../Companies/Create/selectCity";
import ParkCall from "../../../API/Companies/IndustrialPark";
import toasType from "../../../API/CallUtils/tostyNotification";
import Skeleton from "@material-ui/lab/Skeleton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MUIDataTable from "mui-datatables";
import CircularProgress from "@material-ui/core/CircularProgress";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: "#2980b9",
  },
  Skeleton: {
    width: "100%",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
  SubmitDiv: {
    width: "98%",
    display: "flex",
    justifyContent: "end",
    marginRight: "2%",
    marginTop: "2%",
  },
  FormControl: {
    marginTop: "10%",
  },
  CompaniesList: {
    width: "100%",
    padding: "12px",
  },
  btn: {
    width: "94%",
    margin: "2%",
  },
  paper: {
    padding: "2%",
  },
  date: {
    margin: "6%",
  },
  company: {
    width: "94%",
  },
  paperContainer: {
    width: "100%",
    padding: "12px",
  },
  block: {
    width: "100%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inicioOperaciones: null,
      fechaInicio: null,
      country: "",
      industrialPark: "",
      ListOfIndustrialParks: [],
      stateUuid: "",
      ListOfStates: [],
      cityUuid: "",
      ListOfCities: [],
      ListCompanies: [],
    };
  }

  componentDidMount = async () => {
    await this.ShowAllStates();
    await this.ShowAllIndustrialParks();
  };

  ResiveFromChild = (place, value) => {
    console.log(place, value);
    this.setState({
      [place]: value,
    });
  };
  ShowAllIndustrialParks = async () => {
    try {
      const ShowStates = await ParkCall();
      this.setState({ ListOfIndustrialParks: ShowStates.industrialParks });
    } catch (e) {
      console.log(e);
    }
  };
  getCities = async (uuid) => {
    try {
      const ShowCitys = await Places.Citys(uuid);
      this.setState({ ListOfCities: ShowCitys });
    } catch (e) {
      console.log(e);
    }
  };
  ShowAllStates = async () => {
    try {
      const ShowStates = await Places.States();
      this.setState({ ListOfStates: ShowStates });
    } catch (e) {
      console.log(e);
    }
  };
  ShowAllIndustrialParks = async () => {
    try {
      const ShowStates = await ParkCall();
      this.setState({ ListOfIndustrialParks: ShowStates.industrialParks });
    } catch (e) {
      console.log(e);
    }
  };
  changeDate = (name, date) => {
    var fecha = moment(new Date(date)).toISOString();
    this.setState({ [name]: fecha });
  };
  Send = async () => {
    this.setState({ onLoad: true, ListCompanies: null });
    try {
      const CompanyList = await StatisticCall.Companies(this.state);
      console.log(CompanyList);
      if (CompanyList && CompanyList.length > 0) {
        toasType.info(` hay ${CompanyList.length} resultados`);
        let results = CompanyList.map((obj) => {
          return [
            obj.company.name,
            obj.company.industrialPark,
            obj.company.country,
            obj.company.State ? obj.company.State.name : "sin información",
            obj.company.City ? obj.company.City.name : "sin información",
            obj.company.TechnicalDetail.operationStart,
            obj.company.acceptanceDate,
            obj.census ? obj.census.Employees.length : "sin información",
            obj.census
              ? moment(obj.census.createdAt).format("llll")
              : "sin información",
          ];
        });
        this.setState({ ListCompanies: results, onLoad: false });
      } else {
        toasType.info("Sin resultados");
        this.setState({ onLoad: false });
      }
    } catch (e) {
      toasType.error("No fue posible traer la información");
      this.setState({ onLoad: false });
      console.log("Error On Send ", e);
    }
  };
  render() {
    const { classes } = this.props;
    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 50, 100, 200],
    };
    const columnsUsers = [
      "Empresa",
      "Parque Industrial",
      "País de origen",
      "Estado",
      "Municipio",
      "inicio de Operaciones",
      "Fecha de afiliación",
      "Numero de Trabajadores",
      "Fecha de padrón",
    ];

    return (
      <Grid
        item
        container
        //direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid
          item
          xs={3}
          className={classes.Grid}
          container
          direction="row"
          justify="flex-start"
        >
          <Paper className={classes.paperContainer}>
            <Grid
              container
              //direction="row"
              justify="flex-start"
              alignItems="flex-start"
              //spacing={1}
              xs={12}
            >
              <Grid
                item
                xs={this.state.country ? 11 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <SelectContry
                  sentoParent={this.ResiveFromChild}
                  country={this.state.country}
                />
              </Grid>
              {this.state.country && (
                <Grid
                  container
                  item
                  xs={1}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ country: "" });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              //direction="row"
              justify="flex-start"
              alignItems="flex-start"
              //spacing={1}
              xs={12}
            >
              <Grid
                item
                xs={this.state.industrialPark ? 11 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <SelectIndustrialPark
                  industrialPark={this.state.industrialPark}
                  ListOfIndustrialParks={this.state.ListOfIndustrialParks}
                  sentoParent={this.ResiveFromChild}
                />
              </Grid>
              {this.state.industrialPark && (
                <Grid
                  item
                  xs={1}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ industrialPark: "" });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              //direction="row"
              justify="flex-start"
              alignItems="flex-start"
              //spacing={1}
              xs={12}
            >
              <Grid
                item
                xs={this.state.stateUuid ? 11 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <SelectState
                  SelectedState={this.state.stateUuid}
                  ListOfStates={this.state.ListOfStates}
                  sentoParent={this.ResiveFromChild}
                  getCities={this.getCities}
                />
              </Grid>
              {this.state.stateUuid && (
                <Grid
                  item
                  xs={1}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ stateUuid: "", cityUuid: "" });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              //direction="row"
              justify="flex-start"
              alignItems="flex-start"
              //spacing={1}
              xs={12}
            >
              <Grid
                item
                xs={this.state.cityUuid ? 11 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <SelectCity
                  hasState={this.state.stateUuid}
                  CitySelected={this.state.cityUuid}
                  ListOfCities={this.state.ListOfCities}
                  sentoParent={this.ResiveFromChild}
                />
              </Grid>
              {this.state.cityUuid && (
                <Grid
                  item
                  xs={1}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ cityUuid: "" });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              //direction="row"
              justify="flex-start"
              alignItems="flex-start"
              //spacing={1}
              xs={12}
            >
              <Grid
                item
                xs={this.state.inicioOperaciones ? 11 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <DatePicker
                  label="Inicio de Operaciones"
                  value={this.state.inicioOperaciones}
                  onChange={this.changeDate.bind(this, "inicioOperaciones")}
                  margin="normal"
                  disableFuture
                  format="ll"
                  inputVariant="outlined"
                  openTo="year"
                  className={classes.block}
                  emptyLabel="Sin fecha seleccionada"
                />
              </Grid>
              {this.state.inicioOperaciones && (
                <Grid
                  item
                  xs={1}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ inicioOperaciones: null });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              //direction="row"
              justify="flex-start"
              alignItems="flex-start"
              //spacing={1}
              xs={12}
            >
              <Grid
                item
                xs={this.state.fechaInicio ? 11 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <DatePicker
                  label="Fecha de aceptación "
                  value={this.state.fechaInicio}
                  onChange={this.changeDate.bind(this, "fechaInicio")}
                  margin="normal"
                  disableFuture
                  format="ll"
                  inputVariant="outlined"
                  openTo="year"
                  emptyLabel="Sin fecha seleccionada"
                  className={classes.block}
                />
              </Grid>
              {this.state.fechaInicio && (
                <Grid
                  item
                  xs={1}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ fechaInicio: null });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={this.state.onLoad}
              onClick={this.Send}
              className={classes.block}
            >
              {this.state.onLoad ? <CircularProgress /> : "Buscar"}
            </Button>
          </Paper>
        </Grid>

        <Grid
          item
          xs={8}
          className={classes.CompaniesList}
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {this.state.ListCompanies && this.state.ListCompanies.length > 0 && (
            <Paper className={classes.paperContainer}>
              <MUIDataTable
                title={"Empresas"}
                data={this.state.ListCompanies}
                columns={columnsUsers}
                options={options}
                selectableRows="none"
                className={classes.Table}
              />
            </Paper>
          )}
          {this.state.onLoad && (
            <Skeleton
              variant="rect"
              animation="wave"
              className={classes.Skeleton}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Dashboard);
