import React, { Component } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  withStyles,
  Box,
  LinearProgress,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import axios from "axios";
import toasType from "../../API/CallUtils/tostyNotification";
import token from "../../API/getTk";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    maxHeight: "80%",
    padding: "2%",
    marginTop: "10px",
    marginBottom: "5px",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  imgDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Remp: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  btn: {
    marginTop: "5px",
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class ArrayFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: null,
      setUploadPercentage: 0,
      urlImg: null,
      ArrayFiles: [],
    };
  }
  saveFileInfo = async (e) => {
    try {
      const tokenA = token.get();
      const dataForm = new FormData();
      dataForm.append("file", e.target.files[0]);
      dataForm.append("name", e.target.files[0].name);
      dataForm.append("location", this.props.Place);

      const res = await axios.post(
        "https://api.sitimm.org/api/imgs/create",
        dataForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            jwt: `${tokenA}`,
          },
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            this.setState({
              setUploadPercentage: parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
            });
          },
        }
      );
      //console.log(res.data.status)
      if (res.data.status === "success") {
        this.setState({
          ArrayFiles: [...this.state.ArrayFiles, res.data.data.location],
          setUploadPercentage: 0,
        });
        this.props.parentCallback(
          this.state.ArrayFiles,
          this.props.stateNameOnParent
        );
        toasType.success("El Archivo fue cargado exitosamente");
      } else {
        toasType.error("Error en la recepción del archivo");
      }
    } catch (e) {
      console.log(e);
      toasType.error("Error en la carga");
      this.setState({
        setUploadPercentage: 0,
      });
      this.props.parentCallback(
        this.state.ArrayFiles,
        this.props.stateNameOnParent
      );
    }
  };
  componentDidMount = async () => {
    try {
      if (this.props.PrevImg) {
        this.setState({ urlImg: this.props.PrevImg });
      }
      if (this.props.PrevFilesList) {
        this.setState({ ArrayFiles: this.props.PrevFilesList });
      }
      console.log(this.props.PrevFilesList);
    } catch (error) {
      console.log(error);
    }
  };
  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });

    this.props.parentCallback(value, this.props.stateNameOnParent);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <>
          {this.state.ArrayFiles.length > 0 ? (
            <Paper className={classes.Table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Descarga</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.ArrayFiles.map((Article, index) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article}>
                          <TableCell component="th" scope="row">
                            {Article.split("/")[4].substring(0, 15)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <a href={Article}>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                className={classes.btn}
                              >
                                <CloudUploadIcon />
                              </Button>
                            </a>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="secondary"
                              onClick={this.remove.bind(
                                this,
                                index,
                                "ArrayFiles"
                              )}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <div className={classes.imgDiv}>
              <Typography variant="body2" color="textPrimary" align="center">
                Sin {this.props.Type}
              </Typography>
            </div>
          )}
          {this.state.setUploadPercentage > 0
            ? [
                <LinearProgressWithLabel
                  value={this.state.setUploadPercentage}
                />,
              ]
            : [<Grid></Grid>]}
        </>
        <div className={classes.Remp}>
          {this.props.Type === "Imagen"
            ? [
                <input
                  type="file"
                  onChange={this.saveFileInfo.bind(this)}
                  className={classes.input}
                  id="NewImg"
                  required
                  accept="image/*"
                />,
              ]
            : [
                <input
                  type="file"
                  onChange={this.saveFileInfo.bind(this)}
                  className={classes.input}
                  id="NewFile"
                  required
                />,
              ]}

          {this.props.Type === "Imagen"
            ? [
                <label htmlFor="NewImg">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                  >
                    <AddAPhotoIcon />
                  </Button>
                </label>,
              ]
            : [
                <label htmlFor="NewFile">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                  >
                    <NoteAddIcon />
                  </Button>
                </label>,
              ]}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ArrayFiles);
