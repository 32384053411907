import api from "../baseurl";

const companiesFilter = async () => {
    
    const companies = await api.get(`User`).json();
    //console.log("llamada");
    return companies;
    
  };
  
  export default companiesFilter ;