import base from "../baseurl";
import token from "../getTk";

const showAll = async () => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const UsersShow = await api.post("users/index").json();
  console.log(UsersShow);
  return UsersShow.data.users;
};

export default showAll;
