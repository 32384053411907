import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HomeCall from "../../../API/Ceforma/Home";
import CallUtils from "../../../API/CallUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "70%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
  input: {
    display: "none",
  },
});

class home extends React.Component {
  state = {
    principalText: "",
    principalTitle: "",
    ImgText: "",
    motto: "",
    principalIMG: null,
    files: [],
    footerCarusel: [],

    CarruselIMG: null,
    CarruselTitle: "",
    CarruselText: "",
    DialogCarrusel: false,

    CEFORMATitle: "",
    CEFORMAText: "",
    CeformaIMG: null,

    CursoTitle: "",
    CursoText: "",
    CursoIMG: null,

    files2: [],
    BannerIMG: null,
    BannerTitle: "",
    BannerText: "",
    DialogBanner: false,
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    // eslint-disable-next-line
    const polute = await HomeCall.Update(this.state);

    await this.showAll();
  };

  showAll = async () => {
    const polute = await HomeCall.showAll();
    this.setState({
      principalText: polute.welcomeText,
      principalTitle: polute.title,
      ImgText: polute.ImgText,
      motto: polute.lema,
      principalIMG: polute.principalIMG,
      files: polute.footerCarusel,
      CEFORMATitle: polute.CEFORMATitle,
      CEFORMAText: polute.CEFORMAText,
      CeformaIMG: polute.CeformaIMG,

      CursoTitle: polute.CursoTitle,
      CursoText: polute.CursoText,
      CursoIMG: polute.CursoIMG,
      files2: polute.Banners,
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  principalIMG = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_HOME_PrincipalIMG"
    );
    this.setState({
      principalIMG: principal,
    });
  };

  addIMGD = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_HOME_CARRUSEL"
    );
    this.setState({
      CarruselIMG: principal,
    });
  };

  CeformaIMGAdd = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_HOME_QUIENES"
    );
    this.setState({
      CeformaIMG: principal,
    });
  };

  CursoIMGAdd = async (e) => {
    let principal = await this.SendImg(e.target.files[0], "CEFORMA_HOME_Curso");
    this.setState({
      CursoIMG: principal,
    });
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  addCarrusel = async (e) => {
    var newelement = {
      title: this.state.CarruselTitle,
      text: this.state.CarruselText,
      img: this.state.CarruselIMG,
    };

    this.setState({
      files: [...this.state.files, newelement],
    });
    this.setState({
      CarruselIMG: null,
      CarruselTitle: "",
      CarruselText: "",
      DialogCarrusel: false,
    });
  };

  addBanner = async (e) => {
    var newelement = {
      title: this.state.BannerTitle,
      text: this.state.BannerText,
      img: this.state.BannerIMG,
    };

    this.setState({
      files2: [...this.state.files2, newelement],
    });
    this.setState({
      BannerIMG: null,
      BannerTitle: "",
      BannerText: "",
      DialogBanner: false,
    });
  };

  addIMGBanner = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_HOME_BANNERS"
    );
    this.setState({
      BannerIMG: principal,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo Principal"
                name="principalTitle"
                value={this.state.principalTitle}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto Principal"
                className={classes.text}
                margin="normal"
                name="principalText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.principalText}
                variant="outlined"
              />
              <TextField
                id="standard-basic"
                label="Pie de imagen"
                name="ImgText"
                multiline
                rows="3"
                value={this.state.ImgText}
                className={classes.A}
                onChange={this.onChange}
                variant="outlined"
              />
              <TextField
                id="standard-read-only-input"
                label="Cintillo inferior"
                className={classes.text}
                margin="normal"
                name="motto"
                multiline
                rows="2"
                onChange={this.onChange}
                value={this.state.motto}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen principal</Typography>
              </div>

              {this.state.principalIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.principalIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <div className={classes.Remp}>
                  <input
                    type="file"
                    onChange={this.principalIMG}
                    className={classes.input}
                    id="files"
                    required
                  />
                  <label htmlFor="files">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                    >
                      <AddBoxIcon />
                    </Button>
                  </label>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Carrusel</Typography>
            {this.state.files.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Imagen</TableCell>
                      <TableCell>Titulo</TableCell>
                      <TableCell>Texto</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.files.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.img}>
                            <TableCell component="th" scope="row">
                              <img
                                src={Article.img}
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.text}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(this, index, "files")}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogCarrusel")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo"
                name="CEFORMATitle"
                value={this.state.CEFORMATitle}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto Principal"
                className={classes.text}
                margin="normal"
                name="CEFORMAText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.CEFORMAText}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen Ceforma</Typography>
              </div>

              {this.state.CeformaIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.CeformaIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <div className={classes.Remp}>
                  <input
                    type="file"
                    onChange={this.CeformaIMGAdd}
                    className={classes.input}
                    id="Ceforma"
                    required
                  />
                  <label htmlFor="Ceforma">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                    >
                      <AddBoxIcon />
                    </Button>
                  </label>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo Curso"
                name="CursoTitle"
                value={this.state.CursoTitle}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto Principal"
                className={classes.text}
                margin="normal"
                name="CursoText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.CursoText}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen Ceforma</Typography>
              </div>

              {this.state.CursoIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.CursoIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <div className={classes.Remp}>
                  <input
                    type="file"
                    onChange={this.CursoIMGAdd}
                    className={classes.input}
                    id="Curso"
                    required
                  />
                  <label htmlFor="Curso">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                    >
                      <AddBoxIcon />
                    </Button>
                  </label>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Banners</Typography>
            {this.state.files2.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Imagen</TableCell>
                      <TableCell>Titulo</TableCell>
                      <TableCell>Texto</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.files2.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.img}>
                            <TableCell component="th" scope="row">
                              <img
                                src={Article.img}
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.text}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "files2"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogBanner")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.DialogBanner}
          onClose={this.DialogClose.bind(this, "DialogBanner")}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Imagen</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.imgDiv}>
                  <Typography>Imagen</Typography>
                </div>

                {this.state.BannerIMG !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.BannerIMG}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGBanner}
                      className={classes.input}
                      id="files5"
                      required
                    />
                    <label htmlFor="files5">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <TextField
                  id="standard-basic"
                  label="Titulo "
                  name="BannerTitle"
                  value={this.state.BannerTitle}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Texto "
                  name="BannerText"
                  value={this.state.BannerText}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogBanner")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addBanner} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogCarrusel}
          onClose={this.DialogClose.bind(this, "DialogCarrusel")}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Imagen</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.imgDiv}>
                  <Typography>Imagen</Typography>
                </div>

                {this.state.CarruselIMG !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.CarruselIMG}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGD}
                      className={classes.input}
                      id="files3"
                      required
                    />
                    <label htmlFor="files3">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <TextField
                  id="standard-basic"
                  label="Titulo "
                  name="CarruselTitle"
                  value={this.state.CarruselTitle}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Texto "
                  name="CarruselText"
                  value={this.state.CarruselText}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogCarrusel")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addCarrusel} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(home);
