import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Api from "../../../API/CompaniesCampaigs";
import toastType from "../../../API/CallUtils/tostyNotification";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Select from "../Selects/SelectCampaingsCompanies";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chart } from "react-google-charts";
import GetAppIcon from "@material-ui/icons/GetApp";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  Title: {
    width: "90%",
  },
  CloseBtn: {
    //width: "10%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  text: {
    width: "80%",
  },
  Subtitle: {
    alignItems: "center",
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Card: {
    margin: "10px",
  },
  Element: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  full: {
    width: "100%",
    marginTop: "12px",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      ListOfAnswers: [],
      CompanyId: "",
      onNull: false,
      specialReport: false,
    };
  }
  componentDidMount = async () => {
    //this.fetchlist();
    this.getList();
    console.log(this.state.companies);
  };
  getList = async () => {
    try {
      this.setState({
        onLoad: true,
      });
      const Deli = await Api.Show(this.props.EventId);
      console.log(Deli);
      if (Deli.status === "success") {
        this.setState({
          companies: Deli.data.campaign.formDetails[0].companies.map((comp) => {
            return { id: comp.id, name: comp.name };
          }),
          onLoad: false,
          onError: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  fetchlist = async (CompanyId) => {
    try {
      this.setState({
        onLoad: true,
        onNull: false,
      });

      const Deli = await Api.ShowResultCompany(this.props.EventId, CompanyId);
      if (Deli.status === "success") {
        this.setState({
          onLoad: false,
          onError: false,
          onNull: Deli.data.entries.length > 0 ? false : true,
          ListOfAnswers:
            Deli.data.entries.length > 0 ? Deli.data.entries[0].results : [],
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  TypeAnswer = (ans, index) => {
    const { classes } = this.props;
    switch (ans.type) {
      case 1:
        return (
          <Card className={classes.Card}>
            <CardContent>
              <Typography>
                {" "}
                {index + 1}
                {".  "}
                {ans.question}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Respuestas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ans.answers &&
                    ans.answers.length > 0 &&
                    ans.answers.map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {x}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      case 2:
        return (
          <Card className={classes.Card}>
            <CardContent>
              <Typography>
                {" "}
                {index + 1}
                {".  "}
                {ans.question}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Respuestas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ans.answers &&
                    ans.answers.length > 0 &&
                    ans.answers.map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {x}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      case 3:
        return (
          <div>
            <Card className={classes.Card}>
              <CardContent>
                <Typography>
                  {" "}
                  {index + 1}
                  {".  "}
                  {ans.question}
                </Typography>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Respuesta</TableCell>
                      <TableCell>Cantidad</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ans.answers &&
                      ans.answers.length > 0 &&
                      ans.answers.map((x, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {x.answer}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {x.amount}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            <Card className={classes.Card}>
              <CardContent>
                <Chart
                  chartType="PieChart"
                  data={this.getColumn(ans.answers)}
                  options={{
                    title: `GRÁFICA ${ans.question}`,
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </CardContent>
            </Card>
          </div>
        );
      case 4:
        return (
          <div>
            <Card className={classes.Card}>
              <CardContent>
                <Typography>
                  {" "}
                  {index + 1}
                  {".  "}
                  {ans.question}
                </Typography>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Respuesta</TableCell>
                      <TableCell>Cantidad</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ans.answers &&
                      ans.answers.length > 0 &&
                      ans.answers.map((x, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {x.answer}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {x.amount}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            <Card className={classes.Card}>
              <CardContent>
                <Chart
                  chartType="PieChart"
                  data={this.getColumn(ans.answers)}
                  options={{
                    title: `GRÁFICA ${ans.question}`,
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </CardContent>
            </Card>
          </div>
        );
      case 5:
        return (
          <div>
            <Card className={classes.Card}>
              <CardContent>
                <Typography>
                  {" "}
                  {index + 1}
                  {".  "}
                  {ans.question}
                </Typography>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Respuesta</TableCell>
                      <TableCell>Cantidad</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ans.answers &&
                      ans.answers.length > 0 &&
                      ans.answers.map((x, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {x.answer}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {x.amount}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            <Card className={classes.Card}>
              <CardContent>
                <Chart
                  chartType="PieChart"
                  data={this.getColumn(ans.answers)}
                  options={{
                    title: `GRÁFICA ${ans.question}`,
                  }}
                  width={"100%"}
                  height={"400px"}
                />
              </CardContent>
            </Card>
          </div>
        );
      default:
        return <p>Error</p>;
    }
  };
  getColumn = (ans) => {
    let data = [["Respuestas", "Cantidad"]];
    ans.forEach((x) => {
      data.push([`${x.answer}`, x.amount]);
    });
    console.log(data);
    return data;
  };
  onChangeValue = async (name, value) => {
    this.setState({ [name]: value });
    this.fetchlist(value);
  };
  Downloads = () => {
    var blob = new Blob([
      this.state.specialReport ? this.special() : this.regular,
    ]);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Reporte.csv`;
    link.click();
  };
  special = () => {
    let values = this.state.ListOfAnswers.map((x, index) => {
      if (x.type === 1 || x.type === 2) {
        return [
          x.question,
          x.answers.map((x) => {
            return [x, ""];
          }),
        ];
      } else {
        return [x.question, this.getHigherValue(x.answers)];
      }
    });
    console.log("final", values);
    let hope = "";
    values.forEach((x, index) => {
      let string = `\nPregunta ${index + 1},${x[0]}\n`;
      let awns = x[1];
      hope = hope + (string + awns);
    });
    return hope;
  };
  regular = () => {
    let values = this.state.ListOfAnswers.map((x, index) => {
      if (x.type === 1 || x.type === 2) {
        return [
          x.question,
          x.answers.map((x) => {
            return [x, ""];
          }),
        ];
      } else {
        return [
          x.question,
          x.answers.map((ans) => {
            return [ans.answer, `${ans.amount}`];
          }),
        ];
      }
    });
    let hope = "";
    values.forEach((x, index) => {
      let string = `\nPregunta ${index + 1},${x[0]}\n`;
      let awns = x[1].map((x) => x.join(",")).join("\n");
      hope = hope + (string + awns);
    });
    return hope;
  };
  getHigherValue = (array) => {
    let index = array.map((x) => x.amount);
    const max = Math.max(...index);
    let indexof = index.indexOf(max);
    return [array[indexof].answer, `${array[indexof].amount}`];
  };
  getColumnSpecial = (array, question) => {
    let index = array.map((x) => x.amount);
    const max = Math.max(...index);
    let indexof = index.indexOf(max);
    return [`${question} ${array[indexof].answer}`, array[indexof].amount];
  };
  getGraficsSpecial = () => {
    let dataC = [["Beneficio", "Votos"]];
    this.state.ListOfAnswers.forEach((x, index) => {
      if (x.type === 1 || x.type === 2) {
        return [
          x.question,
          x.answers.map((x) => {
            return [x, ""];
          }),
        ];
      } else {
        dataC.push(this.getColumnSpecial(x.answers, x.question));
      }
    });
    return (
      <Card className={this.props.Card}>
        <CardContent>
          <Chart
            chartType="BarChart"
            data={dataC}
            options={{
              title: `GRÁFICA Especial`,
            }}
            width={"100%"}
            height={"400px"}
          />
        </CardContent>
      </Card>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        TransitionComponent={Transition}
        //maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.Title}>
            Resultados de las encuestas
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <Select
            CompaniesList={this.state.companies}
            Select={this.onChangeValue}
            value={this.state.CompanyId}
          />
          {this.state.onLoad && (
            <div className={classes.Element}>
              <CircularProgress />
            </div>
          )}
          {this.state.onNull && (
            <Paper className={classes.Element}>
              <Typography>Sin Respuestas para mostrar</Typography>
            </Paper>
          )}
          {this.state.ListOfAnswers &&
            this.state.ListOfAnswers.length > 0 &&
            this.state.ListOfAnswers.map((x, index) => {
              return this.TypeAnswer(x, index);
            })}

          {this.state.ListOfAnswers &&
            this.state.ListOfAnswers.length > 0 &&
            this.state.specialReport &&
            this.getGraficsSpecial()}
          {this.state.ListOfAnswers && this.state.ListOfAnswers.length > 0 && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.specialReport}
                    onChange={(e) => {
                      this.setState({
                        specialReport: !this.state.specialReport,
                      });
                    }}
                  />
                }
                label="Solicitar información especial "
              />
              <Button
                color="primary"
                variant="contained"
                onClick={this.Downloads}
                className={classes.full}
                startIcon={<GetAppIcon />}
              >
                Descargar la información
              </Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
