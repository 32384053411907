import base from "../baseurl";
import token from "../getTk"

const showAll = async (Uuid,State,fecha) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
  console.log("UUID", Uuid,"ESTADO",State)
    const CompaniesShow = await api.post("consultations/index",{json: {
      searchType:"ADVISOR",
      advisorUuid:Uuid,
      consultationStates:[State],
      consultationTypes:[],
      bottomBoundDate:fecha
    }}).json();
    console.log(CompaniesShow)
    return CompaniesShow.data;
    
    
  };
  
  export default  showAll ;