import base from "../baseurl";
import token from "../getTk";

const Citys = async () => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Country = await api.get("countries/index").json();
  const States = await api
    .get("states/index/" + Country.data.country[0].uuid)
    .json();
  const Citys = await api
    .get("cities/index/" + States.data.states[0].uuid)
    .json();
  return Citys.data.city;
};

export default Citys;
