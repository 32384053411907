import thunk from "redux-thunk";
import rootReducer from "../src/reducers/RootReducer";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const middleware = applyMiddleware(thunk);
const config = {
  key: "root",
  storage: storage,
  whitelist: ["login"],
};

const combinedReducer = persistReducer(config, rootReducer);

export const store = createStore(combinedReducer, middleware);
export const persistor = persistStore(store);
export default store;
