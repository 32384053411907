import base from "../../baseurl";
import token from "../../getTk"


const Delete = async (Uuid) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Delete = await api.post("bonuses/delete",{json: {
      destroyUuid:Uuid,
  }}).json();
    return Delete ;
  };
  
  
  export default  Delete ;