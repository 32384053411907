import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HomeCall from "../../../API/Ceforma/Capacitacion/Sitimm";
import CallUtils from "../../../API/CallUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "80%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
  input: {
    display: "none",
  },
});

class Sitimm extends React.Component {
  state = {
    title: "",
    welcomeText: "",
    banner1: "",
    PrincipalIMG: null,
    characteristics: [],
    Characteristic: "",
    Dialogcharacteristics: false,
    calendar: [],
    EventDate: "",
    EventPlace: "",
    EventTitle: "",
    EventsTopics: "",
    EventParticipants: "",
    Dialogcalendar: false,

    endtext: "",
    bonus: [],
    Dialogbonus: false,
    bonusText: "",
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    const Update = await HomeCall.Update(this.state);
    console.log(Update);
    await this.showAll();
  };

  showAll = async () => {
    const polute = await HomeCall.showAll();
    this.setState({
      title: polute.title,
      welcomeText: polute.welcomeText,
      banner1: polute.banner1,
      PrincipalIMG: polute.PrincipalIMG,
      characteristics: polute.characteristics,
      calendar: polute.calendar,
      endtext: polute.endtext,
      bonus: polute.bonus,
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };
  principalIMG = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_CAP_SITIMM_PrincipalIMG"
    );
    this.setState({
      PrincipalIMG: principal,
    });
  };
  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  addCharacteristics = async (e) => {
    var newelement = {
      characteristic: this.state.Characteristic,
    };

    this.setState({
      characteristics: [...this.state.characteristics, newelement],
    });
    this.setState({
      Dialogcharacteristics: false,
      Characteristic: "",
    });
  };

  addcalendar = async (e) => {
    var newelement = {
      date: this.state.EventDate,
      place: this.state.EventPlace,
      title: this.state.EventTitle,
      topics: this.state.EventsTopics,
      participants: this.state.EventParticipants,
    };

    this.setState({
      calendar: [...this.state.calendar, newelement],
    });
    this.setState({
      Dialogcalendar: false,
      EventDate: "",
      EventPlace: "",
      EventTitle: "",
      EventsTopics: "",
      EventParticipants: "",
    });
  };

  addBonus = async (e) => {
    var newelement = {
      bonus: this.state.bonusText,
    };

    this.setState({
      bonus: [...this.state.bonus, newelement],
    });
    this.setState({
      Dialogbonus: false,
      bonusText: "",
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo de pagina"
                name="title"
                value={this.state.title}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto Principal"
                className={classes.text}
                margin="normal"
                name="welcomeText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.welcomeText}
                variant="outlined"
              />
              <TextField
                id="standard-read-only-input"
                label="Cintillo inferior"
                className={classes.text}
                margin="normal"
                name="banner1"
                multiline
                rows="2"
                onChange={this.onChange}
                value={this.state.banner1}
                variant="outlined"
              />
              <TextField
                id="standard-read-only-input"
                label="Texto inferior"
                className={classes.text}
                margin="normal"
                name="endtext"
                multiline
                rows="4"
                onChange={this.onChange}
                value={this.state.endtext}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen principal</Typography>
              </div>
              {this.state.PrincipalIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.PrincipalIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <div className={classes.Remp}>
                  <input
                    type="file"
                    onChange={this.principalIMG}
                    className={classes.input}
                    id="files"
                    required
                  />
                  <label htmlFor="files">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.btn}
                    >
                      <AddBoxIcon />
                    </Button>
                  </label>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Características</Typography>
            {this.state.characteristics.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Titulo</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.characteristics.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.characteristic}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(
                                  this,
                                  index,
                                  "characteristics"
                                )}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "characteristics"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "characteristics"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "Dialogcharacteristics")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>
          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Calendario</Typography>
            {this.state.calendar.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha</TableCell>
                      <TableCell>SEDE</TableCell>
                      <TableCell>Titulo</TableCell>
                      <TableCell>Temas</TableCell>
                      <TableCell>Participantes</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.calendar.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.date}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.place}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.topics}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.participants}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "calendar")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "calendar"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "calendar"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "Dialogcalendar")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>
          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>Bonus</Typography>
            {this.state.bonus.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Bonus</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.bonus.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.bonus}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "bonus")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(this, index, "bonus")}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(this, index, "bonus")}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "Dialogbonus")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.Dialogbonus}
          onClose={this.DialogClose.bind(this, "Dialogbonus")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Bonus</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Bonus"
                  name="bonusText"
                  value={this.state.bonusText}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "Dialogbonus")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addBonus} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.Dialogcharacteristics}
          onClose={this.DialogClose.bind(this, "Dialogcharacteristics")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Característica</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Característica"
                  name="Characteristic"
                  value={this.state.Characteristic}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "Dialogcharacteristics")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addCharacteristics} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.Dialogcalendar}
          onClose={this.DialogClose.bind(this, "Dialogcalendar")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Evento</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Fecha"
                  name="EventDate"
                  value={this.state.EventDate}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="SEDE"
                  name="EventPlace"
                  value={this.state.EventPlace}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Titulo"
                  name="EventTitle"
                  value={this.state.EventTitle}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Temas"
                  name="EventsTopics"
                  value={this.state.EventsTopics}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
                <TextField
                  id="standard-basic"
                  label="Participantes"
                  name="EventParticipants"
                  value={this.state.EventParticipants}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "Dialogcalendar")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addcalendar} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Sitimm);
