import base from "../../baseurl";
import token from "../../getTk"


const Update = async (state) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
   console.log("estado en la llamada",state)
    const Update = await api.post("pages/update",{json: {
        pageId:8,
        name:"educacion",
        content:{
            title:state.title,
            welcomeText:state.welcomeText,
            ImgPrincipal:state.ImgPrincipal,
            SecondaryText:state.SecondaryText,
            Banner1:state.Banner1,
            requirements:state.requirements
        }
    }
    ,
        timeout:false
}).json();
   console.log(Update)
    return Update ;
    
  };
  
  export default  Update ;