import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import OfficeCall from "../../../API/Headquarters";
import MUIDataTable from "mui-datatables";

const styles = (theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  Head: {
    backgroundColor: "#ad1d21",
  },

  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    // textAlign: 'center',
    color: "#2f3542",
    alignItems: "center",
    justifyContent: "center",
  },

  fab: {
    display: "inlineBlock",
    left: "0",
    color: "#FFFF",
    marginTop: "1%",
    marginRight: "3%",
  },

  add: {
    display: "flex",
    justifyContent: "flex-end",
  },
  color: {
    color: "#FFFF",
  },
  paperTable: {
    overflow: "auto",
    maxHeight: "77vh",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class Headquarters extends Component {
  constructor(props) {
    super(props);
    this.state = { Office: [] };
  }

  fetchOfffice = async () => {
    const Offices = await OfficeCall.ShowAll();
    this.setState({ Office: Offices });
  };
  componentDidMount = async () => {
    await this.fetchOfffice();
  };

  render() {
    const { classes } = this.props;
    const data = [];
    this.state.Office.map((office) => {
      const btn = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Oficinas/Informacion",
            state: {
              HQUuid: office.headquarter.uuid,
              HQname: office.headquarter.name,
              HQaddress: office.headquarter.address,
              //HQCity: office.headquarter.City.uuid,
              //HQState: office.headquarter.State.uuid,
              HQColony: office.headquarter.suburb,
            },
          }}
        >
          <AssignmentIcon />
        </Link>
      );
      if (office.headquarter.name !== "le name 1") {
        data.push([
          office.headquarter.name,
          office.headquarter.State !== null
            ? office.headquarter.State.name
            : "Sin Estado",
          office.headquarter.City !== null
            ? office.headquarter.City.name
            : "Sin Ciudad",
          office.headquarter.address,
          office.noOfEmployees,
          btn,
        ]);
      }

      return data;
    });
    const columns = [
      "Nombre",
      "Estado",
      "Ciudad",
      "Dirección",
      "Empleados",
      "Detalles",
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    return (
      <div>
        <Paper className={classes.paperTable}>
          <MUIDataTable
            title={"Oficinas"}
            data={data}
            columns={columns}
            options={options}
          />
        </Paper>
        <div className={classes.add}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            className={classes.fab}
          >
            <Link className={classes.Link} to="/Oficinas/Crear">
              <AddIcon className={classes.color} />
            </Link>
          </Fab>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Headquarters));
