import base from "../baseurl";
import token from "../getTk";

const showAll = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const show = await api
    .post("company-forms/create", {
      json: {
        formData: {
          name: state.name,
        },
        questions: state.ListOfQuestions,
      },
    })
    .json();
  return show;
};

export default showAll;
