import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";

import toastType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    marginLeft: "10px",
  },
});

class dialogListCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidatesList: [],
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.List) {
        console.log("que llega ", this.props.List);
        this.setState({ candidatesList: this.props.List });
      } else {
        toastType.error("La lista de Candidatos no esta disponible");
      }
    } catch (error) {
      toastType.error("Error con la lista de candidatos");
      console.log(error);
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <>
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Lista de Candidatos</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Paper className={classes.PaperList}>
                {this.state.candidatesList.length > 0
                  ? [
                      <>
                        <Table size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Nombre</TableCell>
                              <TableCell>RFC</TableCell>
                              <TableCell>Puesto</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.candidatesList.map((Article) => {
                              return (
                                <React.Fragment>
                                  <TableRow key={Article.uuid}>
                                    <TableCell component="th" scope="row">
                                      {Article.Employee.User.UserProfile.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {Article.Employee.User.UserProfile.rfc}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {Article.employment}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </>,
                    ]
                  : [
                      <Typography
                        align="Center"
                        variant="h5"
                        color="textPrimary"
                        component="p"
                        className={classes.Subtitle}
                      >
                        Sin Candidatos registrados
                      </Typography>,
                    ]}
              </Paper>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(dialogListCandidates);
