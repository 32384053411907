import base from "../../baseurl";
import token from "../../getTk"

const Create = async (a,b,c,d) => {
  //console.log(c)
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Update = await api.post("blogposts/create",{json: {
      title:a,
      img:{
        name:d,
        content:c
      },
      body:b
    }}).json();
    console.log(Update)
    return Update ;

    
  };
  
  export default  Create ;