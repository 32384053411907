import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import EditOficial from "./EditDialogOficialDoc";

const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  PaperTable: {
    width: "100%",
    height: "200px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
    overflow: "auto",
  },
});

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditDialog: false,
      type: "",
    };
  }
  componentDidMount = async () => {
    console.log("onta la url", this.props.data.colectiveContract);
  };

  Edit = (a, b) => {
    this.setState({ EditDialog: true, type: b });
  };

  close = async (a, b) => {
    await this.props.Refresh();
    this.setState({ EditDialog: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.Initial}>
        <Grid container className={classes.Initial} spacing={1}>
          <Grid container item xs={12} className={classes.Initial}>
            <Typography
              align="Center"
              variant="h5"
              component="p"
              className={classes.Subtitle}
            >
              Documentos Oficiales
            </Typography>
            <Paper className={classes.PaperTable}>
              <Table
                size="small"
                aria-label="a dense table"
                className={classes.TableCompany}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Descargar</TableCell>
                    <TableCell>Editar</TableCell>
                    <TableCell>Fecha de Actualización</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.TableCompany}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Contrato Colectivo de Trabajo
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a
                        href={
                          this.props.data.colectiveContract
                            ? this.props.data.colectiveContract.url
                            : ""
                        }
                      >
                        <Button>
                          <GetAppIcon />
                        </Button>
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        color="primary"
                        onClick={this.Edit.bind(
                          this,
                          "EditDialog",
                          "colectiveContract"
                        )}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {this.props.data.colectiveContract
                        ? this.props.data.colectiveContract.date.split("T")[0]
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Reglamento Interior del Trabajo
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a
                        href={
                          this.props.data.internalRegulation
                            ? this.props.data.internalRegulation.url
                            : ""
                        }
                      >
                        <Button>
                          <GetAppIcon />
                        </Button>
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        color="primary"
                        onClick={this.Edit.bind(
                          this,
                          "EditDialog",
                          "internalRegulation"
                        )}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {this.props.data.internalRegulation
                        ? this.props.data.internalRegulation.date.split("T")[0]
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Histórico de Evolución de prestaciones
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a
                        href={
                          this.props.data.storyOfBenefits
                            ? this.props.data.storyOfBenefits.url
                            : ""
                        }
                      >
                        <Button>
                          <GetAppIcon />
                        </Button>
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        color="primary"
                        onClick={this.Edit.bind(
                          this,
                          "EditDialog",
                          "storyOfBenefits"
                        )}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {this.props.data.storyOfBenefits
                        ? this.props.data.storyOfBenefits.date.split("T")[0]
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Documentos de nombramiento
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a
                        href={
                          this.props.data.nominationDocument
                            ? this.props.data.nominationDocument.url
                            : ""
                        }
                      >
                        <Button>
                          <GetAppIcon />
                        </Button>
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Button
                        color="primary"
                        onClick={this.Edit.bind(
                          this,
                          "EditDialog",
                          "nominationDocument"
                        )}
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {this.props.data.nominationDocument
                        ? this.props.data.nominationDocument.date.split("T")[0]
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>

          {this.state.EditDialog
            ? [
                <EditOficial
                  DialogOpen={this.state.EditDialog}
                  DialogClose={this.close}
                  Info={this.props.data}
                  type={this.state.type}
                />,
              ]
            : [<></>]}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TablePadron);
