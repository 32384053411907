import base from "../baseurl";
import token from "../getTk";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const Create = async (Uuid, Title, Type, candidatePosition, Who) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log(Who);
  const Create = await api
    .post("election/create", {
      json: {
        autor: Who,
        title: Title,
        publicTitle:
          Type === "1"
            ? "Asamblea Electiva para " + candidatePosition.name
            : "Asamblea Electiva para Comite",
        employment: candidatePosition ? candidatePosition.uuid : null,
        candidatesUuid: [],
        type: Number.parseInt(Type),
        companyUuid: Uuid,
        status: 1,
        //Tiempos para la eleccion
        StartAnnouncement: moment().add({ days: 1 }),
        EndAnnouncement: moment().add({ days: 1, months: 1 }),
        StartElecction: moment().add({ days: 3, months: 1 }),
        EndElection: moment().add({ days: 5, months: 1 }),
        //Datos de convocatoria
        announcement: "",
        img:
          "https://sitimm-files.nyc3.digitaloceanspaces.com/SITIMM_VOTING_announcement_IMG73e3c2c3-0a30-44e4-9286-6ebc18332643/status1.png",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      },
    })
    .json();
  console.log(Create);
  return Create;
};

export default Create;
