import base from "../../baseurl";
import token from "../../getTk";

const showAll = async (galleryId) => {
  console.log(galleryId);
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("sport-gallery-element/showall", {
      json: { galleryId },
      timeout: false,
    })
    .json();
  return Create;
};

export default showAll;
