import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
class createNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      NewLink: "",
    };
  }
  componentDidMount = async () => {};
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  addLink = () => {
    let value = this.props.ParentState.virtualUrl;
    let New = [...value, this.state.NewLink];
    this.props.parentCallback(New);
    this.props.DialogClose();
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Nuevo Link</DialogTitle>
        <DialogContent>
          <Grid container className={classes.GridD}>
            <TextField
              label="Link"
              name="NewLink"
              value={this.state.NewLink}
              className={classes.Text}
              onChange={this.onChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={this.addLink} color="primary">
            Añadir link
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(createNewEvent);
