import axios from "axios";
const Report = async (state) => {
  const re = await axios
    .post(`https://api.sitimm.org/api/reports/show`, {
      reportNumber: 5,
      params: {
        companyId: state.Companyid === "ALL" ? undefined : state.Companyid,
        fromDate: state.fromDate,
        toDate: state.toDate,
      },
    })
    .then((res) => {
      console.log(res);
      console.log(res.data);
      return res;
    });
  return re;
};

export default Report;
