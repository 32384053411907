import React from "react";
import { withStyles, Paper, Grid, Typography, Button } from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class Default extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid conteiner iteam>
          <Typography>LSE No hay acciones adicionales</Typography>
          <Button>Regresar</Button>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Default);
