import React from "react";

import {
  Typography,
  Button,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
  Grid,
  Switch,
} from "@material-ui/core";
///import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import toastType from "../../API/CallUtils/tostyNotification";
import Api from "../../API/CompaniesCampaigs";
import AddIcon from "@material-ui/icons/Add";
import DialogCreateEvent from "./Dialogs/Create";
import DialogDetailsEvent from "./Dialogs/DetailsDialog";
import AddBoxIcon from "@material-ui/icons/Add";
import ResultDialog from "./Dialogs/Results";
import ResultDialog2 from "./Dialogs/Results2";
import PieChartIcon from "@material-ui/icons/PieChart";
const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Skeleton: {
    width: "100%",
    height: "400px",
    borderRadius: "10px 10px 10px 10px",
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListEvents: [],
      onLoad: true,
    };
  }
  componentDidMount = async () => {
    this.fetchlist();
  };
  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.ShowAll();
      console.log("Todas", Deli);
      if (Deli.status === "success") {
        this.setState({
          ListEvents: Deli.data.campaigns,
          onLoad: false,
          onError: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  delete = async (id) => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.Delete(id);
      console.log(Deli);
      if (Deli.status === "success") {
        toastType.success("Se borro con exito");
        await this.fetchlist();
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "SSE No fue Posible Eliminar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Dialog = (a, b) => {
    this.setState({ [a]: !this.state[a], EventId: b ? b : null });
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  changeStatus = async (id, newState) => {
    try {
      const Deli = await Api.Show(id);
      if (Deli.status === "success") {
        const change = {
          name: Deli.data.campaign.campaign.name,
          formId: Deli.data.campaign.formDetails[0].formDetail.CompanyFormId,
          companies: Deli.data.campaign.formDetails[0].companies.map((comp) => {
            return { id: comp.id, name: comp.name };
          }),
          id: Deli.data.campaign.campaign.id,
          enabled: newState,
        };
        const Update = await Api.Update(change);
        if (Update.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se cambiado con éxito");
          this.fetchlist();
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible cambiar el elemento");
        }
      } else {
        toastType.error("No es posible realizar el cambio");
        this.setState({
          onLoad: false,
        });
      }
    } catch (error) {
      toastType.error("No es posible realizar el cambio");
      this.setState({
        onLoad: false,
      });
    }
  };
  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <>
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        </>
      );
    } else if (this.state.onError) {
      return (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            Error
          </Typography>
          <Paper className={classes.NoItemPaper}>
            <Typography variant="body1" color="textPrimary" component="p">
              Error
            </Typography>
          </Paper>
          ,
        </>
      );
    } else {
      return (
        <div className={classes.paper}>
          <Typography
            align="Center"
            variant="h5"
            component="p"
            className={classes.Subtitle}
          >
            Listado de Campañas de encuestas
          </Typography>

          {this.state.ListEvents.length > 0 ? (
            <Paper className={classes.Table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Detalles</TableCell>
                    <TableCell>Resultados Por empresa</TableCell>
                    <TableCell>Resultados por Campaña</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.ListEvents.map((Article) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article.id}>
                          <TableCell component="th" scope="row">
                            {Article.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Article.createdAt.split("T")[0]}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Switch
                              color={"primary"}
                              checked={Article.enabled}
                              onChange={this.changeStatus.bind(
                                this,
                                Article.id,
                                !Article.enabled
                              )}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.btn}
                              onClick={this.Dialog.bind(
                                this,
                                "EditDialog",
                                Article.id
                              )}
                            >
                              <EditIcon />
                            </Button>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.btn}
                              onClick={this.Dialog.bind(
                                this,
                                "ResultDialog",
                                Article.id
                              )}
                            >
                              <PieChartIcon />
                            </Button>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.btn}
                              onClick={this.Dialog.bind(
                                this,
                                "ResultDialog2",
                                Article.id
                              )}
                            >
                              <PieChartIcon />
                            </Button>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={this.delete.bind(this, Article.id)}
                              className={classes.btn}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.Margin}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.Dialog.bind(this, "CreateDialog")}
                >
                  <AddIcon />
                </Button>
              </Grid>
              {this.state.CreateDialog
                ? [
                    <DialogCreateEvent
                      DialogOpen={this.state.CreateDialog}
                      DialogClose={this.Dialog.bind(this, "CreateDialog")}
                      Refresh={this.Refresh}
                    />,
                  ]
                : [<></>]}
              {this.state.EditDialog
                ? [
                    <DialogDetailsEvent
                      DialogOpen={this.state.EditDialog}
                      DialogClose={this.Dialog.bind(this, "EditDialog")}
                      EventId={this.state.EventId}
                      Refresh={this.Refresh}
                    />,
                  ]
                : [<></>]}
            </Paper>
          ) : (
            <Paper className={classes.NoItemPaper}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.Dialog.bind(this, "CreateDialog")}
                className={classes.AddCandidate}
              >
                <AddBoxIcon />
              </Button>
              {this.state.CreateDialog
                ? [
                    <DialogCreateEvent
                      DialogOpen={this.state.CreateDialog}
                      DialogClose={this.Dialog.bind(this, "CreateDialog")}
                      Refresh={this.Refresh}
                    />,
                  ]
                : [<></>]}
            </Paper>
          )}

          {this.state.ResultDialog && (
            <ResultDialog
              DialogOpen={this.state.ResultDialog}
              DialogClose={this.Dialog.bind(this, "ResultDialog")}
              Refresh={this.Refresh}
              EventId={this.state.EventId}
            />
          )}
          {this.state.ResultDialog2 && (
            <ResultDialog2
              DialogOpen={this.state.ResultDialog2}
              DialogClose={this.Dialog.bind(this, "ResultDialog2")}
              Refresh={this.Refresh}
              EventId={this.state.EventId}
            />
          )}
        </div>
      );
    }
  }
}

export default withStyles(styles)(Edit);
