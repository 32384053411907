import base from "../baseurl";
import token from "../getTk";

const showAll = async (electionUuid) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("election/stop", {
      json: {
        electionUuid,
      },
    })
    .json();
  return Create;
};

export default showAll;
