import base from "../baseurl";
import token from "../getTk";

const Create = async (Worker, Newpayroll, Newjob) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  //console.log( ElectionUuid)
  const Create = await api
    .post("employee-management/update", {
      json: {
        employeeData: {
          payroll: Newpayroll,
          job: Newjob,
          daySalary: Worker.daySalary ? Worker.daySalary : "",
          entry: Worker.entry ? Worker.entry : "",
          address: Worker.address ? Worker.address : "",
          uuid: Worker.uuid,
        },
        //userData: Worker.User.email,
        //userProfileData: Worker.User.UserProfile,
      },
    })
    .json();
  //console.log(Create);
  return Create;
};
export default Create;
