import base from "../baseurl";
import token from "../getTk";

const Show = async (userId, companyId, title, city) => {
  console.log(userId, companyId, title);
  const tokenA = token.get();

  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const status = await api
    .post("other-assembly-members/create", {
      json: { userId, companyId, title, city },
    })
    .json();
  console.log(status);
  return status;
};

export default Show;
