import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";
import Details from "./showDetails";
import showDetails from "./showDetails";
import showAll from "./showAll";
import GetVotes from "./GetVotes";
import NonVote from "./NonVote";
import Stop from "./Stop";
const Voting = {
  Create,
  Update,
  Delete,
  Details,
  showDetails,
  showAll,
  GetVotes,
  NonVote,
  Stop,
};

export default Voting;

export {
  Create,
  Update,
  Delete,
  Details,
  showDetails,
  showAll,
  GetVotes,
  NonVote,
  Stop,
};
