import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  TextField,
  MenuItem,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  Title: {
    width: "90%",
  },
  CloseBtn: {
    //width: "10%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  Text: {
    width: "100%",
    //margin: "10px",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 1 };
  }
  Dialog = (a, b) => {
    this.setState({ [a]: !this.state[a], EventId: b ? b : null });
  };
  render() {
    const { classes } = this.props;
    let Value = <></>;
    switch (this.props.Answer.CompanyFormQuestionType.name) {
      case "Textbox":
        Value = (
          <TextField
            label={this.props.Answer.content}
            defaultValue={"Campo de texto"}
            variant="outlined"
            margin="dense"
            className={classes.Text}
          ></TextField>
        );
        break;
      case "Textarea":
        Value = (
          <TextField
            label={this.props.Answer.content}
            defaultValue={"Area de texto"}
            variant="outlined"
            multiline
            margin="dense"
            rows={6}
            className={classes.Text}
          ></TextField>
        );
        break;
      case "Radiobutton":
        Value = (
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {this.props.Answer.content}
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              //value={value}
              //onChange={handleChange}
            >
              {this.props.Answer.CompanyFormQuestionAnswers.map((a) => {
                return (
                  <FormControlLabel
                    value={a.content}
                    control={<Radio />}
                    label={a.content}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
        break;
      case "Checkbutton":
        Value = (
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {this.props.Answer.content}
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              //value={value}
              //onChange={handleChange}
            >
              {this.props.Answer.CompanyFormQuestionAnswers.map((a) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        //checked={state.checkedB}
                        //onChange={handleChange}
                        //name="checkedB"
                        color="primary"
                      />
                    }
                    label={a.content}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
        break;
      case "Select":
        Value = (
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {this.props.Answer.content}
            </FormLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select">
              {this.props.Answer.CompanyFormQuestionAnswers.map((a) => {
                return <MenuItem value={a.content}>{a.content}</MenuItem>;
              })}
            </Select>
          </FormControl>
        );
        break;
      default:
        Value = <></>;
    }
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        TransitionComponent={Transition}
        //maxWidth="xl"
        //fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.Title}>Opciones</Typography>
        </DialogTitle>
        <DialogContent className={classes.paper}>{Value}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
