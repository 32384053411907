import React from "react";
import {
  withStyles,
  TextField,
  Typography,
  Grid,
  Checkbox,
} from "@material-ui/core";
//import toastType from "../../../API/CallUtils/tostyNotification";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  text: {
    width: "100%",
  },
});
function FilterOption({
  label,
  name,
  value,
  SendTo,
  classes,
  enable,
  ResiveFromChild,
}) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      xs={12}
      spacing={1}
    >
      <Grid
        item
        xs={12}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="subtitle1" gutterBottom align="center">
          {label}
        </Typography>
      </Grid>

      <Grid item xs={5} direction="column">
        <TextField
          label="Mínimo"
          className={classes.text}
          margin="normal"
          onChange={(e) => {
            SendTo(name, "min", e.target.value);
          }}
          value={value.min}
          variant="outlined"
          name="min"
          size="small"
          disabled={!enable}
        />
      </Grid>
      <Grid
        item
        xs={5}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          label="Máximo"
          className={classes.text}
          margin="normal"
          onChange={(e) => {
            SendTo(name, "max", e.target.value);
          }}
          value={value.max}
          variant="outlined"
          name="max"
          size="small"
          disabled={!enable}
        />
      </Grid>
      <Grid item xs={1} direction="column">
        <Checkbox
          checked={enable}
          onChange={() => {
            ResiveFromChild(name + "Check", !enable);
          }}
        />
      </Grid>
    </Grid>
  );
}
export default withStyles(styles)(FilterOption);
