import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import AddCall from "../../../API/Companies/CreateAdmin";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    //padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    //marginLeft: "10px",
    padding: "1%",
  },
  Select: {
    maxWidth: "100%",
  },
  full: { width: "100%" },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      type: "",
      title: "",
      SelectItems: [],
      titleOther: false,
    };
  }
  componentDidMount = async () => {
    //console.log("llorando", this.props.id);
    if (this.props.type) {
      switch (this.props.type) {
        case "RH":
          this.setState({
            SelectItems: ["Gerente RH", "Director RH", "Supervisor RH"],
          });
          break;
        case "Gerencia":
          this.setState({
            SelectItems: [
              "Gerente",
              "Gerente de planta",
              "Gerente planta",
              "Presidente",
            ],
          });
          break;
        case "Produccion":
          this.setState({
            SelectItems: ["Gerente de Producción", "Encargado de Producción"],
          });
          break;
        case "Otro":
          this.setState({
            titleOther: true,
          });
          break;
        default:
          this.setState({ SelectItems: [] });
      }
    }
  };

  onChange = (event) => {
    //console.log(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  newCandidate = async () => {
    console.log(this.props.id);
    if (this.checkInfoCandidate()) {
      try {
        const newCan = await AddCall(
          this.state.name,
          this.state.email,
          this.state.phone,
          this.props.id,
          this.state.title,
          this.props.type
        );
        //console.log("qwe", newCan);
        if (newCan.status === "success") {
          this.props.Refresh();
          toastType.success("Miembro creado");
          this.cleanCandidateInfo();
        } else {
          console.log(newCan);
          toastType.error("No ha sido posible agregar este Miembro");
        }
      } catch (error) {
        console.log(error);
        toastType.error("No ha sido posible agregar este Miembro");
      }
    }
  };
  checkInfoCandidate = () => {
    if (
      this.state.name &&
      this.state.phone &&
      this.state.email &&
      this.state.title
    ) {
      return true;
    } else {
      if (!this.state.name) {
        toastType.error("Se requiere nombre");
      }
      if (!this.state.phone) {
        toastType.error("Se requiere Teléfono");
      }
      if (!this.props.type) {
        toastType.error("Se requiere un tipo");
      }
      if (!this.state.title) {
        toastType.error("Se requiere Titulo");
      }
      return false;
    }
  };
  cleanCandidateInfo = () => {
    this.setState({
      name: "",
      phone: "",
      email: "",
      type: "",
      title: "",
    });
  };
  OtherTitle = () => {
    this.setState({ titleOther: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        //maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Agregar</DialogTitle>
        <DialogContent>
          <Grid container className={classes.GridD}>
            <Paper className={classes.PaperDialogNewCandidate}>
              <Grid container>
                <Grid
                  iteam
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.GridDialog}
                >
                  <TextField
                    label="Nombre Completo"
                    name="name"
                    value={this.state.name}
                    className={classes.Text}
                    onChange={this.onChange}
                    size="small"
                    variant="outlined"
                  />
                  <TextField
                    label="Email"
                    name="email"
                    value={this.state.email}
                    className={classes.Text}
                    onChange={this.onChange}
                    size="small"
                    variant="outlined"
                  />
                  <TextField
                    label="Teléfono"
                    name="phone"
                    value={this.state.phone}
                    className={classes.Text}
                    onChange={this.onChange}
                    variant="outlined"
                    size="small"
                  />
                  {this.state.titleOther
                    ? [
                        <TextField
                          label="titulo"
                          name="title"
                          value={this.state.title}
                          className={classes.Text}
                          onChange={this.onChange}
                          variant="outlined"
                          size="small"
                        />,
                      ]
                    : [
                        <FormControl className={classes.paper}>
                          <InputLabel id="demo-simple-select-label">
                            Nombre del puesto
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.title}
                            name="title"
                            onChange={this.onChange}
                          >
                            {this.state.SelectItems.length > 0
                              ? [
                                  this.state.SelectItems.map(
                                    (companie, index) => {
                                      return (
                                        <MenuItem
                                          key={companie}
                                          value={companie}
                                        >
                                          {companie}
                                        </MenuItem>
                                      );
                                    }
                                  ),
                                  <MenuItem value="">
                                    <Button
                                      onClick={this.OtherTitle}
                                      className={classes.full}
                                      color="primary"
                                    >
                                      Otro Nombre
                                    </Button>
                                  </MenuItem>,
                                ]
                              : [
                                  <MenuItem value="">
                                    Sin posiciones a mostrar
                                  </MenuItem>,
                                  <MenuItem value="">
                                    <Button
                                      onClick={this.OtherTitle}
                                      className={classes.full}
                                      color="primary"
                                    >
                                      Otro Nombre
                                    </Button>
                                  </MenuItem>,
                                ]}
                          </Select>
                        </FormControl>,
                      ]}

                  <TextField
                    label="Tipo"
                    value={this.props.type}
                    className={classes.Text}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={this.newCandidate} color="primary">
            Crear Miembro
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
