import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import AddBoxIcon from "@material-ui/icons/Add";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
//import EditIcon from "@material-ui/icons/Edit";
import DialogCreateEvent from "../Dialogs/CreateImg";
import toastType from "../../../API/CallUtils/tostyNotification";
import SportEvent from "../../../API/SportEvent";
const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    //padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Table: {
    width: "100%",
    padding: "1%",
  },
  Margin: {
    margin: "5px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPre: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    display: "block",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListEvents: [],
      EventId: null,
      onError: false,
      onLoad: true,
      GallerieName: "",
    };
  }
  componentDidMount = async () => {
    //console.log("La galería", this.props.EventId);
    this.fetchlist();
  };
  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await SportEvent.GalleriesElements(this.props.EventId);
      //console.log("galería", Deli);
      if (Deli.status === "success") {
        this.setState({
          ListEvents: Deli.data.galleryElements,
          onLoad: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "SSE No fue Posible Eliminar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  delete = async (id) => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await SportEvent.GallerierDeleteElements(id);
      console.log(Deli);
      if (Deli.status === "success") {
        toastType.success("Se borro con éxito");
        await this.fetchlist();
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "SSE No fue Posible Eliminar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Dialog = (a, id, name) => {
    this.setState({
      [a]: !this.state[a],
      EventId: id ? id : null,
      GallerieName: name,
    });
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          maxWidth="xl"
        >
          <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
          <DialogContent className={classes.paper}>
            <Skeleton
              variant="rect"
              animation="wave"
              className={classes.Skeleton}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (this.state.onError) {
      return (
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          maxWidth="xl"
        >
          <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
          <DialogContent className={classes.paper}>
            <Paper className={classes.NoItemPaper}>
              <Typography variant="body1" color="textPrimary" component="p">
                Error
              </Typography>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          maxWidth="xl"
        >
          <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
          <DialogContent className={classes.paper}>
            <div className={classes.paper}>
              <Typography
                align="Center"
                variant="h5"
                component="p"
                className={classes.Subtitle}
              >
                Listado de Imágenes
              </Typography>

              {this.state.ListEvents.length > 0 ? (
                <Paper className={classes.Table}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>imagen</TableCell>
                        <TableCell>Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.ListEvents.map((Article) => {
                        return (
                          <React.Fragment>
                            <TableRow key={Article.uuid}>
                              <TableCell component="th" scope="row">
                                {Article.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Article.createdAt.split("T")[0]}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <img
                                  src={Article.url}
                                  className={classes.ImgPre}
                                  alt={Article.name}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={this.delete.bind(
                                    this,
                                    Article.id,
                                    Article.name
                                  )}
                                  className={classes.btn}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.Margin}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.Dialog.bind(this, "CreateDialog")}
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                  {this.state.CreateDialog
                    ? [
                        <DialogCreateEvent
                          DialogOpen={this.state.CreateDialog}
                          DialogClose={this.Dialog.bind(this, "CreateDialog")}
                          Refresh={this.Refresh}
                          id={this.props.EventId}
                        />,
                      ]
                    : [<></>]}
                </Paper>
              ) : (
                <Paper className={classes.NoItemPaper}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.Dialog.bind(this, "CreateDialog")}
                    className={classes.AddCandidate}
                  >
                    <AddBoxIcon />
                  </Button>
                  {this.state.CreateDialog
                    ? [
                        <DialogCreateEvent
                          DialogOpen={this.state.CreateDialog}
                          DialogClose={this.Dialog.bind(this, "CreateDialog")}
                          Refresh={this.Refresh}
                          id={this.props.EventId}
                        />,
                      ]
                    : [<></>]}
                </Paper>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              <CloseIcon />
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }
}

export default withStyles(styles)(addCandidateDialog);
