import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, TextField, Button, Typography } from "@material-ui/core";
import HomeCall from "../../../API/CTM/home";
import Img from "../../../API/CallUtils/img";
import CallUtils from "../../../API/CallUtils";
import Carrusel from "./baseComponets/HomeCarrusel";
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "80vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Actividad: {
    width: "100%",
  },
  SectionTable: {
    width: "70%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
});

class home extends React.Component {
  state = {
    img: "https://ttwo.dk/wp-content/uploads/2017/08/person-placeholder.jpg",

    principalText: "",
    principalTitle: "",
    CTAtext: "",
    CTAurl: "",
    principalIMG: null,

    secondaryText: "",
    secondaryTitle: "",
    motto: "",
    secondaryIMG: null,

    MisionTitle: "",
    MisionText: "",
    MisionIMG: null,

    VisionText: "",
    VisionTitle: "",
    VisionIMG: null,

    ValoresText: "",
    ValoresTitle: "",
    ValoresIMG: "",

    ConsultationTitle: "",
    ConsultationText: "",
    ConsultationLink: "",
    ConsultationIMG: null,

    BonusTitle: "",
    BonusText: "",
    BonusLink: "",
    BonusIMG: null,
    files: [],

    AppTitle: "",
    AppText: "",
    AppLink: "",
    BannerIMG: null,

    ProgramTitle: "",
    ProgramLink: "",
    ProgramResumen: "",
    ProgramIMG: null,
    FilenameIMG: "",

    programTitle1: "",
    programResumen1: "",
    programIMG1: null,

    programTitle2: "",
    programResumen2: "",
    programIMG2: null,

    programTitle3: "",
    programResumen3: "",
    programIMG3: null,

    programTitle4: "",
    programResumen4: "",
    programIMG4: null,

    programTitle5: "",
    programResumen5: "",
    programIMG5: null,

    OrgTitle: "",
    OrgText: "",
    OrgImg: "",
    OrgDoc: "",
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  update = async () => {
    try {
      const Update = await HomeCall.Update(this.state);
      if (Update.status === !"success") {
        toasType.error("No fue posible Guardar SSE");
      } else {
        toasType.success("Sus cambios han sido guardados correctamente ");
      }
    } catch (e) {
      toasType.error("No fue posible Guardar CSE");
    }

    await this.showAll();
  };

  showAll = async () => {
    try {
      const polute = await HomeCall.showAll();
      if (polute) {
        this.setState({
          principalText: polute.content.coverPage.mainText,
          principalTitle: polute.content.coverPage.mainTitle,
          CTAtext: polute.content.coverPage.ctaText,
          CTAurl: polute.content.coverPage.urlCta,
          principalIMG: polute.content.coverPage.img,

          secondaryText: polute.content.whoWeAre.description,
          secondaryTitle: polute.content.whoWeAre.title,
          //motto:polute.content.whoWeAre.motto,
          secondaryIMG: polute.content.whoWeAre.img,

          MisionTitle: polute.content.whoWeAre.lemma.mision.title,
          MisionText: polute.content.whoWeAre.lemma.mision.content,
          MisionIMG: polute.content.whoWeAre.lemma.mision.img,

          VisionText: polute.content.whoWeAre.lemma.vision.content,
          VisionTitle: polute.content.whoWeAre.lemma.vision.title,
          VisionIMG: polute.content.whoWeAre.lemma.vision.img,

          ValoresText: polute.content.whoWeAre.lemma.values.content,
          ValoresTitle: polute.content.whoWeAre.lemma.values.title,
          ValoresIMG: polute.content.whoWeAre.lemma.values.img,

          ConsultationTitle: polute.content.Consultation.title,
          ConsultationText: polute.content.Consultation.description,
          ConsultationLink: polute.content.Consultation.link,
          ConsultationIMG: polute.content.Consultation.img,

          BonusTitle: polute.content.Bonus.title,
          BonusText: polute.content.Bonus.description,
          BonusLink: polute.content.Bonus.link,
          BonusIMG: polute.content.Bonus.img,

          programTitle1: polute.content.Programs[0].title,
          programResumen1: polute.content.Programs[0].text,
          programIMG1: polute.content.Programs[0].img,

          programTitle2: polute.content.Programs[1].title,
          programResumen2: polute.content.Programs[1].text,
          programIMG2: polute.content.Programs[1].img,

          programTitle3: polute.content.Programs[2].title,
          programResumen3: polute.content.Programs[2].text,
          programIMG3: polute.content.Programs[2].img,

          programTitle4: polute.content.Programs[3].title,
          programResumen4: polute.content.Programs[3].text,
          programIMG4: polute.content.Programs[3].img,

          programTitle5: polute.content.Programs[4].title,
          programResumen5: polute.content.Programs[4].text,
          programIMG5: polute.content.Programs[4].img,

          AppTitle: polute.content.Banner.title,
          AppText: polute.content.Banner.description,
          AppLink: polute.content.Banner.link,
          BannerIMG: polute.content.Banner.img,

          OrgTitle: polute.content.Org.OrgTitle,
          OrgText: polute.content.Org.OrgText,
          OrgImg: polute.content.Org.OrgImg,
          OrgDoc: polute.content.Org.OrgDoc,

          //files:polute.content.Programs.array
        });
      } else {
        toasType.error("Error");
      }

      const c = await HomeCall.ShowCarusel();
      this.setState({ files: c });
    } catch (e) {
      console.log(e);
      toasType.error("Error");
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  principalIMG = async (e) => {
    const str = await Img.getBinaryString(e.target.files[0]);
    var imgDone = Img.getImg(str);

    this.setState({
      principalIMG: imgDone,
    });
  };
  secondaryIMG = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_Vision",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      secondaryIMG: upload.data.location,
    });
  };
  ConsultationIMG = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_ConsultationIMG",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      ConsultationIMG: upload.data.location,
    });
  };
  BonusIMG = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_BonusIMG",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      BonusIMG: upload.data.location,
    });
  };
  BannerIMG = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_BannerIMG",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      BannerIMG: upload.data.location,
    });
    console.log(this.state);
  };
  MisionIMG = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_Vision",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      MisionIMG: upload.data.location,
    });
  };
  VisionIMG = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_Vision",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      VisionIMG: upload.data.location,
    });
  };
  ValoresIMG = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_VALORES",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      ValoresIMG: upload.data.location,
    });
  };
  programIMG1 = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_PROGRAM_1",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      programIMG1: upload.data.location,
    });
  };
  programIMG2 = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_PROGRAM_2",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      programIMG2: upload.data.location,
    });
  };
  programIMG3 = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_PROGRAM_3",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      programIMG3: upload.data.location,
    });
  };
  programIMG4 = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_PROGRAM_4",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      programIMG4: upload.data.location,
    });
  };
  programIMG5 = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_PROGRAM_5",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      programIMG5: upload.data.location,
    });
  };
  Opencreate = async () => {
    this.setState({ Dialog: true });
  };
  DialogClose = async () => {
    this.setState({
      Dialog: false,
      ProgramTitle: "",
      ProgramLink: "",
      ProgramResumen: "",
      ProgramIMG: null,
      FilenameIMG: "",
    });
  };
  addOrgImg = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_OrgIMG",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      OrgImg: upload.data.location,
    });
    console.log(this.state);
  };
  addOrgDoc = async (e) => {
    var name = e.target.files[0].name;
    const upload = await CallUtils.UploadNewImg(
      "SITIMM_HOME_OrgDoc",
      name,
      e.target.files[0]
    );
    console.log(upload.data.location);
    this.setState({
      OrgDoc: upload.data.location,
    });
    console.log(this.state);
  };
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography>Sección de quienes somos</Typography>
            <Grid
              item
              xs={4}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo Segundo"
                name="secondaryTitle"
                value={this.state.secondaryTitle}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto quienes somos"
                className={classes.text}
                margin="normal"
                name="secondaryText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.secondaryText}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen Secundaria</Typography>
              </div>

              {this.state.secondaryIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.secondaryIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.secondaryIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={4}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography>Mision</Typography>
              <TextField
                id="standard-basic"
                label="Titulo"
                name="MisionTitle"
                value={this.state.MisionTitle}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto"
                className={classes.text}
                margin="normal"
                name="MisionText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.MisionText}
                variant="outlined"
              />

              {this.state.MisionIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.MisionIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="form-group">
                <input
                  type="file"
                  onChange={this.MisionIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography>Vision</Typography>
              <TextField
                id="standard-basic"
                label="Titulo"
                name="VisionTitle"
                value={this.state.VisionTitle}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto"
                className={classes.text}
                margin="normal"
                name="VisionText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.VisionText}
                variant="outlined"
              />

              {this.state.VisionIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.VisionIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="form-group">
                <input
                  type="file"
                  onChange={this.VisionIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography>Valores</Typography>
              <TextField
                id="standard-basic"
                label="Titulo"
                name="ValoresTitle"
                value={this.state.ValoresTitle}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto"
                className={classes.text}
                margin="normal"
                name="ValoresText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.ValoresText}
                variant="outlined"
              />

              {this.state.ValoresIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.ValoresIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="form-group">
                <input
                  type="file"
                  onChange={this.ValoresIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography>Sección de consultas </Typography>
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo Consultas"
                name="ConsultationTitle"
                value={this.state.ConsultationTitle}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto de consultas"
                className={classes.text}
                margin="normal"
                name="ConsultationText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.ConsultationText}
                variant="outlined"
              />
              <TextField
                id="standard-basic"
                label="Link"
                name="ConsultationLink"
                value={this.state.ConsultationLink}
                className={classes.A}
                onChange={this.onChange}
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen para Consultas</Typography>
              </div>

              {this.state.ConsultationIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.ConsultationIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.ConsultationIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography>Sección de Beneficios </Typography>
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo Beneficios"
                name="BonusTitle"
                value={this.state.BonusTitle}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto de Beneficios"
                className={classes.text}
                margin="normal"
                name="BonusText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.BonusText}
                variant="outlined"
              />
              <TextField
                id="standard-basic"
                label="Link"
                name="BonusLink"
                value={this.state.BonusLink}
                className={classes.A}
                onChange={this.onChange}
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen para Consultas</Typography>
              </div>

              {this.state.ConsultationIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.BonusIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.BonusIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography>Sección de Banner APP </Typography>
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo"
                name="AppTitle"
                value={this.state.AppTitle}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto de Beneficios"
                className={classes.text}
                margin="normal"
                name="AppText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.AppText}
                variant="outlined"
              />
              <TextField
                id="standard-basic"
                label="Link"
                name="AppLink"
                value={this.state.AppLink}
                className={classes.A}
                onChange={this.onChange}
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imgDiv}>
                <Typography>Imagen del Banner</Typography>
              </div>

              {this.state.BannerIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.BannerIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.BannerIMG}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={3}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography>Texto de Organigrama</Typography>

              <TextField
                id="standard-basic"
                label="Titulo"
                name="OrgTitle"
                value={this.state.OrgTitle}
                className={classes.A}
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Texto"
                className={classes.text}
                margin="normal"
                name="OrgText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.OrgText}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography>Imagen</Typography>
              <div className={classes.imgDiv}></div>

              {this.state.OrgImg !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.OrgImg}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                <input
                  type="file"
                  onChange={this.addOrgImg}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography>Documento</Typography>
              <a
                href={this.state.OrgDoc}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Documento actual
              </a>
              <div className="form-group">
                <input
                  type="file"
                  onChange={this.addOrgDoc}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.SectionTable}>
            <Carrusel />
          </div>

          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(home);
