import base from "../baseurl";
import token from "../getTk";

const showAll = async (rfc) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  console.log("el token ");
  const CompaniesShow = await api
    .get("user-profiles/show-history/" + rfc)
    .json();
  console.log(CompaniesShow);
  return CompaniesShow;
};

export default showAll;
