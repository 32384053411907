import React from "react";
import "babel-polyfill";
import { withStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/Add";
import EventCall from "../../../API/CTM/events";
import Img from "../../../API/CallUtils/img";
import { EventsPerMonth, CreateNewEvent } from "./baseComponets";
import { Paper, Grid, Button } from "@material-ui/core";
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "80vh",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  input: {
    display: "none",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  GridCalendari: {
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    overflow: "auto",
  },
});

class events extends React.Component {
  state = {
    events: null,
    Dialog: false,
    DialogDelete: false,
    DialogCreate: false,

    imgRaw: null,
    Uuid: "",
    Title: "",
    img: null,
    place: "",
    regis: "registry",
    Resumen: "",
    FileName: "",
    files: [],
    remplazar: false,
    filesC: [],
    onSave: false,
    MonthsIng: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  DialogEntrando = async () => {
    this.setState({
      Dialog: false,
      Title: "",

      place: "",
      regis: "registry",
      img: null,
      imgRaw: null,
      Resumen: "",
      FileName: "",
      files: [],
      remplazar: false,
      filesC: [],
    });
  };

  DialogCreate = async () => {
    this.setState({
      DialogCreate: false,
      Title: "",

      place: "",
      regis: "registry",
      img: null,
      imgRaw: null,
      Resumen: "",
      FileName: "",
      files: [],
      remplazar: false,
      filesC: [],
    });
  };

  showAll = async () => {
    try {
      const polute = await EventCall.showAll();
      if (polute) {
        this.setState({ events: polute.events });
      } else {
        toasType.error("No fue posible traer la información");
      }
    } catch (e) {
      toasType.error("No fue posible traer la información");
    }
  };

  Opencreate = async () => {
    this.setState({
      DialogCreate: true,
      Title: "",
      place: "",
      regis: "registry",
      img: null,
      imgRaw: null,
      Resumen: "",
      FileName: "",
      files: [],
      remplazar: false,
      filesC: [],
    });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  uploadSingleFile = async (e) => {
    var name = e.target.files[0].name;
    const str = await Img.getBinaryString(e.target.files[0]);
    var imgDone = btoa(str);

    this.setState({
      img: Img.getImg(str),
      imgRaw: imgDone,
      FileName: name,
    });
  };

  handleChange = async (ev) => {
    this.setState({ regis: ev.target.value });
  };

  uploadPDF = async (e) => {
    var filename = e.target.files[0].name;
    const raw = await Img.getBinaryStringFile(e.target.files[0]);
    var file64 = btoa(raw);

    var newelement = {
      name: filename,
      content: file64,
    };

    this.setState({
      files: [...this.state.files, newelement],
    });
  };

  uploadC = async (e) => {
    var filename = e.target.files[0].name;
    const raw = await Img.getBinaryStringFile(e.target.files[0]);
    var file64 = btoa(raw);

    var newelement = {
      name: filename,
      content: file64,
    };

    this.setState({
      filesC: [...this.state.filesC, newelement],
    });
  };

  remove = (i) => {
    this.setState((state) => {
      const files = state.files.filter((item, j) => i !== j);
      return {
        files,
      };
    });
  };
  remplazar = () => {
    this.setState({
      files: [],
      remplazar: true,
    });
  };
  SelectName = (name, index, esto) => {
    //console.log("nota el name", name, index, esto);
    return "Enero";
  };
  handleCallback = async () => {
    this.setState({ events: null });
    this.DialogCreate();
    await this.showAll();
  };
  DeleteFalseEvent = (event) => {
    const eventDate = "2000-02-19T16:49:44.563Z";
    return event.length === 1
      ? event[0].eventDate === eventDate
        ? []
        : event
      : event;
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.GridCalendari}>
          <Grid container className={classes.GridCalendari}>
            <Grid
              item
              xs={12}
              className={classes.GridCalendari}
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              {this.state.events
                ? [
                    this.state.MonthsIng.map((Month) => {
                      return (
                        <>
                          <EventsPerMonth
                            name={Month}
                            eventsPerMonth={this.DeleteFalseEvent(
                              this.state.events[Month]
                            )}
                            parentCallback={this.handleCallback}
                          />
                        </>
                      );
                    }),
                  ]
                : [<></>]}
            </Grid>

            <div className={classes.btnTable}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addBTN}
                onClick={this.Opencreate}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </Grid>
        </Paper>

        <CreateNewEvent
          DialogOpen={this.state.DialogCreate}
          DialogClose={this.DialogCreate}
          parentCallback={this.handleCallback}
        />
      </div>
    );
  }
}

export default withStyles(styles)(events);

/**/
