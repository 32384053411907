import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import toastType from "../../../API/CallUtils/tostyNotification";
import EditOthers from "./EditDialogOtherDoc";

const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    height: "200px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  PaperTable: {
    width: "100%",
    height: "200px",
    borderRadius: "0px 0px 10px 10px",
    // padding: "1%",
    overflow: "auto",
  },
  Margin: {
    marginTop: "1%",
  },
});

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [
        {
          name: "Contrato Colectivo de Trabajo",
          date: "12-01-2012",
        },
        {
          name: "Reglamento Interior del Trabajo",
          date: "12-01-2012",
        },
        {
          name: "Histórico de Evolución de prestaciones",
          date: "12-01-2012",
        },
        {
          name: "Documentos de nombramiento",
          date: "12-01-2012",
        },
      ],
      Files: [],
      EditDialog: false,
      EditDialog2: false,
      type: "",
    };
  }
  componentDidMount = async () => {
    try {
      //console.log('WTF',this.props.Info.company)
      if (this.props.Info) {
        this.setState({ info: this.props.Info.company });
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
    }
  };

  handleCallback = (urlFromChild, stateName) => {
    var info = { ...this.state.info };
    info.company[stateName] = urlFromChild;
    this.setState({ info });
    //this.props.parentCallback(urlFromChild, stateName);
  };
  Edit = (a, b) => {
    this.setState({ [a]: !this.state[a], type: b });
  };
  close = (a, b) => {
    this.setState({ [a]: !this.state[a], type: b });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.Initial}>
        <Grid container className={classes.Initial} spacing={1}>
          <Grid container item xs={12} className={classes.Initial}>
            <Typography
              align="Center"
              variant="h5"
              component="p"
              className={classes.Subtitle}
            >
              Documentos adicionales
            </Typography>
            <Paper className={classes.PaperTable}>
              {this.props.Info.company.otherDocuments &&
              this.props.Info.company.otherDocuments.length > 0 ? (
                <>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    className={classes.TableCompany}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Descarga</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.TableCompany}>
                      {this.props.Info.company.otherDocuments.map((Asesor) => {
                        return (
                          <React.Fragment>
                            <TableRow key={Asesor.Uuid}>
                              <TableCell component="th" scope="row">
                                {Asesor.split("/").slice(-1).pop()}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <a href={Asesor}>
                                  <Button>
                                    <GetAppIcon />
                                  </Button>
                                </a>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.Margin}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.Edit.bind(this, "EditDialog2")}
                    >
                      <EditIcon />
                    </Button>
                  </Grid>
                </>
              ) : (
                <Button
                  className={classes.AddCandidate}
                  onClick={this.Edit.bind(
                    this,
                    "EditDialog2",
                    "nominationDocument"
                  )}
                >
                  Sin Documentos
                </Button>
              )}
            </Paper>
            {this.state.EditDialog2
              ? [
                  <EditOthers
                    DialogOpen={this.state.EditDialog2}
                    DialogClose={this.Edit.bind(this, "EditDialog2", "")}
                    Info={this.props.Info}
                    parentCallback={this.handleCallback}
                  />,
                ]
              : [<></>]}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TablePadron);
