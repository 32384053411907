import React, { Component } from "react";
import ApiCallGeT from "../../API/GeneralCall/get";
import Skeleton from "@material-ui/lab/Skeleton";
//import ApiCallPost from "../../API/GeneralCall/get";

export default function withDataFetching(WrappedComponent, endpoint) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = { data: {}, ConnectionStatus: "OnLoading" };
    }

    componentDidMount() {
      this.FetchInfo();
    }

    FetchInfo = async () => {
      try {
        const info = await ApiCallGeT(endpoint);
        if (info && info.status && info.status === "success") {
          this.setState({ ConnectionStatus: "OnSuccess", data: info.data });
        } else {
          this.setState({ ConnectionStatus: "OnError" });
        }
      } catch (e) {
        this.setState({ ConnectionStatus: "OnError" });
        console.log(`Error on fetch ${endpoint}`, e);
      }
    };

    render() {
      switch (this.state.ConnectionStatus) {
        case "OnLoading":
          return (
            <>
              <Skeleton
                animation="wave"
                style={{ width: "80%", height: "100px" }}
              />
            </>
          );
        case "OnError":
          return (
            <>
              <p>Error en la carga</p>
            </>
          );
        case "OnSuccess":
          return <WrappedComponent data={this.state.data} {...this.props} />;
        default:
          return (
            <>
              <p> Falta de estado Default</p>
            </>
          );
      }
    }
  };
}
