import React, { useState } from "react";
import { withStyles, Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Utils from "../../../../API/Reports";
import toasType from "../../../../API/CallUtils/tostyNotification";

const Classes = (theme) => ({
  Paper: {
    width: "100%",
    minHeight: "20vh",
    padding: "12px",
  },
  title: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    padding: "8px",
    fontWeight: "bold",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  formControl: {
    width: "100%",
  },
  Skeleton: {
    width: "100%",
    minHeight: "200px",
  },
});

function AllTechnicalInformation({ classes }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [report, setReport] = useState(undefined);

  const AllTechnicalHistoryReport = async () => {
    try {
      setLoading(true);
      const doc = await Utils.SmallStatistics();
      console.log("el doc", doc);
      if (doc.data) {
        setLoading(false);
        toasType.success("Su reporte esta listo para Descargar");
        const blob = new Blob(["\ufeff", doc.data]);
        setReport(blob);
      } else {
        toasType.error("No fue posible generar su reporte SSE");
        setLoading(false);
        setError(true);
      }
    } catch (e) {
      toasType.error("No fue posible generar su reporte");
    }
  };
  return (
    <>
      {!loading ? (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "12px",
            }}
          >
            {report ? (
              <Button
                variant="contained"
                color="primary"
                href={window.URL.createObjectURL(report)}
                disabled={error}
                download="Reporte estadística ligera de empresas.csv"
              >
                {error ? "No fue posible Generar su reporte" : "Descargar"}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  AllTechnicalHistoryReport();
                }}
              >
                Generar Reporte
              </Button>
            )}
          </div>
        </>
      ) : (
        <Skeleton className={classes.Skeleton} animation="wave" />
      )}
    </>
  );
}

export default withStyles(Classes)(AllTechnicalInformation);
