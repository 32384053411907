import React from "react";
import AllStates from "./AllStates";
import ListOfParticipant from "./ListOfParticipant";

class SelectTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  Refresh = async (id) => {
    this.props.Refresh(id);
  };
  render() {
    console.log(this.props.Type[0]);
    switch (this.props.Type[0]) {
      case 1:
        return (
          <ListOfParticipant
            List={this.props.List}
            Title="Suscritos"
            Type={1}
            Refresh={this.Refresh}
          />
        );

      case 2:
        return (
          <ListOfParticipant
            List={this.props.List}
            Title="Aptos"
            Type={2}
            Refresh={this.Refresh}
          />
        );

      case 3:
        return (
          <ListOfParticipant
            List={this.props.List}
            Title="No Aptos"
            Type={3}
            Refresh={this.Refresh}
          />
        );

      case 4:
        return (
          <ListOfParticipant
            List={this.props.List}
            Title="Verificando Documentos"
            Type={4}
            Refresh={this.Refresh}
          />
        );

      case 5:
        return (
          <ListOfParticipant
            List={this.props.List}
            Title="Rechazados"
            Type={5}
            Refresh={this.Refresh}
          />
        );

      default:
        return <AllStates List={this.props.List} />;
    }
  }
}

export default SelectTable;
