import TecnicalAll from "./TecnicalAll";
import TecnicalHistory from "./TecnicalHistory";
import TecnicalSingular from "./TecnicalSingular";
import MembershipTokens from "./MembershipTokens";
import AllWeekFeeds from "./AllWeekFeeds";
import GeneralReports from "./generalReports";
import SmallStatistics from "./SmallStatistics";
import fullProfileMoms from "./fullProfileMoms";
import companyMoms from "./companyMoms";
import AllMoms from "./AllMoms";
import AllPadrones from "./AllPadrones";
import ThreeMonthReport from "./ThreeMonthReport";
import AllSons from "./AllSons";
import AdvisorSons from "./AdvisorSons";
import AdvisorMoms from "./AdvisorMoms";

const Reports = {
  TecnicalAll,
  TecnicalHistory,
  TecnicalSingular,
  MembershipTokens,
  AllWeekFeeds,
  GeneralReports,
  SmallStatistics,
  fullProfileMoms,
  companyMoms,
  AllMoms,
  AllPadrones,
  ThreeMonthReport,
  AllSons,
  AdvisorSons,
  AdvisorMoms,
};

export default Reports;
