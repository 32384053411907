import React, { Component } from "react";
import {
  Typography,
  Button,
  Grid,
  Paper,
  withStyles,
  Box,
  TextField,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import LinearProgress from "@material-ui/core/LinearProgress";
import VotingCall from "../../../API/Voting";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import NoVoteList from "./DialogNoVoteList";
import VoteList from "./DialogVoteList";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    //display:"flex",
    //justifyContent:"end",
    //marginBottom:"10px"
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    marginTop: "10px",
    //paddingTop:"5px"
  },
  paperFilter: {
    width: "90%",
    padding: "2%",
  },
  formControl: {
    width: "50%",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  SectionPaper: {
    width: "100%",
    padding: "1%",
    //marginTop:"1%",
    marginBottom: "2%",
  },
  actionBtn: {
    margin: "5px",
  },
  Skeleton: {
    width: "100%",
    height: "200px",
    //marginTop:"20px"
    borderRadius: "0px 0px 10px 10px",
  },
  Subtitle: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  GridSectionItem: {
    //marginTop:"15px"
  },
  Subtitle2: {
    borderRadius: "5px 5px 5px 5px",
    backgroundColor: "#7f8c8d",
    color: "rgb(247, 244, 244)",
    marginTop: "5px",
  },
  ImgPlaceholder: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  ImgProp: {
    width: "100%",
    height: "100%",
    //padding:"1px",
    borderRadius: "2px 2px 2px 2px",
  },
  btn: {
    width: "100%",
  },
  Table: {
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "150px",
  },
  TimePicker: {
    marginTop: "10px",
    width: "100%",
    minHeight: "140px",
    padding: "2%",
  },
  DataTimePicker: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  BtnSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "5px",
  },
  PaperDialogNewCandidate: {
    padding: "5px",
  },
  SubSection: {
    width: "100%",
    marginTop: "10px",
    minHeight: "200px",
    maxHeight: "300px",
  },
  SubSectionBottom: {
    width: "100%",
    marginTop: "2px",
    minHeight: "100px",
    maxHeight: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  SubSectionTop: {
    width: "100%",
    marginTop: "2px",
    minHeight: "100px",
    maxHeight: "50%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  TextShow: {
    width: "100%",
    marginTop: "10px",
    marginLeft: "10px",
    minHeight: "50px",
    maxHeight: "33%",
  },
  BtnVotes: {
    margin: "5px",
  },
  TextShow1: {
    width: "90%",
    marginTop: "10px",
    marginLeft: "10px",
    minHeight: "50px",
    maxHeight: "33%",
  },
});

function CircularProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={3}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class ElectionsTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TimeInfo: {},
      VoteInfo: {
        TotalVoter: "",
        Voted: "",
        NonVoted: "",
        VotedPercent: 0,
        nonVotedPercent: 0,
      },
    };
  }
  componentDidMount = async () => {
    console.log(this.props.ElectionInfo);
    try {
      if (this.props.ElectionInfo) {
        this.GetTimes(
          this.props.ElectionInfo.StartElecction,
          this.props.ElectionInfo.EndElection
        );
        this.interval = setInterval(() => {
          this.GetVotes(this.props.ElectionInfo.uuid);
        }, 500);
      } else {
        console.log("error", this.props.ElectionInfo);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  GetTimes = (end, vote) => {
    let startVotes = moment(end);
    let endVotes = moment(vote);
    let now = moment();

    let TimeConsume = now.diff(startVotes, "hours", true);
    let TimeLeft = endVotes.diff(now, "hours", true);
    let TotalTimeR = endVotes.diff(startVotes, "hours", true);

    let LeftPercent = (TimeLeft / TotalTimeR) * 100;
    let ConsumePercent = (TimeConsume / TotalTimeR) * 100;

    this.setState({
      TimeInfo: {
        TotalTime: Math.round(TotalTimeR) + " horas",
        usedTime: Math.round(TimeConsume) + " horas",
        restTime: Math.round(TimeLeft) + " horas",
        LeftPercent: Math.round(LeftPercent),
        ConsumePercent: Math.round(ConsumePercent),
      },
    });
  };
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  GetVotes = async (uuid) => {
    try {
      const Votes = await VotingCall.GetVotes(uuid);
      if (Votes.status === "success") {
        //console.log("Lo que retorna endpoint de votos", Votes);
        var VotesC = Votes.data.votes.votes.map((type) => {
          return type.amount;
        });
        VotesC = VotesC.reduce((a, b) => a + b, 0);
        //console.log(Votes.data.votes.);
        const TotalVotes = Votes.data.votes.totalPossibleVotants;
        let PercentVotes = (VotesC / TotalVotes) * 100;
        this.setState({
          VoteInfo: {
            TotalVoter: TotalVotes,
            Voted: VotesC,
            NonVoted: TotalVotes - VotesC,
            VotedPercent: PercentVotes,
            nonVotedPercent: 100 - PercentVotes,
          },
        });
      } else {
        toastType.error("Error en el servidor");
      }
    } catch (error) {
      //toastType.error("Error de conexión");
      console.log("El error", error);
    }
  };
  render() {
    const { classes } = this.props;
    if (this.props.onlyVotes) {
      return (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.ElectionsVotes}
          >
            <Typography
              align="Center"
              variant="h5"
              color="textPrimary"
              component="p"
              className={classes.Subtitle2}
            >
              Votos de la elección
            </Typography>
            <Paper className={classes.SubSection}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.ElectionsTimes}
                >
                  <TextField
                    label="Total de votantes"
                    value={this.state.VoteInfo.TotalVoter}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Votos Emitidos"
                    value={this.state.VoteInfo.Voted}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Votos No emitidos"
                    value={this.state.VoteInfo.NonVoted}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.ElectionsTimes}
                >
                  <div className={classes.TextShow}></div>
                  <CircularProgressWithLabel
                    value={this.state.VoteInfo.VotedPercent}
                    className={classes.TextShow}
                  />
                  <CircularProgressWithLabel
                    color="secondary"
                    value={this.state.VoteInfo.nonVotedPercent}
                    className={classes.TextShow}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.ElectionsTimes}
          >
            <Typography
              align="Center"
              variant="h5"
              color="textPrimary"
              component="p"
              className={classes.Subtitle2}
            >
              Tiempos de la elección
            </Typography>
            <Paper className={classes.SubSection}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.ElectionsTimes}
                >
                  <TextField
                    label="Tiempo total de la elección"
                    value={this.state.TimeInfo.TotalTime}
                    className={classes.TextShow1}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Inicio de la convocatoria"
                    value={
                      this.props.ElectionInfo.StartAnnouncement.split("T")[0] +
                      " " +
                      this.props.ElectionInfo.StartAnnouncement.split(
                        "T"
                      )[1].split(".")[0]
                    }
                    className={classes.TextShow1}
                    //variant="outlined"
                    size="small"
                  />{" "}
                  <TextField
                    label="Final de la convocatoria"
                    value={
                      this.props.ElectionInfo.EndAnnouncement.split("T")[0] +
                      " " +
                      this.props.ElectionInfo.EndAnnouncement.split(
                        "T"
                      )[1].split(".")[0]
                    }
                    className={classes.TextShow1}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Fecha de inicio"
                    value={
                      this.props.ElectionInfo.StartElecction.split("T")[0] +
                      " " +
                      this.props.ElectionInfo.StartElecction.split(
                        "T"
                      )[1].split(".")[0]
                    }
                    className={classes.TextShow1}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Fecha de Terminación"
                    value={
                      this.props.ElectionInfo.EndElection.split("T")[0] +
                      " " +
                      this.props.ElectionInfo.EndElection.split("T")[1].split(
                        "."
                      )[0]
                    }
                    className={classes.TextShow1}
                    //variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.ElectionsTimes}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<ThumbUpIcon />}
              onClick={this.Dialog.bind(this, "VoteListDialog")}
              className={classes.BtnVotes}
            >
              Lista de personal que voto
            </Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<ThumbDownAltIcon />}
              onClick={this.Dialog.bind(this, "NoVoteListDialog")}
              className={classes.BtnVotes}
            >
              Lista de personal que NO Voto
            </Button>
          </Grid>
          {this.state.NoVoteListDialog
            ? [
                <NoVoteList
                  DialogOpen={this.state.NoVoteListDialog}
                  DialogClose={this.Dialog.bind(this, "NoVoteListDialog")}
                  List={this.props.NoVotesList}
                  uuid={this.props.ElectionInfo.uuid}
                />,
              ]
            : [<></>]}
          {this.state.VoteListDialog
            ? [
                <VoteList
                  DialogOpen={this.state.VoteListDialog}
                  DialogClose={this.Dialog.bind(this, "VoteListDialog")}
                  List={this.props.VotesList}
                  uuid={this.props.ElectionInfo.uuid}
                />,
              ]
            : [<></>]}
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.ElectionsVotes}
          >
            <Typography
              align="Center"
              variant="h5"
              color="textPrimary"
              component="p"
              className={classes.Subtitle2}
            >
              Votos de la elección
            </Typography>
            <Paper className={classes.SubSection}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.ElectionsTimes}
                >
                  <TextField
                    label="Total de votantes"
                    value={this.state.VoteInfo.TotalVoter}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Votos Emitidos"
                    value={this.state.VoteInfo.Voted}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Votos restantes"
                    value={this.state.VoteInfo.NonVoted}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.ElectionsTimes}
                >
                  <div className={classes.TextShow}></div>
                  <CircularProgressWithLabel
                    value={this.state.VoteInfo.VotedPercent}
                    className={classes.TextShow}
                  />
                  <CircularProgressWithLabel
                    color="secondary"
                    value={this.state.VoteInfo.nonVotedPercent}
                    className={classes.TextShow}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.ElectionsTimes}
          >
            <Typography
              align="Center"
              variant="h5"
              color="textPrimary"
              component="p"
              className={classes.Subtitle2}
            >
              Tiempos de la elección
            </Typography>
            <Paper className={classes.SubSection}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.ElectionsTimes}
                >
                  <TextField
                    label="Tiempo total de la elección"
                    value={this.state.TimeInfo.TotalTime}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Tiempo restante"
                    value={this.state.TimeInfo.restTime}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                  <TextField
                    label="Tiempo consumiendo"
                    value={this.state.TimeInfo.usedTime}
                    className={classes.TextShow}
                    //variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className={classes.ElectionsTimes}
                >
                  <div className={classes.TextShow}></div>
                  <CircularProgressWithLabel
                    value={this.state.TimeInfo.LeftPercent}
                    className={classes.TextShow}
                  />
                  <CircularProgressWithLabel
                    color="secondary"
                    value={this.state.TimeInfo.ConsumePercent}
                    className={classes.TextShow}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.ElectionsTimes}
            spacing={1}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<ThumbUpIcon />}
              onClick={this.Dialog.bind(this, "VoteListDialog")}
              className={classes.BtnVotes}
            >
              Votantes
            </Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<ThumbDownAltIcon />}
              onClick={this.Dialog.bind(this, "NoVoteListDialog")}
              className={classes.BtnVotes}
            >
              No votantes
            </Button>
          </Grid>
          {this.state.NoVoteListDialog
            ? [
                <NoVoteList
                  DialogOpen={this.state.NoVoteListDialog}
                  DialogClose={this.Dialog.bind(this, "NoVoteListDialog")}
                  List={this.props.NoVotesList}
                  uuid={this.props.ElectionInfo.uuid}
                />,
              ]
            : [<></>]}
          {this.state.VoteListDialog
            ? [
                <VoteList
                  DialogOpen={this.state.VoteListDialog}
                  DialogClose={this.Dialog.bind(this, "VoteListDialog")}
                  List={this.props.VotesList}
                  uuid={this.props.ElectionInfo.uuid}
                />,
              ]
            : [<></>]}
        </Grid>
      );
    }
  }
}

export default withStyles(styles)(ElectionsTimes);
