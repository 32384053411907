import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import ProgramsCall from "../../../API/CTM/NewPrograms";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";
import CallUtils from "../../../API/CallUtils";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "80vh",
    overflow: "auto",
    padding: "10px",
  },
  Gridlateral: {
    width: "100%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  gridSub: {
    padding: "2%",
    overflow: "auto",
  },
  input: {
    display: "none",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
    height: "10%",
  },
  Grid: {
    padding: "5px",
  },
});

class Benefits extends React.Component {
  state = {
    programs: [],
    Title: "",
    Description: "",
    Content: "",
    ProgramIMG: null,
    SubPrograms: [],
    DialogProgram: false,

    SubTitle: "",
    SubContent: "",
    SubImg: "",
    SubProgramsIMGS: [],

    btn: "crear",
    index: "",
    FileName: "",
    actions: false,
    SubUuid: "",

    ImgUrl: null,
    ImgDescription: "",
    DialogImg: false,
    Imgs: [],

    indexSub: 0,
    indexProgram: 0,
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  showAll = async () => {
    try {
      const polute = await ProgramsCall.showAll();
      if (!polute) {
        toasType.error("No fue posible traer la información");
      }

      this.setState({ programs: polute.programs });
    } catch (e) {}
    toasType.error("No fue posible traer la información");
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  update = async () => {
    try {
      var Update = await ProgramsCall.Update(this.state);

      if (Update.status === "success") {
        toasType.success("Información Guardada");
      } else {
        toasType.error("No fue posible actualizar");
      }
      await this.showAll();
    } catch (e) {
      toasType.error("No fue posible actualizar");
    }
  };

  addProgram = async () => {
    var newelement = {
      title: this.state.Title,
      content: this.state.Content,
      description: this.state.Description,

      img: this.state.ProgramIMG,
      subProgramsData: this.state.SubPrograms,
    };

    this.setState({
      programs: [...this.state.programs, newelement],
    });
    this.setState({
      DialogProgram: false,
      Title: "",
      Description: "",
      Content: "",
      ProgramIMG: null,
      SubPrograms: [],
    });
  };

  addSubProgram = async () => {
    var newelement = {
      title: this.state.SubTitle,
      content: this.state.SubContent,
      img: this.state.SubImg,
      ImgList: this.state.Imgs,
    };

    this.setState({
      SubPrograms: [...this.state.SubPrograms, newelement],
    });
    this.setState({
      DialogSubProgram: false,
      SubTitle: "",
      SubContent: "",
      SubImg: "",
      Imgs: [],
    });
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };

  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };

  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };

  DialogClose = async (name) => {
    this.setState({ [name]: false });

    this.setState({
      DialogEditProgram: false,
      Title: "",
      Description: "",
      Content: "",
      ProgramIMG: null,
      SubPrograms: [],
      indexProgram: 0,
    });

    this.setState({
      DialogEditSubProgram: false,
      SubTitle: "",
      SubContent: "",
      SubImg: "",
      Imgs: [],
      indexSub: 0,
    });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };
  principalIMG = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "SITIMM_PROGRAMS_PRINCIPAL_IMG"
    );
    this.setState({
      ProgramIMG: principal,
    });
  };

  subImg = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "SITIMM_SUBPROGRAMS_PRINCIPAL_IMG"
    );
    this.setState({
      SubImg: principal,
    });
  };

  SubC = (a, b, c, d, f) => {
    this.setState({
      SubTitle: a,
      SubContent: b,
      SubImg: c,
      Imgs: d,
      indexSub: f,
      DialogEditSubProgram: true,
    });
  };

  ProgramsEDIT = (a, b, c, d, e, f) => {
    this.setState({
      Title: a,
      Content: b,
      Description: c,
      ProgramIMG: d,
      SubPrograms: e,
      indexProgram: f,
      DialogEditProgram: true,
    });
  };

  addIMG = async (e) => {
    var newelement = {
      ImgUrl: this.state.ImgUrl,
      ImgDescripcion: this.state.ImgDescription,
    };

    this.setState({
      Imgs: [...this.state.Imgs, newelement],
    });
    this.setState({
      DialogImg: false,
      ImgDescription: "",
      ImgUrl: null,
    });
  };
  addIMGD = async (e) => {
    let principal = await this.SendImg(e.target.files[0], "SITIMM_SUB_LIST");
    this.setState({
      ImgUrl: principal,
    });
  };

  EditProgram = async () => {
    var newelement = {
      title: this.state.Title,
      content: this.state.Content,
      description: this.state.Description,
      img: this.state.ProgramIMG,
      subProgram: this.state.SubPrograms,
    };

    let NPrograms = [...this.state.programs];

    NPrograms[this.state.indexProgram] = newelement;

    this.setState({
      programs: NPrograms,
    });

    this.setState({
      DialogEditProgram: false,
      Title: "",
      Description: "",
      Content: "",
      ProgramIMG: null,
      SubPrograms: [],
      indexProgram: 0,
    });
  };

  EditSubProgram = async () => {
    var newelement = {
      title: this.state.SubTitle,
      content: this.state.SubContent,
      img: this.state.SubImg,
      ImgList: this.state.Imgs,
    };

    let NSubPrograms = [...this.state.SubPrograms];

    NSubPrograms[this.state.indexSub] = newelement;

    this.setState({
      SubPrograms: NSubPrograms,
    });

    this.setState({
      DialogEditSubProgram: false,
      SubTitle: "",
      SubContent: "",
      SubImg: "",
      Imgs: [],
      indexSub: 0,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        {this.state.programs ? (
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {this.state.programs.map((Article, index) => {
              return (
                <React.Fragment>
                  <Grid
                    item
                    xs={4}
                    className={classes.Grid}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <TextField
                      id="standard-basic"
                      label="Titulo"
                      name="MisionTitle"
                      value={Article.title}
                    />
                    <TextField
                      id="standard-read-only-input"
                      label="Resumen"
                      className={classes.text}
                      margin="normal"
                      name="MisionText"
                      multiline
                      rows="6"
                      value={Article.description}
                      variant="outlined"
                    />
                    <TextField
                      id="standard-read-only-input"
                      label="contenido"
                      className={classes.text}
                      margin="normal"
                      name="MisionText"
                      multiline
                      rows="6"
                      value={Article.content}
                      variant="outlined"
                    />
                    {Article.img !== null ? (
                      <div className={classes.imgDiv}>
                        <img
                          src={Article.img}
                          className={classes.ImgPre}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div>
                        <Typography>Sin imagen Seleccionada</Typography>
                      </div>
                    )}
                    <div className={classes.btnTable}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.addBTN}
                        onClick={this.ProgramsEDIT.bind(
                          this,
                          Article.title,
                          Article.content,
                          Article.description,
                          Article.img,
                          Article.subProgram,
                          index
                        )}
                      >
                        <EditIcon />
                      </Button>

                      <Button
                        color="Secondary"
                        variant="contained"
                        className={classes.addBTN}
                        onClick={this.remove.bind(this, index, "programs")}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          <Paper className={classes.Table}>
            <Typography>Sin programas para mostrar</Typography>
          </Paper>
        )}

        <div>
          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.DialogOpen.bind(this, "DialogProgram")}
            >
              <AddBoxIcon />
            </Button>
          </div>
        </div>
        <div className={classes.Remp}>
          <Button
            color="primary"
            variant="contained"
            className={classes.addBTN}
            onClick={this.update}
          >
            guardar
          </Button>
        </div>

        <Dialog
          open={this.state.DialogProgram}
          onClose={this.DialogClose.bind(this, "DialogProgram")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Crear Programa</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <TextField
                id="standard-basic"
                label="Titulo"
                name="Title"
                value={this.state.Title}
                variant="outlined"
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Resumen"
                className={classes.text}
                margin="normal"
                name="Description"
                multiline
                rows="6"
                value={this.state.Description}
                variant="outlined"
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Contenido"
                className={classes.text}
                margin="normal"
                name="Content"
                multiline
                rows="6"
                value={this.state.Content}
                variant="outlined"
                onChange={this.onChange}
              />
              {this.state.ProgramIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.ProgramIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="form-group">
                <input
                  type="file"
                  accept=".jpg, .png"
                  onChange={this.principalIMG}
                  className={classes.imgDiv}
                />
              </div>

              <Grid
                item
                xs={12}
                className={classes.Gridlateral}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
              ></Grid>
              <Grid
                item
                xs={8}
                className={classes.gridSub}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                {this.state.SubPrograms !== null ? (
                  <Paper className={classes.Table}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Modificar</TableCell>
                          <TableCell>Eliminar</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.SubPrograms.map((Article, index) => {
                          return (
                            <React.Fragment>
                              <TableRow key={Article.uuid}>
                                <TableCell component="th" scope="row">
                                  {Article.title}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="primary"
                                    onClick={this.SubC.bind(
                                      this,
                                      Article.title,
                                      Article.content,
                                      Article.img,
                                      Article.ImgList,
                                      index
                                    )}
                                  >
                                    <EditIcon />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    onClick={this.remove.bind(
                                      this,
                                      index,
                                      "SubPrograms"
                                    )}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <Paper className={classes.Table}></Paper>
                )}
                <div className={classes.btnTable}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addBTN}
                    onClick={this.DialogOpen.bind(this, "DialogSubProgram")}
                  >
                    <AddBoxIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogProgram")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addProgram} color="primary">
              Crear Programa
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogSubProgram}
          onClose={this.DialogClose.bind(this, "DialogSubProgram")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Crear subPrograma</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Titulo"
                  className={classes.text}
                  margin="normal"
                  name="SubTitle"
                  value={this.state.SubTitle}
                  onChange={this.onChange}
                  variant="outlined"
                />

                <TextField
                  id="standard-read-only-input"
                  label="Contenido"
                  className={classes.text}
                  margin="normal"
                  name="SubContent"
                  multiline
                  rows="6"
                  value={this.state.SubContent}
                  onChange={this.onChange}
                  variant="outlined"
                />
                {this.state.SubImg !== "" ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.SubImg}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="form-group">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={this.subImg}
                    className={classes.imgDiv}
                  />
                </div>
              </Grid>
              <div className={classes.SectionTable}>
                <Typography className={classes.Section}>
                  Lista de Imágenes
                </Typography>
                {this.state.Imgs.length > 0 ? (
                  <Paper className={classes.Table}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Pie de imagen</TableCell>
                          <TableCell>Imagen</TableCell>
                          <TableCell>Subir</TableCell>
                          <TableCell>Bajar</TableCell>
                          <TableCell>Eliminar</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.Imgs.map((Article, index) => {
                          return (
                            <React.Fragment>
                              <TableRow key={Article.uuid}>
                                <TableCell component="th" scope="row">
                                  {Article.ImgDescripcion}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <img
                                    src={Article.ImgUrl}
                                    style={{ width: "100px", height: "100px" }}
                                    alt=""
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="primary"
                                    onClick={this.Up.bind(this, index, "Imgs")}
                                  >
                                    <ArrowUp />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    onClick={this.Down.bind(
                                      this,
                                      index,
                                      "Imgs"
                                    )}
                                  >
                                    <ArrowDown />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    onClick={this.remove.bind(
                                      this,
                                      index,
                                      "Imgs"
                                    )}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <Paper className={classes.Table}></Paper>
                )}

                <div className={classes.Remp}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                    onClick={this.DialogOpen.bind(this, "DialogImg")}
                  >
                    <AddBoxIcon />
                  </Button>
                </div>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogSubProgram")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addSubProgram} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.Dialog}
          onClose={this.DialogEntrando}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          fullWidth="xl"
        >
          <DialogTitle id="form-dialog-title">Modificar Programas</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <TextField
                id="standard-basic"
                label="Titulo"
                name="title"
                value={this.state.title}
                variant="outlined"
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Resumen"
                className={classes.text}
                margin="normal"
                name="description"
                multiline
                rows="6"
                value={this.state.description}
                variant="outlined"
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Contenido"
                className={classes.text}
                margin="normal"
                name="content"
                multiline
                rows="6"
                value={this.state.content}
                variant="outlined"
                onChange={this.onChange}
              />
              {this.state.img !== null ? (
                <div className={classes.imgDiv}>
                  <img src={this.state.img} className={classes.ImgPre} alt="" />
                </div>
              ) : (
                <div></div>
              )}
              <div className="form-group">
                <input
                  type="file"
                  accept=".jpg, .png"
                  onChange={this.uploadSingleFile}
                  className={classes.imgDiv}
                />
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogEntrando} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.update} color="primary">
              Guardar Cambios
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogImg}
          onClose={this.DialogClose.bind(this, "DialogImg")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Imagen</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.imgDiv}>
                  <Typography>Imagen</Typography>
                </div>

                {this.state.ImgUrl !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.ImgUrl}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGD}
                      className={classes.input}
                      id="files7"
                      required
                    />
                    <label htmlFor="files7">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <TextField
                  id="standard-basic"
                  label="Pie de pagìna "
                  name="ImgDescription"
                  value={this.state.ImgDescription}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogImg")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addIMG} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogEditSubProgram}
          onClose={this.DialogClose.bind(this, "DialogEditSubProgram")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Editar subPrograma</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Titulo"
                  className={classes.text}
                  margin="normal"
                  name="SubTitle"
                  value={this.state.SubTitle}
                  onChange={this.onChange}
                  variant="outlined"
                />

                <TextField
                  id="standard-read-only-input"
                  label="Contenido"
                  className={classes.text}
                  margin="normal"
                  name="SubContent"
                  multiline
                  rows="6"
                  value={this.state.SubContent}
                  onChange={this.onChange}
                  variant="outlined"
                />
                {this.state.SubImg !== "" ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.SubImg}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="form-group">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={this.subImg}
                    className={classes.imgDiv}
                  />
                </div>
              </Grid>
              <div className={classes.SectionTable}>
                <Typography className={classes.Section}>
                  Lista de Imágenes
                </Typography>
                {this.state.Imgs.length > 0 ? (
                  <Paper className={classes.Table}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Pie de imagen</TableCell>
                          <TableCell>Imagen</TableCell>
                          <TableCell>Subir</TableCell>
                          <TableCell>Bajar</TableCell>
                          <TableCell>Eliminar</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.Imgs.map((Article, index) => {
                          return (
                            <React.Fragment>
                              <TableRow key={Article.uuid}>
                                <TableCell component="th" scope="row">
                                  {Article.ImgDescripcion}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <img
                                    src={Article.ImgUrl}
                                    style={{ width: "100px", height: "100px" }}
                                    alt=""
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="primary"
                                    onClick={this.Up.bind(this, index, "Imgs")}
                                  >
                                    <ArrowUp />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    onClick={this.Down.bind(
                                      this,
                                      index,
                                      "Imgs"
                                    )}
                                  >
                                    <ArrowDown />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    onClick={this.remove.bind(
                                      this,
                                      index,
                                      "Imgs"
                                    )}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <Paper className={classes.Table}></Paper>
                )}

                <div className={classes.Remp}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                    onClick={this.DialogOpen.bind(this, "DialogImg")}
                  >
                    <AddBoxIcon />
                  </Button>
                </div>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogEditSubProgram")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.EditSubProgram} color="primary">
              Modificar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogEditProgram}
          onClose={this.DialogClose.bind(this, "DialogEditProgram")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Editar</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <TextField
                id="standard-basic"
                label="Titulo"
                name="Title"
                value={this.state.Title}
                variant="outlined"
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Resumen"
                className={classes.text}
                margin="normal"
                name="Description"
                multiline
                rows="6"
                value={this.state.Description}
                variant="outlined"
                onChange={this.onChange}
              />
              <TextField
                id="standard-read-only-input"
                label="Contenido"
                className={classes.text}
                margin="normal"
                name="Content"
                multiline
                rows="6"
                value={this.state.Content}
                variant="outlined"
                onChange={this.onChange}
              />
              {this.state.ProgramIMG !== null ? (
                <div className={classes.imgDiv}>
                  <img
                    src={this.state.ProgramIMG}
                    className={classes.ImgPre}
                    alt=""
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div className="form-group">
                <input
                  type="file"
                  accept=".jpg, .png"
                  onChange={this.principalIMG}
                  className={classes.imgDiv}
                />
              </div>

              <Grid
                item
                xs={12}
                className={classes.Gridlateral}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
              ></Grid>
              <Grid
                item
                xs={8}
                className={classes.gridSub}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                {this.state.SubPrograms !== null ? (
                  <Paper className={classes.Table}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Modificar</TableCell>
                          <TableCell>Eliminar</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.SubPrograms.map((Article, index) => {
                          return (
                            <React.Fragment>
                              <TableRow key={Article.uuid}>
                                <TableCell component="th" scope="row">
                                  {Article.title}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="primary"
                                    onClick={this.SubC.bind(
                                      this,
                                      Article.title,
                                      Article.content,
                                      Article.img,
                                      Article.ImgList,
                                      index
                                    )}
                                  >
                                    <EditIcon />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    onClick={this.remove.bind(
                                      this,
                                      index,
                                      "SubPrograms"
                                    )}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <Paper className={classes.Table}></Paper>
                )}
                <div className={classes.btnTable}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addBTN}
                    onClick={this.DialogOpen.bind(this, "DialogSubProgram")}
                  >
                    <AddBoxIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogEditProgram")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.EditProgram} color="primary">
              Modificar Programa
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Benefits);
