import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const styles = (theme) => ({
  FormControl: {
    width: "100%",
    marginBottom: "8px",
    marginTop: "8px",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function SelectRegis(props) {
  return (
    <FormControl component="fieldset" className={props.classes.FormControl}>
      <FormLabel component="legend">Información de los Afiliados</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.raw}
              onChange={(e) => {
                props.sentoParent("raw", !props.raw);
              }}
            />
          }
          label="Solicitar detalles"
        />
      </FormGroup>
    </FormControl>
  );
}

export default withStyles(styles)(SelectRegis);
