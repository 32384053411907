import base from "../baseurl";
import token from "../getTk"

const SaveCompanies = async (Uuid,Companies) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
  console.log(Companies,"Uuid",Uuid)
    const SavCompanies = await api.post("sections/update",{json: {
      advisorUuid:Uuid,
      companiesUuid:Companies,
    }}).json();
    console.log(SavCompanies)
    return SavCompanies;
  };
  
  export default  SaveCompanies ;