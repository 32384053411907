import ShowAll from "./ShowAll";
import Show from "./Show";
import Create from "./Create";
import Delete from "./Delete";
import Update from "./Update";

const SportEvents = {
  ShowAll,
  Create,
  Delete,
  Show,
  Update,
};

export default SportEvents;
