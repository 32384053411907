import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import BusStop from "../../../API/Permissions";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogCreateEvent from "./AddStopBus";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  Margin: {
    margin: "5px",
    padding: "5px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Oncreate: false,
      can: [],
      cannot: [],
      type: null,
      needUpdate: false,
    };
  }
  componentDidMount = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await BusStop.Show(this.props.id);
      if (Deli.status === "success") {
        if (Deli.data.permissions) {
          this.setState({
            can: Deli.data.permissions.can,
            cannot: Deli.data.permissions.cannot,
          });
        } else {
          this.setState({ needUpdate: true });
        }
        this.setState({
          onLoad: false,
        });
      } else {
        toastType.error("SSE No fue posible Traer la información");
        this.setState({
          onError: true,
          onLoad: false,
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Traer la información");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
      });
    }
  };
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });
      let permissions;
      if (this.state.needUpdate) {
        permissions = BusStop.Create;
      } else {
        permissions = BusStop.Update;
      }
      const Deli = await permissions(
        this.props.id,
        this.state.can,
        this.state.cannot
      );
      if (Deli.status === "success") {
        this.setState({
          Oncreate: false,
        });
        toastType.success("Se creado con éxito");
        //await this.props.Refresh("CreateDialog");
      } else {
        this.setState({
          Oncreate: false,
        });
        toastType.error("SSE No fue Posible Crear el elemento");
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Crear el evento");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  Dialog = (a, b, c) => {
    this.setState({
      [a]: !this.state[a],
      type: b ? b : null,
      permission: c ? c : null,
    });
  };
  GetFromChild = (type, url, action) => {
    console.log(type, url, action);
    console.log(this.state[type]);
    let temporal = this.state[type];
    temporal.push(`/api${url}${action}`);
    this.setState({ [type]: temporal });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Permisos y Denegaciones
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {this.state.can.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Entidad</TableCell>
                      <TableCell>Acción</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.can.map((Article, index) => {
                      return (
                        <React.Fragment key={Article}>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {Article.split("/")[1]}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.split("/")[2]}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                variant="contained"
                                className={classes.btn}
                                onClick={() => {
                                  let value = this.state.can.filter(
                                    (item, j) => index !== j
                                  );
                                  this.setState({ can: value });
                                }}
                              >
                                <CloseIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.NoItemPaper}>
                Sin Permisos Asignados
              </Paper>
            )}
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className={classes.Margin}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.Dialog.bind(
                  this,
                  "CreateDialog",
                  "can",
                  "Permission"
                )}
              >
                <AddIcon />
              </Button>
            </Grid>
            {this.state.CreateDialog && (
              <DialogCreateEvent
                DialogOpen={this.state.CreateDialog}
                DialogClose={this.Dialog.bind(this, "CreateDialog")}
                Refresh={this.Refresh}
                id={this.state.Id}
                type={this.state.type}
                SendToParent={this.GetFromChild}
                permission={this.state.permission}
              />
            )}
          </div>
          <div className={classes.paper}>
            {this.state.cannot.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Entidad</TableCell>
                      <TableCell>Acción</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.cannot.map((Article, index) => {
                      return (
                        <React.Fragment key={Article}>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {Article}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.split("/")[1]}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                variant="contained"
                                className={classes.btn}
                                onClick={() => {
                                  let value = this.state.cannot.filter(
                                    (item, j) => index !== j
                                  );
                                  this.setState({ cannot: value });
                                }}
                              >
                                <CloseIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.NoItemPaper}>
                Sin Denegaciones Asignadas
              </Paper>
            )}
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className={classes.Margin}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.Dialog.bind(
                  this,
                  "CreateDialog",
                  "cannot",
                  "Denegation"
                )}
              >
                <AddIcon />
              </Button>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.can && this.state.cannot
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    Guardar
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  Guardar
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
