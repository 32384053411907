import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
  Avatar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import UsersCall from "../../API/Users";
import Skeleton from "@material-ui/lab/Skeleton";
import EditProfile from "./EditDialog";
import EditPassword from "./PasswordDialog";
import EditIcon from "@material-ui/icons/Edit";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "70%",
    padding: "2%",
  },

  Grid: {
    width: "100%",
    height: "100%",
  },

  textField: {
    width: "88%",
  },

  div: {
    display: "flex",
    width: "96%",
    justifyContent: "end",
    marginTop: "3%",
  },

  btn: {
    marginRight: "10px",
  },

  DatosDiv: {
    width: "100%",
  },
  DivAgeC: {
    width: "50%",
  },

  color: {
    color: "#9e9e9e",
  },
  formControl: {
    width: "85%",
  },
  Skeleton: {
    //width: "400px",
    //height: "500px",
    marginTop: "10px",
  },
  Margin: {
    padding: "1%",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  Full: { width: "100%" },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
  };
};

class UsersProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditDialog: false,
      State: "OnLoad",
      UserInfo: {},
      EditProfileDialog: false,
      EditPasswordDialog: false,
    };
  }
  componentDidMount = async () => {
    if (this.props.Uuid) {
      await this.fetchProfile(this.props.Uuid);
    } else {
      this.props.history.push("/Usuarios");
    }
  };

  fetchProfile = async (Uuid) => {
    try {
      const UserProfile = await UsersCall.Profile(Uuid);
      console.log(UserProfile);
      if (UserProfile) {
        this.setState({ UserInfo: UserProfile, State: "OnSuccess" });
      } else {
        this.setState({ State: "OnError" });
      }
    } catch (e) {
      console.log(e);
      this.setState({ State: "OnError" });
    }
  };
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  Refresh = async (Dialog) => {
    await this.fetchProfile(this.props.Uuid);
    this.setState({ [Dialog]: false });
  };

  render() {
    const { classes } = this.props;
    switch (this.state.State) {
      case "OnLoad":
        return (
          <Paper className={classes.paper}>
            <Grid
              xs={12}
              className={classes.Grid}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                container
                iteam
                xs={12}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Skeleton
                  variant="circle"
                  animation="wave"
                  width={250}
                  height={118}
                />
              </Grid>
              <Grid iteam xs={12}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={500}
                  height={50}
                  className={classes.Skeleton}
                />
                <Skeleton
                  variant="rect"
                  width={500}
                  height={50}
                  animation="wave"
                  className={classes.Skeleton}
                />
                <Skeleton
                  variant="rect"
                  width={500}
                  height={50}
                  animation="wave"
                  className={classes.Skeleton}
                />
                <Skeleton
                  variant="rect"
                  width={500}
                  height={50}
                  animation="wave"
                  className={classes.Skeleton}
                />
                <Skeleton
                  variant="rect"
                  width={500}
                  height={50}
                  animation="wave"
                  className={classes.Skeleton}
                />
              </Grid>
            </Grid>
          </Paper>
        );
      case "OnError":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnSuccess":
        return (
          <Paper className={classes.paper}>
            <Grid
              className={classes.Grid}
              xs={12}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid
                  container
                  iteam
                  xs={12}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Avatar className={classes.large} />
                </Grid>
                <Grid
                  container
                  iteam
                  xs={12}
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <TextField
                    label="Nombre"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChange}
                    value={this.state.UserInfo.UserProfile.name}
                  />
                  <TextField
                    label="Apellidos"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChange}
                    value={this.state.UserInfo.UserProfile.lastNames}
                  />
                  <TextField
                    label="Sexo"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={this.onChange}
                    value={
                      this.state.UserInfo.UserProfile.sex
                        ? this.state.UserInfo.UserProfile.sex
                        : "N/A"
                    }
                  />
                  <TextField
                    label="Teléfono"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={this.state.UserInfo.UserProfile.mobilePhone}
                  />
                  <TextField
                    label="E-mail"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={this.state.UserInfo.email}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.Grid}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Link className={classes.Link} to="/Empresas">
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btn}
                    >
                      cancelar
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    color="Primary"
                    className={classes.btn}
                    onClick={this.Dialog.bind(this, "EditProfileDialog")}
                    startIcon={<EditIcon />}
                  >
                    Perfil
                  </Button>
                  <Button
                    variant="contained"
                    color="Primary"
                    className={classes.btn}
                    onClick={this.Dialog.bind(this, "EditPasswordDialog")}
                    startIcon={<EditIcon />}
                  >
                    Contraseña
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {this.state.EditProfileDialog
              ? [
                  <EditProfile
                    DialogOpen={this.state.EditProfileDialog}
                    DialogClose={this.Dialog.bind(this, "EditProfileDialog")}
                    Info={this.state.UserInfo}
                    Refresh={this.Refresh}
                  />,
                ]
              : [<></>]}
            {this.state.EditPasswordDialog
              ? [
                  <EditPassword
                    DialogOpen={this.state.EditPasswordDialog}
                    DialogClose={this.Dialog.bind(this, "EditPasswordDialog")}
                    Info={this.state.UserInfo}
                    Refresh={this.Refresh}
                  />,
                ]
              : [<></>]}
          </Paper>
        );
      default:
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(UsersProfile));
