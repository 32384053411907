import Create from "./Create";
import Update from "./Update";
import ShowAll from "./ShowAll";
import Show from "./Show"

const Headquarters = { ShowAll,Create,Update,Show };

export default Headquarters;

export {
    Create,
    Update,
    ShowAll,
    Show,
}