import base from "../baseurl";
import token from "../getTk"

const showAll = async (Uuid) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });

    const show = await api.post("bulletins/showall",{json: {
      companyUuid: Uuid
    }}).json(); 
    return show;
    
  };
  
  export default  showAll ;