import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Paper,
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Grid,
  Button,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import toastType from "../../../API/CallUtils/tostyNotification";
import Industrialparks from "../../../API/Industrialparks";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogCreateEvent from "./AddIndustrialPark";
import AddBoxIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Table: {
    width: "100%",
    padding: "1%",
  },
  Margin: {
    margin: "5px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Skeleton: {
    width: "100%",
    height: "400px",
    borderRadius: "10px 10px 10px 10px",
    marginTop: "1%",
  },
  ImgPre: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    display: "block",
  },
});
const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Sports extends Component {
  constructor(props) {
    super(props);
    this.state = { List: [], DialogCreateEvent: false, EventName: "" };
  }
  componentDidMount = async () => {
    this.fetchlist();
  };
  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Industrialparks.ShowAll();
      //console.log("Parques Indutriales", Deli);
      if (Deli.status === "success") {
        this.setState({
          List: Deli.data.industrialParks,
          onLoad: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "SSE No fue Posible Eliminar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  delete = async (id) => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Industrialparks.Delete(id);
      console.log("borrar parque", Deli);
      if (Deli.status === "success") {
        toastType.success("Se borro con éxito");
        await this.fetchlist();
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "SSE No fue Posible Eliminar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Dialog = (a, b, c) => {
    this.setState({
      [a]: !this.state[a],
      EventId: b ? b : null,
      EventName: c ? c : "",
    });
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <>
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        </>
      );
    } else if (this.state.onError) {
      return (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            Error
          </Typography>
          <Paper className={classes.NoItemPaper}>
            <Typography variant="body1" color="textPrimary" component="p">
              Error
            </Typography>
          </Paper>
          ,
        </>
      );
    } else {
      return (
        <div className={classes.paper}>
          {this.state.List.length > 0 ? (
            <Paper className={classes.Table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Borrar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.List.map((Article) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article.uuid}>
                          <TableCell component="th" scope="row">
                            {Article.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="secondary"
                              variant="contained"
                              className={classes.btn}
                              onClick={this.delete.bind(
                                this,
                                Article.id,
                                Article.name
                              )}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.Margin}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.Dialog.bind(this, "CreateDialog")}
                >
                  <AddIcon />
                </Button>
              </Grid>
              {this.state.CreateDialog
                ? [
                    <DialogCreateEvent
                      DialogOpen={this.state.CreateDialog}
                      DialogClose={this.Dialog.bind(this, "CreateDialog")}
                      Refresh={this.Refresh}
                      id={this.props.id}
                    />,
                  ]
                : [<></>]}
            </Paper>
          ) : (
            <Paper className={classes.NoItemPaper}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.Dialog.bind(this, "CreateDialog")}
                className={classes.AddCandidate}
              >
                <AddBoxIcon />
              </Button>
              {this.state.CreateDialog
                ? [
                    <DialogCreateEvent
                      DialogOpen={this.state.CreateDialog}
                      DialogClose={this.Dialog.bind(this, "CreateDialog")}
                      Refresh={this.Refresh}
                      id={this.props.id}
                    />,
                  ]
                : [<></>]}
            </Paper>
          )}
        </div>
      );
    }
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Sports));
