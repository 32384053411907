import base from "../../baseurl";
import token from "../../getTk"

const Create = async (State) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Update = await api.post("bonuses/create",{json: {
      business:State.bussiness,
      address:State.address,
      discount:State.off,
      city:State.city,
    }}).json();
   
    return Update ;
    
  };
  
  export default  Create ;