import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles, Grid, Typography } from "@material-ui/core";
import toasType from "../../../API/CallUtils/tostyNotification";
import Company from "../../../API/Companies";
import Header from "./HeaderInfo";
import Benefits from "./BenefitsInfo";
import CompanyData from "./CompanyData";
import CompanyOfical from "./CompanyOficialData";
import CompanyPersonal from "./CompanyPersonal";
import GeneralInfo from "./GeneralInfo";
import Commite from "./Commite";
import Statics from "./Statiscs";
import Admin from "./admistrative";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  Skeleton: {
    width: "100%",
    height: "200px",
    borderRadius: "10px 10px 10px 10px",
  },
  Section: {
    margin: "5px",
  },
  PricipalContainer: {
    padding: "1%",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Uuid: "",
      onFetch: true,
      onError: false,
      CompanyInfo: {},
    };
  }

  componentDidMount = async () => {
    if (this.props.location.state) {
      await this.fetchSeccions();
    } else {
      this.props.history.push("/Empresas");
    }
  };

  newfetch = async () => {
    toasType.success("Actualizando la información");
    await this.fetchSeccions();
  };

  fetchSeccions = async () => {
    //console.log('llego al inicial')
    try {
      const SeccionsShow = await Company.Show(
        this.props.location.state.companieUuid
      );
      if (SeccionsShow) {
        this.setState({
          onFetch: false,
          CompanyInfo: SeccionsShow,
        });
        console.log(SeccionsShow);
      } else {
        toasType.error("Error en la petición al servidor");
        console.log(SeccionsShow);
        this.setState({
          onFetch: false,
          onError: true,
        });
      }
    } catch (e) {
      toasType.error("Error en la red / Error de conexión");
      this.setState({
        onError: true,
        onFetch: false,
      });
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;

    if (this.state.onFetch === false) {
      if (this.state.onError === true) {
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      } else {
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              className={classes.Initial}
              spacing={1}
            >
              <Header
                info={this.state.CompanyInfo}
                Refresh={this.fetchSeccions}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.Initial}
              spacing={1}
            >
              <Grid container item xs={12}>
                {this.state.CompanyInfo.company.hasCommittee ? (
                  <Commite
                    uuid={this.state.CompanyInfo.company.uuid}
                    id={this.state.CompanyInfo.company.id}
                    Refresh={this.fetchSeccions}
                  />
                ) : (
                  <CompanyPersonal
                    uuid={this.state.CompanyInfo.company.uuid}
                    id={this.state.CompanyInfo.company.id}
                    Refresh={this.fetchSeccions}
                  />
                )}
              </Grid>
              <Grid container item xs={12}>
                <Admin
                  uuid={this.state.CompanyInfo.company.uuid}
                  id={this.state.CompanyInfo.company.id}
                  Refresh={this.fetchSeccions}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.Initial}
              spacing={1}
            >
              <Grid container item xs={12} sm={12} md={5} lg={5} xl={5}>
                <GeneralInfo
                  info={this.state.CompanyInfo}
                  Refresh={this.fetchSeccions}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Benefits
                  info={this.state.CompanyInfo}
                  Refresh={this.fetchSeccions}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Statics info={this.state.CompanyInfo} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.Initial}
              spacing={1}
            >
              <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CompanyData
                  Info={this.state.CompanyInfo}
                  Refresh={this.fetchSeccions}
                />
              </Grid>
              <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CompanyOfical
                  data={this.state.CompanyInfo.company}
                  Refresh={this.fetchSeccions}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      }
    } else {
      return (
        <Grid container>
          <Grid container className={classes.Section} spacing={1}>
            <Grid container item xs={8}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
            <Grid container item xs={4}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.Section} spacing={1}>
            <Grid container item xs={12}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.Section} spacing={1}>
            <Grid container item xs={4}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
            <Grid container item xs={4}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
            <Grid container item xs={4}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.Section} spacing={1}>
            <Grid container item xs={6}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
            <Grid container item xs={6}>
              <Skeleton
                variant="rect"
                animation="wave"
                className={classes.Skeleton}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(TablePadron));
