import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import Api from "../../../API/CompaniesCampaigs";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectCompany from "../Selects/SelectCompany";
import SelectForms from "../Selects/SelectForms";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  text: {
    width: "80%",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      name: "",
      formId: null,
      companies: [],
    };
  }
  componentDidMount = async () => {};
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });

      if (
        this.state.name.length > 0 &&
        this.state.formId &&
        this.state.companies.length > 0
      ) {
        const Deli = await Api.Create(this.state);
        console.log(Deli);
        if (Deli.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se creado con éxito");
          await this.props.Refresh("CreateDialog");
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible Eliminar el elemento");
        }
      } else {
        if (!this.state.name.length > 0) {
          toastType.error("No se puede crear con nombre vació");
        }
        if (!this.state.formId) {
          toastType.error("No se puede crear Sin Formulario");
        }
        if (!this.state.companies.length > 0) {
          toastType.error("No se puede crear Sin compañías");
        }
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  selectChange = async (name, value) => {
    this.setState({ [name]: value });
  };
  deleteCompanies = (index) => {
    let value = this.state.companies.filter((item, j) => index !== j);
    this.setState({ companies: value });
  };

  multiSelectChange = (name, value) => {
    this.setState({ [name]: [...this.state[name], value] });
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Nuevo Campaña</DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <TextField
              label="Nombre de la campaña"
              margin="normal"
              size="small"
              name="name"
              onChange={this.onChange}
              className={classes.text}
            />
            <SelectForms
              Select={this.selectChange}
              formId={this.state.formId}
            />
            <SelectCompany
              Select={this.multiSelectChange}
              companies={this.state.companies}
            />
            {this.state.companies.length > 0 && (
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Empresa</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.companies.map((Article, index) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article.id}>
                          <TableCell component="th" scope="row">
                            {Article.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={this.deleteCompanies.bind(this, index)}
                              className={classes.btn}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.name.length > 0
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
