import base from "../baseurl";
import token from "../getTk";
import Redux from "../../StoreConfig";
import { addCompaniesList } from "../../Accions/actions";

const showAllNotSta = async () => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const showAllNot = await api
    .get("companies/showall", {
      timeout: false,
    })
    .json();
  if (showAllNot.status === "success") {
    Redux.dispatch(addCompaniesList(showAllNot.data));
  }
  return showAllNot.data;
};

export default showAllNotSta;
