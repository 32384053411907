import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    UNSAFE_componentWillMount() {
      if (this.props.auth_in) {
        this.props.history.push("/Empresas");
      }
    }
    UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.auth_in) {
        this.props.history.push("/Empresas");
      }
    }
    render() {
      return (
        <div>
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  Authenticate.contextTypes = {
    router: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      auth_in: state.login.user.auth_in,
    };
  }

  return connect(mapStateToProps)(Authenticate);
}
