import base from "../../../baseurl";
import token from "../../../getTk"


const Update = async (state) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
   console.log("estado en la llamada",state)
    const Update = await api.post("pages/update",{json: {
        pageId:12,
        name:"capacitacionSitimm",
        content:{
            title:state.title,
            welcomeText:state.welcomeText,
            banner1:state.banner1,
            PrincipalIMG:state.PrincipalIMG,
            characteristics:state.characteristics,
            calendar:state.calendar,
            endtext:state.endtext,
            bonus:state.bonus,
        }
    }
    ,
        timeout:false
}).json();
   console.log(Update)
    return Update ;
    
  };
  
  export default  Update ;