import base from "../baseurl";
import token from "../getTk";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const Create = async (State, Who, type) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Create = await api
    .post("election/update", {
      json: {
        autor: Who,
        title: State.title,
        publicTitle: State.publicTitle,
        employment: State.employment,
        candidatesUuid: State.candidatesUuid ? State.candidatesUuid : [],
        type: State.type,
        //El cambio de estado
        status: type,
        //Tiempos para la eleccion

        StartAnnouncement: State.StartAnnouncement,
        EndAnnouncement: State.EndAnnouncement,
        StartElecction: State.StartElecction,
        EndElection: State.EndElection,
        //Datos de convocatoria
        announcement: State.announcement.toString(),
        img: State.img,
        description: State.description,
        electionUuid: State.uuid,
      },
    })
    .json();

  return Create;
};

export default Create;
