import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Grid,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import EmployeesCall from "../../../API/Companies";
import Skeleton from "@material-ui/lab/Skeleton";
import ListIcon from "@material-ui/icons/List";
import AttachmentIcon from "@material-ui/icons/Attachment";
import toastType from "../../../API/CallUtils/tostyNotification";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#24e05c",
    //backgroundColor: "#24e05c",
    "&:hover": {
      backgroundColor: "#cdfada",
    },
  },
}))(Button);

const styles = (theme) => ({
  paper: {
    width: "90%",
    padding: "1%",
  },
  Skeleton: {
    width: "450px",
    height: "100px",
    borderRadius: "10px 10px 10px 10px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      StateTransaction: "OnLoad",
      Census: [],
    };
  }
  componentDidMount = async () => {
    if (this.props.uuid) {
      this.fetchCensus(this.props.uuid);
    }
  };

  fetchCensus = async (Uuid) => {
    try {
      const dates = await EmployeesCall.CensusDates(Uuid);
      console.log("Las fechas", dates);
      if (dates) {
        dates.length > 0
          ? this.setState({ StateTransaction: "OnSuccess", Census: dates })
          : this.setState({ StateTransaction: "OnNull" });
      } else {
        this.setState({ StateTransaction: "onError" });
      }
    } catch (e) {
      console.log(e);
      this.setState({ StateTransaction: "OnError" });
    }
  };

  downloadFile = (url) => {
    console.log(url);
    try {
      if (url) {
        window.open(url);
      } else {
        toastType.error("El Archivo no esta disponible");
      }
    } catch (e) {
      toastType.error("No Fue posible abrir el archivo");
    }
  };
  SendBack = (uuid, date) => {
    this.props.SelectCensus(uuid, date);
  };

  render() {
    const { classes } = this.props;
    switch (this.state.StateTransaction) {
      case "OnLoad":
        return (
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        );
      case "OnError":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnNull":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Ningún Padrón Disponible
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnSuccess":
        return (
          <Paper className={classes.FullPaper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Fechas</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Altas</TableCell>
                  <TableCell>Bajas</TableCell>
                  <TableCell>Padrones</TableCell>
                  <TableCell>Archivo Original</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.Census.map((Dates, index) => {
                  return (
                    <React.Fragment>
                      <TableRow key={Dates.uuid}>
                        <TableCell component="th" scope="row" align="center">
                          {Dates.createdAt.split("T")[0]}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {Dates.total}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <ColorButton
                            color="primary"
                            //align="center"
                            onClick={this.SendBack.bind(
                              this,
                              "Details",
                              Dates.NewOne,
                              Dates.createdAt.split("T")[0]
                            )}
                          >
                            {Dates.NewOne.length}
                          </ColorButton>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Button
                            color="secondary"
                            onClick={this.SendBack.bind(
                              this,
                              "Details",
                              Dates.DeletedOne,
                              Dates.createdAt.split("T")[0]
                            )}
                          >
                            {Dates.DeletedOne.length}
                          </Button>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Button
                            color="primary"
                            align="center"
                            onClick={this.SendBack.bind(
                              this,
                              "Census",
                              Dates.uuid,
                              Dates.createdAt.split("T")[0]
                            )}
                          >
                            <ListIcon />
                          </Button>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Button
                            color="primary"
                            align="center"
                            onClick={this.downloadFile.bind(
                              this,
                              Dates.fileUrl
                            )}
                          >
                            <AttachmentIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        );
      default:
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
    }
  }
}

export default withStyles(styles)(addCandidateDialog);
