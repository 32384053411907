//import Create from "./Create";
import Update from "./Update";
import showAll from "./showAll";
//import Delete from "./Delete"
import UpdateCarusel from "./UpdateCarusel";
import ShowCarusel from "./ShowCarusel";

const Home = {
  Update,
  showAll,
  UpdateCarusel,
  ShowCarusel,
  //Create,Delete
};

export default Home;

export {
  //Create,
  Update,
  showAll,
  UpdateCarusel,
  ShowCarusel,
  //Delete,
};
