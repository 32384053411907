import base from "../baseurl";

const showAll = async () => {

    const CompaniesShow = await base.get("bulletins-general/showall",{
      timeout:false
    }).json();
    
    return CompaniesShow;
    
  };
  
  export default  showAll ;