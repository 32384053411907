import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import MagazineCall from "../../../API/CTM/magazine";
import Img from "../../../API/CallUtils/img";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Document, Page, pdfjs } from "react-pdf";
import toasType from "../../../API/CallUtils/tostyNotification";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "100%",
    height: "80vh",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
});

class magazine extends React.Component {
  state = {
    PDF: [],
    Dialog: false,
    Uuid: "",
    Title: "",
    img: null,
    numPages: null,
    pageNumber: 1,
    pdfFile: null,
    pdfFileRaw: null,
    FileNameIMG: "",
    FileNamePDF: "",
    onSave: false,
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  showAll = async () => {
    try {
      const polute = await MagazineCall.showAll();
      if (polute) {
        this.setState({ PDF: polute });
      } else {
        toasType.error("No fue posible traer la información ");
      }
    } catch (e) {
      toasType.error("No fue posible traer la información ");
    }
  };

  DialogEntrando = async () => {
    this.setState({
      Uuid: "",
      Title: "",
      img: null,
      imgRaw: null,
      Dialog: false,
    });
  };

  DialogCreate = async () => {
    this.setState({ DialogCreate: false });
  };

  create = async () => {
    try {
      this.setState({ onSave: true });
      const create = await MagazineCall.Create(
        this.state.Title,
        this.state.imgRaw,
        this.state.pdfFileRaw,
        this.state.FileNameIMG,
        this.state.FileNamePDF
      );

      if (create.status === "success") {
        toasType.success("Nueva Revista agregada");
      } else {
        toasType.error("No fue posible crear");
      }

      this.setState({
        DialogCreate: false,
        img: null,
        imgRaw: null,
        Title: "",
        pdfFile: null,
        pdfFileRaw: null,
        onSave: false,
      });
      await this.showAll();
    } catch (e) {
      toasType.error("No fue posible crear");
    }
  };

  delete = async (Uuid, e) => {
    try {
      const Delete = await MagazineCall.Delete(Uuid);
      if (Delete.status === "success") {
        toasType.success("Revista Eliminada");
      } else {
        toasType.error("No fue posible Eliminar");
      }
      await this.showAll();
    } catch (e) {
      toasType.error("No fue posible Eliminar");
    }
  };

  Opencreate = async () => {
    this.setState({ DialogCreate: true });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  uploadSingleFile = async (e) => {
    var name = e.target.files[0].name;
    const str = await Img.getBinaryString(e.target.files[0]);
    var imgDone = btoa(str);
    this.setState({
      img: Img.getImg(str),
      imgRaw: imgDone,
      FileNameIMG: name,
    });
  };

  uploadPDF = async (e) => {
    var pdfname = e.target.files[0].name;
    var file = e.target.files[0];
    const raw = await Img.getBinaryStringFile(e.target.files[0]);
    var file64 = btoa(raw);

    this.setState({
      pdfFile: URL.createObjectURL(file),
      pdfFileRaw: file64,
      FileNamePDF: pdfname,
    });
  };

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container>
            {this.state.PDF.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.PDF.map((Article) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.updatedAt.split("T")[0]}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.delete.bind(this, Article.uuid)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}
            <div className={classes.btnTable}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addBTN}
                onClick={this.Opencreate}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </Grid>
        </Paper>

        <Dialog
          open={this.state.DialogCreate}
          onClose={this.DialogCreate}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Revista</DialogTitle>

          {this.state.onSave === true ? (
            <DialogContent>
              <div className={classes.load}>
                <CircularProgress />
              </div>
              <div className={classes.load}>Procesando Acción</div>
            </DialogContent>
          ) : (
            <DialogContent>
              <Grid container className={classes.GridD}>
                <div className={classes.text}>
                  <TextField
                    id="standard-basic"
                    label="Titulo"
                    name="Title"
                    value={this.state.Title}
                    className={classes.Title}
                    onChange={this.onChange}
                  />
                </div>

                <div className={classes.imgDiv}>
                  <Typography>Imagen principal</Typography>
                </div>

                {this.state.img !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.img}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    onChange={this.uploadSingleFile}
                    className={classes.imgDiv}
                  />
                </div>

                <Grid
                  item
                  xs={12}
                  className={classes.GridC}
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Typography>archivo PDF</Typography>

                  {this.state.pdfFile !== null ? (
                    <div>
                      <Document
                        file={this.state.pdfFile}
                        onLoadSuccess={this.onDocumentLoad}
                      >
                        <Page pageNumber={this.state.pageNumber} />
                      </Document>
                      <p>
                        Page {this.state.pageNumber} of {this.state.numPages}
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={this.uploadPDF}
                      className={classes.imgDiv}
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          )}

          <DialogActions>
            <Button onClick={this.DialogCreate} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.create} color="primary">
              Crear Publicación
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(magazine);
