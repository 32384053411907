import React from "react";
import {
  withStyles,
  TextField,
  Typography,
  Grid,
  Paper,
} from "@material-ui/core";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  text: {
    width: "100%",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    marginBottom: "8px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Paper: {
    width: "100%",
    minHeight: "250px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
  },
  margin: {
    marginTop: "12px",
    marginBottom: "12px",
  },
});
function FilterOption({ classes, value, mean, mode, stdDeviation, label }) {
  if (mean || mode || stdDeviation) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={12}
        spacing={1}
        className={classes.margin}
      >
        <Typography
          align="Center"
          variant="h5"
          component="p"
          className={classes.Subtitle}
        >
          {label}
        </Typography>

        {mean && (
          <Grid
            //Promedio
            item
            xs={4}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              align="Center"
              variant="subtitle"
              component="p"
              className={classes.Subtitle}
            >
              Promedio
            </Typography>
            <Paper className={classes.Paper}>
              <TextField
                label="Salario"
                className={classes.text}
                margin="normal"
                value={Math.round(value.meanSalary)}
                variant="outlined"
                name="max"
                size="small"
              />
              <TextField
                label="Edad"
                className={classes.text}
                margin="normal"
                value={Math.round(value.meanAge)}
                variant="outlined"
                name="max"
                size="small"
              />
              <TextField
                label="Antigüedad"
                className={classes.text}
                margin="normal"
                value={Math.round(value.meanAntiquity)}
                variant="outlined"
                name="max"
                size="small"
              />
            </Paper>
          </Grid>
        )}
        {mode && (
          <Grid
            //Promedio
            item
            xs={4}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              align="Center"
              variant="subtitle"
              component="p"
              className={classes.Subtitle}
            >
              Moda
            </Typography>
            <Paper className={classes.Paper}>
              <TextField
                label="Salario"
                className={classes.text}
                margin="normal"
                value={Math.round(value.modeSalary[0])}
                variant="outlined"
                name="max"
                size="small"
              />
              <TextField
                label="Edad"
                className={classes.text}
                margin="normal"
                value={Math.round(value.modeAge[0])}
                variant="outlined"
                name="max"
                size="small"
              />
              <TextField
                label="Antigüedad"
                className={classes.text}
                margin="normal"
                value={Math.round(value.modeAntiquity[0])}
                variant="outlined"
                name="max"
                size="small"
              />
            </Paper>
          </Grid>
        )}
        {stdDeviation && (
          <Grid
            //Promedio
            item
            xs={4}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              align="Center"
              variant="subtitle"
              component="p"
              className={classes.Subtitle}
            >
              Desviación
            </Typography>
            <Paper className={classes.Paper}>
              <TextField
                label="Salario"
                className={classes.text}
                margin="normal"
                value={Math.round(value.stdDeviationSalary)}
                variant="outlined"
                name="max"
                size="small"
              />
              <TextField
                label="Edad"
                className={classes.text}
                margin="normal"
                value={Math.round(value.stdDeviationAge)}
                variant="outlined"
                name="max"
                size="small"
              />
              <TextField
                label="Antigüedad"
                className={classes.text}
                margin="normal"
                value={Math.round(value.stdDeviationAntiquity)}
                variant="outlined"
                name="max"
                size="small"
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return null;
  }
}
export default withStyles(styles)(FilterOption);
