import React from "react";
import { Typography, Button, Paper, withStyles } from "@material-ui/core";
import UpdateS from "../../../API/DeliveryOfGoods";
import MUIDataTable from "mui-datatables";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import SelectStates from "../Components/SelectStates";
import toastType from "../../../API/CallUtils/tostyNotification";
import Details from "../Dialogs/DocsDetails";
const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Skeleton1: {
    width: "100%",
    height: "20px",
    borderRadius: "10px 10px 10px 10px",
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Onload: false, Docs: false, IdDocs: "" };
  }
  selectType = async (newType, id) => {
    if (!this.state.OnLoad) {
      this.setState({
        OnLoad: true,
      });
      try {
        const update = await UpdateS.UpdateState(id, newType[0]);
        if (update.status === "success") {
          this.setState({
            OnLoad: false,
          });
          toastType.success("Cambio realizado con exito");
          this.props.Refresh([this.props.Type]);
        } else {
          this.setState({
            OnLoad: false,
          });
          toastType.error("SSE No fue posible Cambiar de estado");
        }
      } catch (e) {
        console.log(e);
        this.setState({
          OnLoad: false,
        });
        toastType.error("CSE No fue posible Cambiar de estado");
      }
    } else {
      toastType.error("El cambio actual se sigue ejecutando");
    }
  };
  Dialog = (a, id) => {
    this.setState({ [a]: !this.state[a], IdDocs: id });
  };
  refresh = async () => {
    this.props.Refresh([this.props.Type]);
  };
  render() {
    const { classes } = this.props;
    const data = this.props.List.map((Article) => [
      Article.User.UserProfile.name + " " + Article.User.UserProfile.lastNames,
      Article.User.UserProfile.rfc,
      Article.updatedAt.split("T")[0],
      this.props.Type === 4 ? (
        <Button
          color="Primary"
          className={classes.btn}
          onClick={this.Dialog.bind(this, "Docs", Article.User.id)}
        >
          <FindInPageIcon />
        </Button>
      ) : (
        <SelectStates
          Select={this.selectType}
          Width={"100%"}
          initial={Article.GoodDeliveryParticipantStateId}
          id={Article.id}
        />
      ),
    ]);
    const columns = [
      "Nombre",
      "RFC",
      "Fecha de aprobación",
      this.props.Type === 4 ? "Documentos" : "Estado",
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin datos",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    return (
      <div className={classes.paper}>
        <Typography
          align="Center"
          variant="h5"
          component="p"
          className={classes.Subtitle}
        >
          {this.props.Title}
        </Typography>

        {this.props.List.length > 0 ? (
          <Paper className={classes.Table}>
            <MUIDataTable
              title={this.props.Title}
              data={data}
              columns={columns}
              options={options}
              className={classes.table}
              selectableRows="none"
            />
            {this.state.Docs
              ? [
                  <Details
                    DialogOpen={this.state.Docs}
                    DialogClose={this.Dialog.bind(this, "Docs")}
                    id={this.state.IdDocs}
                    Refresh={this.refresh}
                  />,
                ]
              : [<></>]}
          </Paper>
        ) : (
          <Paper className={classes.NoItemPaper}>
            <Typography variant="body1" color="textPrimary" component="p">
              Sin elementos disponibles
            </Typography>
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Edit);
