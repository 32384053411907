import React from "react";

import {
  Typography,
  Button,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  withStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import toastType from "../../API/CallUtils/tostyNotification";
import Api from "../../API/WeeklyFeeds";
import DialogDetailsEvent from "./Dialogs/DetailsDialog";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");
const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Skeleton: {
    width: "100%",
    height: "400px",
    borderRadius: "10px 10px 10px 10px",
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListEvents: [],
      onLoad: true,
    };
  }
  componentDidMount = async () => {
    this.fetchlist();
  };
  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.ShowAll();
      console.log(Deli);
      if (Deli.status === "success") {
        this.setState({
          ListEvents: Deli.data.reports,
          onLoad: false,
          onError: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Dialog = (a, b) => {
    this.setState({ [a]: !this.state[a], EventId: b ? b : null });
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <>
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        </>
      );
    } else if (this.state.onError) {
      return (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            Error
          </Typography>
          <Paper className={classes.NoItemPaper}>
            <Typography variant="body1" color="textPrimary" component="p">
              Error
            </Typography>
          </Paper>
          ,
        </>
      );
    } else {
      return (
        <div className={classes.paper}>
          <Typography
            align="Center"
            variant="h5"
            component="p"
            className={classes.Subtitle}
          >
            Listado de Semanas
          </Typography>

          {this.state.ListEvents.length > 0 ? (
            <Paper className={classes.Table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Semana</TableCell>
                    <TableCell>Total de empresas</TableCell>
                    <TableCell>Sin Pago</TableCell>
                    <TableCell>Con pago</TableCell>
                    <TableCell>Recaudado</TableCell>
                    <TableCell>Total esperado</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Detalles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.ListEvents.map((Article) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article.id}>
                          <TableCell component="th" scope="row">
                            {Article.weeklyFee.weekNumber}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Article.debtedCompanies.length +
                              Article.nonDebtedCompanies.length}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Article.debtedCompanies.length}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Article.nonDebtedCompanies.length}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Article.actualTributation}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {Math.floor(Article.totalPossibleTributation)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(Article.weeklyFee.createdAt).format("ll")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="primary"
                              variant="contained"
                              className={classes.btn}
                              onClick={this.Dialog.bind(
                                this,
                                "EditDialog",
                                Article.weeklyFee.id
                              )}
                            >
                              <EditIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <Paper className={classes.NoItemPaper}></Paper>
          )}
          {this.state.EditDialog && (
            <DialogDetailsEvent
              DialogOpen={this.state.EditDialog}
              DialogClose={this.Dialog.bind(this, "EditDialog")}
              EventId={this.state.EventId}
              Refresh={this.Refresh}
            />
          )}
        </div>
      );
    }
  }
}

export default withStyles(styles)(Edit);
