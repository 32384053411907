import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AsesorInfo from "./AsesorInfo";
import Statics from "./Statics";
import ListOfCompanys from "./ListOfCompanys";

const styles = {
  paper: {
    width: "100%",
    height: "100vh",
  },
  RootGrid: {
    width: "100%",
    height: "100%",
  },
  Grid: {
    //padding: "2%",
    //overflow: "auto",
  },

  padding: { padding: "5px" },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditDialog: false,
      INFO: null,
      force: true,
    };
  }

  componentDidMount = async () => {
    this.SetInfo();
  };
  SetInfo = () => {
    try {
      const infoAsesor = {
        Name: this.props.location.state.AsesorName,
        Lastname: this.props.location.state.Profile.lastNames,
        phone: this.props.location.state.Profile.mobilePhone,
        email: this.props.location.state.email,
        Uuid: this.props.location.state.AsesorUuid,
        Refresh: false,
      };
      this.setState({ INFO: infoAsesor });
    } catch (e) {
      console.log(e);
    }
  };
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  Refresh = () => {
    this.setState({ force: !this.state.force });
    this.setState({ force: !this.state.force });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid container className={classes.RootGrid}>
          <Grid
            item
            xs={4}
            className={classes.Grid}
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            {this.state.INFO && <AsesorInfo info={this.state.INFO} />}
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.Grid}
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            {this.state.INFO && this.state.force && (
              <Statics info={this.state.INFO} />
            )}
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.Grid}
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            {this.state.INFO && (
              <ListOfCompanys info={this.state.INFO} Refresh={this.Refresh} />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
