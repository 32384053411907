import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import toastType from "../../../../API/CallUtils/tostyNotification";
import EventCall from "../../../../API/CTM/events";
import { DatePicker } from "material-ui-pickers";
import UploadFile from "../../../../Components/Header/UploadFiles";
import ArrayFiles from "../../../../Components/Header/ArrayFiles";
import SelectRegis from "../CreateEventSteps/SelectRegis";
import SelectType from "../CreateEventSteps/SelectType";
import SelectTransport from "../CreateEventSteps/SelectTransport";
import SelectParticipant from "../CreateEventSteps/SelectParticipant";
import SelectConfirmation from "../CreateEventSteps/SelectConfirmation";
import LinksTable from "../CreateEventSteps/LinksTable";
import BusStop from "../CreateEventSteps/BusStop";
import CampusId from "../CreateEventSteps/Campus";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "80%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  BtnAdd: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});
class updateNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventUuid: "",
      title: "",
      eventDate: "",
      content: "",
      description: "",
      img: "",
      files: [],
      place: "",
      register: "",
      enabled: "",
      daysBeforeAnouncment: "",
      busStopsId: [],
      campusesId: [],
      virtualUrl: [],
      audience: [],
      audienceTitles: [],
      eventTypeId: [],
      EditDialog: false,
      hasConfirmation: false,
      offersMobility: false,
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.eventInfo) {
        this.setState({
          eventUuid: this.props.eventInfo.uuid,
          title: this.props.eventInfo.title,
          eventDate: this.props.eventInfo.eventDate,
          content: this.props.eventInfo.description,
          description: this.props.eventInfo.description,
          img: this.props.eventInfo.img,
          files: this.props.eventInfo.files,
          place: this.props.eventInfo.place,
          register: this.props.eventInfo.register,
          enabled: this.props.eventInfo.enabled,
          daysBeforeAnouncment: this.props.eventInfo.daysBeforeAnouncment,
          busStopsId:
            this.props.eventInfo.BusStops.length > 0
              ? this.props.eventInfo.BusStops.map((x) => x.id)
              : [],
          campusesId:
            this.props.eventInfo.Campuses.length > 0
              ? this.props.eventInfo.Campuses.map((x) => x.id)
              : [],
          virtualUrl: this.props.eventInfo.virtualUrl,
          audience: this.props.eventInfo.audience,
          audienceTitles: [],
          eventTypeId: this.props.eventInfo.EventTypeId,
          hasConfirmation:
            this.props.eventInfo.daysBeforeAnouncment === 0 ? false : true,
          offersMobility: this.props.eventInfo.offersMobility,
        });
      } else {
        toastType.error("La información no pudo ser agregada correctamente");
      }
    } catch (e) {
      toastType.error("Error, not dnd");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  changeDate = (name, acceptanceDate) => {
    var fecha = moment(new Date(acceptanceDate)).toISOString();
    //this.setState({ [name]: fecha });
    this.setState({ [name]: fecha });
  };
  handleCallback = (urlFromChild, stateName) => {
    console.log(urlFromChild, stateName);
    this.setState({
      [stateName]: urlFromChild,
    });
  };
  UpdateEvent = async () => {
    try {
      if (true) {
        const create = await EventCall.Update(this.state);
        if (create.status === "success") {
          toastType.success("Evento Editado con éxito");
          this.props.parentCallback();
        } else {
          toastType.error("No fue posible crear el evento,BackSide Error");
        }
      } else {
        console.log("evento NO valido de a madres ");
      }
    } catch (e) {
      console.log(e);
      toastType.error("No fue posible crear el evento,FrontSide Error");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="md"
      >
        <DialogTitle id="form-dialog-title">Modificar evento</DialogTitle>

        <DialogContent dividers>
          <Grid container className={classes.center}>
            <TextField
              id="standard-basic"
              label="Titulo"
              name="title"
              value={this.state.title}
              className={classes.text}
              onChange={this.onChange}
            />
            <TextField
              id="standard-basic"
              label="Lugar"
              name="place"
              value={this.state.place}
              className={classes.text}
              onChange={this.onChange}
            />
            <DatePicker
              label="Fecha del evento"
              value={this.state.eventDate}
              onChange={this.changeDate.bind(this, "eventDate")}
              className={classes.text}
            />

            <div className={classes.text}>
              <SelectRegis
                ParentState={this.state}
                ParentSet={this.handleCallback}
              />
            </div>
            {this.state.register && (
              <div className={classes.text}>
                <SelectConfirmation
                  ParentState={this.state}
                  ParentSet={this.handleCallback}
                />
              </div>
            )}
            {this.state.hasConfirmation && (
              <TextField
                id="standard-basic"
                label="Días previos para enviar recordatorio"
                name="daysBeforeAnouncment"
                value={this.state.daysBeforeAnouncment}
                className={classes.text}
                onChange={this.onChange}
              />
            )}
            <div className={classes.text}>
              <SelectParticipant
                ParentState={this.state}
                ParentSet={this.handleCallback}
              />
            </div>
            <div className={classes.text}>
              <SelectType
                ParentState={this.state}
                ParentSet={this.handleCallback}
              />
            </div>
            {this.state.eventTypeId !== 1 && (
              <div className={classes.text}>
                <LinksTable
                  ParentState={this.state}
                  ParentSet={this.handleCallback}
                />
              </div>
            )}

            {this.state.eventTypeId !== 2 && (
              <div className={classes.text}>
                <SelectTransport
                  ParentState={this.state}
                  ParentSet={this.handleCallback}
                />
              </div>
            )}
            {this.state.offersMobility && this.state.eventTypeId !== 2 && (
              <div className={classes.text}>
                <BusStop
                  ParentState={this.state}
                  ParentBusSet={this.handleCallback}
                />
              </div>
            )}
            {this.state.eventTypeId === 3 && (
              <div className={classes.text}>
                <CampusId
                  ParentState={this.state}
                  ParentBusSet={this.handleCallback}
                />
              </div>
            )}

            <div className={classes.text}>
              <UploadFile
                Type="Imagen"
                Place="SITIMM_EVENTS_Private_IMG"
                parentCallback={this.handleCallback}
                stateNameOnParent="img"
                PrevImg={this.state.img}
              />
            </div>

            <TextField
              id="standard-read-only-input"
              label="Resumen del evento"
              className={classes.text}
              margin="normal"
              name="description"
              multiline
              rows="6"
              onChange={this.onChange}
              value={this.state.description}
              variant="outlined"
            />
            <div className={classes.text}>
              <ArrayFiles
                Type="Documento"
                Place="SITIMM_EVENTS_Private_Files"
                parentCallback={this.handleCallback}
                stateNameOnParent="files"
                PrevFilesList={this.state.files}
              />
            </div>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={this.UpdateEvent} color="primary">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(updateNewEvent);
