export const addPermissions = (permissions) => ({
  type: "ADD_PERMISSIONS",
  payload: permissions,
});
export const authorization = (res) => ({
  type: "ADD_AUTH",
  payload: res.data,
});
export const addCompaniesList = (res) => ({
  type: "ADD_COMPANIES_LIST",
  payload: res,
});
