import base from "../baseurl";
import token from "../getTk"

const Countries = async () => {
  const tokenA = token.get()

  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Country = await api.get("countries/index").json();
    //console.log(Country)
    return Country ;
    
  };
  
  export default  Countries ;