import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";
import Edit from "./SelectCampus";
import SportEvent from "../../../../API/CTM/events";
import { OnLoadSelect } from "../../../../Components/StatesForComponets";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "100%",
    //height: "98%",
    //padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Remp: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});
class createNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EditDialog: false,
      List: [],
    };
  }
  componentDidMount = async () => {
    try {
      const CompanyList = await SportEvent.CampusShowAll();
      console.log("Lista de campus", CompanyList);

      if (CompanyList.status === "success") {
        this.setState({
          List: CompanyList.data.campuses,
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  parentCallback = (value) => {
    let val = this.props.ParentState.campusesId;
    val.push(value);
    this.props.ParentBusSet(val, "campusesId");
  };
  deleteStop = (i) => {
    let val = this.props.ParentState.campusesId.filter((item, j) => i !== j);
    this.props.ParentBusSet(val, "campusesId");
  };
  render() {
    const { classes } = this.props;
    if (this.state.List !== []) {
      return (
        <Grid container className={classes.Grid}>
          <div className={classes.Grid}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              component="p"
              className={classes.center}
            >
              Ceformas / Cedes para eventos mixtos
            </Typography>
            {this.props.ParentState.campusesId.length > 0
              ? [
                  <Paper className={classes.Grid}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Capacidad</TableCell>
                          <TableCell>Dirección</TableCell>
                          <TableCell>Eliminar</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.ParentState.campusesId.map(
                          (CampusId, index) => {
                            return this.state.List.map((list) => {
                              if (list.id === CampusId) {
                                return (
                                  <React.Fragment>
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {list.name}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {list.capacity}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {list.address}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Button
                                          color="secondary"
                                          onClick={this.deleteStop.bind(
                                            this,
                                            index
                                          )}
                                        >
                                          <DeleteIcon />
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                );
                              } else {
                                return <></>;
                              }
                            });
                          }
                        )}
                      </TableBody>
                    </Table>
                  </Paper>,
                ]
              : [
                  <div className={classes.center}>
                    <Button
                      onClick={this.Dialog.bind(this, "EditDialog")}
                      className={classes.AddCandidate}
                    >
                      Sin cedes asignadas
                    </Button>
                  </div>,
                ]}
            {this.props.ParentState.campusesId.length > 0 && (
              <div className={classes.Remp}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.btn}
                  onClick={this.Dialog.bind(this, "EditDialog")}
                >
                  <AddBoxIcon />
                </Button>
              </div>
            )}
          </div>
          {this.state.List.length > 0 && (
            <Edit
              DialogOpen={this.state.EditDialog}
              DialogClose={this.Dialog.bind(this, "EditDialog")}
              SendToParent={this.parentCallback}
              List={this.state.List}
            />
          )}
        </Grid>
      );
    } else {
      return <OnLoadSelect />;
    }
  }
}

export default withStyles(styles)(createNewEvent);
