import React from "react";
import { Paper, withStyles, Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import GetHistory from "../../../API/Users/History";

const styles = (theme) => ({
  paper: {
    width: "90%",
    padding: "1%",
  },
  Skeleton: {
    width: "400px",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
  Red: {
    color: "red",
  },
  Green: {
    color: "green",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      StateTransaction: "OnSuccess",
      Employees: [],
      dots: null,
    };
  }
  componentDidMount = async () => {
    try {
      //console.log("Llego", this.props.rfc);
      const timeLine = await GetHistory(this.props.rfc);
      if (timeLine.status === "success") {
        this.setState({
          dots:
            timeLine.data.User.Employees.length > 0
              ? timeLine.data.User.Employees.reverse()
              : [],
        });
      } else {
        console.log("ServerSideError");
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;

    switch (this.state.StateTransaction) {
      case "OnLoad":
        return (
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        );
      case "OnError":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnNull":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Ningún Cambio Disponible
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnSuccess":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Timeline align="alternate">
                {this.state.dots
                  ? [
                      this.state.dots.map((Article, index) => {
                        return (
                          <TimelineItem>
                            <TimelineOppositeContent>
                              <Typography variant="body2" color="textSecondary">
                                {Article.Company.name}
                              </Typography>
                              <Typography>
                                {Article.createdAt.split("T")[0]}
                              </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color="primary" />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent></TimelineContent>
                          </TimelineItem>
                        );
                      }),
                    ]
                  : []}
              </Timeline>
            </Grid>
          </Paper>
        );
      default:
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
    }
  }
}

export default withStyles(styles)(addCandidateDialog);
