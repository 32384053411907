import React from "react";
import ApiFetchWrapper from "../../../Utils/HoC/ApiCall";

import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

function OfficeList({ data, value, Send }) {
  return (
    <>
      <FormControl style={{ width: "85%" }}>
        <InputLabel id="demo-simple-select-label">Oficina</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={(e) => {
            Send(e.target.value);
          }}
        >
          {data &&
            data.headquarters.length > 0 &&
            data.headquarters.map((head) => {
              return (
                <MenuItem
                  value={head.headquarter.uuid}
                  key={head.headquarter.uuid}
                >
                  {head.headquarter.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
}
export default ApiFetchWrapper(OfficeList, "headquarters/index");
