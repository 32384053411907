import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Event from "../CreateEventSteps/Event";
import toastType from "../../../../API/CallUtils/tostyNotification";
import EventCall from "../../../../API/CTM/events";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "90%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class createNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      eventDate: moment().toISOString(),
      content: "",
      description: "",
      img: null,
      place: "",
      Files: [],
      eventTypeId: 1,
      register: false,
      offersMobility: false,
      daysBeforeAnouncment: "0",
      isPrivate: true,
      audience: "Publico",
      virtualUrl: [],
      busStopsId: [],
      campusesId: [],
      hasConfirmation: false,
    };
  }

  NewEvent = async () => {
    try {
      console.log(this.IsValidEvent());
      if (this.IsValidEvent()) {
        const create = await EventCall.Create(this.state);
        if (create.status === "success") {
          toastType.success("Evento Creado con éxito");
          this.props.parentCallback();
        } else {
          console.log(create);
          toastType.error("No fue posible crear el evento,BackSide Error");
        }
      } else {
        toastType.error(
          "No fue posible crear el evento, Revisa que todo este bien configurado"
        );
      }
    } catch (e) {
      console.log(e);
      toastType.error("No fue posible crear el evento,FrontSide Error");
    }
  };
  handleCallback = (urlFromChild, stateName) => {
    //console.log(urlFromChild, stateName);
    this.setState({
      [stateName]: urlFromChild,
    });
  };
  changeDate = (acceptanceDate) => {
    var fecha = moment(new Date(acceptanceDate)).toISOString();
    this.setState({ eventDate: fecha });
  };
  IsValidEvent = () => {
    let count = 0;
    this.state.title !== "" && this.state.title.length > 5
      ? (count += 1)
      : toastType.error("Titulo vació o muy corto");
    this.state.place !== "" && this.state.place.length > 5
      ? (count += 1)
      : toastType.error("Lugar del evento vació o muy corto");
    this.state.description !== "" && this.state.description.length > 5
      ? (count += 1)
      : toastType.error("Resumen vació o muy corto");
    this.state.img !== null
      ? (count += 1)
      : toastType.error("El evento de contener imagen de portada");
    if (count === 4) {
      return true;
    } else {
      console.log(count);
      return false;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth="md"
      >
        <DialogTitle id="form-dialog-title"> Crear nuevo evento</DialogTitle>
        <DialogContent dividers>
          <Event
            ParentState={this.state}
            onChange={this.onChange}
            changeDate={this.changeDate}
            parentCallback={this.handleCallback}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.DialogClose}
            className={classes.backButton}
            color="secondary"
            variant="contained"
          >
            Cerrar
          </Button>
          <Button color="primary" variant="contained" onClick={this.NewEvent}>
            Crear evento
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(createNewEvent);
