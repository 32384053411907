import base from "../baseurl";
import token from "../getTk";

const show = async (Uuid) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log("UUID de la WS", Uuid);
  const showWS = await api
    .post("candidate-worksheets/show", {
      json: {
        candidateWorkSheetUuid: Uuid,
      },
    })
    .json();
  return showWS;
};

export default show;
