import React, { Component } from "react";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { OnLoadSelect } from "../../../Components/StatesForComponets";

const styles = (theme) => ({
  formControl: {
    width: "80%",
    margin: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleChangeState = async (event) => {
    console.log(event.target.value);
    this.props.Select(event.target.value);
    this.setState({ SelectedCompanie: event.target.value });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.props.CompaniesList && this.props.CompaniesList.length > 0
          ? [
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Listado de Empresas
                </InputLabel>
                <Select
                  value={this.props.CompanyId}
                  onChange={(event) => {
                    this.props.Select("CompanyId", event.target.value);
                  }}
                >
                  {this.props.CompaniesList.map((companie, index) => {
                    return (
                      <MenuItem value={companie.id} key={companie.id}>
                        {companie.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>,
            ]
          : [
              <div className={classes.formControl}>
                <OnLoadSelect />
              </div>,
            ]}
      </>
    );
  }
}

export default withStyles(styles)(Bulletin);
