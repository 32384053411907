import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import SportEvent from "../../../API/SportEvent/index";
import { OnLoadSelect } from "../../../Components/StatesForComponets";

const styles = (theme) => ({
  formControl: {
    width: "100%",
    margin: "10px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SportNameList: [],
      SelectedSport: "",
      ALL: "",
    };
  }

  fetchCompanyList = async () => {
    try {
      const CompanyList = await SportEvent.DocumentsTypeShowAll();
      console.log("Lista de tipos", CompanyList);
      if (CompanyList) {
        this.setState({
          SportNameList: CompanyList.data.sportEventDocumentTypes,
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChangeState = async (event) => {
    console.log(event.target.value);
    this.props.Select(event.target.value);
    this.setState({ SelectedSport: event.target.value });
  };

  componentDidMount = async () => {
    this.fetchCompanyList();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.SportNameList.length > 0
          ? [
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Listado de Deportes
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.SelectedSport}
                  name="id"
                  onChange={this.handleChangeState}
                >
                  {this.state.SportNameList.map((companie, index) => {
                    return (
                      <MenuItem value={companie.id} key={companie.id}>
                        {companie.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>,
            ]
          : [<OnLoadSelect />]}
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
