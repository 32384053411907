import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SelecType from "../Components/SelectStates";
import toastType from "../../../API/CallUtils/tostyNotification";
import Delivery from "../../../API/DeliveryOfGoods";
import {
  OnError,
  OnLoadTable,
  Default,
  OnNull,
} from "../../../Components/StatesForComponets";
import TypeTable from "../List/SelectTable";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedState: "Initial",
      TypeList: null,
      List: null,
    };
  }
  componentDidMount = async () => {};
  selectType = async (id) => {
    await this.FetchList(id);
  };
  FetchList = async (id) => {
    try {
      this.setState({
        SelectedState: "Onload",
      });

      const Deli = await Delivery.Participant(this.props.EventId, id);
      console.log("Lista de personal", Deli);
      if (Deli.status === "success") {
        Deli.data.deliveryParticipants.length > 0
          ? this.setState({
              SelectedState: "Success",
              TypeList: id,
              List: Deli.data.deliveryParticipants,
            })
          : this.setState({
              SelectedState: "Null",
            });
      } else {
        this.setState({
          SelectedState: "Error",
        });
        toastType.error("SSE No fue Posible Obtener la información");
      }
    } catch (e) {
      this.setState({
        SelectedState: "Error",
      });
      toastType.error("CSE No fue Posible Obtener la información");
      console.log(e);
    }
  };

  render() {
    var Selected = <></>;
    switch (this.state.SelectedState) {
      case "Success":
        Selected = (
          <Grid className={this.props.paper}>
            <SelecType Select={this.selectType} Width={"50%"} />
            <TypeTable
              Type={this.state.TypeList}
              List={this.state.List}
              Refresh={this.FetchList}
            />
          </Grid>
        );
        break;
      case "Initial":
        Selected = <SelecType Select={this.selectType} Width={"100%"} />;
        break;
      case "Null":
        Selected = (
          <Grid>
            <SelecType Select={this.selectType} Width={"100%"} />
            <OnNull />
          </Grid>
        );
        break;
      case "Error":
        Selected = <OnError />;
        break;
      case "Onload":
        Selected = <OnLoadTable />;
        break;
      default:
        Selected = <Default />;
        break;
    }
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">Detalles</DialogTitle>
        <DialogContent className={classes.paper}>{Selected}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.SelectedState === "Success" ||
          this.state.SelectedState === "Null"
            ? [
                <Button
                  onClick={() => {
                    this.setState({
                      SelectedState: "Initial",
                    });
                  }}
                  color="Primary"
                >
                  <ArrowBackIcon />
                </Button>,
              ]
            : [<></>]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
