import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const Classes = (theme) => ({
  paper: {
    width: "100%",
    padding: "12px",
  },
});

function DialogContainer({ classes, name, DialogOpen, DialogClose, children }) {
  return (
    <Dialog open={DialogOpen} onClose={DialogClose} maxWidth="lg">
      <DialogTitle id="form-dialog-title">{name}</DialogTitle>
      <DialogContent className={classes.paper}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={DialogClose} color="secondary">
          <CloseIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(Classes)(DialogContainer);
