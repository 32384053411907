import base from "../baseurl";
import token from "../getTk";

const showAll = async (state) => {
  console.log(state);
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const show = await api
    .post("company-forms/update", {
      json: {
        formData: {
          name: state.name,
          id: state.idForm,
        },
        questions: state.ListEvents,
      },
    })
    .json();
  return show;
};

export default showAll;
