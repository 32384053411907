import React, { Component } from "react";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import VotingCall from "../../../API/Voting";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    //display:"flex",
    //justifyContent:"end",
    //marginBottom:"10px"
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    marginTop: "10px",
    //paddingTop:"5px"
  },
  paperFilter: {
    width: "90%",
    padding: "2%",
  },
  formControl: {
    width: "50%",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  SectionPaper: {
    width: "100%",
    padding: "1%",
    //marginTop:"1%",
    marginBottom: "2%",
  },
  actionBtn: {
    margin: "5px",
  },
  Skeleton: {
    width: "100%",
    height: "200px",
    //marginTop:"20px"
    borderRadius: "0px 0px 10px 10px",
  },
  Subtitle: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  GridSectionItem: {
    //marginTop:"15px"
  },
  Subtitle2: {
    borderRadius: "5px 5px 5px 5px",
    backgroundColor: "#7f8c8d",
    color: "rgb(247, 244, 244)",
    marginTop: "5px",
  },
  ImgPlaceholder: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  ImgProp: {
    width: "100%",
    height: "100%",
    //padding:"1px",
    borderRadius: "2px 2px 2px 2px",
  },
  btn: {
    width: "100%",
  },
  Table: {
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "150px",
  },
  TimePicker: {
    marginTop: "10px",
    width: "100%",
    minHeight: "140px",
    padding: "2%",
  },
  DataTimePicker: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  BtnSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "5px",
  },
  PaperDialogNewCandidate: {
    padding: "5px",
  },
  SubSection: {
    width: "100%",
    marginTop: "10px",
    minHeight: "200px",
    maxHeight: "300px",
  },
  SubSectionBottom: {
    width: "100%",
    marginTop: "2px",
    minHeight: "100px",
    maxHeight: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  SubSectionTop: {
    width: "100%",
    marginTop: "2px",
    minHeight: "100px",
    maxHeight: "50%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  TextShow: {
    width: "100%",
    marginTop: "10px",
    marginLeft: "10px",
    minHeight: "50px",
    maxHeight: "33%",
  },
});

class EmergencyStop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Time: {},
      DialogDelete: false,
    };
  }
  componentDidMount = async () => {
    try {
      console.log("props,on stop", this.props);
      if (this.props.ElectionTime) {
        this.setState({ Time: this.props.ElectionTime });
      } else {
        //toastType.error("Información perdida en los tiempos de votación");
      }
    } catch (error) {
      //console.log(error);
      //toastType.error("Información perdida en los tiempos de votación");
    }
  };
  DialogControl = (DIALOG, VALUE) => {
    if (VALUE) {
      this.setState({ [DIALOG]: true });
    } else {
      this.setState({ [DIALOG]: false });
    }
  };
  trueDelete = async () => {
    try {
      const Stop = await VotingCall.Stop(this.props.UuidElection);
      if (Stop.status === "success") {
        toastType.success("Elección detenida");
        this.props.history.push("/Votaciones");
      } else {
        toastType.error(" SSE  No fue posible detener la Elección");
      }
    } catch (e) {
      console.log(e);
      toastType.error(" CSE  No fue posible detener la Elección");
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Typography
          align="Center"
          variant="body2"
          color="textPrimary"
          component="p"
        >
          El Paro de emergencia se detendrá la elección,todos los dispositivos
          dejaran de poder votar y los registros serán eliminados, esta acción
          sera informada por correo a todo los administradores
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.DialogControl.bind(this, "DialogDelete", true)}
        >
          Detener Elección
        </Button>

        <Dialog
          open={this.state.DialogDelete}
          onClose={this.DialogControl.bind(this, "DialogDelete", false)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Borrar</DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              color="textPrimary"
              component="p"
              className={classes.Title}
            >
              Esta acción es permanente y sera registrada
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogControl.bind(this, "DialogDelete", false)}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
            <Button
              onClick={this.trueDelete}
              variant="contained"
              color="primary"
            >
              Borrar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(EmergencyStop);
