import React from "react";
import MUIDataTable from "mui-datatables";
import { Checkbox } from "@material-ui/core";

function CompaniesIntTheEvent({ List, handleChange }) {
  console.log("asdf", List);
  const columns = ["Empresa", "Seleccionada"];
  const options = {
    textLabels: {
      body: {
        noMatch: "Estableciendo conexión",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente pagina",
        previous: "Pagina anterior",
        rowsPerPage: "Columnas por pagina:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tablas",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la tabla",
      },
      selectedRows: {
        text: "fila(s) seleccionadas",
        delete: "Borrar",
        deleteAria: "Borrar Filas seleccionadas",
      },
    },
    filterType: "textField",
    page: 0,
    selectableRows: "none",
    print: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 50, 100, 200],
  };
  return (
    <MUIDataTable
      title={"Empresas a cargo"}
      data={List.map((company) => {
        return [
          company.name,
          <Checkbox
            size="small"
            checked={company.enable}
            onChange={() => {
              handleChange(company.id);
            }}
          />,
        ];
      })}
      columns={columns}
      options={options}
      selectableRows="none"
    />
  );
}

export default CompaniesIntTheEvent;
