import base from "../../baseurl";
import token from "../../getTk";

const Update = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const update = await api
    .post("bulletins-general/update", {
      timeout: false,
      json: {
        title: state.EditTitle,
        description: state.EditDescrip,
        img: state.EditPdfImg,
        pdf: state.EditPdfCont,
        links: state.links.toString(),
        bulletinUuid: state.EditUuid,
        isSportBulletin: true,
      },
    })
    .json();

  return update;
};

export default Update;
