import ShowAll from "./ShowAll";
import Show from "./Show";
import Create from "./Create";
import Delete from "./Delete";
import Update from "./Update";
import ShowResults from "./ShowResults";
import ShowResultCompany from "./ShowResultCompany";

const SportEvents = {
  ShowAll,
  Create,
  Delete,
  Show,
  Update,
  ShowResults,
  ShowResultCompany,
};

export default SportEvents;
