import ShowAll from "./ShowAll";
import Types from "./TypesDelivery";
import Delete from "./DeleteEvent";
import Create from "./CreateEvent";
import States from "./StatesOfUsers";
import Participant from "./ShowAllParticipan";
import UpdateState from "./UpdateState";
import Documents from "./Docs";
import ShowAllSons from "./ShowAllSons";
import DocFromSons from "./DocFromSons";
import GetSons from "./GetSons";
import UpdateSons from "./UpdateSons";
const Delibery = {
  ShowAll,
  Types,
  Delete,
  Create,
  States,
  Participant,
  UpdateState,
  Documents,
  ShowAllSons,
  DocFromSons,
  GetSons,
  UpdateSons,
};
export default Delibery;
