import base from "../baseurl";
import token from "../getTk"

const Types = async () => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Country = await api.get("consultation-types/index").json();
    return Country.data.consultationTypes ;
    
  };
  
  export default  Types ;