import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import Skeleton from "./SkeletonGrid";
import SeccionsCall from "../../../API/Sections";
import toasType from "../../../API/CallUtils/tostyNotification";
import CompaniesCall from "../../../API/Companies";
import DialogEdit from "./DialogEditCompany";
import EditIcon from "@material-ui/icons/Edit";

const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "200px",
    maxHeight: "500px",
    overflow: "auto",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
    //marginTop:'1%',
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
  padding: {
    padding: "5px",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  full: {
    width: "100%",
  },
});

class asesorInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditDialog: false,
      ConectionState: "onLoad",
      ConectionState1: "onLoad",
      List: [],
      invertList: [],
    };
  }

  componentDidMount = async () => {
    this.CompanyList();
    this.RefreshList();
  };
  RefreshList = async () => {
    this.setState({ ConectionState: "onLoad" });
    try {
      const AsesorComp = await SeccionsCall.Show(this.props.info.Uuid);
      if (AsesorComp) {
        let FilterList = this.state.invertList;
        AsesorComp.forEach((x) => {
          FilterList.splice(
            FilterList.findIndex((element) => element.uuid === x.uuid),
            1
          );
        });
        this.setState({
          List: AsesorComp,
          invertList: FilterList,
          ConectionState: "onSuccess",
        });
      } else {
        this.setState({
          ConectionState: "onNull",
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        ConectionState: "onError",
      });
      toasType.error("Error en la carga de datos");
    }
  };
  CompanyList = async () => {
    this.setState({ ConectionState1: "onLoad" });
    try {
      let CompaniesNotS = await CompaniesCall.showAllNotSta();
      if (CompaniesNotS) {
        this.setState({
          invertList: CompaniesNotS,
          ConectionState1: "onSuccess",
        });
      } else {
        this.setState({
          ConectionState1: "onError",
        });
        toasType.error("Error en la lista de Compañías");
      }
    } catch (e) {
      this.setState({
        ConectionState1: "onError",
      });
      toasType.error("Error en la lista de Compañías");
    }
  };
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  Refresh = () => {
    this.CompanyList();
    this.RefreshList();
    this.props.Refresh();
    this.setState({ EditDialog: false });
  };
  render() {
    const { classes } = this.props;
    switch (this.state.ConectionState) {
      case "onLoad":
        return (
          <Skeleton Name="Listado de Empresas" className={classes.Skeleton} />
        );
      case "onSuccess":
        return (
          <>
            {" "}
            <Grid className={classes.Initial}>
              <Grid container className={classes.Initial} spacing={1}>
                <Grid container item xs={12} className={classes.Initial}>
                  <Typography
                    align="Center"
                    variant="h5"
                    component="p"
                    className={classes.Subtitle}
                  >
                    Listado de Empresas
                  </Typography>
                </Grid>
                <Paper className={classes.Paper}>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    className={classes.TableCompany}
                  >
                    <TableBody className={classes.TableCompany}>
                      {this.state.List.map((companie) => {
                        return (
                          <React.Fragment>
                            <TableRow key={companie.Uuid}>
                              <TableCell component="th" scope="row">
                                {companie.name}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.padding}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.Dialog.bind(this, "EditDialog")}
                    >
                      <EditIcon />
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            {this.state.EditDialog && (
              <DialogEdit
                DialogOpen={this.state.EditDialog}
                DialogClose={this.Dialog.bind(this, "EditDialog")}
                Refresh={this.Refresh}
                add={this.state.List}
                not={this.state.invertList}
                endLoad={
                  this.state.ConectionState === "onSuccess" &&
                  this.state.ConectionState1 === "onSuccess"
                    ? true
                    : false
                }
                info={this.props.info}
              />
            )}
          </>
        );
      case "onError":
        return (
          <React.Fragment>
            <Grid
              item
              xs={11}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography
                align="Center"
                variant="h5"
                component="p"
                className={classes.Subtitle}
              >
                Listado de Empresas
              </Typography>
              <Paper className={classes.load}>
                <Typography variant="h6" gutterBottom align="center">
                  No fue posible obtener la Información
                </Typography>
              </Paper>
            </Grid>
          </React.Fragment>
        );
      case "onNull":
        return (
          <React.Fragment>
            <Grid
              item
              xs={11}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Typography
                align="Center"
                variant="h5"
                component="p"
                className={classes.Subtitle}
              >
                Listado de Empresas
              </Typography>
              <Paper className={classes.load}>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  className={classes.full}
                >
                  Sin Compañías Asignadas
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.padding}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.Dialog.bind(this, "EditDialog")}
                  >
                    <EditIcon />
                  </Button>
                </Grid>
                {this.state.EditDialog && (
                  <DialogEdit
                    DialogOpen={this.state.EditDialog}
                    DialogClose={this.Dialog.bind(this, "EditDialog")}
                    Refresh={this.Refresh}
                    add={this.state.List}
                    not={this.state.invertList}
                    info={this.props.info}
                  />
                )}
              </Paper>
            </Grid>
          </React.Fragment>
        );
      default:
        return (
          <Skeleton Name=" Sin Información" className={classes.Skeleton} />
        );
    }
  }
}

export default withStyles(styles)(asesorInfo);
