import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Button,
  Grid,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { BasicInfo, Announcemnt } from "../componetsUtils";
import Skeleton from "@material-ui/lab/Skeleton";
import VotingCall from "../../../API/Voting";
import toastType from "../../../API/CallUtils/tostyNotification";
import CallUtils from "../../../API/CallUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    //display:"flex",
    //justifyContent:"end",
    //marginBottom:"10px"
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    marginTop: "10px",
    //paddingTop:"5px"
  },
  paperFilter: {
    width: "90%",
    padding: "2%",
  },
  formControl: {
    width: "50%",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  SectionPaper: {
    width: "100%",
    padding: "1%",
    //marginTop:"1%",
    marginBottom: "2%",
  },
  actionBtn: {
    margin: "5px",
  },
  Skeleton: {
    width: "100%",
    height: "200px",
    //marginTop:"20px"
    borderRadius: "0px 0px 10px 10px",
  },
  Subtitle: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  GridSectionItem: {
    //marginTop:"15px"
  },
  Subtitle2: {
    borderRadius: "5px 5px 5px 5px",
    backgroundColor: "#7f8c8d",
    color: "rgb(247, 244, 244)",
    marginTop: "5px",
  },
  ImgPlaceholder: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  ImgProp: {
    width: "100%",
    height: "100%",
    //padding:"1px",
    borderRadius: "2px 2px 2px 2px",
  },
  btn: {
    width: "100%",
  },
  Table: {
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "150px",
  },
  TimePicker: {
    marginTop: "10px",
    width: "100%",
    minHeight: "140px",
    padding: "2%",
  },
  DataTimePicker: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  BtnSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "5px",
  },
  PaperDialogNewCandidate: {
    padding: "5px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class V_NEW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoad: true,
      ElectionType: 1,
      candidatePosition: null,
      ElectionInfo: {},
      fillOnload: ["Datos generales", "Convocatoria", "Candidatos"],
      updateDialog: false,
    };
  }

  componentDidMount = async () => {
    try {
      if (this.props.location.state.UuidVoting) {
        //console.log("uuid", this.props.location.state.UuidCompanie);
        await this.fetchVotingDetails(this.props.location.state.UuidVoting);
      } else {
        console.log("El Uuid es perdido");
        this.props.history.push("/Votaciones");
      }
    } catch (error) {
      console.log(error);
      this.props.history.push("/Votaciones");
    }
  };
  fetchVotingDetails = async (UuidVoting) => {
    try {
      this.setState({ onLoad: true });
      const Polute = await VotingCall.showDetails(UuidVoting);
      console.log("datos de la elección", Polute);

      if (Polute.status === "success") {
        this.setState({
          ElectionType: Polute.data.type,
          ElectionInfo: Polute.data,
          candidatePosition: Polute.data.employment,
          onLoad: false,
        });
      } else {
        toastType.error("Error en el servidor");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log("El error", error);
    }
  };
  changeDate = (name, acceptanceDate) => {
    var fecha = moment(new Date(acceptanceDate)).toISOString();
    this.setState((prevState) => ({
      ElectionInfo: {
        ...prevState.ElectionInfo,
        [name]: fecha,
      },
    }));
  };
  onChange = (event) => {
    let nameA = event.target.name;
    let value = event.target.value;
    this.setState((prevState) => ({
      ElectionInfo: {
        ...prevState.ElectionInfo,
        [nameA]: value,
      },
    }));
  };
  addIMG = async (Name, e) => {
    this.setState({
      onSave: true,
    });
    let principal = await this.SendImg(
      e.target.files[0],
      "SITIMM_VOTING_announcement_IMG" + this.props.location.state.UuidVoting
    );
    this.setState((prevState) => ({
      ElectionInfo: {
        ...prevState.ElectionInfo,
        [Name]: principal,
      },
    }));
  };
  addDoc = async (Name, e) => {
    this.setState({
      onSave: true,
    });
    let principal = await this.SendImg(
      e.target.files[0],
      "SITIMM_VOTING_announcement_DOC" + this.props.location.state.UuidVoting
    );

    let copy = [...this.state.ElectionInfo.announcement, principal];
    this.setState((prevState) => ({
      ElectionInfo: {
        ...prevState.ElectionInfo,
        [Name]: copy,
      },
    }));
  };
  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };
  deleteDoc = (i, Name1) => {
    let value = this.state.ElectionInfo.announcement.filter(
      (_item, j) => i !== j
    );
    this.setState((prevState) => ({
      ElectionInfo: {
        ...prevState.ElectionInfo,
        announcement: value,
      },
    }));
  };
  backToSowAll = () => {
    this.props.history.push("/Votaciones");
  };
  openPublishDialog = () => {
    this.setState({
      updateDialog: true,
    });
  };
  DialogClose = async (Name) => {
    this.setState({ [Name]: false });
  };
  updateElectionState = async () => {
    try {
      if (true) {
        this.setState({ onSendTo: true });
        const Update = await VotingCall.Update(
          this.state.ElectionInfo,
          this.props.Uuid,
          2
        );
        if (Update.status === "success") {
          toastType.success(
            "Publicada correctamente, Su votación paso al listado 'Programadas' "
          );
          this.setState({ onSendTo: false });
          this.props.history.push("/Votaciones");
        } else {
          toastType.error("Error en la publicación, server side error");
          this.setState({ onSendTo: false });
          console.log(Update);
        }
      } else {
        toastType.error(
          "Error algunas de las fechas designadas tiene conflictos lógicos "
        );
        this.setState({ onSendTo: false });
      }
    } catch (error) {
      toastType.error("Error en la conexión, unknown network error");
      console.log(error);
      this.setState({ onSendTo: false });
    }
  };
  validateDates = () => {
    let a = moment(this.state.ElectionInfo.StartAnnouncement);
    let b = moment(this.state.ElectionInfo.EndAnnouncement);
    let c = moment(this.state.ElectionInfo.StartElecction);
    let diffDays = b.diff(a, "days", true);
    if (diffDays > 14) {
      let diffhours = c.diff(b, "hours", true);
      console.log(diffhours);
      if (diffhours >= 23 && diffhours <= 49) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  render() {
    const { classes } = this.props;
    if (this.state.onLoad) {
      return (
        <Paper className={classes.paperFilter}>
          {this.state.fillOnload.map((type) => {
            return (
              <Paper className={classes.SectionPaper}>
                <Typography
                  align="Center"
                  variant="h5"
                  component="p"
                  className={classes.Subtitle}
                >
                  {type}
                </Typography>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  className={classes.Skeleton}
                />
              </Paper>
            );
          })}
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.paperFilter}>
          <Typography
            align="Center"
            variant="h5"
            color="textPrimary"
            component="p"
            className={classes.Subtitle}
          >
            Datos generales
          </Typography>
          <Paper className={classes.SectionPaper} elevation={3}>
            <BasicInfo ElectionInfo={this.state.ElectionInfo} />
          </Paper>

          <Typography
            align="Center"
            variant="h5"
            color="textPrimary"
            component="p"
            className={classes.Subtitle}
          >
            Convocatoria
          </Typography>
          <Paper className={classes.SectionPaper} elevation={3}>
            {this.state.ElectionInfo
              ? [
                  <Announcemnt
                    AnnouncemntImg={this.state.ElectionInfo.img}
                    AnnouncemntText={this.state.ElectionInfo.description}
                    AnnouncemntDoc={
                      this.state.ElectionInfo.announcement
                        ? this.state.ElectionInfo.announcement
                        : []
                    }
                    AnnouncemntInitTime={
                      this.state.ElectionInfo.StartAnnouncement
                    }
                    AnnouncemntEndTime={this.state.ElectionInfo.EndAnnouncement}
                    ElectionStarTime={this.state.ElectionInfo.StartElecction}
                    ElectionEndTime={this.state.ElectionInfo.EndElection}
                    onChangeTimes={this.changeDate}
                    onChangeText={this.onChange}
                    addIMG={this.addIMG}
                    addDoc={this.addDoc}
                    deleteDoc={this.deleteDoc}
                  />,
                ]
              : [
                  <Announcemnt
                    AnnouncemntImg={""}
                    AnnouncemntText={""}
                    AnnouncemntDoc={""}
                    AnnouncemntInitTime={""}
                    AnnouncemntEndTime={""}
                    ElectionEndTime={""}
                  />,
                ]}
          </Paper>
          <div className={classes.SectionPaper}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
              spacing={1}
            >
              <Button
                color="Secondary"
                variant="contained"
                onClick={this.backToSowAll}
                className={classes.actionBtn}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.openPublishDialog}
                className={classes.actionBtn}
              >
                Publicar
              </Button>
            </Grid>
          </div>
          <Dialog
            open={this.state.updateDialog}
            onClose={this.DialogClose.bind(this, "updateDialog")}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">Publicar</DialogTitle>
            <DialogContent>
              <Typography
                variant="body1"
                color="textPrimary"
                component="p"
                className={classes.Title}
              >
                Esta elección sera programada y se habilitara el espacio para la
                captura de de candidatos autorizados.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.DialogClose.bind(this, "updateDialog")}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>

              {this.state.onSendTo
                ? [
                    <Button color="primary" disabled variant="contained">
                      <CircularProgress />
                    </Button>,
                  ]
                : [
                    <Button
                      onClick={this.updateElectionState}
                      variant="contained"
                      color="primary"
                    >
                      Publicar
                    </Button>,
                  ]}
            </DialogActions>
          </Dialog>
        </Paper>
      );
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(V_NEW));
