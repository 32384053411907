import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
} from "@material-ui/core";
import apiCall from "../../../API/CallUtils";
import { OnLoadSelect } from "../../../Components/StatesForComponets";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
    marginBottom: "8px",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
class SelectedCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = { List: [] };
  }
  componentDidMount = async () => {
    try {
      const getCitiesList = await apiCall.GtoCitys();
      if (getCitiesList && getCitiesList.length > 0) {
        this.setState({
          List: getCitiesList,
        });
      } else {
        console.log("server error");
      }
    } catch (e) {
      console.log("error, client error");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.Table}>
        {this.props.ListAdvisors.length > 0
          ? [
              <FormControl className={classes.Table}>
                <InputLabel>Asesor o Asesores </InputLabel>
                <Select
                  value={this.props.SelectedAdvisors}
                  multiple
                  variant="outlined"
                  input={<Input />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={
                            value.UserProfile.name +
                            " " +
                            value.UserProfile.lastNames
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  onChange={(event) => {
                    this.props.sentoParent("advisorsUuid", event.target.value);
                  }}
                >
                  {this.props.ListAdvisors.map((Advisor, index) => {
                    return (
                      <MenuItem value={Advisor} key={Advisor.uuid}>
                        {Advisor.UserProfile.name +
                          " " +
                          Advisor.UserProfile.lastNames}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>,
            ]
          : [<OnLoadSelect />]}
      </Grid>
    );
  }
}

export default withStyles(styles)(SelectedCity);
