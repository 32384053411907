import base from "../baseurl";
import token from "../getTk"


const Get_Imgs = async (location) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Update = await api.post("imgs/showall",{json: {
        filter:location,
    },
    timeout:false
}).json();
   console.log(Update.data)
    return Update.data ;
    
  };
  
  export default  Get_Imgs ;