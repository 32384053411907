import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Avatar,
} from "@material-ui/core";
import toastType from "../../API/CallUtils/tostyNotification";
import User from "../../API/Users";
const styles = (theme) => ({
  PaperDialog: {
    //height: "85vh",
    width: "100%",
    //padding: "1%",
  },
  Radio: {
    margin: "2%",
    width: "51%",
  },
  textField: {
    width: "88%",
  },
});

class EditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { Uuid: "", pass: "", VerifyPass: "" };
  }
  componentDidMount = async () => {
    try {
      console.log(this.props.Info);
      if (this.props.Info) {
        this.setState({
          Uuid: this.props.Info.uuid,
        });
      } else {
        toastType.error("No hay datos");
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  Save = async () => {
    try {
      console.log(this.state.pass, this.state.VerifyPass);
      if (this.state.pass === this.state.VerifyPass) {
        var State = {
          Uuid: this.state.Uuid,
          pass: this.state.pass,
        };

        const save = await User.ResetPass(State);
        if (save.status === "success") {
          this.props.Refresh("EditPasswordDialog");
          toastType.success("Guardado");
        } else {
          toastType.error("No fue posible Guardar");
        }
      } else {
        toastType.error("Las contraseñas no son iguales");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log(error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="md"
      >
        <DialogTitle>Editar</DialogTitle>
        <DialogContent className={classes.PaperDialog}>
          <Paper className={classes.PaperDialog}>
            <Grid
              item
              xs={12}
              className={classes.PaperDialog}
              container
              //direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                container
                iteam
                xs={12}
                //direction="column"
                justify="center"
                alignItems="center"
                className={classes.PaperDialog}
              >
                <Avatar className={classes.large} />
              </Grid>
              <Grid
                container
                iteam
                xs={12}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <TextField
                  label="Nueva Contraseña"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  onChange={this.onChange}
                  name="pass"
                  type="password"
                  value={this.state.pass}
                />
                <TextField
                  label="Repita su contraseña"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  onChange={this.onChange}
                  name="VerifyPass"
                  type="password"
                  value={this.state.VerifyPass}
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={this.Save} color="primary">
            Guardar
          </Button>
          ,
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EditDialog);
