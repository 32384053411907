import base from "../baseurl";
import token from "../getTk";

const Show = async (Uuid) => {
  const tokenA = token.get();

  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const status = await api
    .post("other-assembly-members/showall", { json: { companyId: Uuid } })
    .json();
  //console.log(status);
  return status.data;
};

export default Show;
