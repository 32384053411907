import React from "react";
import {
  Grid,
  withStyles,
  TextField,
  Button,
  Typography,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
//import toastType from "../../../API/CallUtils/tostyNotification";
import LinkIcon from "@material-ui/icons/Link";
import AddBoxIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  GridSectionItem: {
    //marginTop:"15px"
  },
  Subtitle2: {
    borderRadius: "5px 5px 5px 5px",
    backgroundColor: "#7f8c8d",
    color: "rgb(247, 244, 244)",
    marginTop: "5px",
  },
  ImgPlaceholder: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  ImgProp: {
    width: "100%",
    height: "100%",
    //padding:"1px",
    borderRadius: "2px 2px 2px 2px",
  },
  btn: {
    width: "100%",
  },
  Table: {
    marginTop: "10px",
    overflow: "auto",
    maxHeight: "150px",
  },
  TimePicker: {
    marginTop: "10px",
    width: "100%",
    minHeight: "140px",
    padding: "2%",
  },
  DataTimePicker: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  BtnSection: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "5px",
  },
  input: {
    display: "none",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    //display:"flex",
    //justifyContent:"end",
    //marginBottom:"10px"
  },
  Text: {
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
  },
});
class announcement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Election: {},
      Doc: [],
      VotingImg:
        "https://sitimm-files-dev.nyc3.digitaloceanspaces.com/SITIMM_VOTING_announcement_DOC/tt.jpg",
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.ElectionInfo) {
        this.setState({ Election: this.props.ElectionInfo });
      } else {
        //console.log("La informacion de la votacion esta perdida ");
      }
    } catch (error) {
      console.log(error);
    }
  };
  compareTime = (init, end) => {
    var a = moment(init);
    var b = moment(end);
    var diffDays = b.diff(a, "days", true);

    if (diffDays < 1) {
      var diffhours = b.diff(a, "hours", true);
      if (this.props.Programed) {
        return diffhours < 0 ? "Ya ocurrió" : diffhours.toFixed(2) + " Horas";
      } else {
        return diffhours < 0 ? "Ahora" : diffhours.toFixed(2) + " Horas";
      }
    }
    if (diffDays > 365) {
      diffhours = b.diff(a, "years", true);
      return diffhours.toFixed(0) + " años";
    } else {
      return diffDays.toFixed(0) + " Dias";
    }
  };
  render() {
    const { classes } = this.props;
    if (this.props.Programed) {
      return (
        <>
          <Grid
            container
            direction="row"
            justify="start"
            alignItems="start"
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Imagen
              </Typography>

              {this.props.AnnouncemntImg ? (
                <Paper className={classes.ImgPlaceholder}>
                  <img
                    src={this.props.AnnouncemntImg}
                    alt="placeholder"
                    className={classes.ImgProp}
                  />
                </Paper>
              ) : (
                <Paper className={classes.ImgPlaceholder}>
                  <Typography
                    align="Center"
                    variant="body2"
                    color="textPrimary"
                    component="p"
                  >
                    Sin imagen
                  </Typography>
                </Paper>
              )}
              <div className={classes.Remp}>
                <input
                  type="file"
                  onChange={this.props.addIMG.bind(this, "img")}
                  className={classes.input}
                  id="newImg"
                  required
                />
                <label htmlFor="newImg">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small"
                    className={classes.btn}
                  >
                    <AddBoxIcon />
                  </Button>
                </label>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Resumen
              </Typography>
              <TextField
                name="description"
                value={this.props.AnnouncemntText}
                className={classes.Text}
                onChange={this.props.onChangeText.bind(this)}
                variant="outlined"
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="start"
            alignItems="start"
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Fechas
              </Typography>
              <Paper className={classes.TimePicker}>
                <DatePicker
                  label="Publicación de convocatoria"
                  inputVariant="outlined"
                  value={this.props.StartAnnouncement}
                  className={classes.DataTimePicker}
                  readOnly
                  disabled
                />
                <DatePicker
                  label="Finalization de convocatoria"
                  inputVariant="outlined"
                  value={this.props.AnnouncemntEndTime}
                  className={classes.DataTimePicker}
                  readOnly
                  disabled
                />
                <DatePicker
                  label="Inicio de la votación"
                  inputVariant="outlined"
                  value={this.props.ElectionStarTime}
                  className={classes.DataTimePicker}
                  readOnly
                  disabled
                />
                <DatePicker
                  label="Finalization de la votación"
                  inputVariant="outlined"
                  value={this.props.ElectionEndTime}
                  className={classes.DataTimePicker}
                  readOnly
                  disabled
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Duración
              </Typography>
              <Paper className={classes.TimePicker}>
                <TextField
                  label="Tiempo para el lanzamiento"
                  name="Title"
                  className={classes.Text}
                  onChange={this.onChange}
                  variant="outlined"
                  size="small"
                  value={
                    this.props.AnnouncemntInitTime
                      ? this.compareTime(
                          moment(),
                          this.props.AnnouncemntInitTime
                        )
                      : "Seleccione la fecha de inicio"
                  }
                />
                <TextField
                  label="Duración de la convocatoria"
                  name="Duration"
                  value={
                    this.props.AnnouncemntInitTime &&
                    this.props.AnnouncemntEndTime
                      ? this.compareTime(
                          this.props.AnnouncemntInitTime,
                          this.props.AnnouncemntEndTime
                        )
                      : "Seleccione ambas fechas"
                  }
                  className={classes.Text}
                  //onChange={this.onChange}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  label="Duración de la Votación"
                  name="Duration"
                  value={
                    this.props.ElectionStarTime && this.props.ElectionEndTime
                      ? this.compareTime(
                          this.props.ElectionStarTime,
                          this.props.ElectionEndTime
                        )
                      : "Seleccione ambas fechas"
                  }
                  className={classes.Text}
                  //onChange={this.onChange}
                  variant="outlined"
                  size="small"
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Documentos
              </Typography>
              {this.props.AnnouncemntDoc.length > 0 ? (
                <Paper className={classes.Table}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Link</TableCell>
                        <TableCell>Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.AnnouncemntDoc.map((Article, index) => {
                        return (
                          <React.Fragment>
                            <TableRow key={Article}>
                              <TableCell component="th" scope="row">
                                <a href={Article}>
                                  <Button color="primary">
                                    <LinkIcon />
                                  </Button>
                                </a>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  color="secondary"
                                  onClick={this.props.deleteDoc}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              ) : (
                <Paper className={classes.ImgPlaceholder}>
                  <Typography
                    align="Center"
                    variant="body2"
                    color="textPrimary"
                    component="p"
                  >
                    Sin Documentos
                  </Typography>
                </Paper>
              )}
              <div className={classes.Remp}>
                <input
                  type="file"
                  onChange={this.props.addDoc.bind(this, "announcement")}
                  className={classes.input}
                  id="NewDoc"
                  required
                />
                <label htmlFor="NewDoc">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                    size="small"
                  >
                    <AddBoxIcon />
                  </Button>
                </label>
              </div>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid
            container
            direction="row"
            justify="start"
            alignItems="start"
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Imagen
              </Typography>

              {this.props.AnnouncemntImg ? (
                <Paper className={classes.ImgPlaceholder}>
                  <img
                    src={this.props.AnnouncemntImg}
                    alt="placeholder"
                    className={classes.ImgProp}
                  />
                </Paper>
              ) : (
                <Paper className={classes.ImgPlaceholder}>
                  <Typography
                    align="Center"
                    variant="body2"
                    color="textPrimary"
                    component="p"
                  >
                    Sin imagen
                  </Typography>
                </Paper>
              )}
              <div className={classes.Remp}>
                <input
                  type="file"
                  onChange={this.props.addIMG.bind(this, "img")}
                  className={classes.input}
                  id="newImg"
                  required
                />
                <label htmlFor="newImg">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small"
                    className={classes.btn}
                  >
                    <AddBoxIcon />
                  </Button>
                </label>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Resumen
              </Typography>
              <TextField
                name="description"
                value={this.props.AnnouncemntText}
                className={classes.Text}
                onChange={this.props.onChangeText.bind(this)}
                variant="outlined"
                multiline
                rows={6}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="start"
            alignItems="start"
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Fechas
              </Typography>
              <Paper className={classes.TimePicker}>
                <DatePicker
                  label="Publicación de convocatoria"
                  inputVariant="outlined"
                  value={this.props.AnnouncemntInitTime}
                  onChange={this.props.onChangeTimes.bind(
                    this,
                    "StartAnnouncement"
                  )}
                  className={classes.DataTimePicker}
                />
                <DatePicker
                  label="Finalization de convocatoria"
                  inputVariant="outlined"
                  value={this.props.AnnouncemntEndTime}
                  onChange={this.props.onChangeTimes.bind(
                    this,
                    "EndAnnouncement"
                  )}
                  className={classes.DataTimePicker}
                />
                <DatePicker
                  label="Inicio de la votación"
                  inputVariant="outlined"
                  value={this.props.ElectionStarTime}
                  onChange={this.props.onChangeTimes.bind(
                    this,
                    "StartElecction"
                  )}
                  className={classes.DataTimePicker}
                />
                <DatePicker
                  label="Finalization de la votación"
                  inputVariant="outlined"
                  value={this.props.ElectionEndTime}
                  onChange={this.props.onChangeTimes.bind(this, "EndElection")}
                  className={classes.DataTimePicker}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Duración
              </Typography>
              <Paper className={classes.TimePicker}>
                <TextField
                  label="Tiempo para el lanzamiento"
                  name="Title"
                  className={classes.Text}
                  onChange={this.onChange}
                  variant="outlined"
                  size="small"
                  value={
                    this.props.AnnouncemntInitTime
                      ? this.compareTime(
                          moment(),
                          this.props.AnnouncemntInitTime
                        )
                      : "Seleccione la fecha de inicio"
                  }
                />
                <TextField
                  label="Duración de la convocatoria"
                  name="Duration"
                  value={
                    this.props.AnnouncemntInitTime &&
                    this.props.AnnouncemntEndTime
                      ? this.compareTime(
                          this.props.AnnouncemntInitTime,
                          this.props.AnnouncemntEndTime
                        )
                      : "Seleccione ambas fechas"
                  }
                  className={classes.Text}
                  //onChange={this.onChange}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  label="Duración de la Votación"
                  name="Duration"
                  value={
                    this.props.ElectionStarTime && this.props.ElectionEndTime
                      ? this.compareTime(
                          this.props.ElectionStarTime,
                          this.props.ElectionEndTime
                        )
                      : "Seleccione ambas fechas"
                  }
                  className={classes.Text}
                  //onChange={this.onChange}
                  variant="outlined"
                  size="small"
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.GridSectionItem}
            >
              <Typography
                align="Center"
                variant="h6"
                color="textPrimary"
                component="p"
                className={classes.Subtitle2}
              >
                Documentos
              </Typography>
              {this.props.AnnouncemntDoc.length > 0 ? (
                <Paper className={classes.Table}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Link</TableCell>
                        <TableCell>Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.AnnouncemntDoc.map((Article, index) => {
                        return (
                          <React.Fragment>
                            <TableRow key={Article}>
                              <TableCell component="th" scope="row">
                                <a href={Article}>
                                  <Button color="primary">
                                    <LinkIcon />
                                  </Button>
                                </a>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  color="secondary"
                                  onClick={this.props.deleteDoc.bind(
                                    this,
                                    index
                                  )}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              ) : (
                <Paper className={classes.ImgPlaceholder}>
                  <Typography
                    align="Center"
                    variant="body2"
                    color="textPrimary"
                    component="p"
                  >
                    Sin Documentos
                  </Typography>
                </Paper>
              )}
              <div className={classes.Remp}>
                <input
                  type="file"
                  onChange={this.props.addDoc.bind(this, "announcement")}
                  className={classes.input}
                  id="NewDoc"
                  required
                />
                <label htmlFor="NewDoc">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.btn}
                    size="small"
                  >
                    <AddBoxIcon />
                  </Button>
                </label>
              </div>
            </Grid>
          </Grid>
        </>
      );
    }
  }
}

export default withStyles(styles)(announcement);
