import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import toastType from "../../../API/CallUtils/tostyNotification";
import CandidatesCall from "../../../API/Cadidates";
import DialogNewCandidate from "./addCandidateDialog";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    marginLeft: "10px",
  },
});

class candidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DialogCandidate: false,
      Candidate: [],
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.ElectionUuid) {
        this.showCandidates();
      }
    } catch (error) {
      toastType.error("Error en la obtención de trabajadores");
      console.log(error);
    }
  };
  AddCandidate = () => {
    this.setState({ DialogCandidate: true });
  };
  DialogClose = async () => {
    this.setState({ DialogCandidate: false });
    this.setState({ candidateNew: false });
    this.showCandidates();
  };
  showCandidates = async () => {
    try {
      const Can = await CandidatesCall.showAll(this.props.ElectionUuid);
      console.log("POLUTE candidates ", Can);
      if (Can.status === "success") {
        this.setState({ Candidate: Can.data });
      } else {
        toastType.error("No ha sido posible Traer a los candidatos");
      }
    } catch (error) {
      toastType.error("No ha sido posible Traer a los candidatos");
    }
  };
  deleteCandidates = async (candidateUuid) => {
    try {
      const deleteCan = await CandidatesCall.Delete(candidateUuid);
      if (deleteCan.status === "success") {
        toastType.success("Candidato Eliminado");
        this.showCandidates();
      } else {
        toastType.error("No ha sido posible Eliminar el candidato");
      }
    } catch (error) {
      toastType.error("No ha sido posible Eliminar el candidato");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.candidate} elevation={3}>
        {this.state.Candidate.length > 0
          ? [
              <>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>RFC</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Candidate.map((Article) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.Employee.User.UserProfile.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.Employee.User.UserProfile.rfc}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.deleteCandidates.bind(
                                  this,
                                  Article.uuid
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
                <div className={classes.BtnSection}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.AddCandidate}
                  >
                    <AddBoxIcon />
                  </Button>
                </div>
              </>,
            ]
          : [
              <Button
                className={classes.AddCandidate}
                onClick={this.AddCandidate}
              >
                <AddBoxIcon />
              </Button>,
            ]}
        {this.props.CompanieUuid ? (
          <DialogNewCandidate
            CompanieUuid={this.props.CompanieUuid}
            ElectionUuid={this.props.ElectionUuid}
            DialogOpen={this.state.DialogCandidate}
            DialogClose={this.DialogClose}
            type={"SINGULAR"}
            CandiatePosition={this.props.CandidatePosition}
          />
        ) : (
          <></>
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(candidates);
