import Create from "./Create";
import Update from "./Update";
import showAll from "./showAll";
import Delete from "./Delete"

const Benefits = { Update, showAll,Create,Delete };

export default Benefits;

export {
    Create,
    Update,
    showAll,
    Delete,
}