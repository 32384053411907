import Create from "./Create";
import Delete from "./Delete";
import ShowAll from "./ShowAll";
import Entities from "./Entities";
import Show from "./Show";
import Update from "./Update";
const Companies = {
  ShowAll,
  Delete,
  Create,
  Entities,
  Show,
  Update,
};

export default Companies;

export { Create, ShowAll, Delete, Entities, Show, Update };
