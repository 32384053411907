import base from "../../baseurl";
import token from "../../getTk";

const Create = async (disciplineTypeId, sportEventId, name) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const newS = await api
    .post("disciplines/update", {
      json: { disciplineTypeId, sportEventId, name },
      timeout: false,
    })
    .json();
  return newS;
};

export default Create;
