import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { OnLoadSelect } from "../../../Components/StatesForComponets";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
    marginBottom: "8px",
    maxWidth: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
class SelectedState extends React.Component {
  constructor(props) {
    super(props);
    this.state = { List: [] };
  }
  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.Table}>
        {this.props.ListOfIndustrialParks.length > 0
          ? [
              <FormControl className={classes.Table}>
                <InputLabel>Parque Industrial</InputLabel>
                <Select
                  value={this.props.industrialPark}
                  onChange={(event) => {
                    this.props.sentoParent(
                      "industrialPark",
                      event.target.value
                    );
                  }}
                >
                  {this.props.ListOfIndustrialParks.map((city, index) => {
                    return (
                      <MenuItem value={city.name} key={city.uuid}>
                        {city.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>,
            ]
          : [<OnLoadSelect />]}
      </Grid>
    );
  }
}

export default withStyles(styles)(SelectedState);
