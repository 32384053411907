import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  DialogActions,
  TextField,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Api from "../../../API/CompaniesCampaigs";
import toastType from "../../../API/CallUtils/tostyNotification";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectCompany from "../Selects/SelectCompany";
import SelectForms from "../Selects/SelectForms";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  Title: {
    width: "90%",
  },
  CloseBtn: {
    //width: "10%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  text: {
    width: "80%",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      name: "",
      formId: "",
      companies: [],
      id: "",
    };
  }
  componentDidMount = async () => {
    this.fetchlist();
  };
  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.Show(this.props.EventId);
      console.log(Deli);
      if (Deli.status === "success") {
        this.setState({
          name: Deli.data.campaign.campaign.name,
          formId: Deli.data.campaign.formDetails[0].formDetail.CompanyFormId,
          companies: Deli.data.campaign.formDetails[0].companies.map((comp) => {
            return { id: comp.id, name: comp.name };
          }),
          id: Deli.data.campaign.campaign.id,
          enabled: Deli.data.campaign.campaign.enabled,
          onLoad: false,
          onError: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  selectChange = async (name, value) => {
    this.setState({ [name]: value });
  };
  deleteCompanies = (index) => {
    let value = this.state.companies.filter((item, j) => index !== j);
    this.setState({ companies: value });
  };
  multiSelectChange = (name, value) => {
    this.setState({ [name]: [...this.state[name], value] });
  };
  Send = async () => {
    try {
      this.setState({
        Oncreate: true,
      });
      if (
        this.state.name.length > 0 &&
        this.state.formId &&
        this.state.companies.length > 0
      ) {
        const Deli = await Api.Update(this.state);
        console.log(Deli);
        if (Deli.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se creado con éxito");
          await this.props.Refresh("EditDialog");
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible Eliminar el elemento");
        }
      } else {
        if (!this.state.name.length > 0) {
          toastType.error("No se puede actualizar con nombre vació");
        }
        if (!this.state.formId) {
          toastType.error("No se puede actualizar Sin Formulario");
        }
        if (!this.state.companies.length > 0) {
          toastType.error("No se puede actualizar Sin compañías");
        }
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        TransitionComponent={Transition}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.Title}>Preguntas</Typography>
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <TextField
              label="Nombre de la campaña"
              margin="normal"
              size="small"
              name="name"
              onChange={this.onChange}
              className={classes.text}
              value={this.state.name}
            />
            <SelectForms
              Select={this.selectChange}
              formId={this.state.formId}
            />
            <SelectCompany
              Select={this.multiSelectChange}
              companies={this.state.companies}
            />
            {this.state.companies.length > 0 && (
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Empresa</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.companies.map((Article, index) => {
                    return (
                      <React.Fragment>
                        <TableRow key={Article.id}>
                          <TableCell component="th" scope="row">
                            {Article.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={this.deleteCompanies.bind(this, index)}
                              className={classes.btn}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cerrar
          </Button>
          <Button onClick={this.Send} color="Primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
