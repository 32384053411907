import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Places from "../../../API/CallUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Company from "../../../API/Companies";

const styles = (theme) => ({
  paper: {
    //height: "90vh",
    width: "80%",
    //marginLeft:"20%",
    padding: "2%",
  },

  Grid: {
    width: "100%",
    height: "100%",
  },

  textField: {
    // marginLeft:"20px"
    width: "88%",
  },
  textFieldM: {
    width: "70%",
  },

  button: {
    marginLeft: "20px",
    marginTop: "24px",
    width: "25%",
  },

  div: {
    display: "flex",
    width: "96%",
    justifyContent: "end",
    marginTop: "3%",
  },

  AvatarDiv: {
    width: "97%",
    display: "flex",
  },
  AvatarDiv1: {
    width: "50%",
    display: "flex",
    marginTop: "4%",
    marginLeft: "2%",
  },
  textFieldAvatar: {
    width: "100%",
    marginTop: "1%",
    marginLeft: "2%",
  },
  PersonDiv: {
    width: "100%",
  },
  textFieldPerson: {
    width: "90%",
  },
  DatosDiv: {
    width: "100%",
  },
  ContratoDiv: {
    width: "100%",
  },
  Asesores: {
    width: "97%",
  },

  Select: {
    marginTop: "16px",
    marginBottom: "8px",
    width: "100%",
  },
  SelectState: {
    marginBottom: "8px",
    width: "100%",
  },
  formControl: {
    width: "90%",
  },
  btn: {
    display: "flex",
    width: "100%",
    justifyContent: "end",
    marginTop: "3%",
  },

  List: {
    width: "100%",
    maxHeight: "50vh",
    overflow: "auto",
  },
  ListIteam: {
    width: "90%",
    margin: "2%",
  },
  Dialog: {
    width: "50wv",
    height: "50hv",
  },
  statistics: {},
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class CompanieDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Uuid: "",
      Td_Uuid: "",
      B_Uuid: "",
      name: "",
      phone: "",
      address: "",
      country: "",
      operationStart: "",
      product: "",
      clients: "",
      industrialPark: "",
      acceptanceDate: "",
      StateUuid: "",
      CityUuid: undefined,
      contract: "",
      chrismasBox: "",
      attendanceBonus: "",
      holidayBonus: "",
      savingFund: "",
      utilities: "",
      vouchers: "",
      lunchBonus: "",
      transportBonus: "",
      delegate: "",
      other: "",
      ciudad: "",
      estado: "",
      Estados: [],
      Ciudades: [],
      rh: {
        uuid: "",
        name: "",
        charge: "gerente_rh",
        phone: "",
        email: "",
      },
      productionManager: {
        name: "",
        charge: "gerente_genereal",
        phone: "",
        email: "",
      },
      manager: {
        name: "",
        charge: "gerente_de_produccion",
        phone: "",
        email: "",
      },
      AsesorCompanies: [
        /*dentro*/
      ],
      NotAsesorCompanies: [],
      Dialog: false,
      readOnly: true,
      btn: "Editar",
      Workers: " ",
      Womens: " ",
      Mens: " ",
      MaxSal: " ",
      MinSal: " ",

      Delegados: {
        name: " ",
        municipio: " ",
        phone: " ",
        email: " ",
      },
      Comicionados: {
        name: " ",
        municipio: " ",
        phone: " ",
        email: " ",
      },
      Secretario: {
        name: " ",
        municipio: " ",
        phone: " ",
        email: " ",
      },
    };
  }

  componentDidMount = async () => {
    if (this.props.location.state) {
      this.setState({
        Uuid: this.props.location.state.companieUuid,
        //Workers: this.props.location.state.employees
      });

      await this.ShowCompany(this.props.location.state.companieUuid);
    } else {
      this.props.history.push("/Empresas");
    }
  };

  onChangeRH = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      rh: {
        ...prevState.rh,
        [name]: value,
      },
    }));
  };
  onChangeRHSelec = (name) => (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      rh: {
        ...prevState.rh,
        charge: value,
      },
    }));
  };
  onChangeGeneral = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      manager: {
        ...prevState.manager,
        [name]: value,
      },
    }));
  };
  onChangeGeneralSelec = (name) => (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      manager: {
        ...prevState.manager,
        charge: value,
      },
    }));
  };
  onChangeProduccion = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState((prevState) => ({
      productionManager: {
        ...prevState.productionManager,
        [name]: value,
      },
    }));
  };
  onChangeProduccionSelec = (name) => (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      productionManager: {
        ...prevState.productionManager,
        charge: value,
      },
    }));
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeState = async (event) => {
    this.setState({ StateUuid: event.target.value });
    await this.ShowAllCitys(event.target.value);
  };

  handleChangeCity = (name) => (event) => {
    console.log(event.target.value);
    this.setState({ [name]: event.target.value });
  };

  ShowAllStates = async () => {
    const ShowStates = await Places.States();
    this.setState({ Estados: ShowStates });
  };

  ShowAllCitys = async (uuid) => {
    const ShowCitys = await Places.Citys(uuid);
    this.setState({ Ciudades: ShowCitys });
  };

  onDelegados = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      Delegados: {
        ...prevState.Delegados,
        [name]: value,
      },
    }));
  };
  onComicionados = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      Comicionados: {
        ...prevState.Comicionados,
        [name]: value,
      },
    }));
  };
  onSecretario = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      Secretario: {
        ...prevState.Secretario,
        [name]: value,
      },
    }));
  };

  DeleteCompany = (index, Uuid, name, e) => {
    var Nname = name;
    var NUuid = Uuid;
    //console.log(Uuid,name)

    const Companies = Object.assign([], this.state.AsesorCompanies);
    Companies.splice(index, 1);
    this.setState({ AsesorCompanies: Companies });

    var notCompanies = { Uuid: NUuid, name: Nname };
    this.setState((state) => {
      const NotAsesorCompanies = [...state.NotAsesorCompanies, notCompanies];
      return {
        NotAsesorCompanies,
      };
    });
  };

  NotDeleteCompany = (index, Uuid, name, e) => {
    var Nname = name;
    var NUuid = Uuid;

    const Companies = Object.assign([], this.state.NotAsesorCompanies);
    Companies.splice(index, 1);
    this.setState({ NotAsesorCompanies: Companies });

    var notCompanies = { Uuid: NUuid, name: Nname };
    this.setState((state) => {
      const AsesorCompanies = [...state.AsesorCompanies, notCompanies];
      return {
        AsesorCompanies,
      };
    });
  };

  DialogOpen = async () => {
    if (this.state.Dialog) {
      this.setState({ Dialog: false });
    } else {
      this.setState({ Dialog: true });
      //await this.fechAsesorNotCompanies(this.state.Uuid);
    }
  };

  Send = async () => {
    if (this.state.btn === "Editar") {
      this.setState({ btn: "Guardar" });
      this.setState({ readOnly: false });
    } else {
      this.setState({ btn: "Editar" });
      this.setState({ readOnly: true });
      await this.Update(this.state);
      await this.ShowCompany(this.props.location.state.companieUuid);
    }
  };

  Update = async (body) => {
    const create = await Company.Update(body);
    console.log(create);
  };

  ShowCompany = async (Uuid) => {
    const Details = await Company.Show(Uuid);
    console.log("respuesta del servidor", Details);
    this.setState({
      Td_Uuid: Details.company.TechnicalDetail.uuid,
      B_Uuid: Details.company.Benefit.uuid,

      AsesorCompanies: Details.company.Advisors,
      name: Details.company.name,
      phone: Details.company.phone,
      //delegate:Details.company.TechnicalDetail.delegate,
      address: Details.company.address,
      country: Details.company.country,
      industrialPark: Details.company.industrialPark,
      acceptanceDate: Details.company.acceptanceDate,
      clients: Details.company.TechnicalDetail.clients,
      product: Details.company.TechnicalDetail.product,
      contract: Details.company.TechnicalDetail.contract,
      operationStart: Details.company.TechnicalDetail.operationStart,

      MaxSal: Details.statistics.maxWage,
      MinSal: Details.statistics.minWage,
      Womens: Math.round(Details.statistics.meanWage),
      Mens: Math.round(Details.statistics.meanAge),
      Workers: Details.statistics.noOfEmployees,
      attendanceBonus: Details.company.Benefit.attendanceBonus,
      chrismasBox: Details.company.Benefit.christmasBox,
      holidayBonus: Details.company.Benefit.holydayBonus,
      lunchBonus: Details.company.Benefit.lunchBonus,
      other: Details.company.Benefit.other,
      savingFund: Details.company.Benefit.savingFund,
      transportBonus: Details.company.Benefit.transportBonus,
      vouchers: Details.company.Benefit.vouchers,
      utilities: Details.company.Benefit.utilities,
      manager: {
        name:
          Details.company.TechnicalDetail.manager !== null
            ? Details.company.TechnicalDetail.manager.name
            : " ",
        charge:
          Details.company.TechnicalDetail.manager !== null
            ? Details.company.TechnicalDetail.manager.charge
            : " ",
        phone:
          Details.company.TechnicalDetail.manager !== null
            ? Details.company.TechnicalDetail.manager.phone
            : " ",
        email:
          Details.company.TechnicalDetail.manager !== null
            ? Details.company.TechnicalDetail.manager.email
            : " ",
      },
      productionManager: {
        name:
          Details.company.TechnicalDetail.productionManager !== null
            ? Details.company.TechnicalDetail.productionManager.name
            : " ",
        charge:
          Details.company.TechnicalDetail.productionManager !== null
            ? Details.company.TechnicalDetail.productionManager.charge
            : " ",
        phone:
          Details.company.TechnicalDetail.productionManager !== null
            ? Details.company.TechnicalDetail.productionManager.phones
            : " ",
        email:
          Details.company.TechnicalDetail.productionManager !== null
            ? Details.company.TechnicalDetail.productionManager.email
            : " ",
      },

      rh: {
        name:
          Details.company.Rh.UserProfile !== null
            ? Details.company.Rh.UserProfile.name
            : " ",
        charge:
          Details.company.Rh.UserProfile !== null
            ? Details.company.Rh.UserProfile.charge
            : " ",
        phone:
          Details.company.Rh.UserProfile !== null
            ? Details.company.Rh.UserProfile.personalPhone
            : " ",
        email: Details.company.Rh !== null ? Details.company.Rh.email : " ",
        uuid: Details.company.Rh !== null ? Details.company.Rh.uuid : " ",
      },

      estado: Details.company.State !== null ? Details.company.State.name : " ",
      StateUuid:
        Details.company.State !== null ? Details.company.State.uuid : " ",
      ciudad: Details.company.City !== null ? Details.company.City.name : " ",
      CityUuid: Details.company.City !== null ? Details.company.City.uuid : " ",

      Delegados:
        Details.company.TechnicalDetail.delegate !== null
          ? Details.company.TechnicalDetail.delegate.delegates
          : { name: " ", municipio: " ", phone: " ", email: " " },
      Comicionados:
        Details.company.TechnicalDetail.delegate !== null
          ? Details.company.TechnicalDetail.delegate.commissioners
          : { name: " ", municipio: " ", phone: " ", email: " " },
      Secretario:
        Details.company.TechnicalDetail.delegate !== null
          ? Details.company.TechnicalDetail.delegate.secretaries
          : { name: " ", municipio: " ", phone: " ", email: " " },
    });

    //console.log(this.state.manager.charge)
    if (Details.company.State !== null) {
      await this.ShowAllStates();
      await this.ShowAllCitys(Details.company.State.uuid);

      this.setState({
        StateUuid: Details.company.State.uuid,
      });
    } else {
      await this.ShowAllStates();
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.Grid}>
          <div className={classes.AvatarDiv}>
            <TextField
              id="standard-read-only-input"
              label="Nombre de la Empresa"
              className={classes.textFieldAvatar}
              margin="normal"
              name="name"
              onChange={this.onChange}
              value={this.state.name}
              variant="outlined"
              InputProps={{ readOnly: this.state.readOnly }}
            />
          </div>
          <div className={classes.AvatarDiv}>
            <TextField
              id="standard-read-only-input"
              label="Teléfono de la empresa"
              className={classes.AvatarDiv1}
              margin="normal"
              name="phone"
              onChange={this.onChange}
              value={this.state.phone}
              variant="outlined"
              InputProps={{ readOnly: this.state.readOnly }}
            />
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Asesores
            </Typography>
          </div>
          <div className={classes.Asesores}>
            {this.state.AsesorCompanies.length > 0 ? (
              <Paper>
                <Table
                  size="small"
                  aria-label="a dense table"
                  className={classes.TableCompany}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Asesores</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Telefono</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.TableCompany}>
                    {this.state.AsesorCompanies.map((Asesor) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Asesor.Uuid}>
                            <TableCell component="th" scope="row">
                              {Asesor.UserProfile.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Asesor.email}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Asesor.UserProfile.mobilePhone}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <div className={classes.load}>
                <Paper>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    className={classes.TableCompany}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Asesores</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.TableCompany}>
                      <React.Fragment>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Sin Asesor asignado
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            )}
          </div>
          <div className={classes.Asesores}>
            <Grid container>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="Delegados"
                  label="Lista de delegados"
                  className={classes.textField}
                  margin="normal"
                  name="name"
                  multiline
                  rows="3"
                  onChange={this.onDelegados}
                  value={this.state.Delegados.name}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Lista de Comicionados"
                  className={classes.textField}
                  margin="normal"
                  name="name"
                  multiline
                  rows="3"
                  onChange={this.onComicionados}
                  value={this.state.Comicionados.name}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Secretarios generales"
                  className={classes.textField}
                  margin="normal"
                  name="name"
                  multiline
                  rows="3"
                  onChange={this.onSecretario}
                  value={this.state.Secretario.name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="Delegados"
                  label="Telefonos"
                  className={classes.textField}
                  margin="normal"
                  name="phone"
                  multiline
                  rows="3"
                  onChange={this.onDelegados}
                  value={this.state.Delegados.phone}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Telefonos"
                  className={classes.textField}
                  margin="normal"
                  name="phone"
                  multiline
                  rows="3"
                  onChange={this.onComicionados}
                  value={this.state.Comicionados.phone}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Telefonos"
                  className={classes.textField}
                  margin="normal"
                  name="phone"
                  multiline
                  rows="3"
                  onChange={this.onSecretario}
                  value={this.state.Secretario.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="Delegados"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  name="email"
                  multiline
                  rows="3"
                  onChange={this.onDelegados}
                  value={this.state.Delegados.email}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  name="email"
                  multiline
                  rows="3"
                  onChange={this.onComicionados}
                  value={this.state.Comicionados.email}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  name="email"
                  multiline
                  rows="3"
                  onChange={this.onSecretario}
                  value={this.state.Secretario.email}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="Delegados"
                  label="Municipio"
                  className={classes.textField}
                  margin="normal"
                  name="municipio"
                  multiline
                  rows="3"
                  onChange={this.onDelegados}
                  value={this.state.Delegados.municipio}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Municipio"
                  className={classes.textField}
                  margin="normal"
                  name="municipio"
                  multiline
                  rows="3"
                  onChange={this.onComicionados}
                  value={this.state.Comicionados.municipio}
                  variant="outlined"
                />
                <TextField
                  id="standard-read-only-input"
                  label="Municipio"
                  className={classes.textField}
                  margin="normal"
                  name="municipio"
                  multiline
                  rows="3"
                  onChange={this.onSecretario}
                  value={this.state.Secretario.municipio}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Personal Administrativo
            </Typography>
          </div>
          <div className={classes.Administracion}>
            <Grid container>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Nombre"
                  className={classes.textField}
                  margin="normal"
                  name="name"
                  onChange={this.onChangeRH}
                  value={this.state.rh.name}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Nombre"
                  className={classes.textField}
                  margin="normal"
                  name="name"
                  onChange={this.onChangeGeneral}
                  value={this.state.manager.name}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Nombre"
                  className={classes.textField}
                  margin="normal"
                  name="name"
                  onChange={this.onChangeProduccion}
                  value={this.state.productionManager.name}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                {this.state.manager !== "" ? (
                  <>
                    <div className={classes.Select}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Cargo
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.rh.charge}
                          onChange={this.onChangeRHSelec("charge")}
                        >
                          <MenuItem value={"gerente_rh"}>Gerente RH</MenuItem>
                          <MenuItem value={"director_rh"}>Director RH</MenuItem>
                          <MenuItem value={"supervisor_rh"}>
                            Supervisor RH
                          </MenuItem>
                          <MenuItem value={"otro"}>otro (RH)</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.Select}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Cargo
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.manager.charge}
                          onChange={this.onChangeGeneralSelec("charge")}
                        >
                          <MenuItem value={"gerente_genereal"}>
                            Gerente
                          </MenuItem>
                          <MenuItem value={"director_de_plata"}>
                            Gerente de planta
                          </MenuItem>
                          <MenuItem value={"gerente_de_planta"}>
                            Gerente planta
                          </MenuItem>
                          <MenuItem value={"presidente"}>Presidente</MenuItem>
                          <MenuItem value={"otro"}>Otro(gerente)</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.Select}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Cargo
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.productionManager.charge}
                          onChange={this.onChangeProduccionSelec("charge")}
                        >
                          <MenuItem value={"gerente_de_produccion"}>
                            Gerente de produccion
                          </MenuItem>
                          <MenuItem value={"director_de_operaciones"}>
                            Director de operaciones
                          </MenuItem>
                          <MenuItem value={"gerente_de_planta"}>
                            Gerente Planta
                          </MenuItem>
                          <MenuItem value={"gerente_de_operaciones"}>
                            Gerente de Operaciones
                          </MenuItem>
                          <MenuItem value={"presidente"}>presidente</MenuItem>
                          <MenuItem value={"otro"}>otro</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.Select}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Cargo
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.rh.charge}
                          onChange={this.onChangeRHSelec("charge")}
                        >
                          <MenuItem value={"gerente_rh"}>Gerente RH</MenuItem>
                          <MenuItem value={"director_rh"}>Director RH</MenuItem>
                          <MenuItem value={"supervisor_rh"}>
                            Supervisor RH
                          </MenuItem>
                          <MenuItem value={"otro"}>otro (RH)</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.Select}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Cargo
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.manager.charge}
                          onChange={this.onChangeGeneralSelec("charge")}
                        >
                          <MenuItem value={"gerente_genereal"}>
                            Gerente
                          </MenuItem>
                          <MenuItem value={"director_de_plata"}>
                            Gerente de planta
                          </MenuItem>
                          <MenuItem value={"director_de_planta"}>
                            Gerente planta
                          </MenuItem>
                          <MenuItem value={"presidente"}>Presidente</MenuItem>
                          <MenuItem value={"otro"}>Otro(gerente)</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.Select}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Cargo
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.productionManager.charge}
                          onChange={this.onChangeProduccionSelec("charge")}
                        >
                          <MenuItem value={"gerente_de_produccion"}>
                            Gerente de produccion
                          </MenuItem>
                          <MenuItem value={"director_de_operaciones"}>
                            Director de operaciones
                          </MenuItem>
                          <MenuItem value={"gerente_de_planta"}>
                            Gerente Planta
                          </MenuItem>
                          <MenuItem value={"gerente_de_operaciones"}>
                            Gerente de Operaciones
                          </MenuItem>
                          <MenuItem value={"presidente"}>presidente</MenuItem>
                          <MenuItem value={"otro"}>otro</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Telefono"
                  className={classes.textField}
                  margin="normal"
                  name="phone"
                  onChange={this.onChangeRH}
                  value={this.state.rh.phone}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Telefono"
                  className={classes.textField}
                  margin="normal"
                  name="phone"
                  onChange={this.onChangeGeneral}
                  value={this.state.manager.phone}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Telefono"
                  className={classes.textField}
                  margin="normal"
                  name="phone"
                  onChange={this.onChangeProduccion}
                  value={this.state.productionManager.phone}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  name="email"
                  onChange={this.onChangeRH}
                  value={this.state.rh.email}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  name="email"
                  onChange={this.onChangeGeneral}
                  value={this.state.manager.email}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  name="email"
                  onChange={this.onChangeProduccion}
                  value={this.state.productionManager.email}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Datos generales
            </Typography>
          </div>
          <div className={classes.DatosDiv}>
            <Grid container className={classes.Grid}>
              <Grid
                item
                xs={4}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.SelectState}>
                  {this.state.btn === "Guardar" ? (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Estados
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.StateUuid}
                        name="stateUuid"
                        onChange={this.handleChangeState}
                        //inputProps={{ readOnly: true }}
                      >
                        {this.state.Estados.map((Estado, index) => {
                          return (
                            <MenuItem value={Estado.uuid} key={Estado.uuid}>
                              {Estado.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      id="standard-read-only-input"
                      label="Estado"
                      className={classes.textField}
                      margin="normal"
                      name="name"
                      value={this.state.estado}
                      onChange={this.onChange}
                      InputProps={{
                        readOnly: this.state.readOnly,
                      }}
                    />
                  )}
                </div>

                <div className={classes.SelectState}>
                  {this.state.btn === "Guardar" ? (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Municipio
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.CityUuid}
                        onChange={this.handleChangeCity("CityUuid")}
                        //inputProps={{ readOnly: true }}
                      >
                        {this.state.Ciudades.map((ciudad, index) => {
                          return (
                            <MenuItem value={ciudad.uuid} key={ciudad.uuid}>
                              {ciudad.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      id="standard-read-only-input"
                      label="Municipio"
                      className={classes.textField}
                      margin="normal"
                      name="name"
                      value={this.state.ciudad}
                      onChange={this.onChange}
                      InputProps={{
                        readOnly: this.state.readOnly,
                      }}
                    />
                  )}
                </div>
                <TextField
                  id="standard-read-only-input"
                  label="Direccion"
                  className={classes.textField}
                  margin="normal"
                  name="address"
                  onChange={this.onChange}
                  value={this.state.address}
                  variant="outlined"
                  multiline
                  rows="3"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Pais de origen"
                  className={classes.textField}
                  margin="normal"
                  name="country"
                  onChange={this.onChange}
                  value={this.state.country}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Año de inicio"
                  className={classes.textField}
                  margin="normal"
                  name="operationStart"
                  onChange={this.onChange}
                  value={this.state.operationStart}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Producto que elabora"
                  className={classes.textField}
                  margin="normal"
                  name="product"
                  onChange={this.onChange}
                  value={this.state.product}
                  variant="outlined"
                  multiline
                  rows="3"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Principales Clientes"
                  className={classes.textField}
                  margin="normal"
                  name="clients"
                  onChange={this.onChange}
                  value={this.state.clients}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Fecha de vigencia del contrato"
                  className={classes.textField}
                  margin="normal"
                  name="contract"
                  onChange={this.onChange}
                  value={this.state.contract}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Parque industrial"
                  className={classes.textField}
                  margin="normal"
                  name="industrialPark"
                  onChange={this.onChange}
                  value={this.state.industrialPark}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Año de afiliacion"
                  className={classes.textField}
                  margin="normal"
                  name="acceptanceDate"
                  onChange={this.onChange}
                  value={this.state.acceptanceDate}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
              </Grid>
              {this.state.btn === "Editar" ? (
                <Grid
                  item
                  xs={4}
                  className={classes.Grid}
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <TextField
                    id="standard-read-only-input"
                    label="Total de Trabajadores"
                    className={classes.statistics}
                    margin="normal"
                    name="product"
                    multiline
                    rows="2"
                    value={this.state.Workers}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="standard-read-only-input"
                    label="Promedio Sueldo"
                    className={classes.statistics}
                    margin="normal"
                    name="clients"
                    value={this.state.Womens}
                    variant="outlined"
                    multiline
                    rows="2"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="standard-read-only-input"
                    label="Promedio Edad"
                    className={classes.statistics}
                    margin="normal"
                    name="contract"
                    value={this.state.Mens}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="standard-read-only-input"
                    label="Salario Mas alto"
                    className={classes.statistics}
                    margin="normal"
                    name="industrialPark"
                    value={this.state.MaxSal}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    id="standard-read-only-input"
                    label="Salarios mas Bajo"
                    className={classes.statistics}
                    margin="normal"
                    name="acceptanceDate"
                    value={this.state.MinSal}
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              ) : (
                <div></div>
              )}
            </Grid>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Datos Contractuales
            </Typography>
          </div>
          <div className={classes.ContratoDiv}>
            <Grid container className={classes.Grid}>
              <Grid
                item
                xs={6}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Aguinaldo"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="chrismasBox"
                  onChange={this.onChange}
                  value={this.state.chrismasBox}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Prima Vacacional"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="holidayBonus"
                  onChange={this.onChange}
                  value={this.state.holidayBonus}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Utilidades"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="utilities"
                  onChange={this.onChange}
                  value={this.state.utilities}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Servicio de Comedor"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="lunchBonus"
                  onChange={this.onChange}
                  value={this.state.lunchBonus}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-read-only-input"
                  label="Bono por Asistencia"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="attendanceBonus"
                  onChange={this.onChange}
                  value={this.state.attendanceBonus}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Fondo de Ahorro"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="savingFund"
                  onChange={this.onChange}
                  value={this.state.savingFund}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Vales de despensa"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="vouchers"
                  onChange={this.onChange}
                  value={this.state.vouchers}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Servicio de Transporte"
                  className={classes.textFieldPerson}
                  margin="normal"
                  name="transportBonus"
                  onChange={this.onChange}
                  value={this.state.transportBonus}
                  variant="outlined"
                  InputProps={{ readOnly: this.state.readOnly }}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.Asesores}>
            <TextField
              id="standard-read-only-input"
              label="Listado de beneficios adicionales"
              className={classes.textField}
              margin="normal"
              name="other"
              multiline
              rows="5"
              onChange={this.onChange}
              value={this.state.other}
              variant="outlined"
            />
          </div>
          <div className={classes.div}>
            <Link className={classes.Link} to="/Empresas">
              <Button
                variant="contained"
                color="secondary"
                className={classes.btnEnd}
              >
                cancelar
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnEnd}
              onClick={this.Send}
            >
              {this.state.btn}
            </Button>
          </div>
        </Grid>
        <Dialog
          open={this.state.Dialog}
          onClose={this.DialogOpen}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Agregar/Eliminar empresas
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={6}
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <Typography variant="button">Eliminar</Typography>
                {this.state.AsesorCompanies.length > 0 ? (
                  <div>
                    {this.state.AsesorCompanies.map((companie, index) => {
                      return (
                        <React.Fragment>
                          <Grid container>
                            <Grid
                              item
                              xs={8}
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Typography
                                variant="button"
                                className={classes.text}
                              >
                                {companie.name}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Button
                                color="secondary"
                                onClick={this.DeleteCompany.bind(
                                  this,
                                  index,
                                  companie.Uuid,
                                  companie.name
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <div className={classes.load}>
                    <CircularProgress />
                  </div>
                )}
              </Grid>

              <Grid
                item
                xs={6}
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <Typography variant="button">Agregar</Typography>
                {this.state.NotAsesorCompanies.length > 0 ? (
                  <div>
                    {this.state.NotAsesorCompanies.map((companie, index) => {
                      return (
                        <React.Fragment>
                          <Grid container>
                            <Grid
                              item
                              xs={8}
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Typography variant="button">
                                {companie.name}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Button
                                color="primary"
                                onClick={this.NotDeleteCompany.bind(
                                  this,
                                  index,
                                  companie.Uuid,
                                  companie.name
                                )}
                              >
                                <AddIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <div className={classes.load}>
                    <CircularProgress />
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogOpen} color="primary">
              Cancel
            </Button>
            <Button onClick={this.SaveChanges} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CompanieDetails));
