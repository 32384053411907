import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  Select,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import LogsCall from "../../../API/Logs";
import UsersCall from "../../../API/Users";

const styles = (theme) => ({
  paper: {
    overflow: "auto",
    maxHeight: "84vh",
  },
  formControl: {
    width: "100%",
  },
  textField: {
    width: "95%",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Logs: [],
      admin: "",
      general: "",
      asesor: "",
      Users: [],
      elementos: 0,
      paginas: 0,
      Uuid: "",
      adminC: "",
      generalC: "",
      asesorC: "",
    };
  }
  fetchLogs = async (State) => {
    const LogsShow = await LogsCall.ShowAll(State);
    console.log(LogsShow);
    //this.setState({ Logs: LogsShow });
  };

  componentDidMount = async () => {
    const UsersShow = await UsersCall.ShowAll();
    this.setState({ Users: UsersShow });
  };
  handleChangeOperation = (name) => (event) => {
    this.setState({ [name]: event.target.checked });

    event.target.checked
      ? this.setState({ [event.target.id]: event.target.value })
      : this.setState({ [event.target.id]: " " });
  };

  Send = async () => {
    var state = {};
    if (this.state.Uuid !== "") {
      state = {
        quantity: parseInt(this.state.elementos),
        page: parseInt(this.state.paginas),
        userTypes: [],
        userUuid: this.state.Uuid,
      };
    } else {
      state = {
        quantity: parseInt(this.state.elementos),
        page: parseInt(this.state.paginas),
        userTypes: [this.state.adminC, this.state.asesorC, this.state.generalC],
        userUuid: "",
      };
    }

    await this.fetchLogs(state);
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const data = [];
    this.state.Logs.map((log) => {
      data.push([log.name, log.accion, log.date, log.time, log.table]);
      return data;
    });
    const columns = ["Nombre", "Acción", "Fecha", "Hora", "Tablas"];
    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid
              item
              xs={2}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-read-only-input"
                label="Elementos"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="elementos"
                onChange={this.onChange}
                value={this.state.elementos}
                InputProps={{
                  readOnly: this.state.readOnly,
                }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-read-only-input"
                label="Paginas"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                name="paginas"
                onChange={this.onChange}
                value={this.state.paginas}
                InputProps={{
                  readOnly: this.state.readOnly,
                }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <FormControl component="fieldset" className={classes.FormControl}>
                <FormLabel component="legend">Usuarios</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.admin}
                        onChange={this.handleChangeOperation("admin")}
                        value="72468"
                        id="adminC"
                      />
                    }
                    label="Administrativo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.general}
                        onChange={this.handleChangeOperation("general")}
                        value="48235"
                        id="generalC"
                      />
                    }
                    label="General"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.asesor}
                        onChange={this.handleChangeOperation("asesor")}
                        value="81493"
                        id="asesorC"
                      />
                    }
                    label="Asesor"
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={3}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              {this.state.Users.length > 0 ? (
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Lista de Usuarios
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.Uuid}
                    name="Uuid"
                    onChange={this.onChange}
                  >
                    {this.state.Users.map((Estado, index) => {
                      return (
                        <MenuItem value={Estado.uuid} key={Estado.uuid}>
                          {Estado.UserProfile.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Lista de Usuarios
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    <MenuItem value={0}>_</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Button onClick={this.Send} color="primary" variant="contained">
                Buscar
              </Button>
            </Grid>
          </Grid>

          <MUIDataTable
            title={"Registros"}
            data={data}
            columns={columns}
            options={options}
          />
        </Paper>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Logs));
