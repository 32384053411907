import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  DialogActions,
  Paper,
  Grid,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Api from "../../../API/CompaniesForms";
import toastType from "../../../API/CallUtils/tostyNotification";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";
import DialogAddQuestion from "./DialogAddQuestion";
import AddIcon from "@material-ui/icons/Add";
import DialogQuestionDetails from "../../CompaniesFormsQuestions/Dialogs/DetailsDialog";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  Title: {
    width: "90%",
  },
  CloseBtn: {
    //width: "10%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 1, name: "Cargando" };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  componentDidMount = async () => {
    if (this.props.Questions && this.props.Questions.length > 0) {
      this.setState({ List: this.props.Questions });
    } else {
      console.log("obtener la lista");
    }
    this.fetchlist();
  };

  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.Show(this.props.EventId);
      console.log("detalles", Deli);
      if (Deli.status === "success") {
        this.setState({
          ListEvents: Deli.data.companyForm.questions.map((q) => {
            return q.id;
          }),
          name: Deli.data.companyForm.form.name,
          idForm: Deli.data.companyForm.form.id,
          onLoad: false,
          onError: false,
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Dialog = (a, b) => {
    console.log("la pregunta", b);
    this.setState({ [a]: !this.state[a], Answer: b ? b : null });
  };
  Refresh = async (a) => {
    await this.fetchlist();
    this.Dialog(a);
  };
  AddNewQuestion = (id) => {
    this.setState({ ListEvents: [...this.state.ListEvents, id] });
  };
  deleteQuestions = (index) => {
    let value = this.state.ListEvents.filter((item, j) => index !== j);
    this.setState({ ListEvents: value });
  };
  Update = async () => {
    try {
      this.setState({
        Oncreate: true,
      });
      if (this.state.ListEvents.length > 0) {
        const Deli = await Api.Update(this.state);
        console.log(Deli);
        if (Deli.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se a actualizado con éxito");
          await this.props.Refresh("EditDialog");
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible Crear el elemento");
        }
      } else {
        if (this.state.ListOfQuestions < 1) {
          toastType.error("No se puede Crear un Formulario Sin preguntas");
        }
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        TransitionComponent={Transition}
        maxWidth="xl"
        //fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.Title}>{this.state.name}</Typography>
        </DialogTitle>
        <DialogContent className={classes.paper}>
          {this.state.ListEvents && this.state.ListEvents.length > 0 ? (
            <>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Pregunta</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Opciones</TableCell>
                    <TableCell>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.ListEvents &&
                    this.state.ListEvents.map((Article, index) => {
                      // eslint-disable-next-line array-callback-return
                      return this.state.List.map((Question) => {
                        if (Question.id === Article) {
                          return (
                            <React.Fragment>
                              <TableRow key={Question.id}>
                                <TableCell component="th" scope="row">
                                  {Question.content}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Question.CompanyFormQuestionType.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Question.createdAt.split("T")[0]}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.btn}
                                    onClick={this.Dialog.bind(
                                      this,
                                      "DialogQuestionDetails",
                                      Question
                                    )}
                                  >
                                    <EditIcon />
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={this.deleteQuestions.bind(
                                      this,
                                      index
                                    )}
                                    className={classes.btn}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        }
                      });
                    })}
                </TableBody>
              </Table>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.Margin}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.Dialog.bind(this, "DialogAddQuestion")}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </>
          ) : (
            <Paper className={classes.NoItemPaper}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.Dialog.bind(this, "DialogAddQuestion")}
                className={classes.AddCandidate}
              >
                <AddBoxIcon />
              </Button>
            </Paper>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cerrar
          </Button>
          <Button onClick={this.Update} color="primary">
            Guardar
          </Button>
        </DialogActions>
        {this.state.DialogAddQuestion && (
          <DialogAddQuestion
            DialogOpen={this.state.DialogAddQuestion}
            DialogClose={this.Dialog.bind(this, "DialogAddQuestion")}
            Refresh={this.Refresh}
            Questions={this.state.List}
            Send={this.AddNewQuestion}
          />
        )}
        {this.state.DialogQuestionDetails && (
          <DialogQuestionDetails
            DialogOpen={this.state.DialogQuestionDetails}
            DialogClose={this.Dialog.bind(this, "DialogQuestionDetails")}
            Answer={this.state.Answer}
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
