import ShowAll from "./ShowAll";
import Show from "./Show";
import Create from "./Create";
import Delete from "./Delete";
import Types from "./Types";

const SportEvents = {
  ShowAll,
  Create,
  Delete,
  Show,
  Types,
};

export default SportEvents;
