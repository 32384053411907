import api from "../baseurl";
import toastType from "../CallUtils/tostyNotification";

export function Login(data) {
  try {
    const status = api
      .post("users/login", {
        json: {
          email: data.email,
          pass: data.pass,
        },
        timeout: false,
      })
      .json();

    return status;
  } catch (e) {
    toastType.error("Error en la conexión");
    console.log(e);
  }
}

export default Login;
