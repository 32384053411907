import base from "../baseurl";
import token from "../getTk";

const ResetPass = async (State) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  //console.log(State)
  const UserUpdate = await api
    .post("users/password-update", {
      timeout: false,
      json: {
        userUuid: State.Uuid,
        password: State.pass,
      },
    })
    .json();
  console.log(UserUpdate);
  return UserUpdate;
};

export default ResetPass;
