import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Paper, Typography, Button } from "@material-ui/core";
import List from "../../../API/Companies";
import MUIDataTable from "mui-datatables";
import DialogDetailsCompany from "../Dialogs/DialogDetailsCompany";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DialogMassiveCreate from "../Dialogs/DialogMassiveCreate";

const styles = (theme) => ({
  ErrorMain: {
    width: "100%",
    height: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  TableContainer: {
    width: "100%",
    height: "auto",
    maxHeight: "80vh",
    overflow: "auto",
  },
  AddButtonContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
    margin: "12px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
    TotalList: state.login.user.TotalCompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompaniesList: [],
      SelectedCompanie: "",
      CompanyInUse: {},
      rawList: [],
    };
  }

  fetchCompanyList = async () => {
    try {
      const CompanyList = await List.showAllNotSta();
      if (CompanyList) {
        this.FilterInfo(CompanyList);
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount = async () => {
    if (this.props.userType === 45298 || this.props.userType === 72468) {
      this.props.TotalList && this.props.TotalList.length > 0
        ? this.FilterInfo(this.props.TotalList)
        : await this.fetchCompanyList();
    } else {
      this.FilterInfo(this.props.Companies);
    }
  };
  FilterInfo = (List) => {
    const getCleanList = List.map((company) => {
      return [
        company.name,
        <Button
          color="Primary"
          onClick={() => {
            this.setState({
              CompanyInUse: company,
              DialogDetailsCompany: true,
            });
          }}
        >
          <EditIcon />
        </Button>,
      ];
    });
    this.setState({ CompaniesList: getCleanList, rawList: List });
  };
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  render() {
    const { classes } = this.props;
    const columns = ["Empresa", "Eventos"];
    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 50, 100, 200],
    };
    if (this.state.CompaniesList.length > 0) {
      return (
        <>
          <Paper className={classes.TableContainer}>
            <MUIDataTable
              title={"Empresas a cargo"}
              data={this.state.CompaniesList}
              columns={columns}
              options={options}
              selectableRows="none"
            />
            <DialogDetailsCompany
              DialogOpen={this.state.DialogDetailsCompany}
              DialogClose={() => {
                this.setState({ CompanyInUse: {} });
                this.Dialog("DialogDetailsCompany");
              }}
              companyInfo={this.state.CompanyInUse}
            />
            <DialogMassiveCreate
              DialogOpen={this.state.DialogMassiveCreate}
              DialogClose={this.Dialog.bind(this, "DialogMassiveCreate")}
              companiesList={this.state.rawList}
            />
          </Paper>
          <div className={classes.AddButtonContainer}>
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              color="primary"
              onClick={this.Dialog.bind(this, "DialogMassiveCreate")}
            >
              Crear nuevo evento
            </Button>
          </div>
        </>
      );
    }
    return (
      <Paper className={classes.Title}>
        <Typography
          variant="h5"
          color="textPrimary"
          component="p"
          className={classes.Title}
        >
          Sin empresas Asignadas
        </Typography>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
