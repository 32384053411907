import React from "react";
import { connect } from "react-redux";
import Layout from "./Layout";

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    UNSAFE_componentWillMount() {
      if (!this.props.auth_in) {
        this.props.history.push("/");
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (!nextProps.auth_in) {
        this.props.history.push("/");
      }
    }

    render() {
      return (
        <React.Fragment>
          <Layout>
            <ComposedComponent {...this.props} />
          </Layout>
        </React.Fragment>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      auth_in: state.login.user.auth_in,
      userTyper: state.login.user.userType,
    };
  }

  return connect(mapStateToProps)(Authenticate);
}
