import base from "../baseurl";
import token from "../getTk";

const Show = async (a, b, c, d, f, g) => {
  console.log(d);
  const tokenA = token.get();

  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const status = await api
    .post("company-admins/create", {
      json: {
        companyId: d,
        admin: {
          fullName: a,
          email: b,
          phone: c,
          title: f,
          titleType: g,
        },
      },
    })
    .json();
  console.log(status);
  return status;
};

export default Show;
