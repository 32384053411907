import api from "../../baseurl";

const Create = async () => {
  const Create = await api.get("magazines/showall").json();
  console.log('====================================');
  console.log(Create);
  console.log('====================================');
  return Create.data.magazines;
};

export default Create;
