import React from "react";
import {
  withStyles,
  Typography,
  Toolbar,
  Tab,
  Tabs,
  Paper,
} from "@material-ui/core";
import SportList from "./SportList/ListSport";
import SportDocList from "./SportDocumentsList/ListDocsType";
import IndustrialParks from "./IndustrialParks/ListIndustrialparks";
import Campus from "./Campuses/ListCampus";
import BusStops from "./BusStops/ListCampus";
//import Notifications from "./NotificationsTest";
import Permissions from "./Permissions/ListCampus";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    return (
      <Paper>
        <>
          {" "}
          <Toolbar>
            <Tabs value={this.state.value} onChange={this.handleChange}>
              <Tab value={6} label="Paradas de Autobus" />
              <Tab value={1} label="Lugar de Eventos" />
              <Tab value={2} label="Parques Industriales" />
              <Tab value={3} label="Lista de deportes" />
              <Tab
                value={4}
                label="Lista de documentos para eventos deportivos"
              />
              <Tab value={0} label="Permisos" />
              {
                //<Tab value={5} label="Notificaciones" />
              }
            </Tabs>
          </Toolbar>
        </>
        <>
          {this.state.value === 0 && (
            <TabContainer>
              <Permissions />
            </TabContainer>
          )}

          {this.state.value === 1 && (
            <TabContainer>
              <Campus />
            </TabContainer>
          )}
          {this.state.value === 2 && (
            <TabContainer>
              <IndustrialParks />
            </TabContainer>
          )}
          {this.state.value === 3 && (
            <TabContainer>
              <SportList />
            </TabContainer>
          )}
          {this.state.value === 4 && (
            <TabContainer>
              <SportDocList />
            </TabContainer>
          )}
          {/*this.state.value === 5 && (
            <TabContainer>
              <Notifications />
            </TabContainer>
          )*/}
          {this.state.value === 6 && (
            <TabContainer>
              <BusStops />
            </TabContainer>
          )}
        </>
      </Paper>
    );
  }
}

export default withStyles(styles)(AdminPanel);
