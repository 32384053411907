import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import toastType from "../../API/CallUtils/tostyNotification";
import SportEvent from "../../API/DeliveryOfGoods";
import DialogCreateEvent from "./AddSport";
import SelectCompany from "./SelectCompany";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { green } from "@material-ui/core/colors";
import DialogRejectionNotes from "./rejectionNotes";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Table: {
    width: "100%",
    padding: "1%",
  },
  Margin: {
    margin: "5px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Skeleton: {
    width: "100%",
    height: "400px",
    borderRadius: "10px 10px 10px 10px",
    marginTop: "1%",
  },
  ImgPre: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    display: "block",
  },
});
const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
class Sports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [],
      DialogCreateEvent: false,
      EventName: "",
      CompanySelected: null,
      Details: [],
      rejectionNotes: "",
      RejectionNotesDialog: false,
      request: null,
      FetchState: "onSelect",
    };
  }
  componentDidMount = async () => {};
  fetchlist = async (id) => {
    try {
      this.setState({
        FetchState: "onLoad",
      });

      const Deli = await SportEvent.GetSons(id);
      console.log(Deli);
      if (Deli.status === "success") {
        this.setState({
          List: Deli.data.sons,
          FetchState: Deli.data.sons.length > 0 ? "onResult" : "onNull",
        });
      } else {
        toastType.error("CSE No fue posible Traer los Elementos");
        this.setState({
          FetchState: "onError",
          ErrorCase: "SSE No fue Posible Eliminar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE No fue posible Traer los Elementos");
      console.log(e);
      this.setState({
        FetchState: "onError",
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  update = async (sonId, fullName, scholarshipId, age, verified) => {
    try {
      this.setState({
        FetchState: "onLoad",
      });
      const Deli = await SportEvent.UpdateSons(
        sonId,
        fullName,
        scholarshipId,
        age,
        verified,
        this.state.rejectionNotes !== "" ? this.state.rejectionNotes : null
      );
      console.log(Deli);
      if (Deli.status === "success") {
        toastType.success("Se cambio con éxito");
        this.setState({ RejectionNotesDialog: false, request: null });
        await this.fetchlist(this.state.CompanySelected);
      } else {
        toastType.error("SSE No fue Posible cambiar el elemento");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "SSE No fue Posible cambiar el elemento",
        });
      }
    } catch (e) {
      toastType.error("CSE Error en la llamada de los recursos");
      console.log(e);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  Dialog = (a, Son, requestInfo) => {
    this.setState({
      [a]: !this.state[a],
      Details: Son,
      request: requestInfo,
    });
  };
  Refresh = async (a) => {
    await this.fetchlist(this.state.CompanySelected);
    this.Dialog(a);
  };
  selectCompany = async (uuid) => {
    this.setState({ CompanySelected: uuid });
    this.fetchlist([uuid]);
  };
  onChange = (e) => {
    this.setState({
      rejectionNotes: e,
    });
  };
  Body = () => {
    const { classes } = this.props;
    switch (this.state.FetchState) {
      case "onLoad":
        return (
          <Paper className={classes.Table}>
            <Skeleton
              variant="rect"
              animation="wave"
              className={classes.Skeleton}
            />
          </Paper>
        );
      case "onResult":
        return (
          <Paper className={classes.Table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre del padre/madre</TableCell>
                  <TableCell>Nombre del hijo</TableCell>
                  <TableCell>Fecha de nacimiento</TableCell>
                  <TableCell>Documentos</TableCell>
                  <TableCell>Aprobar</TableCell>
                  <TableCell>Rechazar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.List.map((Article) => {
                  return (
                    <React.Fragment>
                      <TableRow key={Article.id}>
                        <TableCell component="th" scope="row">
                          {Article.User.UserProfile.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {Article.fullName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {Article.birthDay}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.btn}
                            onClick={this.Dialog.bind(
                              this,
                              "CreateDialog",
                              Article.SonDocuments ? Article.SonDocuments : []
                            )}
                          >
                            <DescriptionIcon />
                          </Button>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <ColorButton
                            color="secondary"
                            variant="contained"
                            className={classes.btn}
                            onClick={this.update.bind(
                              this,
                              Article.id,
                              Article.fullName,
                              Article.ScholarshipId,
                              Article.birthDay,
                              1
                            )}
                          >
                            <CheckCircleIcon style={{ color: "white" }} />
                          </ColorButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Button
                            color="secondary"
                            variant="contained"
                            className={classes.btn}
                            onClick={this.Dialog.bind(
                              this,
                              "RejectionNotesDialog",
                              null,
                              Article
                            )}
                          >
                            <HighlightOffIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        );
      case "onError":
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Ocurrio un error</Typography>
          </Grid>
        );
      case "onNull":
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Sin elementos</Typography>
          </Grid>
        );
      case "onSelect":
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Seleccione una Empresa</Typography>
          </Grid>
        );
      default:
        return (
          <Paper className={classes.Table}>
            <Skeleton
              variant="rect"
              animation="wave"
              className={classes.Skeleton}
            />
          </Paper>
        );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <SelectCompany Select={this.selectCompany} />
        {this.Body()}
        {this.state.CreateDialog && (
          <DialogCreateEvent
            DialogOpen={this.state.CreateDialog}
            DialogClose={this.Dialog.bind(this, "CreateDialog")}
            Refresh={this.Refresh}
            docs={this.state.Details}
          />
        )}
        {this.state.RejectionNotesDialog && (
          <DialogRejectionNotes
            DialogOpen={this.state.RejectionNotesDialog}
            DialogClose={this.Dialog.bind(this, "RejectionNotesDialog")}
            Refresh={this.Refresh}
            notes={this.state.rejectionNotes}
            onChange={this.onChange}
            send={this.update}
            info={this.state.request}
          />
        )}
      </Paper>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Sports));
