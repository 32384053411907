import axios from "axios";
const Report = async (Companyid) => {
  const re = await axios
    .post(`https://api.sitimm.org/api/reports/show`, {
      reportNumber: 2,
      params: {
        id: Companyid,
      },
    })
    .then((res) => {
      return res;
    });
  return re;
};

export default Report;
