import Create from "./Create";
import Update from "./Update";
import Show from "./Show";
import ShowAll from "./ShowAll";
import ShowAllEmployees from "./Employees";
import UpdateEmployees from "./UpdateEmployees";
import CensusDates from "./CensusDates";
import showAllNotSta from "./ShowAllNotSta";
import NewUser from "./NewUser";

const Companies = {
  Update,
  ShowAll,
  Create,
  ShowAllEmployees,
  UpdateEmployees,
  CensusDates,
  Show,
  showAllNotSta,
  NewUser,
};

export default Companies;

export {
  Create,
  Update,
  ShowAll,
  ShowAllEmployees,
  UpdateEmployees,
  CensusDates,
  Show,
  showAllNotSta,
  NewUser,
};
