import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";

import Edit from "./Links";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Remp: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});
class createNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EditDialog: false,
    };
  }
  componentDidMount = async () => {};

  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  deleteLink = (i) => {
    let value = this.props.ParentState.virtualUrl.filter((item, j) => i !== j);
    this.props.ParentSet(value, "virtualUrl");
  };
  parentCallback = (value) => {
    this.props.ParentSet(value, "virtualUrl");
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.center}>
        <div className={classes.text}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            component="p"
            className={classes.center}
          >
            Links Para el evento virtual
          </Typography>
          {this.props.ParentState.virtualUrl.length > 0
            ? [
                <Paper className={classes.Table}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Link</TableCell>
                        <TableCell>Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.ParentState.virtualUrl.map(
                        (Article, index) => {
                          return (
                            <React.Fragment>
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {Article}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="secondary"
                                    onClick={this.deleteLink.bind(this, index)}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </Paper>,
              ]
            : [
                <div className={classes.center}>
                  <Button
                    onClick={this.Dialog.bind(this, "EditDialog")}
                    className={classes.AddCandidate}
                  >
                    Sin links
                  </Button>
                </div>,
              ]}
          {this.props.ParentState.virtualUrl.length > 0 && (
            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.Dialog.bind(this, "EditDialog")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          )}
        </div>

        <Edit
          DialogOpen={this.state.EditDialog}
          DialogClose={this.Dialog.bind(this, "EditDialog")}
          parentCallback={this.parentCallback}
          ParentState={this.props.ParentState}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(createNewEvent);
