import colony from "./colony";
import companiesFilter from "./companiesFilter";
import Companies from "./Companies";
import Employees from "./Employees";
import GetParks from "./GetParks";

const Statistic = { colony, companiesFilter, Companies, Employees, GetParks };

export default Statistic;

export { Employees, Companies, colony, companiesFilter, GetParks };
