import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Places from "../../../API/CallUtils";
import HQCall from "../../../API/Headquarters";
import SeccionsCall from "../../../API/Sections";

const styles = (theme) => ({
  paper: {
    height: "60vh",
    width: "50%",
    padding: "2%",
  },

  Grid: {
    width: "100%",
    height: "96%",
  },

  textField: {},
  textFieldM: {
    //marginLeft:"20px",
    width: "70%",
  },

  button: {
    marginLeft: "20px",
    marginTop: "24px",
    width: "25%",
  },

  div: {
    display: "flex",
    width: "100%",
    justifyContent: "end",
    marginTop: "2%",
  },

  fix: {
    marginTop: "90px",
  },
  fix2: {
    marginTop: "15px",
  },

  Avatar: {
    width: "75px",
    height: "75px",
    marginLeft: "20px",
    marginTop: "15px",
  },
  btn: {
    marginRight: "10px",
  },
  textFieldMulti: {
    width: "100%",
  },
  formControl: {
    width: "77%",
  },
  TableCompany: {
    marginTop: "5%",
    maxHeight: "90%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  GridTable: {
    maxHeight: "90%",
    overflow: "auto",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class HeadquartersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Uuid: "",
      name: "",
      stateUuid: "",
      cityUuid: "",
      Colony: "",
      address: "",
      Estados: [],
      Ciudades: [],
      Users: {},
      readOnly: true,
      btn: "Editar",
      estado: "",
      ciudad: "",
      suburb: "",
    };
  }

  TEST = async () => {
    const Companies = await SeccionsCall.AsesorCompanies();
    this.setState({ Users: Companies });
  };

  componentDidMount = async () => {
    if (this.props.location.state) {
      const {
        HQUuid,
        HQname,
        HQaddress,
        HQCity,
        HQState,
        HQColony,
      } = this.props.location.state;

      this.setState({ name: HQname });
      this.setState({ Uuid: HQUuid });
      this.setState({ address: HQaddress });
      this.setState({ cityUuid: HQCity });
      this.setState({ stateUuid: HQState });
      this.setState({ suburb: HQColony });

      await this.fetchOffice(HQUuid);
      //console.log("Uuid",HQUuid)
    } else {
      this.props.history.push("/Oficinas");
    }
  };

  ShowAllStates = async () => {
    const ShowStates = await Places.States();
    this.setState({ Estados: ShowStates });
  };

  ShowAllCitys = async (uuid) => {
    const ShowCitys = await Places.Citys(uuid);
    this.setState({ Ciudades: ShowCitys });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeState = async (event) => {
    this.setState({ stateUuid: event.target.value });
    await this.ShowAllCitys(event.target.value);
  };

  handleChangeCity = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  Edit = async (event) => {
    if (this.state.btn === "Editar") {
      this.setState({ btn: "Guardar" });
      this.setState({ readOnly: false });
    } else {
      this.setState({ btn: "Editar" });
      this.setState({ readOnly: true });
      await this.Update(this.state);

      //await this.fetchOffice(this.state.Uuid);
    }
  };

  Update = async (State) => {
    // eslint-disable-next-line
    const HQUpdate = await HQCall.Update(State);
    //console.log(HQUpdate)
    this.props.history.push("/Oficinas");
  };

  fetchOffice = async (State) => {
    const Show = await HQCall.Show(State);
    //console.log("Regreso",Show)
    this.setState({ Users: Show === null ? {} : Show.Users });

    await this.ShowAllStates();
    if (Show !== null) {
      await this.ShowAllCitys(Show.State.uuid);
      this.setState({ estado: Show.State.name });
      this.setState({ stateUuid: Show.State.uuid });
    }
    if (Show !== null) {
      this.setState({ ciudad: Show.City.name });
      this.setState({ cityUuid: Show.City.uuid });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container className={classes.Grid}>
            <Grid
              item
              xs={5}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div>
                <TextField
                  id="standard-read-only-input"
                  label="Nombre"
                  className={classes.textField}
                  margin="normal"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
                {this.state.btn === "Guardar" ? (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Estados
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.stateUuid}
                      name="stateUuid"
                      onChange={this.handleChangeState}
                      //inputProps={{ readOnly: this.state.readOnly }}
                    >
                      {this.state.Estados.map((Estado, index) => {
                        return (
                          <MenuItem value={Estado.uuid} key={Estado.Uuid}>
                            {Estado.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    id="standard-read-only-input"
                    label="Estado"
                    className={classes.textField}
                    margin="normal"
                    name="name"
                    value={this.state.estado}
                    onChange={this.onChange}
                    InputProps={{
                      readOnly: this.state.readOnly,
                    }}
                  />
                )}
                {this.state.btn === "Guardar" ? (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Municipio
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.cityUuid}
                      onChange={this.handleChangeCity("cityUuid")}
                      // inputProps={{ readOnly: this.state.readOnly }}
                    >
                      {this.state.Ciudades.map((ciudad, index) => {
                        return (
                          <MenuItem value={ciudad.uuid} key={ciudad.Uuid}>
                            {ciudad.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    id="standard-read-only-input"
                    label="Ciudad"
                    className={classes.textField}
                    margin="normal"
                    name="name"
                    value={this.state.ciudad}
                    onChange={this.onChange}
                    InputProps={{
                      readOnly: this.state.readOnly,
                    }}
                  />
                )}
                <TextField
                  id="standard-read-only-input"
                  label="Colonia"
                  className={classes.textField}
                  margin="normal"
                  name="suburb"
                  value={this.state.suburb}
                  onChange={this.onChange}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
                <TextField
                  id="standard-read-only-input"
                  label="Calle"
                  className={classes.textField}
                  margin="normal"
                  name="address"
                  value={this.state.address}
                  onChange={this.onChange}
                  InputProps={{
                    readOnly: this.state.readOnly,
                  }}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={7}
              className={classes.GridTable}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              {this.state.Users.length > 0 ? (
                <Table
                  size="small"
                  aria-label="a dense table"
                  className={classes.TableCompany}
                >
                  <TableBody className={classes.TableCompany}>
                    {this.state.Users.map((users) => {
                      return (
                        <TableRow key={users.Uuid}>
                          <TableCell
                            key={users.Uuid}
                            component="th"
                            scope="row"
                          >
                            {users.UserProfile.name}-
                            {users.UserProfile.lastNames}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <div className={classes.load}>
                  <CircularProgress />
                </div>
              )}
            </Grid>
            <div className={classes.div}>
              <Link className={classes.Link} to="/oficinas">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                >
                  cancelar
                </Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                onClick={this.Edit}
              >
                {this.state.btn}
              </Button>
            </div>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(HeadquartersDetails)
);
