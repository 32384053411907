import base from "./baseurl";
import token from "./getTk";

const tokenA = token.get();

const api = base.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set("jwt", `token ${tokenA}`);
      },
    ],
  },
});

export default api;
