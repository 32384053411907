import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import CensusSelected from "./Census";
import ListOfCensus from "./ListOfDates";
import ListofChanges from "./DetailsChanges";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  paper: {
    width: "100%",
    //padding: "1%",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Selected: "",
      SelectedComp: "List",
    };
  }
  componentDidMount = async () => {};

  SelectCensus = async (type, uuid, date) => {
    if (type === "Census") {
      this.setState({
        Selected: uuid,
        SelectDate: date,
        SelectedComp: "Census",
      });
    } else {
      this.setState({
        Selected: uuid,
        SelectDate: date,
        SelectedComp: "Details",
      });
    }
  };

  render() {
    const { classes } = this.props;
    var Selected = <></>;
    switch (this.state.SelectedComp) {
      case "List":
        Selected = (
          <ListOfCensus
            uuid={this.props.uuid}
            SelectCensus={this.SelectCensus}
          />
        );
        break;
      case "Census":
        Selected = (
          <CensusSelected
            SelectDate={this.state.SelectDate}
            date={this.state.Selected}
          />
        );
        break;
      case "Details":
        Selected = (
          <ListofChanges
            SelectDate={this.state.SelectDate}
            date={this.state.Selected}
          />
        );
        break;
      default:
        Selected = (
          <ListOfCensus
            list={this.props.uuid}
            SelectCensus={this.SelectCensus}
          />
        );
        break;
    }

    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Padrones</DialogTitle>
        <DialogContent className={classes.paper}>{Selected}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.SelectedComp !== "List"
            ? [
                <Button
                  onClick={() => {
                    this.setState({ SelectedComp: "List" });
                  }}
                  color="primary"
                >
                  <ArrowBackIcon />
                </Button>,
              ]
            : [<></>]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
