import React, { useState, useEffect } from "react";
import {
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import CompanyList from "../../../../API/Companies";
import Skeleton from "@material-ui/lab/Skeleton";
import Utils from "../../../../API/Reports";
import CircularProgress from "@material-ui/core/CircularProgress";
import toasType from "../../../../API/CallUtils/tostyNotification";
import { DatePicker } from "material-ui-pickers";
import MUIDataTable from "mui-datatables";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const Classes = (theme) => ({
  Paper: {
    width: "100%",
    minHeight: "20vh",
    padding: "12px",
  },
  title: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    padding: "8px",
    fontWeight: "bold",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  formControl: {
    width: "100%",
  },
  Skeleton: {
    width: "100%",
    minHeight: "200px",
  },
});
const columns = [
  "Nombre",
  "Sexo",
  "Municipio",
  "Direccion",
  "Fecha de nacimiento",
  "Estado civil",
  "Escolaridad",
  "Telefono",
  "Fecha de Afiliación",
];

const options = {
  textLabels: {
    body: {
      noMatch: "Estableciendo conexion",
      toolTip: "Ordenar",
      columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
    },
    pagination: {
      next: "Siguiente pagina",
      previous: "Pagina anterior",
      rowsPerPage: "Columnas por pagina:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tablas",
    },
    filter: {
      all: "Todos",
      title: "FILTROS",
      reset: "RESET",
    },
    viewColumns: {
      title: "Mostrar Columnas",
      titleAria: "Mostrar/Ocultar Columnas de la tabla",
    },
    selectedRows: {
      text: "fila(s) seleccionadas",
      delete: "Borrar",
      deleteAria: "Borrar Filas seleccionadas",
    },
  },
  filterType: "textField",
  page: 0,
  selectableRows: "none",
  print: false,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 15, 50, 100],
};

function TechnicalInformation({ classes }) {
  const [loading, setLoading] = useState(false);
  const [initialDate, setInitialDate] = useState(moment());
  const [finalDate, setFinalDate] = useState(moment());
  const [companies, setCompanies] = useState([]);
  const [report, setReport] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  useEffect(() => {
    fetchPark();
  }, []);
  const fetchPark = async () => {
    const CompaniesList = await CompanyList.showAllNotSta();
    setCompanies(CompaniesList);
  };
  const TecnicalHistoryReport = async (companyId) => {
    try {
      setLoading(true);
      const state = {
        companyId: companyId,
        fromDate: initialDate,
        toDate: finalDate,
      };
      const doc = await Utils.MembershipTokens(state);
      console.log("el doc", doc);
      if (doc.status === 200) {
        setLoading(false);
        if (doc.data && doc.data.length > 0) {
          const prune = doc.data.map((x) => {
            return [
              x.User.UserProfile.name + x.User.UserProfile.lastNames,
              x.User.UserProfile.sex,
              x.User.UserProfile.city,
              x.User.UserProfile.address,
              x.User.UserProfile.dateOfBirth,
              x.User.UserProfile.CivilStateId,
              x.User.UserProfile.ScholarshipId,
              x.User.UserProfile.mobilePhone,
              x.User.UserProfile.affiliationDate,
            ];
          });
          setReport(prune);
        } else {
          toasType.error("No resultados para su búsqueda");
        }
      } else {
        toasType.error("No fue posible generar su reporte SSE");
        setLoading(false);
      }
    } catch (e) {
      toasType.error("No fue posible generar su reporte");
    }
  };
  return (
    <>
      {companies.length > 0 ? (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel>Compañía</InputLabel>
            <Select
              value={selectedCompany}
              onChange={(e) => {
                setReport(false, setSelectedCompany(e.target.value));
              }}
            >
              {companies.map((Estado, index) => {
                return (
                  <MenuItem value={Estado.id} key={Estado}>
                    {Estado.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <DatePicker
            label="Desde"
            value={initialDate}
            onChange={(e) => {
              setInitialDate(moment(new Date(e)).toISOString());
            }}
            disableFuture
            inputVariant="outlined"
            format="ll"
            className={classes.TextField}
          />
          <DatePicker
            label="Hasta"
            value={finalDate}
            onChange={(e) => {
              setFinalDate(moment(new Date(e)).toISOString());
            }}
            disableFuture
            inputVariant="outlined"
            format="ll"
            className={classes.TextField}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "12px",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={selectedCompany === undefined}
              onClick={() => {
                TecnicalHistoryReport();
              }}
            >
              {loading ? <CircularProgress color="white" /> : "Buscar"}
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "12px",
            }}
          >
            {report && report.length > 0 && (
              <MUIDataTable
                title={"Resultado"}
                data={report}
                columns={columns}
                options={options}
                selectableRows="none"
              />
            )}
          </div>
        </>
      ) : (
        <Skeleton className={classes.Skeleton} animation="wave" />
      )}
    </>
  );
}

export default withStyles(Classes)(TechnicalInformation);
