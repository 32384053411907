import base from "../baseurl";
import token from "../getTk";

const Delete = async (
  sonId,
  fullName,
  scholarshipId,
  age,
  verified,
  rejectionNotes
) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log(
    "llega a la llamda",
    sonId,
    fullName,
    scholarshipId,
    age,
    verified,
    rejectionNotes
  );
  const Create = await api
    .post("sons/update", {
      json: {
        sonId,
        fullName,
        scholarshipId,
        age,
        verified,
        rejectionNotes,
      },
    })
    .json();
  console.log(Create);
  return Create;
};

export default Delete;
