import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import BusStop from "../../../API/BusStops";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectCity from "./SelectCity";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      name: "",
      address: "",
      CitySelected: "",
    };
  }
  componentDidMount = async () => {};
  create = async () => {
    try {
      this.setState({
        Oncreate: true,
      });

      const Deli = await BusStop.Create(
        this.state.name,
        this.state.address,
        this.state.CitySelected
      );
      if (Deli.status === "success") {
        this.setState({
          Oncreate: false,
        });
        toastType.success("Se creado con éxito");
        await this.props.Refresh("CreateDialog");
      } else {
        this.setState({
          Oncreate: false,
        });
        toastType.error("SSE No fue Posible Crear el elemento");
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Crear el evento");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Nueva Parada de autobus
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            {" "}
            <TextField
              label="Nombre"
              margin="normal"
              size="small"
              name="name"
              onChange={this.onChange}
              value={this.state.name}
              fullWidth
            />
            <TextField
              label="Dirección"
              margin="normal"
              size="small"
              name="address"
              onChange={this.onChange}
              value={this.state.address}
              fullWidth
            />
            <SelectCity
              SendToParent={this.onChange}
              CitySelected={this.state.CitySelected}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.name.length > 0
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
