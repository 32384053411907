import React from "react";
import "babel-polyfill";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBoxIcon from "@material-ui/icons/Add";
import BenefitsCall from "../../../API/CTM/Benefits";
import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import toasType from "../../../API/CallUtils/tostyNotification";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "100%",
    height: "80vh",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "90%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "cover",
  },
});

class B extends React.Component {
  state = {
    Blog: [
      {
        uuid: "",
        city: "",
        bussiness: "",
        address: "",
        off: "",
      },
    ],
    Dialog: false,
    DialogDelete: false,
    DialogCreate: false,

    Uuid: "",
    city: "",
    bussiness: "",
    address: "",
    off: "",
  };

  componentDidMount = async () => {
    await this.showAll();
  };

  ArticleD = (Uuid, city, bussiness, address, off, e) => {
    this.setState({
      Uuid: Uuid,
      city: city,
      bussiness: bussiness,
      address: address,
      off: off,
      Dialog: true,
    });
  };

  DialogEntrando = async () => {
    this.setState({
      Uuid: "",
      city: "",
      bussiness: "",
      address: "",
      off: "",
      Dialog: false,
    });
  };

  DialogDelete = async () => {
    this.setState({ DialogDelete: false });
  };

  DialogCreate = async () => {
    this.setState({
      DialogCreate: false,
      city: "",
      bussiness: "",
      address: "",
      off: "",
    });
  };

  create = async () => {
    try {
      const CreateBenefit = await BenefitsCall.Create(this.state);
      if (CreateBenefit.status === "success") {
        toasType.success("Creado con éxito");
      } else {
        toasType.error("No fue posible crear");
      }
    } catch (e) {
      toasType.error("No fue posible crear");
    }

    this.setState({
      DialogCreate: false,
      city: "",
      bussiness: "",
      address: "",
      off: "",
    });
    await this.showAll();
  };

  update = async () => {
    try {
      const updateBenefit = await BenefitsCall.Update(this.state);
      if (updateBenefit.status === "success") {
        toasType.success("Beneficio actualizado");
      } else {
        toasType.error("No fue posible actualizar");
      }
    } catch (e) {
      toasType.error("No fue posible actualizar");
    }

    this.setState({
      city: "",
      bussiness: "",
      address: "",
      off: "",
      Dialog: false,
    });
    await this.showAll();
  };

  delete = async (Uuid) => {
    try {
      const removeBenefite = await BenefitsCall.Delete(Uuid);
      if (removeBenefite.status === "success") {
        toasType.success("Fue eliminado");
      } else {
        toasType.error("No fue posible eliminar ");
      }
    } catch (e) {
      toasType.error("No fue posible eliminar ");
    }

    await this.showAll();
  };

  showAll = async () => {
    try {
      const polute = await BenefitsCall.showAll();
      if (polute) {
        this.setState({ Blog: polute.bonuses });
      } else {
        toasType.error("Error al obtener la información");
      }
    } catch (e) {
      toasType.error("Error al obtener la información");
    }
  };

  Opencreate = async () => {
    this.setState({ DialogCreate: true });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container>
            {this.state.Blog.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ciudad</TableCell>
                      <TableCell>Negocio</TableCell>
                      <TableCell>Domicilio</TableCell>
                      <TableCell>Descuento</TableCell>
                      <TableCell>Detalles</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Blog.map((Article) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.city}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.business}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.address}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Article.discount}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.ArticleD.bind(
                                  this,
                                  Article.uuid,
                                  Article.city,
                                  Article.business,
                                  Article.address,
                                  Article.discount
                                )}
                              >
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.delete.bind(this, Article.uuid)}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}
            <div className={classes.btnTable}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addBTN}
                onClick={this.Opencreate}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </Grid>
        </Paper>

        <Dialog
          open={this.state.Dialog}
          onClose={this.DialogEntrando}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          fullWidth="xl"
        >
          <DialogTitle id="form-dialog-title">Beneficios</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Ciudad"
                  name="city"
                  value={this.state.city}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Negocio"
                  name="bussiness"
                  value={this.state.bussiness}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Dirección"
                  name="address"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.address}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Descuento"
                  name="off"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.off}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogEntrando} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.update} color="primary">
              Guardar Cambios
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogDelete}
          onClose={this.DialogDelete}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Eliminar Articulo</DialogTitle>
          <DialogContent>
            <DialogContentText>Esta acción sera registrada</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogDelete} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.DialogDelete} color="primary">
              Eliminar Articulo
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogCreate}
          onClose={this.DialogCreate}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth="md"
        >
          <DialogTitle id="form-dialog-title">Nuevo Descuento</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Ciudad"
                  name="city"
                  value={this.state.city}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Negocio"
                  name="bussiness"
                  value={this.state.bussines}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Dirección"
                  name="address"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.address}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Descuento"
                  name="off"
                  multiline
                  rows="4"
                  variant="outlined"
                  value={this.state.off}
                  className={classes.Title}
                  onChange={this.onChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogCreate} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.create} color="primary">
              Crear Beneficio
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(B);
