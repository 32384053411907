import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Button,
  Paper,
  withStyles,
  FormControl,
  Input,
  InputLabel,
  Avatar,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  paperImg: {
    padding: theme.spacing(4),
    margin: "auto",
    maxWidth: 500,
  },
  Grid: {
    marginTop: theme.spacing(2),
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Bulletin extends Component {
  constructor(props) {
    super(props);
    this.state = { salute: "Tardes" };
  }

  componentDidMount = async () => {
    if (this.props.userType === 45298 || this.props.userType === 72468) {
      //await this.fetchCompanyList();
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCallback = (urlFromChild, stateName) => {
    this.setState({
      [stateName]: urlFromChild,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Segunda Verificación
          </Typography>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Correo Electrónico</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email}
                onChange={this.onChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="pass"
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.pass}
                onChange={this.onChange}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onSubmit={this.onSubmit}
            >
              Entrar
            </Button>
          </form>
        </Paper>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Bulletin));
