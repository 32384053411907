import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { OnLoadSelect } from "../../../../Components/StatesForComponets";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
  },
  event: {
    width: "98%",
    //height: "98%",
    margin: "1%",
  },
  Grid: {
    width: "98%",
    //height: "98%",
    padding: "1%",
  },
  text: {
    width: "60%",
    margin: "3px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
class createNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onUse: "",
    };
  }
  componentDidMount = async () => {};
  onChange = (event) => {
    console.log(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  SendToParent = () => {
    console.log("llego");
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Nueva Parada</DialogTitle>
        <DialogContent>
          <Grid container className={classes.Table}>
            {this.props.List.length > 0
              ? [
                  <FormControl className={classes.Table}>
                    <InputLabel id="demo-simple-select-label">
                      Listado de Paradas
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.onUse}
                      name="onUse"
                      onChange={this.onChange}
                    >
                      {this.props.List.map((companie, index) => {
                        return (
                          <MenuItem value={companie.id} key={companie.id}>
                            {companie.name + " " + companie.address}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>,
                ]
              : [<OnLoadSelect />]}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cancelar
          </Button>
          <Button
            disabled={this.state.onUse !== "" ? false : true}
            onClick={() => {
              this.props.SendToParent(this.state.onUse);
              this.setState({ onUse: "" });
              this.props.DialogClose();
            }}
            color="primary"
          >
            Agregar Parada
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(createNewEvent);
