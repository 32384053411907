import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Consult from "../../API/Consultations";

import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  GridC: {
    width: "100%",
    //maxHeight:"30vh",
    //margin:"3%"
  },
  paper: {
    width: "100%",
    maxHeight: "70vh",
    //display:"flex",
    //justifyContent: "center",
  },
  titulo: {
    width: "100%",
  },
  text: {
    width: "100%",
    marginTop: "10px",
  },
  RadioC: {
    width: "100%",
  },

  Radio: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  TableCompany: {
    maxHeight: "30vh",
    overflow: "auto",
  },
  paperC: {
    width: "100%",
    maxHeight: "30vh",
    overflow: "auto",
  },
  comp: {
    width: "100%",
  },
  warninig: {
    width: "100%",
    color: "red",
  },
});

const mapStateToProps = (state) => {
  return { Uuid: state.login.user.userUuid };
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "day",
      Entrando: [],
      Progreso: [],
      Terminadas: [],

      nameE: "",
      companyE: "",
      UuidE: "1234",
      phoneE: "",
      emailE: "",
      detailsE: "",
      notasE: "",
      NoRfcE: false,
      NoRfcP: false,
      DialogEntrando: false,
      dateE: "",

      nameP: "",
      companyP: "",
      UuidP: "1234",
      phoneP: "",
      emailP: "",
      detailsP: "",
      notasP: "",
      timeP: "",
      Notas: " ",
      DialogProgreso: false,

      nameT: "",
      companyT: "",
      phoneT: "",
      emailT: "",
      detailsT: "",
      notasT: "",
      timeT: "",
      notasEnd: "",
      cal: "",

      DialogTerminado: false,

      UuidStateE: "",
      UuidStateP: "",
      UuidStateT: "",
      hoy: moment().toISOString(),
      feedback: "",
    };
  }

  fetchConsult = async (Uuid) => {
    const Estados = await Consult.States();
    this.setState({
      UuidStateE: Estados[0].uuid,
      UuidStateP: Estados[1].uuid,
      UuidStateT: Estados[2].uuid,
    });

    const ConsultasE = await Consult.ShowAll(Uuid, this.state.UuidStateE);
    this.setState({ Entrando: ConsultasE });
    const ConsultasP = await Consult.ShowAll(Uuid, this.state.UuidStateP);
    this.setState({ Progreso: ConsultasP });
    const ConsultasT = await Consult.ShowAll(Uuid, this.state.UuidStateT);
    this.setState({ Terminadas: ConsultasT });
  };

  TakeConsult = async (Uuid) => {
    await Consult.Take(Uuid, this.props.Uuid);
    const ConsultasE = await Consult.ShowAll(
      this.props.Uuid,
      this.state.UuidStateE
    );
    this.setState({ Entrando: ConsultasE });
    const ConsultasP = await Consult.ShowAll(
      this.props.Uuid,
      this.state.UuidStateP
    );
    this.setState({ Progreso: ConsultasP });
  };
  CloseConsult = async (Uuid, notas) => {
    await Consult.Close(Uuid, notas);
    const ConsultasP = await Consult.ShowAll(
      this.props.Uuid,
      this.state.UuidStateP
    );
    this.setState({ Progreso: ConsultasP });
    const ConsultasT = await Consult.ShowAll(
      this.props.Uuid,
      this.state.UuidStateT
    );
    this.setState({ Terminadas: ConsultasT });
  };

  componentDidMount = async () => {
    await this.fetchConsult(this.props.Uuid);
  };

  EntrandoD = (
    Name,
    Uuid,
    notas,
    fecha,
    motivo,
    telefono,
    email,
    comp,
    rfc,
    e
  ) => {
    this.setState({
      UuidE: Uuid,
      nameE: Name,
      notasE: notas,
      dateE: fecha,
      detailsE: motivo,
      phoneE: telefono,
      emailE: email,
      companyE: comp,
      NoRfcE: rfc,
      DialogEntrando: true,
    });
  };

  DialogEntrando = async () => {
    this.setState({ DialogEntrando: false });
  };

  TomarConsulta = async () => {
    await this.TakeConsult(this.state.UuidE);
    this.setState({ DialogEntrando: false });
  };
  TerminarConsultaEntrante = async () => {
    //await this.TakeConsult(this.state.UuidE);
    this.setState({ DialogEntrando: false });
  };

  TerminarConsulta = async () => {
    await this.CloseConsult(this.state.UuidP, this.state.Notas);
    this.setState({ DialogProgreso: false });
  };

  ProgresoD = (
    Name,
    Uuid,
    notas,
    fecha,
    motivo,
    telefono,
    email,
    comp,
    rfc,
    e
  ) => {
    this.setState({
      UuidP: Uuid,
      nameP: Name,
      companyP: comp,
      phoneP: telefono,
      emailP: email,
      notasP: notas,
      detailsP: motivo,
      DialogProgreso: true,
      timeP: fecha,
      Notas: " ",
      NoRfcP: rfc,
    });
  };

  DialogProgreso = async () => {
    this.setState({ DialogProgreso: false });
  };

  TerminadoD = (
    Name,
    Uuid,
    notas,
    fecha,
    motivo,
    telefono,
    email,
    notasEnd,
    score,
    feed,
    comp,
    e
  ) => {
    this.setState({
      nameT: Name,
      companyT: comp,
      phoneT: telefono,
      emailT: email,
      notasT: notas,
      detailsT: motivo,
      DialogTerminado: true,
      timeT: fecha,
      notasEnd: notasEnd,
      cal: score,
      feedback: feed,
    });
  };

  DialogTerminado = async () => {
    this.setState({ DialogTerminado: false });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = async (ev) => {
    this.setState({ date: ev.target.value });
    //mandar fecha
    var time = moment();

    switch (ev.target.value) {
      case "day":
        time = null;
        break;
      case "week":
        time = time.subtract(8, "days");
        time = time.toISOString();
        break;

      case "month":
        time = time.subtract(1, "months");
        time = time.toISOString();
        break;
      case "year":
        time = time.subtract(1, "years");
        time = time.toISOString();
        break;
      case "all":
        time = time.subtract(5, "years");
        time = time.toISOString();
        break;
      default:
        time = moment();
        break;
    }
    const ConsultasT = await Consult.ShowAll(
      this.props.Uuid,
      this.state.UuidStateT,
      time
    );
    this.setState({ Terminadas: ConsultasT });
    //console.log(ConsultasT)
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container className={classes.Grid} spacing={2}>
          <Grid
            item
            xs={12}
            className={classes.GridC}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={"auto"}
              className={classes.GridC}
              container
              justify="flex-start"
              alignItems="flex-start"
            ></Grid>
            <Grid
              item
              xs={12}
              className={classes.GridC}
              container
              justify="flex-start"
              alignItems="flex-start"
            >
              <Paper className={classes.paper}>
                <div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.titulo}
                  >
                    Entrantes
                  </Typography>
                </div>

                {this.state.Entrando.length > 0 ? (
                  <Paper className={classes.paperC}>
                    <Table
                      size="small"
                      aria-label="a dense table"
                      className={classes.TableCompany}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Empresa</TableCell>
                          <TableCell>Detalles</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.TableCompany}>
                        {this.state.Entrando.map((Consulta) => {
                          return (
                            <React.Fragment>
                              <TableRow key={Consulta.uuid}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={Consulta.uuid}
                                >
                                  {Consulta.Employee !== null
                                    ? Consulta.Employee.UserProfile.name
                                    : Consulta.OptionalDatum.name !== null
                                    ? Consulta.OptionalDatum.name
                                    : " "}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={Consulta.uuid}
                                >
                                  {Consulta.Company.name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={Consulta.uuid}
                                >
                                  <Button
                                    color="primary"
                                    onClick={this.EntrandoD.bind(
                                      this,
                                      Consulta.Employee !== null
                                        ? Consulta.Employee.UserProfile.name
                                        : Consulta.OptionalDatum !== null
                                        ? Consulta.OptionalDatum.name
                                        : " ",
                                      Consulta.uuid,
                                      Consulta.details,
                                      Consulta.createdAt.split("T")[0],
                                      Consulta.ConsultationType !== null
                                        ? Consulta.ConsultationType.content
                                        : " ",
                                      Consulta.OptionalDatum.phone !== null
                                        ? Consulta.OptionalDatum.phone
                                        : " ",
                                      Consulta.OptionalDatum.email !== null
                                        ? Consulta.OptionalDatum.email
                                        : " ",
                                      Consulta.Company !== null
                                        ? Consulta.Company.name
                                        : "",
                                      Consulta.Company !== null
                                        ? Consulta.Company.highPriority
                                        : ""
                                    )}
                                  >
                                    info
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <Paper>
                    <Table
                      size="small"
                      aria-label="a dense table"
                      className={classes.TableCompany}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Empresa</TableCell>
                          <TableCell>Detalles</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.TableCompany}>
                        <React.Fragment>
                          <TableRow>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                          </TableRow>
                        </React.Fragment>
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.GridC}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Paper className={classes.paper}>
              <div>
                <Typography variant="h6" gutterBottom>
                  En Progreso
                </Typography>
              </div>
              {this.state.Progreso.length > 0 ? (
                <Paper>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    className={classes.TableCompany}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Empresa</TableCell>
                        <TableCell>Detalles</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.TableCompany}>
                      {this.state.Progreso.map((Consulta) => {
                        return (
                          <React.Fragment>
                            <TableRow key={Consulta.uuid}>
                              <TableCell component="th" scope="row">
                                {Consulta.Employee !== null
                                  ? Consulta.Employee.UserProfile.name
                                  : Consulta.OptionalDatum.name !== null
                                  ? Consulta.OptionalDatum.name
                                  : " "}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Consulta.Company.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  color="primary"
                                  onClick={this.ProgresoD.bind(
                                    this,
                                    Consulta.Employee !== null
                                      ? Consulta.Employee.UserProfile.name
                                      : Consulta.OptionalDatum !== null
                                      ? Consulta.OptionalDatum.name
                                      : " ",
                                    Consulta.uuid,
                                    Consulta.details,
                                    Consulta.createdAt.split("T")[0],
                                    Consulta.ConsultationType !== null
                                      ? Consulta.ConsultationType.content
                                      : " ",
                                    Consulta.OptionalDatum.phone !== null
                                      ? Consulta.OptionalDatum.phone
                                      : " ",
                                    Consulta.OptionalDatum.email !== null
                                      ? Consulta.OptionalDatum.email
                                      : " ",
                                    Consulta.Company !== null
                                      ? Consulta.Company.name
                                      : "",
                                    Consulta.Company !== null
                                      ? Consulta.Company.highPriority
                                      : ""
                                  )}
                                >
                                  info
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              ) : (
                <Paper>
                  <Table
                    size="small"
                    aria-label="a dense table"
                    className={classes.TableCompany}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Empresa</TableCell>
                        <TableCell>Detalles</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.TableCompany}>
                      <React.Fragment>
                        <TableRow>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                        </TableRow>
                      </React.Fragment>
                    </TableBody>
                  </Table>
                </Paper>
              )}
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.GridC}
            container
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={2}
              className={classes.GridC}
              container
              justify="flex-start"
              alignItems="flex-start"
            >
              <Paper className={classes.Radio}>
                <FormControl component="fieldset" className={classes.Radio}>
                  <FormLabel component="legend">Consultas</FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    onChange={this.handleChange}
                    value={this.state.date}
                  >
                    <FormControlLabel
                      value="day"
                      control={<Radio color="primary" />}
                      label="Dia"
                      labelPlacement="end"
                      className={classes.RadioC}
                    />
                    <FormControlLabel
                      value="week"
                      control={<Radio color="primary" />}
                      label="Semana"
                      labelPlacement="end"
                      className={classes.RadioC}
                    />
                    <FormControlLabel
                      value="month"
                      control={<Radio color="primary" />}
                      label="Mes"
                      labelPlacement="end"
                      className={classes.RadioC}
                    />
                    <FormControlLabel
                      value="year"
                      control={<Radio color="primary" />}
                      label="Año"
                      labelPlacement="end"
                      className={classes.RadioC}
                    />
                    <FormControlLabel
                      value="all"
                      control={<Radio color="primary" />}
                      label="Todas"
                      labelPlacement="end"
                      className={classes.RadioC}
                    />
                  </RadioGroup>
                </FormControl>
              </Paper>
            </Grid>

            <Grid
              item
              xs={10}
              className={classes.GridC}
              container
              justify="flex-start"
              alignItems="flex-start"
            >
              <Paper className={classes.paper}>
                <div>
                  <Typography variant="h6" gutterBottom>
                    Terminadas
                  </Typography>
                </div>
                {this.state.Terminadas.length > 0 ? (
                  <Paper>
                    <Table
                      size="small"
                      aria-label="a dense table"
                      className={classes.TableCompany}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Empresa</TableCell>
                          <TableCell>Detalles</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.TableCompany}>
                        {this.state.Terminadas.map((Consulta) => {
                          return (
                            <React.Fragment>
                              <TableRow key={Consulta.uuid}>
                                <TableCell component="th" scope="row">
                                  {Consulta.Employee !== null
                                    ? Consulta.Employee.UserProfile.name
                                    : Consulta.OptionalDatum.name !== null
                                    ? Consulta.OptionalDatum.name
                                    : " "}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Consulta.Company.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    color="primary"
                                    onClick={this.TerminadoD.bind(
                                      this,
                                      Consulta.Employee !== null
                                        ? Consulta.Employee.UserProfile.name
                                        : Consulta.OptionalDatum !== null
                                        ? Consulta.OptionalDatum.name
                                        : " ",
                                      Consulta.uuid,
                                      Consulta.details,
                                      Consulta.createdAt.split("T")[0],
                                      Consulta.ConsultationType !== null
                                        ? Consulta.ConsultationType.content
                                        : " ",
                                      Consulta.OptionalDatum.phone !== null
                                        ? Consulta.OptionalDatum.phone
                                        : " ",
                                      Consulta.OptionalDatum.email !== null
                                        ? Consulta.OptionalDatum.email
                                        : " ",
                                      Consulta.solution !== null
                                        ? Consulta.solution
                                        : "Sin Notas de solución",
                                      Consulta.score !== null
                                        ? Consulta.score
                                        : "No ha sido calificado",
                                      Consulta.feedback !== null
                                        ? Consulta.feedback
                                        : "No hay notas ",
                                      Consulta.Company !== null
                                        ? Consulta.Company.name
                                        : ""
                                    )}
                                  >
                                    info
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                ) : (
                  <Paper>
                    <Table
                      size="small"
                      aria-label="a dense table"
                      className={classes.TableCompany}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Empresa</TableCell>
                          <TableCell>Detalles</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.TableCompany}>
                        <React.Fragment>
                          <TableRow>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell component="th" scope="row"></TableCell>
                          </TableRow>
                        </React.Fragment>
                      </TableBody>
                    </Table>
                  </Paper>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.DialogEntrando}
          onClose={this.DialogEntrando}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Consulta</DialogTitle>
          <DialogContent>
            <DialogContentText>Detalles de la consulta</DialogContentText>
            <Grid container className={classes.Grid} spacing={1}>
              <Grid
                item
                xs={4}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Nombre"
                  value={this.state.nameE}
                />
              </Grid>
              <Grid
                item
                xs={4}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Teléfono"
                  value={this.state.phoneE}
                />
                <TextField
                  id="standard-basic"
                  label="Email"
                  value={this.state.emailE}
                />
              </Grid>

              <Grid
                item
                xs={4}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Motivo"
                  value={this.state.detailsE}
                />
                {this.state.NoRfcE === false ? (
                  <TextField
                    id="standard-basic"
                    label="Estado del perfil"
                    value={"Perfil verificado"}
                  />
                ) : (
                  <div>
                    <TextField
                      id="standard-basic"
                      label="Estado del perfil"
                      value={"Perfil no verificado"}
                      disabled
                    />

                    <div className={classes.warninig}>
                      <Typography variant="body1">
                        Esta consulta es de un perfil no verificado
                      </Typography>
                      <Typography variant="body1">
                        Sera necesario contactar al afiliado por alguno de los
                        medios proporcionados para solucionar la consulta
                      </Typography>
                      <Typography variant="body1">
                        El afiliado no tendrá acceso a las notas de terminación
                      </Typography>
                    </div>
                  </div>
                )}
                <TextField
                  id="standard-basic"
                  label="Fecha Solicitud"
                  value={this.state.dateE}
                />
                <TextField
                  id="standard-basic"
                  label="Empresa"
                  value={this.state.companyE}
                  rows="3"
                  multiline
                />
              </Grid>
              <TextField
                className={classes.text}
                id="standard-basic"
                label="Detalles"
                value={this.state.notasE}
                rows="3"
                multiline
                variant="outlined"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogEntrando} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.TomarConsulta} color="primary">
              Tomar consulta
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogProgreso}
          onClose={this.DialogProgreso}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Consulta</DialogTitle>
          <DialogContent>
            <DialogContentText>Progreso</DialogContentText>
            <TextField
              id="standard-basic"
              label="Empresa"
              value={this.state.companyP}
              disabled
              rows="2"
              multiline
              className={classes.comp}
            />
            <TextField
              id="standard-basic"
              label="Nombre"
              value={this.state.nameP}
              disabled
            />
            <TextField
              id="standard-basic"
              label="Teléfono"
              value={this.state.phoneP}
              disabled
            />
            <TextField
              id="standard-basic"
              label="Email"
              value={this.state.emailP}
              disabled
            />
            <TextField
              id="standard-basic"
              label="Motivo"
              value={this.state.detailsP}
              disabled
            />
            <TextField
              id="standard-basic"
              label="Fecha"
              value={this.state.timeP}
              disabled
            />
            {this.state.NoRfcP === false ? (
              <TextField
                id="standard-basic"
                label="Estado del perfil"
                value={"Perfil verificado"}
                disabled
              />
            ) : (
              <div>
                <TextField
                  id="standard-basic"
                  label="Estado del perfil"
                  value={"Perfil no verificado"}
                  disabled
                />

                <div className={classes.warninig}>
                  <Typography variant="body1">
                    Esta consulta es de un perfil no verificado
                  </Typography>
                  <Typography variant="body1">
                    Sera necesario contactar al afiliado por alguno de los
                    medios proporcionados para solucionar la consulta
                  </Typography>
                  <Typography variant="body1">
                    El afiliado no tendrá acceso a las notas de terminación
                  </Typography>
                </div>
              </div>
            )}
            <TextField
              className={classes.text}
              id="standard-basic"
              label="Notas de la Consulta"
              rows="3"
              multiline
              variant="outlined"
              disabled
              value={this.state.notasP}
            />
            <TextField
              name="Notas"
              className={classes.text}
              id="standard-basic"
              label="Notas de terminación"
              rows="3"
              multiline
              variant="outlined"
              value={this.state.Notas}
              onChange={this.onChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogProgreso} color="primary">
              Cancel
            </Button>
            <Button onClick={this.TerminarConsulta} color="primary">
              Terminar consulta
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogTerminado}
          onClose={this.DialogTerminado}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Consulta</DialogTitle>
          <DialogContent>
            <DialogContentText>Detalles de la Conclusion</DialogContentText>
            <TextField
              id="standard-basic"
              label="Empresa"
              value={this.state.companyT}
              disabled
              rows="2"
              multiline
              className={classes.comp}
            />
            <TextField
              id="standard-basic"
              label="Nombre"
              value={this.state.nameT}
              disabled
            />
            <TextField
              id="standard-basic"
              label="Teléfono"
              value={this.state.phoneT}
              disabled
            />
            <TextField
              id="standard-basic"
              label="Email"
              value={this.state.emailT}
              disabled
            />
            <TextField
              id="standard-basic"
              label="Motivo"
              value={this.state.detailsT}
              disabled
            />
            <TextField
              id="standard-basic"
              label="tiempo"
              value={this.state.timeT}
              disabled
            />

            <TextField
              className={classes.text}
              id="standard-basic"
              label="Notas de la Consulta"
              rows="3"
              multiline
              variant="outlined"
              disabled
              value={this.state.notasT}
            />
            <TextField
              className={classes.text}
              id="standard-basic"
              label="Notas de la Terminación"
              rows="3"
              multiline
              variant="outlined"
              disabled
              value={this.state.notasEnd}
            />
            <TextField
              className={classes.text}
              id="standard-basic"
              label="Notas del afiliado"
              rows="3"
              multiline
              variant="outlined"
              disabled
              value={this.state.feedback}
            />
            <TextField
              id="standard-basic"
              label="calificación"
              value={this.state.cal}
              disabled
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.DialogTerminado} color="primary">
              cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Users));
