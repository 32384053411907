import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Edit from "./EditDialogGenera";
const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "200px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
    //marginTop:'1%',
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
});

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = { EditDialog: false };
  }
  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  Refresh = () => {
    this.props.Refresh();
    this.setState({ EditDialog: false });
  };
  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.Initial}>
        <Grid container className={classes.Initial} spacing={1}>
          <Grid container item xs={12} className={classes.Initial}>
            <Typography
              align="Center"
              variant="h5"
              component="p"
              className={classes.Subtitle}
            >
              Generales
            </Typography>
            <Paper className={classes.Paper}>
              <Grid
                container
                item
                xs={12}
                className={classes.Initial}
                spacing={1}
              >
                <Grid container item xs={6}>
                  <TextField
                    label="Estado"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.State
                        ? this.props.info.company.State.name
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Municipio"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.City
                        ? this.props.info.company.City.name
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>

                <Grid container item xs={6}>
                  <TextField
                    label="País de Origen"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.country
                        ? this.props.info.company.country
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Ano de inicio"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.TechnicalDetail.operationStart
                        ? this.props.info.company.TechnicalDetail.operationStart
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Producto que elabora"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.TechnicalDetail.product
                        ? this.props.info.company.TechnicalDetail.product
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Principales Clientes"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.TechnicalDetail.clients
                        ? this.props.info.company.TechnicalDetail.clients
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Fecha de Vigencia del contrato"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.TechnicalDetail.contract
                        ? this.props.info.company.TechnicalDetail.contract
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    label="Ano de afiliación"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.company.acceptanceDate
                        ? this.props.info.company.acceptanceDate
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    label="Dirección"
                    margin="normal"
                    size="small"
                    multiline
                    rowsMax={4}
                    fullWidth
                    value={
                      this.props.info.company.address
                        ? this.props.info.company.address
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.Margin}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.Dialog.bind(this, "EditDialog")}
                >
                  <EditIcon />
                </Button>
              </Grid>
            </Paper>
            {this.state.EditDialog
              ? [
                  <Edit
                    DialogOpen={this.state.EditDialog}
                    DialogClose={this.Dialog.bind(this, "EditDialog")}
                    Info={this.props.info}
                    Refresh={this.Refresh}
                  />,
                ]
              : [<></>]}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TablePadron);
