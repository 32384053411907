import api from "../baseurl";

const colony = async () => {
    
    const colonyList = await api.get(`User`).json();
    //console.log("llamada");
    return colonyList;
    
  };
  
  export default colony ;