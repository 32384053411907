import base from "../../baseurl";
import token from "../../getTk";

const Update = async (state) => {
  console.log(state);
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Update = await api
    .post("events/update", {
      timeout: false,
      json: {
        eventUuid: state.uuid,
        title: state.title,
        eventDate: state.eventDate,
        content: state.description,
        description: state.description,
        img: state.img,
        files: state.files,
        place: state.place,
        register: state.regis,
        enabled: !state.enabled,
        daysBeforeAnouncment: state.daysBeforeAnouncment,
        busStopsId: state.busStopsId ? state.busStopsId : [],
        campusesId: state.campusesId ? state.campusesId : [],
        virtualUrl: state.virtualUrl,
        audience: state.audience,
        audienceTitles: state.audienceTitles,
        eventTypeId: state.EventTypeId,
      },
    })
    .json();
  console.log(Update);
  return Update;
};

export default Update;
