import base from "../baseurl";
import token from "../getTk";

const Create = async (name, address, cityId) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const create = await api
    .post("user-permissions/entities", {
      timeout: false,
      json: {},
    })
    .json();
  return create;
};

export default Create;
