import base from "../../../baseurl";
import token from "../../../getTk"


const Update = async (state) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
   console.log("estado en la llamada",state)
    const Update = await api.post("pages/update",{json: {
        pageId:10,
        name:"capacitacionEmpresas",
        content:{
            title:state.principalTitle,
            welcomeText:state.principalText,
            banner1:state.banner1,
            banner2:state.banner2,
            Topics:state.Topics,
            ImportantPoints:state.ImportantPoints,
            Imgs:state.Imgs
        }
    }
    ,
        timeout:false
}).json();
   console.log(Update)
    return Update ;
    
  };
  
  export default  Update ;