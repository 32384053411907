import axios from "axios";
const Report = async (companyId) => {
  const re = await axios
    .post(`https://api.sitimm.org/api/reports/show`, {
      reportNumber: 6,
      params: {
        companyId,
      },
    })
    .then((res) => {
      return res;
    });
  return re;
};

export default Report;
