import base from "../baseurl";
import token from "../getTk"


const ImgPrincipal = async (url) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
  console.log(url)
    const Update = await api.post("imgs/delete",{json: {
        fileUrl:url,
    }
    ,timeout:false}

).json();    
return Update
    
  };
  
  export default  ImgPrincipal ;