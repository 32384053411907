import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles, Grid, Typography } from "@material-ui/core";
import SeccionsCall from "../../../API/Sections";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import AssignmentIcon from "@material-ui/icons/Assignment";
import toasType from "../../../API/CallUtils/tostyNotification";
import { CircularProgress } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
const styles = (theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Link: {
    width: "100%",
    display: "block",
    textDecoration: "none",
    // textAlign: 'center',
    color: "#2f3542",
    alignItems: "center",
    justifyContent: "center",
  },
  add: {
    display: "flex",
    justifyContent: "flex-end",
  },
  color: {
    color: "#FFFF",
  },
  Full: {
    width: "100%",
  },
  FullPaper: {
    width: "100%",
    padding: "1%",
  },
  Skeleton: {
    width: "100%",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Seccion: [],
      ConectionState: "onLoad",
    };
  }

  componentDidMount = async () => {
    await this.fetchSeccions();
  };

  fetchSeccions = async () => {
    try {
      const SeccionsShow = await SeccionsCall.showAllNotStat();
      if (SeccionsShow) {
        this.setState({
          Seccion: SeccionsShow,
          ConectionState: SeccionsShow.length > 0 ? "onSuccess" : "onNull",
        });
      } else {
        toasType.error("SSE No fue posible encontrar su información");
        console.log(SeccionsShow);
        this.setState({ ConectionState: "onError" });
      }
    } catch (e) {
      toasType.error("CSE No es posible obtener su información");
      this.setState({ ConectionState: "onError" });
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    const data = [];

    this.state.Seccion.map((seccion) => {
      const btn = (
        <Link
          className={classes.Link}
          to={{
            pathname: "/Secciones/Informacion",
            state: {
              AsesorName: seccion.section.UserProfile.name,
              AsesorUuid: seccion.section.uuid,
              //Companies: seccion.section.AdvisedCompanies,
              Profile: seccion.section.UserProfile,
              email: seccion.section.email,
            },
          }}
        >
          <AssignmentIcon />
        </Link>
      );

      data.push([
        seccion.section.UserProfile.name,
        seccion.section.UserProfile.lastNames,
        seccion.statistics.companiesCount,
        seccion.statistics.lastUpdate.split("T")[0],
        btn,
      ]);
      return data;
    });
    const columns = [
      "Asesor",
      "Apellidos",
      "Empresas",
      "Última actualización",
      "Detalles",
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    switch (this.state.ConectionState) {
      case "onLoad":
        return (
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        );
      case "onSuccess":
        return (
          <Paper>
            <MUIDataTable
              title={"Asesores"}
              data={data}
              columns={columns}
              options={options}
            />
          </Paper>
        );
      case "onError":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      case "onNull":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <CircularProgress size="90px"></CircularProgress>
            </Grid>
          </Paper>
        );
      default:
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <CircularProgress size="90px"></CircularProgress>
            </Grid>
          </Paper>
        );
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(TablePadron));
