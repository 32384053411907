import base from "../../baseurl";
import token from "../../getTk"

const Create = async (title,img,pdf,imgName,pdfName) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
    const Update = await api.post("magazines/create",{timeout:false, json: {
      
      title:title,
      img:{
        name:imgName,
        content:img,
      },
      pdf:{
        name:pdfName,
        content:pdf,
      }

    }}).json();
   //console.log(Update)
    return Update ;
  };
  
  export default  Create ;