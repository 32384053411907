import React from "react";
import { Paper, withStyles, Grid, Typography } from "@material-ui/core";
import EmployeesCall from "../../../API/Companies";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = (theme) => ({
  paper: {
    width: "90%",
    padding: "1%",
  },
  Skeleton: {
    width: "400px",
    height: "500px",
    borderRadius: "10px 10px 10px 10px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      StateTransaction: "OnLoad",
      Employees: [],
    };
  }
  componentDidMount = async () => {
    try {
      this.fetchEmployees(this.props.date);
    } catch (e) {
      console.log(e);
    }
  };

  fetchEmployees = async (censusUuid) => {
    try {
      const EmployeesShow = await EmployeesCall.ShowAllEmployees(censusUuid);
      console.log("El padrón", EmployeesShow);
      if (EmployeesShow) {
        EmployeesShow.length > 0
          ? this.setState({
              StateTransaction: "OnSuccess",
              Employees: EmployeesShow,
            })
          : this.setState({ StateTransaction: "OnNull" });
      } else {
        this.setState({ StateTransaction: "onError" });
      }
    } catch (e) {
      console.log(e);
      this.setState({ StateTransaction: "OnError" });
    }
  };

  render() {
    const { classes } = this.props;
    const data = [];
    let counter = 0;
    this.state.Employees.map((employees) => {
      data.push([
        (counter += 1),
        employees.payroll,
        employees.User.UserProfile.name,
        employees.job,
        employees.User.UserProfile.rfc,
        employees.entry,
        employees.daySalary,
        employees.City !== null ? employees.City.name : "",
        employees.Localplace !== null ? employees.Localplace.name : "",
        employees.address,
        employees.User.UserProfile.sex,
        moment().diff(employees.User.UserProfile.dateOfBirth, "years"),
        employees.User.UserProfile.dateOfBirth,
      ]);
      return data;
    });
    const columns = [
      "No.",
      "No. Nomina",
      "Nombre",
      "Puesto",
      "RFC",
      "Fecha de ingreso",
      "Salario",
      "Municipio",
      "Colonia",
      "Dirección",
      "Sexo",
      "Edad",
      "Fecha de Nacimiento",
    ];
    const options = {
      textLabels: {
        body: {
          noMatch: "Sin datos",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
    };
    switch (this.state.StateTransaction) {
      case "OnLoad":
        return (
          <Skeleton
            variant="rect"
            animation="wave"
            className={classes.Skeleton}
          />
        );
      case "OnError":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnNull":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Ningún Padrón Disponible
              </Typography>
            </Grid>
          </Paper>
        );
      case "OnSuccess":
        return (
          <Paper className={classes.FullPaper}>
            <MUIDataTable
              title={"Padrón del " + this.props.SelectDate}
              data={data}
              columns={columns}
              options={options}
              className={classes.table}
              selectableRows="none"
            />
          </Paper>
        );
      default:
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
    }
  }
}

export default withStyles(styles)(addCandidateDialog);
