import ShowAll from "./ShowAll";
import Show from "./Show";
import Update from "./Update";
import Report from "./Report";
import CompaniesList from "./CompaniesList";
import UpdateCompaniesFeeds from "./UpdateCompaniesFeeds";

const SportEvents = {
  ShowAll,
  Show,
  Update,
  Report,
  CompaniesList,
  UpdateCompaniesFeeds,
};

export default SportEvents;
