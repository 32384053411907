import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ServiciosCall from "../../../API/Ceforma/Servicios";
import CallUtils from "../../../API/CallUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/Add";

import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowUp from "@material-ui/icons/ArrowUpward";
import ArrowDown from "@material-ui/icons/ArrowDownward";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    height: "75vh",
    overflow: "auto",
  },
  Gridlateral: {
    padding: "2%",
  },
  img: {
    width: "50%",
    height: 200,
    backgroundColor: "dodgerblue",
    display: "inline-block",
    margin: 2,
    border: "5px solid #333",
    borderBottom: "5px solid #222",
    borderLeft: "5px solid #222",
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundImage: `url(https://unsplash.it/150/200?image=1)`,
    transition: "background-image 1s ease-in-out",
  },
  btn: {
    width: "20% ",
  },
  Table: {
    width: "100%",
  },
  btnTable: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    height: "10%",
  },
  addBTN: {
    marginRight: "10px",
    marginTop: "10px",
  },
  text: {
    width: "100%",
  },
  Title: {
    width: "30%",
    marginBottom: "10px",
  },
  GridD: {
    width: "100%",
  },
  imgDiv: {
    display: "inline-grid",
    width: "100%",
    marginBottom: "15px",
  },
  editor: {
    width: "100wh",
    height: "80vh",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  event: {
    width: "98%",
    height: "98%",
    margin: "1%",
  },
  Section: {
    width: "100%",
    display: "grid",
    justifyContent: "center",
  },
  SectionTable: {
    width: "70%",
    margin: "5px",
  },
  Remp: {
    width: "100%",
    display: "grid",
    justifyContent: "Flex-end",
  },
  input: {
    display: "none",
  },
  Actividad: {
    width: "100%",
  },
});

class Servicios extends React.Component {
  state = {
    pageTitle: "",
    descriptiveText: "",
    Services: [],
    ServiceTex: "",
    Imgs: [],
    ImgUrl: null,
    ImgDescription: "",

    DialogServices: false,
    DialogImg: false,
  };

  componentDidMount = async () => {
    await this.showAll();
  };
  update = async () => {
    const Update = await ServiciosCall.Update(this.state);
    console.log(Update);
    await this.showAll();
  };
  showAll = async () => {
    const polute = await ServiciosCall.showAll();
    console.log(polute);
    this.setState({
      pageTitle: polute.pageTitle,
      descriptiveText: polute.descriptiveText,
      Services: polute.Services,
      Imgs: polute.Imgs,
    });
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addService = async (e) => {
    var newelement = {
      Servicio: this.state.ServiceTex,
    };

    this.setState({
      Services: [...this.state.Services, newelement],
    });
    this.setState({
      DialogServices: false,
      ServiceTex: "",
    });
  };

  addIMG = async (e) => {
    var newelement = {
      ImgUrl: this.state.ImgUrl,
      ImgDescripcion: this.state.ImgDescription,
    };

    this.setState({
      Imgs: [...this.state.Imgs, newelement],
    });
    this.setState({
      DialogImg: false,
      ImgDescription: "",
      ImgUrl: null,
    });
  };

  remove = (i, Name1) => {
    let value = this.state[Name1].filter((item, j) => i !== j);
    this.setState({ [Name1]: value });
  };
  Up = (i, Name1) => {
    if (i - 1 < 0) {
    } else {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i - 1];
      arry[i - 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  Down = (i, Name1) => {
    var long = this.state[Name1].length;
    if (i + 1 < long) {
      var arry = [];
      arry = this.state[Name1];
      var temp = arry[i + 1];
      arry[i + 1] = arry[i];
      arry[i] = temp;
      this.setState({ [Name1]: arry });
    }
  };
  DialogClose = async (name) => {
    this.setState({ [name]: false });
  };
  DialogOpen = async (name) => {
    this.setState({ [name]: true });
  };

  addIMGD = async (e) => {
    let principal = await this.SendImg(
      e.target.files[0],
      "CEFORMA_SERVICES_LIST"
    );
    this.setState({
      ImgUrl: principal,
    });
  };

  SendImg = async (img, location) => {
    var name = img.name;
    const upload = await CallUtils.UploadNewImg(location, name, img);
    console.log(upload.data.location);
    return upload.data.location;
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.paper}>
          <Grid
            item
            xs={12}
            className={classes.Gridlateral}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              xs={5}
              className={classes.Gridlateral}
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <TextField
                id="standard-basic"
                label="Titulo de la pagina"
                name="pageTitle"
                multiline
                rows="2"
                variant="outlined"
                value={this.state.pageTitle}
                className={classes.A}
                onChange={this.onChange}
              />

              <TextField
                id="standard-read-only-input"
                label="Texto de bienvenida"
                className={classes.text}
                margin="normal"
                name="descriptiveText"
                multiline
                rows="6"
                onChange={this.onChange}
                value={this.state.descriptiveText}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>
              {" "}
              Lista de Servicios
            </Typography>
            {this.state.Services.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Servicio</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Services.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.Servicio}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "Services")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(
                                  this,
                                  index,
                                  "Services"
                                )}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(
                                  this,
                                  index,
                                  "Services"
                                )}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogServices")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.SectionTable}>
            <Typography className={classes.Section}>
              Lista de Imagenes
            </Typography>
            {this.state.Imgs.length > 0 ? (
              <Paper className={classes.Table}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pie de imagen</TableCell>
                      <TableCell>Imagen</TableCell>
                      <TableCell>Subir</TableCell>
                      <TableCell>Bajar</TableCell>
                      <TableCell>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.Imgs.map((Article, index) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Article.uuid}>
                            <TableCell component="th" scope="row">
                              {Article.ImgDescripcion}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <img
                                src={Article.ImgUrl}
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="primary"
                                onClick={this.Up.bind(this, index, "Imgs")}
                              >
                                <ArrowUp />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.Down.bind(this, index, "Imgs")}
                              >
                                <ArrowDown />
                              </Button>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Button
                                color="secondary"
                                onClick={this.remove.bind(this, index, "Imgs")}
                              >
                                <DeleteIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper className={classes.Table}></Paper>
            )}

            <div className={classes.Remp}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.btn}
                onClick={this.DialogOpen.bind(this, "DialogImg")}
              >
                <AddBoxIcon />
              </Button>
            </div>
          </div>

          <div className={classes.btnTable}>
            <Button
              color="primary"
              variant="contained"
              className={classes.addBTN}
              onClick={this.update}
            >
              guardar
            </Button>
          </div>
        </Grid>

        <Dialog
          open={this.state.DialogServices}
          onClose={this.DialogClose.bind(this, "DialogServices")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Servicio</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <TextField
                  id="standard-basic"
                  label="Texto"
                  name="ServiceTex"
                  value={this.state.ServiceTex}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogServices")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addService} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.DialogImg}
          onClose={this.DialogClose.bind(this, "DialogImg")}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Imagen</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <Grid
                item
                xs={12}
                className={classes.Grid}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <div className={classes.imgDiv}>
                  <Typography>Imagen</Typography>
                </div>

                {this.state.ImgUrl !== null ? (
                  <div className={classes.imgDiv}>
                    <img
                      src={this.state.ImgUrl}
                      className={classes.ImgPre}
                      alt=""
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="form-group">
                  <div className={classes.Remp}>
                    <input
                      type="file"
                      onChange={this.addIMGD}
                      className={classes.input}
                      id="files"
                      required
                    />
                    <label htmlFor="files">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        className={classes.btn}
                      >
                        <AddBoxIcon />
                      </Button>
                    </label>
                  </div>
                </div>
                <TextField
                  id="standard-basic"
                  label="Pie de paginá "
                  name="ImgDescription"
                  value={this.state.ImgDescription}
                  className={classes.Actividad}
                  onChange={this.onChange}
                  multiline
                  rows="2"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.DialogClose.bind(this, "DialogImg")}
              color="secondary"
            >
              Cancelar
            </Button>
            <Button onClick={this.addIMG} color="primary">
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Servicios);
