import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, withStyles, Grid, TextField, Button } from "@material-ui/core";
import SelectAdvisor from "./selectAdvisor";
import SelectState from "./SelectState";
import SelectCity from "./selectCity";
import SelectIndustrialPark from "./selectIndustrialPark";
import SelectContry from "./selectContry";
import SelectType from "./selectType";
import Company from "../../../API/Companies";
import { DatePicker } from "material-ui-pickers";
import SeccionsCall from "../../../API/Sections";
import Places from "../../../API/CallUtils";
import ParkCall from "../../../API/Companies/IndustrialPark";
import toasType from "../../../API/CallUtils/tostyNotification";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    width: "70%",
    padding: "2%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  textField: {
    width: "100%",
  },
  btn: {
    margin: "10px",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class CompanieDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      address: "",
      country: "",
      operationStart: moment(),
      product: "",
      clients: "",
      industrialPark: "",
      acceptanceDate: moment(),
      stateUuid: "",
      CityId: "",
      contract: moment(),
      chrismasBox: "",
      attendanceBonus: "",
      holidayBonus: "",
      savingFund: "",
      utilities: "",
      vouchers: "",
      lunchBonus: "",
      transportBonus: "",
      delegate: "",
      other: "",
      lastRevision: moment(),
      ListAdvisors: [],
      advisorsUuid: [],
      ListOfStates: [],
      ListOfCities: [],
      ListOfIndustrialParks: [],
      electoralId: "",
      rhPassword: "",
      hasCommittee: false,
    };
  }
  componentDidMount = async () => {
    await this.fechAsesores();
    await this.ShowAllStates();
    await this.ShowAllIndustrialParks();
  };
  fechAsesores = async () => {
    try {
      const SeccionsShow = await SeccionsCall.AdvisorList();
      console.log(SeccionsShow);
      if (SeccionsShow.status === "success") {
        this.setState({ ListAdvisors: SeccionsShow.data.advisors });
      } else {
        console.log("ServerError");
      }
    } catch (error) {
      console.log(error);
    }
  };
  ShowAllStates = async () => {
    try {
      const ShowStates = await Places.States();
      this.setState({ ListOfStates: ShowStates });
    } catch (e) {
      console.log(e);
    }
  };
  ShowAllIndustrialParks = async () => {
    try {
      const ShowStates = await ParkCall();
      this.setState({ ListOfIndustrialParks: ShowStates.industrialParks });
    } catch (e) {
      console.log(e);
    }
  };
  changeDate = (name, date) => {
    var fecha = moment(new Date(date)).toISOString();
    this.setState({ [name]: fecha });
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onChangeRadio = (event) => {
    var info = { ...this.state.info };
    info.company.hasCommittee = !this.state.commite;
    this.setState({ info });
    this.setState({ commite: !this.state.commite });
  };
  Send = async () => {
    const advisor = this.state.advisorsUuid.map((x) => x.uuid);
    try {
      const create = await Company.Create(this.state, advisor);
      console.log(create);
      if (create) {
        toasType.success("Creado con éxito");
        this.setState({
          name: "",
          phone: "",
          address: "",
          country: "",
          operationStart: moment(),
          product: "",
          clients: "",
          industrialPark: "",
          acceptanceDate: moment(),
          stateUuid: "",
          CityId: "",
          contract: moment(),
          chrismasBox: "",
          attendanceBonus: "",
          holidayBonus: "",
          savingFund: "",
          utilities: "",
          vouchers: "",
          lunchBonus: "",
          transportBonus: "",
          delegate: "",
          other: "",
          lastRevision: moment(),
          advisorsUuid: [],
          electoralId: "",
          rhPassword: "",
          hasCommittee: moment(),
        });
      } else {
        console.log(create);
        toasType.error(" SSE No fue posible crear");
      }
    } catch (e) {
      toasType.error(" CSE No fue posible crear");
      console.log(e);
    }
  };
  ResiveFromChild = (place, value) => {
    console.log(place, value);
    this.setState({
      [place]: value,
    });
  };
  getCities = async (uuid) => {
    try {
      const ShowCitys = await Places.Citys(uuid);
      this.setState({ ListOfCities: ShowCitys });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.Grid}>
          <TextField
            label="Nombre de la Empresa"
            className={classes.textField}
            margin="normal"
            name="name"
            onChange={this.onChange}
            value={this.state.name}
            variant="outlined"
          />
          <TextField
            label="Teléfono de la empresa"
            className={classes.textField}
            margin="normal"
            name="phone"
            onChange={this.onChange}
            value={this.state.phone}
            variant="outlined"
          />
          <TextField
            label="Contraseña para rh"
            className={classes.textField}
            margin="normal"
            name="rhPassword"
            onChange={this.onChange}
            value={this.state.rhPassword}
            variant="outlined"
          />
          <TextField
            label="ID electoral"
            className={classes.textField}
            margin="normal"
            name="electoralId"
            onChange={this.onChange}
            value={this.state.electoralId}
            variant="outlined"
          />
          <SelectType
            sentoParent={this.ResiveFromChild}
            hasCommittee={this.state.hasCommittee}
          />
          <SelectAdvisor
            SelectedAdvisors={this.state.advisorsUuid}
            ListAdvisors={this.state.ListAdvisors}
            sentoParent={this.ResiveFromChild}
          />
          <SelectState
            SelectedState={this.state.stateUuid}
            ListOfStates={this.state.ListOfStates}
            sentoParent={this.ResiveFromChild}
            getCities={this.getCities}
          />
          <SelectCity
            hasState={this.state.stateUuid}
            CitySelected={this.state.cityUuid}
            ListOfCities={this.state.ListOfCities}
            sentoParent={this.ResiveFromChild}
          />
          <SelectIndustrialPark
            industrialPark={this.state.industrialPark}
            ListOfIndustrialParks={this.state.ListOfIndustrialParks}
            sentoParent={this.ResiveFromChild}
          />
          <SelectContry
            sentoParent={this.ResiveFromChild}
            country={this.state.country}
          />
          <DatePicker
            label="Fecha de Aceptación"
            value={this.state.acceptanceDate}
            onChange={this.changeDate.bind(this, "acceptanceDate")}
            className={classes.textField}
            margin="normal"
            disableFuture
            format="ll"
            inputVariant="outlined"
            openTo="month"
          />
          <DatePicker
            label="Fecha de Inicio de Operaciones"
            value={this.state.operationStart}
            onChange={this.changeDate.bind(this, "operationStart")}
            className={classes.textField}
            margin="normal"
            disableFuture
            format="ll"
            inputVariant="outlined"
            openTo="month"
          />
          <DatePicker
            label="Fecha de vigencia del contrato"
            value={this.state.contract}
            onChange={this.changeDate.bind(this, "contract")}
            className={classes.textField}
            margin="normal"
            format="ll"
            inputVariant="outlined"
            openTo="month"
          />
          <DatePicker
            label="Ultima revision del contrato"
            value={this.state.lastRevision}
            onChange={this.changeDate.bind(this, "lastRevision")}
            className={classes.textField}
            margin="normal"
            format="ll"
            inputVariant="outlined"
            openTo="month"
          />
          <TextField
            label="Dirección"
            className={classes.textField}
            margin="normal"
            name="address"
            onChange={this.onChange}
            value={this.state.address}
            variant="outlined"
          />
          <TextField
            label="Producto que elabora"
            className={classes.textField}
            margin="normal"
            name="product"
            onChange={this.onChange}
            value={this.state.product}
            variant="outlined"
          />
          <TextField
            label="Principales Clientes"
            className={classes.textField}
            margin="normal"
            name="clients"
            onChange={this.onChange}
            value={this.state.clients}
            variant="outlined"
          />
          <TextField
            label="Aguinaldo"
            className={classes.textField}
            margin="normal"
            name="chrismasBox"
            onChange={this.onChange}
            value={this.state.chrismasBox}
            variant="outlined"
          />
          <TextField
            label="Prima Vacacional"
            className={classes.textField}
            margin="normal"
            name="holidayBonus"
            onChange={this.onChange}
            value={this.state.holidayBonus}
            variant="outlined"
          />
          <TextField
            label="Utilidades"
            className={classes.textField}
            margin="normal"
            name="utilities"
            onChange={this.onChange}
            value={this.state.utilities}
            variant="outlined"
          />
          <TextField
            label="Servicio de Comedor"
            className={classes.textField}
            margin="normal"
            name="lunchBonus"
            onChange={this.onChange}
            value={this.state.lunchBonus}
            variant="outlined"
          />
          <TextField
            label="Bono por Asistencia"
            className={classes.textField}
            margin="normal"
            name="attendanceBonus"
            onChange={this.onChange}
            value={this.state.attendanceBonus}
            variant="outlined"
          />
          <TextField
            label="Fondo de Ahorro"
            className={classes.textField}
            margin="normal"
            name="savingFund"
            onChange={this.onChange}
            value={this.state.savingFund}
            variant="outlined"
          />
          <TextField
            label="Vales de despensa"
            className={classes.textField}
            margin="normal"
            name="vouchers"
            onChange={this.onChange}
            value={this.state.vouchers}
            variant="outlined"
          />
          <TextField
            label="Servicio de Transporte"
            className={classes.textField}
            margin="normal"
            name="transportBonus"
            onChange={this.onChange}
            value={this.state.transportBonus}
            variant="outlined"
          />
          <TextField
            label="Listado de beneficios adicionales"
            className={classes.textField}
            margin="normal"
            name="other"
            multiline
            rows="5"
            onChange={this.onChange}
            value={this.state.other}
            variant="outlined"
          />
        </Grid>
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={this.Send}
          >
            Crear
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={() => {
              this.props.history.push("/Empresas");
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CompanieDetails));
