import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import toastType from "../../../API/CallUtils/tostyNotification";
import Update from "../../../API/Companies/Update";
//import UploadFile from "../../../Components/Header/UploadFiles";
import ArrayFile from "../../../Components/Header/ArrayFiles";

const styles = (theme) => ({
  PaperDialog: {
    //height: "85vh",
    width: "100%",
    padding: "1%",
  },
  Radio: {
    margin: "2%",
  },
});

class EditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
      place: false,
    };
  }
  componentDidMount = async () => {
    //console.log(this.props.Info.company.uuid)
    try {
      if (this.props.Info) {
        this.setState({
          info: this.props.Info,
          place: this.props.type,
        });
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
    }
  };

  onChange = (event) => {
    var info = { ...this.state.info };
    info.company[event.target.name] = event.target.value;
    this.setState({ info });
  };

  Save = async () => {
    try {
      const save = await Update(this.state.info.company);
      if (save.status === "success") {
        toastType.success("Guardado");
      } else {
        toastType.error("No fue posible Guardar");
      }
    } catch (error) {
      toastType.error("Error de conexión");
      console.log(error);
    }
  };
  handleCallback = (urlFromChild, stateName) => {
    var info = { ...this.state.info };
    info.company[stateName] = urlFromChild;
    this.setState({ info });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          open={this.props.DialogOpen}
          onClose={this.props.DialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Subir Documento</DialogTitle>
          <DialogContent>
            <Grid container className={classes.GridD}>
              <>
                <ArrayFile
                  Type="Documento"
                  Place={
                    "SITIMM_COMPANY_OTHER_DOC_" + this.props.Info.company.uuid
                  }
                  parentCallback={this.handleCallback}
                  stateNameOnParent={"otherDocuments"}
                  PrevFilesList={this.props.Info.company.otherDocuments}
                />
              </>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.DialogClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.Save} color="primary">
              Guardar
            </Button>
            ,
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(EditDialog);
