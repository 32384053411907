import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Paper,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Places from "../../../API/CallUtils";
import HQ from "../../../API/Headquarters";

const styles = (theme) => ({
  paper: {
    //height: "60vh",
    width: "40%",
    padding: "2%",
  },

  Grid: {
    width: "100%",
    height: "96%",
  },

  textField: {},
  textFieldM: {
    marginLeft: "20px",
    width: "70%",
  },

  button: {
    marginLeft: "20px",
    marginTop: "24px",
    width: "25%",
  },

  div: {
    display: "flex",
    //width: "80%",
    justifyContent: "end",
    marginTop: "2%",
  },

  fix: {
    marginTop: "90px",
  },
  fix2: {
    marginTop: "15px",
  },

  Avatar: {
    width: "75px",
    height: "75px",
    marginLeft: "20px",
    marginTop: "15px",
  },
  btn: {
    marginRight: "10px",
  },
  textFieldMulti: {
    width: "100%",
  },
  formControl: {
    width: "50%",
  },
});

const mapStateToProps = (state) => {
  return { login: state.login };
};

class HeadquartersCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      stateUuid: null,
      cityUuid: "",
      colony: "",
      address: "",
      Estados: {},
      Ciudades: {},
      countryUuid: "",
      suburb: "",
    };
  }

  componentDidMount = async () => {
    await this.ShowCountry();
    await this.ShowAllStates();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeState = async (event) => {
    await this.ShowAllCitys(event.target.value);
    var name = "stateUuid";
    this.setState({ [name]: event.target.value });
  };

  handleChangeCity = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    //console.log(this.state.cityUuid)
  };

  ShowCountry = async () => {
    // eslint-disable-next-line
    const Country = await Places.Country();
  };

  ShowAllStates = async () => {
    const ShowStates = await Places.States();
    this.setState({ Estados: ShowStates });
  };

  ShowAllCitys = async (StateId) => {
    const ShowCitys = await Places.Citys(StateId);
    this.setState({ Ciudades: ShowCitys });
  };

  CreateHQ = async (State) => {
    // eslint-disable-next-line
    const CreateHQ = await HQ.Create(State);

    this.props.history.push("/Oficinas");
    //console.log(CreateHQ)
  };

  Create = async () => {
    await this.CreateHQ(this.state);
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.Grid}>
          <Grid
            item
            xs={12}
            className={classes.Grid}
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <TextField
              id="standard-read-only-input"
              label="Nombre"
              className={classes.textField}
              margin="normal"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
            />

            {this.state.Estados.length > 0 ? (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Estados</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.stateUuid}
                  name="stateUuid"
                  onChange={this.handleChangeState}
                >
                  {this.state.Estados.map((Estado, index) => {
                    return (
                      <MenuItem value={Estado.uuid} key={Estado.uuid}>
                        {Estado.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Estados</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem value={0}>_</MenuItem>
                </Select>
              </FormControl>
            )}

            {this.state.Ciudades.length > 0 ? (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Municipio</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.cityUuid}
                  onChange={this.handleChangeCity("cityUuid")}
                >
                  {this.state.Ciudades.map((ciudad, index) => {
                    return (
                      <MenuItem value={ciudad.uuid} key={ciudad.uuid}>
                        {ciudad.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Municipios
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem value={10}></MenuItem>
                </Select>
              </FormControl>
            )}
            <TextField
              id="standard-read-only-input"
              label="Colonia"
              className={classes.textField}
              margin="normal"
              name="suburb"
              value={this.state.suburb}
              onChange={this.onChange}
            />
            <TextField
              id="standard-read-only-input"
              label="Calle"
              className={classes.textField}
              margin="normal"
              name="address"
              value={this.state.address}
              onChange={this.onChange}
            />
          </Grid>
          <div className={classes.div}>
            <Link className={classes.Link} to="/Oficinas">
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn}
              >
                cancelar
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={this.Create}
            >
              Crear
            </Button>
          </div>
        </Grid>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(HeadquartersCreate));
