import base from "../../baseurl";
import token from "../../getTk"


const Update = async (state) => {
    const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ] 
    }
  });
   console.log("estado en la llamada",state)
    const Update = await api.post("pages/update",{json: {
        pageId:4,
        name:"nosotros",
        content:{
            pageTitle:state.pageTitle,
            descriptiveText:state.descriptiveText,
            PrincipalImg:state.PrincipalImg,
            Certification1:state.Certification1,
            Certification2:state.Certification2,
            CertificationText:state.CertificationText,
            activities: state.activities,
            directiveTable:state.directiveTable,
            companies:state.companies,
            ValuesDescription:state.ValuesDescription,
            ValuesIMG:state.ValuesIMG,
            Nexos:state.Nexos
        }
    }
    ,
        timeout:false
}).json();
   console.log(Update)
    return Update ;
    
  };
  
  export default  Update ;