import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import Skeleton from "./SkeletonGrid";

const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "200px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
    //marginTop:'1%',
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
});

class asesorInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { EditDialog: false, ConectionState: "onLoad" };
  }
  componentDidMount = async () => {
    console.log(this.props.info);
    try {
      if (this.props.info) {
        this.setState({ ConectionState: "onSuccess" });
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    const { classes } = this.props;
    switch (this.state.ConectionState) {
      case "onLoad":
        return (
          <Skeleton
            Name="Información del Asesor"
            className={classes.Skeleton}
          />
        );
      case "onSuccess":
        return (
          <Grid className={classes.Initial}>
            <Grid container className={classes.Initial} spacing={1}>
              <Grid container item xs={12} className={classes.Initial}>
                <Typography
                  align="Center"
                  variant="h5"
                  component="p"
                  className={classes.Subtitle}
                >
                  Información del Asesor
                </Typography>
                <Paper className={classes.Paper}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    iteam
                  >
                    <Grid container item xs={11}>
                      <TextField
                        label="Nombre del Asesor"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={
                          this.props.info
                            ? this.props.info.Name
                            : "Sin Información"
                        }
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Apellidos del Asesor"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={
                          this.props.info
                            ? this.props.info.Lastname
                            : "Sin Información"
                        }
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Teléfono registrado"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={
                          this.props.info.phone
                            ? this.props.info.phone
                            : "Sin Información"
                        }
                        //variant="outlined"
                      />
                    </Grid>
                    <Grid container item xs={11}>
                      <TextField
                        label="Email registrado"
                        margin="normal"
                        size="small"
                        fullWidth
                        value={
                          this.props.info
                            ? this.props.info.email
                            : "Sin Información"
                        }
                        //variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        );
      case "onError":
        return (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
      case "onNull":
        return (
          <Skeleton
            Name="Información del Asesor"
            className={classes.Skeleton}
          />
        );
      default:
        return (
          <Skeleton
            Name="Información del Asesor"
            className={classes.Skeleton}
          />
        );
    }
  }
}

export default withStyles(styles)(asesorInfo);
