import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
//import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  paper: {
    width: "100%",
    height: "50vh",
  },
  Grid: {
    padding: "2%",
    overflow: "auto",
  },
  Skeleton: {
    width: "100%",
    height: "100%",
    borderRadius: "10px 10px 10px 10px",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {};

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid
          item
          xs={11}
          className={classes.Grid}
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Typography
            align="Center"
            variant="h5"
            component="p"
            className={classes.Subtitle}
          >
            {this.props.Name}
          </Typography>
          <Paper className={classes.load}>
            <CircularProgress />
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
