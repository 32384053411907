import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Typography,
  Button,
  Grid,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBoxIcon from "@material-ui/icons/Add";
import VotingCall from "../../../API/Voting";
import ShowTable from "../componetsUtils/showTable";
import toastType from "../../../API/CallUtils/tostyNotification";
import { SelecCandidatePosition } from "../componetsUtils";
import List from "../../../API/Companies";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  Grid: {
    width: "100%",
    height: "100%",
  },
  Boletines: {
    width: "100%",
    padding: "5px",
  },
  media: {
    height: 140,
  },
  add: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
  },
  Title: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  Text: {
    width: "100%",
  },
  ImgPre: {
    borderRadius: "8px",
    width: "250px",
    maxWidth: "250px",
    height: "250px",
    maxHeight: "250px",
    objectFit: "contain",
  },
  Remp: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  imgDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  paperFilter: {
    width: "90%",
    padding: "1%",
    marginBottom: "2%",
  },
  formControl: {
    width: "50%",
  },
  SkeletonGrid: {
    width: "100%",
    padding: "2%",
    marginBottom: "2%",
  },
  load: {
    display: "flex",
    width: "100%",
    height: "70%",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
  },
  SectionGrid: {
    padding: "5px",
    minHeight: "220px",
  },
  NoItemPaper: {
    width: "100%",
    height: "85%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  electionTypeCss: {
    width: "100%",
    marginTop: "10px",
  },
});

const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};

class Voting_dash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CompaniesList: [],
      DialogCreate: false,
      Title: "",
      SelectedCompanie: "",
      onSave: false,
      onDelete: false,
      onErrorFetch: false,
      Selected: false,
      onSendTo: false,
      delete: null,
      electionType: "1",
      candidatePosition: "",
      ShowAllTypes: ["EDIT", "PROGRAMED", "DEPLOYED", "FINISHED"],
      candidatePositionName: "",
    };
  }
  componentDidMount = async () => {
    if (this.props.userType === 45298 || this.props.userType === 72468) {
      await this.fetchCompanyList();
    }
  };
  Create = async () => {
    try {
      if (this.state.electionType === "1") {
        if (this.state.Title !== "" && this.state.candidatePosition !== "") {
          this.setState({ onSendTo: true });
          const Create = await VotingCall.Create(
            this.state.SelectedCompanie,
            this.state.Title,
            this.state.electionType,
            this.state.candidatePosition,
            this.props.Uuid ? this.props.Uuid : ""
          );
          if (Create.status === "success") {
            if (Create.data) {
              this.props.history.push({
                pathname: "/Votaciones/Nueva",
                state: {
                  UuidVoting: Create.data.uuid,
                  UuidCompanie: this.state.SelectedCompanie,
                },
              });
            } else {
              this.setState({ onSendTo: false });
              toastType.error("Error en la creación");
            }
          } else {
            this.setState({ onSendTo: false });
            toastType.error("Error en el servidor");
          }
        } else {
          toastType.error("Las votaciones debe tener nombre y Candidatura");
        }
      } else {
        if (this.state.Title !== "") {
          this.setState({ onSendTo: true });
          const Create = await VotingCall.Create(
            this.state.SelectedCompanie,
            this.state.Title,
            this.state.electionType,
            null,
            this.props.Uuid ? this.props.Uuid : ""
          );
          if (Create.status === "success") {
            if (Create.data) {
              this.props.history.push({
                pathname: "/Votaciones/Nueva",
                state: {
                  UuidVoting: Create.data.uuid,
                  UuidCompanie: this.state.SelectedCompanie,
                },
              });
            } else {
              this.setState({ onSendTo: false });
              toastType.error("Error en la creación");
            }
          } else {
            this.setState({ onSendTo: false });
            toastType.error("Error en el servidor");
          }
        } else {
          toastType.error("Las votaciones debe tener nombre");
        }
      }
    } catch (error) {
      this.setState({ onSendTo: false });
      toastType.error("Error de conexión");
      console.log(error);
    }
  };
  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      Selected: true,
    });
  };
  DialogControl = (DIALOG, VALUE) => {
    if (VALUE) {
      this.setState({ [DIALOG]: true });
    } else {
      this.setState({ [DIALOG]: false });
    }
  };
  fetchCompanyList = async () => {
    try {
      const CompanyList = await List.showAllNotSta();
      if (CompanyList) {
        console.log("Lista", CompanyList);
        this.setState({
          CompaniesList: CompanyList,
        });
      } else {
        console.log("CompanyList NO stat error servidor ");
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.paperFilter}>
          <Typography
            variant="h5"
            color="textPrimary"
            component="p"
            className={classes.Title}
          >
            Selección de empresa
          </Typography>
          {this.props.Companies.length > 0
            ? [
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Listado de Empresas
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.SelectedCompanie}
                    name="SelectedCompanie"
                    onChange={this.onChange}
                    //inputProps={{ readOnly: true }}
                  >
                    {this.props.Companies.map((companie, index) => {
                      return (
                        <MenuItem key={companie.uuid} value={companie.uuid}>
                          {companie.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>,
              ]
            : [
                this.state.CompaniesList.length > 0
                  ? [
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Listado de Empresas
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.SelectedCompanie}
                          name="SelectedCompanie"
                          onChange={this.onChange}
                          //inputProps={{ readOnly: true }}
                        >
                          {this.state.CompaniesList.map((companie, index) => {
                            return (
                              <MenuItem value={companie.uuid}>
                                {companie.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>,
                    ]
                  : [
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        component="p"
                        className={classes.Title}
                      >
                        Sin empresas Asignadas
                      </Typography>,
                    ],
              ]}
        </Paper>
        <Paper className={classes.paper}>
          <Grid container className={classes.Grid}>
            <Grid container className={classes.Grid}>
              {this.state.Selected === true ? (
                <Grid
                  item
                  xs={12}
                  className={classes.Grid}
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    component="p"
                    className={classes.Title}
                  >
                    Votaciones
                  </Typography>
                  <Grid container direction="row" className={classes.Grid}>
                    {this.state.ShowAllTypes.map((ShowType) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={6}
                          className={classes.SectionGrid}
                        >
                          <ShowTable
                            companieUuid={this.state.SelectedCompanie}
                            Type={ShowType}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <div className={classes.add}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.addBTN}
                      onClick={this.DialogControl.bind(
                        this,
                        "DialogCreate",
                        true
                      )}
                    >
                      <AddBoxIcon />
                    </Button>
                  </div>
                </Grid>
              ) : (
                [
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="p"
                    key={"asdasd"}
                    className={classes.load}
                  >
                    Seleccione una empresa para continuar
                  </Typography>,
                ]
              )}
            </Grid>
            <Dialog
              open={this.state.DialogCreate}
              onClose={this.DialogControl.bind(this, "DialogCreate", false)}
              aria-labelledby="form-dialog-title"
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle id="form-dialog-title">Nueva Elección</DialogTitle>
              <DialogContent>
                <Grid container className={classes.GridD}>
                  <TextField
                    label="Titulo"
                    name="Title"
                    value={this.state.Title}
                    className={classes.Text}
                    onChange={this.onChange}
                  />
                  <FormControl
                    component="fieldset"
                    className={classes.electionTypeCss}
                  >
                    <FormLabel component="legend">Tipo de votación</FormLabel>
                    <RadioGroup
                      aria-label="position"
                      name="electionType"
                      onChange={this.onChange}
                      value={this.state.electionType}
                      row
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Singular"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Plantilla"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                  {this.state.electionType === "1"
                    ? [
                        <SelecCandidatePosition
                          type={true}
                          value={this.state.candidatePosition}
                          onChange={this.onChange}
                        />,
                      ]
                    : [<></>]}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.DialogControl.bind(this, "DialogCreate", false)}
                  variant="contained"
                  color="secondary"
                >
                  Cancelar
                </Button>
                {this.state.electionType === "1"
                  ? [
                      this.state.Title !== "" &&
                      this.state.candidatePosition !== "" ? (
                        [
                          this.state.onSendTo
                            ? [
                                <Button
                                  color="primary"
                                  disabled
                                  variant="contained"
                                >
                                  <CircularProgress />
                                </Button>,
                              ]
                            : [
                                <Button
                                  onClick={this.Create}
                                  variant="contained"
                                  color="primary"
                                >
                                  Crear Votación
                                </Button>,
                              ],
                        ]
                      ) : (
                        <Button color="primary" disabled variant="contained">
                          Crear Votación
                        </Button>
                      ),
                    ]
                  : [
                      this.state.Title !== "" ? (
                        [
                          this.state.onSendTo
                            ? [
                                <Button
                                  color="primary"
                                  disabled
                                  variant="contained"
                                >
                                  <CircularProgress />
                                </Button>,
                              ]
                            : [
                                <Button
                                  onClick={this.Create}
                                  variant="contained"
                                  color="primary"
                                >
                                  Crear Votación
                                </Button>,
                              ],
                        ]
                      ) : (
                        <Button color="primary" disabled variant="contained">
                          Crear Votación
                        </Button>
                      ),
                    ]}
              </DialogActions>
            </Dialog>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Voting_dash));
