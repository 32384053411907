import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Edit from "./EditDialogHeader";
import GetAppIcon from "@material-ui/icons/GetApp";
import TimelineIcon from "@material-ui/icons/Timeline";
import Report from "../../../API/Reports";
import CircularProgress from "@material-ui/core/CircularProgress";
const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "250px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
  Margin: {
    marginTop: "1%",
  },
  btn: {
    margin: "5px",
  },
});

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EditDialog: false,
    };
  }
  componentDidMount = async () => {};

  Dialog = (a) => {
    this.setState({ [a]: !this.state[a] });
  };
  Refresh = () => {
    this.props.Refresh();
    this.setState({ EditDialog: false });
  };

  Report = async (type) => {
    try {
      if (type === "History") {
        this.setState({ LoadH: true });
        const doc = await Report.TecnicalHistory(this.props.info.company.id);
        if (doc.data) {
          const blob = new Blob([doc.data], {
            type: "text/csv;charset=utf-8;",
          });
          this.setState({ History: blob, LoadH: false });
        } else {
          this.setState({ History: "#", LoadH: false });
        }
      } else {
        this.setState({ LoadA: true });
        const doc = await Report.TecnicalSingular(this.props.info.company.id);
        if (doc.data) {
          const blob = new Blob([doc.data], {
            type: "text/csv;charset=utf-8;",
          });
          this.setState({ Actual: blob, LoadA: false });
        } else {
          this.setState({ Actual: "#", LoadA: false });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.Initial}>
        <Grid container className={classes.Initial} spacing={1}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            className={classes.Initial}
          >
            <Typography
              align="Center"
              variant="h5"
              component="p"
              className={classes.Subtitle}
            >
              Datos principales
            </Typography>
            <Paper className={classes.Paper}>
              <Grid container spacing={1}>
                <Grid container item xs={12} className={classes.Initial}>
                  <TextField
                    label="Empresa"
                    margin="normal"
                    size="small"
                    fullWidth
                    //className={classes.Initial}
                    value={this.props.info.company.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.Initial}
                  spacing={1}
                >
                  <Grid container item xs={4}>
                    <TextField
                      label="Teléfono"
                      margin="none"
                      size="small"
                      fullWidth
                      value={this.props.info.company.phone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item xs={4}>
                    <TextField
                      label="Contraseña"
                      margin="none"
                      size="small"
                      fullWidth
                      value={this.props.info.company.rhPassword}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item xs={4}>
                    <TextField
                      label="Fecha de inicio"
                      margin="none"
                      size="small"
                      fullWidth
                      value={this.props.info.company.acceptanceDate}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item xs={4}>
                    <TextField
                      label="Ultima Actualización"
                      margin="none"
                      size="small"
                      fullWidth
                      value={this.props.info.company.updatedAt.split("T")[0]}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item xs={4}>
                    <TextField
                      label="Tipo de organización"
                      margin="none"
                      size="small"
                      fullWidth
                      value={
                        this.props.info.company.hasCommittee
                          ? "Regional"
                          : "De Industria"
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item xs={4}>
                    <TextField
                      label="Parque Industrial"
                      margin="none"
                      size="small"
                      fullWidth
                      value={this.props.info.company.industrialPark}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                className={classes.Margin}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                  onClick={this.Dialog.bind(this, "EditDialog")}
                >
                  <EditIcon />
                </Button>

                {!this.state.Actual
                  ? [
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.btn}
                        onClick={this.Report.bind(this, "Actual")}
                      >
                        {this.state.LoadA ? (
                          <CircularProgress />
                        ) : (
                          <GetAppIcon />
                        )}
                      </Button>,
                    ]
                  : [
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        href={window.URL.createObjectURL(this.state.Actual)}
                        onClick={() => {
                          this.setState({ Actual: null });
                        }}
                        download="Ficha tecnica.csv"
                      >
                        Descargar
                      </Button>,
                    ]}
                {!this.state.History
                  ? [
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.btn}
                        onClick={this.Report.bind(this, "History")}
                      >
                        {this.state.LoadH ? (
                          <CircularProgress />
                        ) : (
                          <TimelineIcon />
                        )}
                      </Button>,
                    ]
                  : [
                      <Button
                        variant="contained"
                        color="primary"
                        href={window.URL.createObjectURL(this.state.History)}
                        download="Historial.csv"
                        className={classes.btn}
                        onClick={() => {
                          this.setState({ History: null });
                        }}
                      >
                        Descargar
                      </Button>,
                    ]}
              </Grid>
            </Paper>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            className={classes.Initial}
          >
            <Typography
              align="Center"
              variant="h5"
              className={classes.Subtitle}
            >
              Asesores
            </Typography>
            <Paper className={classes.Paper}>
              {this.props.info.advisors.length > 0 ? (
                <Table
                  size="small"
                  aria-label="a dense table"
                  className={classes.TableCompany}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Teléfono</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.TableCompany}>
                    {this.props.info.advisors.map((Asesor) => {
                      return (
                        <React.Fragment>
                          <TableRow key={Asesor.Uuid}>
                            <TableCell component="th" scope="row">
                              {Asesor.UserProfile.name +
                                " " +
                                Asesor.UserProfile.lastNames}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Asesor.email}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {Asesor.UserProfile.mobilePhone}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <>Sin Asesor</>
              )}
            </Paper>
          </Grid>
        </Grid>
        {this.state.EditDialog
          ? [
              <Edit
                DialogOpen={this.state.EditDialog}
                DialogClose={this.Dialog.bind(this, "EditDialog")}
                Info={this.props.info}
                Refresh={this.Refresh}
              />,
            ]
          : [<></>]}
      </Grid>
    );
  }
}

export default withStyles(styles)(TablePadron);
