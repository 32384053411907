import React from "react";
import {
  Button,
  Paper,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/Add";
import MUIDataTable from "mui-datatables";
import EmployeesCall from "../../../API/Companies";
import toastType from "../../../API/CallUtils/tostyNotification";
import Update from "../../../API/CallUtils/UpdateInForCommite";
import AddCall from "../../../API/Companies/CreatePersonal";
import Skeleton from "@material-ui/lab/Skeleton";
import Places from "../../../API/CallUtils";

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    //marginLeft: "10px",
    padding: "1%",
  },
  Select: {
    maxWidth: "100%",
  },
  Skeleton: {
    width: "500px",
    height: "400px",
    borderRadius: "10px 10px 10px 10px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateCity: "",
      candidateName: "",
      candidateRFC: "",
      candidatePhoto: "",
      candidateMovil: "",
      candidateMail: "",
      candidateNew: false,
      candidateUuid: "",
      candidateINFO: {},
      Candidate: [],
      fullList: [],
      CandidatePosition: null,
      candidatePosition: "",
      Onload: true,
      OnError: false,
      Ciudades: [],
      cityUuid: "",
      stateUuid: "",
    };
  }
  componentDidMount = async () => {
    try {
      if (this.props.CompanieUuid) {
        const dates = await EmployeesCall.CensusDates(this.props.CompanieUuid);
        if (dates[0]) {
          await this.fetchEmployees(dates[0].uuid);
        } else {
          this.setState({ Onload: false, OnError: true });
          toastType.error(
            "Ningún padrón existente, error: ningún Miembro posible"
          );
        }
      } else {
        this.setState({ Onload: false, OnError: true });
        toastType.error("El id de la compañía esta perdido");
      }
    } catch (error) {
      this.setState({ Onload: false, OnError: true });
      toastType.error("Error en la obtención de trabajadores");
      console.log(error);
    }
  };
  DialogBack = async () => {
    this.setState({ candidateNew: false });
  };
  fetchEmployees = async (censusUuid) => {
    try {
      await this.ShowAllCitys();
      const EmployeesShow = await EmployeesCall.ShowAllEmployees(censusUuid);
      if (EmployeesShow) {
        this.setState({
          fullList: EmployeesShow,
          Onload: false,
        });
      } else {
        this.setState({ Onload: false, OnError: true });
        toastType.error("No fue posible traer el padrón");
      }
    } catch (error) {
      this.setState({ Onload: false, OnError: true });
      toastType.error("No fue posible traer el padrón");
      console.log(error);
    }
  };
  onChange = (event) => {
    console.log(event.target.name, event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  CandidateDetails = async (candidate, e) => {
    try {
      if (candidate.City) {
        this.setState({
          cityUuid: candidate.City ? candidate.City.name : "",
        });
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({
      candidateNew: true,
      candidateINFO: candidate,
      candidateName: candidate.User.UserProfile.name,
      candidateRFC: candidate.User.UserProfile.rfc,
      candidateUuid: candidate.User.id,
      candidateMovil: candidate.User.UserProfile.personalPhone
        ? candidate.User.UserProfile.personalPhone
        : "",
      candidateMail: candidate.User.UserProfile.otherMail
        ? candidate.User.UserProfile.otherMail
        : "",
      candidateCity: candidate.User.UserProfile.otherMail
        ? candidate.User.UserProfile.otherMail
        : "",
    });
  };
  newCandidate = async () => {
    if (this.checkInfoCandidate()) {
      try {
        if (this.checkInfoChange()) {
          const newCan = await AddCall(
            this.state.candidateINFO.User.id,
            this.props.id,
            this.props.type,
            this.state.cityUuid
          );
          //console.log("qwe", newCan);
          if (newCan.status === "success") {
            this.props.Refresh();
            toastType.success("Miembro creado");
            this.cleanCandidateInfo();
            this.DialogBack();
          } else {
            console.log(newCan);
            toastType.error("No ha sido posible agregar este Miembro");
            this.cleanCandidateInfo();
          }
        } else {
          toastType.error("No ha sido posible agregar este Miembro");
          this.cleanCandidateInfo();
        }
      } catch (error) {
        console.log(error);
        this.cleanCandidateInfo();
        toastType.error("No ha sido posible agregar este Miembro");
      }
    }
  };
  checkInfoCandidate = () => {
    if (
      this.state.candidateMovil &&
      this.state.candidateMail &&
      this.state.cityUuid
    ) {
      return true;
    } else {
      if (!this.state.candidateMovil) {
        toastType.error("El Miembro Requiere un numero de teléfono");
      }
      if (!this.state.candidateMail) {
        toastType.error("El Miembro Requiere un coreo electrónico");
      }
      if (!this.props.type) {
        toastType.error("El Miembro Requiere una candidatura");
      }
      if (!this.state.cityUuid) {
        toastType.error("El Miembro Requiere una ciudad");
      }
      return false;
    }
  };
  checkInfoChange = async () => {
    try {
      if (
        this.state.candidateINFO.User.UserProfile.personalPhone ===
          this.state.candidateMovil &&
        this.state.candidateINFO.User.UserProfile.otherMail ===
          this.state.candidateMail
      ) {
        return true;
      } else {
        const UpdateWorker = await Update(
          this.state.candidateINFO,
          this.state.candidateMovil,
          this.state.candidateMail
        );
        if (UpdateWorker.status === "success") {
          toastType.success("Información Actualizada");
          console.log(UpdateWorker);
          return true;
        } else {
          console.log("no se pudo subir");
          toastType.error("No ha sido posible agregar este Miembro");
          return false;
        }
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  cleanCandidateInfo = () => {
    this.setState({
      candidateName: "",
      candidateRFC: "",
      candidatePhoto: "",
      candidateUuid: "",
      candidateINFO: {},
      candidateMovil: "",
      candidateMail: "",
      candidateNew: false,
      candidatePosition: "",
    });
  };
  ShowAllCitys = async () => {
    const ShowCitys = await Places.GtoCitys();
    this.setState({ Ciudades: ShowCitys });
  };

  render() {
    const { classes } = this.props;
    const data = [];
    const columns = ["Nombre", "RFC", "Seleccionar"];
    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 20,
      rowsPerPageOptions: [10, 50, 100, 200],
    };
    if (this.state.fullList.length > 0) {
      this.state.fullList.map((Comp) => {
        const btn2 = (
          <Button
            color="primary"
            onClick={this.CandidateDetails.bind(this, Comp)}
          >
            {" "}
            <AddBoxIcon />{" "}
          </Button>
        );

        data.push([
          Comp.User.UserProfile.name,
          Comp.User.UserProfile.rfc,
          btn2,
        ]);

        return data;
      });
    }
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Agregar Personal</DialogTitle>
        <DialogContent>
          <Grid container className={classes.GridD}>
            {!this.state.Onload
              ? [
                  !this.state.OnError
                    ? [
                        <Paper className={classes.PaperDialogNewCandidate}>
                          {this.state.candidateNew
                            ? [
                                <>
                                  <Grid container>
                                    <Grid
                                      iteam
                                      xs={12}
                                      sm={12}
                                      className={classes.GridDialog}
                                    >
                                      <TextField
                                        label="Nombre"
                                        name="candidateName"
                                        value={this.state.candidateName}
                                        className={classes.Text}
                                        //onChange={this.onChange}
                                        disabled
                                        size="small"
                                      />
                                      <TextField
                                        label="RFC"
                                        name="candidateRFC"
                                        value={this.state.candidateRFC}
                                        className={classes.Text}
                                        //onChange={this.onChange}
                                        disabled
                                        size="small"
                                      />
                                      <TextField
                                        label="Teléfono"
                                        name="candidateMovil"
                                        value={this.state.candidateMovil}
                                        className={classes.Text}
                                        onChange={this.onChange}
                                        variant="outlined"
                                        size="small"
                                      />
                                      <TextField
                                        label="Email"
                                        name="candidateMail"
                                        value={this.state.candidateMail}
                                        className={classes.Text}
                                        onChange={this.onChange}
                                        variant="outlined"
                                        size="small"
                                      />
                                      <TextField
                                        label="Titulo"
                                        name="candidateMail"
                                        value={this.props.type}
                                        className={classes.Text}
                                        variant="outlined"
                                        size="small"
                                      />
                                      <FormControl className={classes.Text}>
                                        <InputLabel id="demo-simple-select-label">
                                          Municipio
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          name="cityUuid"
                                          value={this.state.cityUuid}
                                          onChange={this.onChange}
                                        >
                                          {this.state.Ciudades.map(
                                            (ciudad, index) => {
                                              return (
                                                <MenuItem
                                                  value={ciudad.name}
                                                  key={ciudad.uuid}
                                                >
                                                  {ciudad.name}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </>,
                              ]
                            : [
                                this.state.fullList.length > 0
                                  ? [
                                      <MUIDataTable
                                        title={"Empleados"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                        selectableRows="none"
                                      />,
                                    ]
                                  : [
                                      <Typography
                                        align="Center"
                                        variant="h5"
                                        color="textPrimary"
                                        component="p"
                                        className={classes.Subtitle}
                                      >
                                        No hay Empleados
                                      </Typography>,
                                    ],
                              ]}
                        </Paper>,
                      ]
                    : [
                        <>
                          <p>Error</p>
                        </>,
                      ],
                ]
              : [
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    className={classes.Skeleton}
                  />,
                ]}
          </Grid>
        </DialogContent>
        <DialogActions>
          {this.state.candidateNew
            ? [
                <>
                  <Button onClick={this.DialogBack} color="secondary">
                    Regresar
                  </Button>
                  <Button onClick={this.props.DialogClose} color="secondary">
                    Cancelar
                  </Button>
                </>,
              ]
            : [
                <Button onClick={this.props.DialogClose} color="secondary">
                  Cancelar
                </Button>,
              ]}

          {this.state.candidateNew
            ? [
                <Button onClick={this.newCandidate} color="primary">
                  Crear Miembro
                </Button>,
              ]
            : []}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
