import ShowAll from "./ShowAll";
import Take from "./Take";
import States from "./States"
import Close from "./Close"
import ShowAllCompany from "./ShowAllCompany"



const Consultations = { ShowAll,Take,States,Close,ShowAllCompany};

export default Consultations;

export {
    ShowAll,
    Take,
    States,
    Close,
    ShowAllCompany
}