import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper,withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography
   } from '@material-ui/core';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Places from "../../../API/CallUtils";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';






const styles = theme => ({
  
  paper: {
    //height: "90vh",
    width:'70%',
    //marginLeft:"20%",
    padding:"2%"

  },

  Grid:{
    width:'100%',
    height: "100%"
  },

  textField:{
       // marginLeft:"20px"
       width:'88%',

  },
  textFieldM:{
    width:"70%"
},

  button:{
    marginLeft:"20px",
    marginTop:"24px",
    width:"25%"
  },

  div:{
    display:"flex",
    width: "96%",
    justifyContent:"end",
    marginTop:"3%" 
  },

    AvatarDiv:{
      width:"100%",
      display:"flex"
    },
    textFieldAvatar:{
      width:"84%",
      marginTop:"4%",
      marginLeft:"2%"
    },
    PersonDiv:{
      width:"100%"
    },
    textFieldPerson:{
      width:"90%"
    },
    DatosDiv:{
      width:"100%"
    },
    ContratoDiv:{
      width:"100%"
    },
    Asesores:{
      width:"100%"
    },

    Select:{
      marginTop: "16px",
      marginBottom: "8px",
      width:"100%"
    },
    formControl:{
      width:"90%"
    },
    btn:{
    display:"flex",
    width: "100%",
    justifyContent:"end",
    marginTop:"3%" 
    },
    
  List:{
    width:"100%",
    maxHeight:"50vh",
    overflow:"auto",
  },
  ListIteam:{
    width:"90%",
    margin:"2%",
  },
  Dialog:{
    width:"50wv",
    height: "50hv",
  }
});


const mapStateToProps = state => {
  return { login: state.login };
};

class CompanieDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      Asesores: [{name:"juan perez perez",Uuid:"1-1-1"},{name:"pedro godinez godinez",Uuid:"1-1-2"},{name:"martin zavala zavala",Uuid:"1-1-3"}],
      name:"",
      phone:"",
      address:"",
      country:"",
      operationStart:"",
      product:"",
      clients:"",
      industrialPark:"",
      acceptanceDate:"",
      StateId:"",
      CityId:"",
      contract:"",
      chrismasBox:"",
      attendanceBonus:"",
      holidayBonus:"",
      savingFund:"",
      utilities:"",
      vouchers:"",
      lunchBonus:"",
      transportBonus:"",
      delegate:"",
      other:"",
      Estados:{},
      Ciudades:{},
      rh:{
        name:"",
        charge:72468,
        phone:"",
        email:""
      },
      productionManager:{
        name:"",
        charge:72468,
        phone:"",
        email:""
      },
      manager:{
        name:"",
        charge:72468,
        phone:"",
        email:""
      },
      AsesorCompanies:[{Uuid:"nada", name:'Dentro'}],
      NotAsesorCompanies:[
        {Uuid:"nada", name:'Fuera'},
        {Uuid:"nada2", name:'Test2'},
      ],
      Dialog:false,
      cityUuid:"",
      stateUuid:"",
      };
  }
  
  componentDidMount = async () => {
    if (this.props.location.state) {
      const {companieUuid} = this.props.location.state
      this.setState({ Uuid: companieUuid });
      await this.ShowAllStates();

      //await this.fechAsesores(AsesorUuid);
      //await this.fechAsesorData(AsesorUuid);
      //await this.fechAsesorStatistic(AsesorUuid);
      
    }

    else
    {
      this.props.history.push("/Empresas");
    }    
  };

  onChangeRH = (event) =>{
    const name = event.target.name
    const value = event.target.value
   this.setState(prevState => ({
     rh:{
       ...prevState.rh,
       [name]:value
     }
   }));
 }
 onChangeRHSelec = name => event =>{
  const value = event.target.value
 this.setState(prevState => ({
   rh:{
     ...prevState.rh,
     charge:value
   }
 }));
}
 onChangeGeneral = (event) =>{
  const name = event.target.name
  const value = event.target.value
 this.setState(prevState => ({
   manager:{
     ...prevState.manager,
     [name]:value
   }
 }));
}
onChangeGeneralSelec = name => event =>{
const value = event.target.value
this.setState(prevState => ({
   manager:{
     ...prevState.manager,
     charge:value
   }
 }));
}
onChangeProduccion = (event) =>{
  const name = event.target.name
  const value = event.target.value
 this.setState(prevState => ({
   productionManager:{
     ...prevState.productionManager,
     [name]:value
   }
 }));
}
onChangeProduccionSelec = name => event =>{  
const value = event.target.value
this.setState(prevState => ({
   productionManager:{
     ...prevState.productionManager,
     charge:value
   }
 }));
}
onChange=(event)=>{
  this.setState({[event.target.name]: event.target.value});
}
handleChangeState = async (event)=>{
  this.setState({ stateUuid: event.target.value });
  await this.ShowAllCitys(event.target.value);
}

handleChangeCity = (event) =>{
  //console.log("Ciudad en cambio",event.target.value)
  this.setState({ cityUuid: event.target.value });
}

ShowAllStates = async () => {
  const ShowStates = await Places.States();
  this.setState({ Estados: ShowStates});
};


ShowAllCitys = async () => {
  const ShowCitys = await Places.Citys(this.state.StateId);
  this.setState({ Ciudades: ShowCitys});
};


DeleteCompany= (index,Uuid,name, e) =>{
  var Nname = name
  var NUuid = Uuid 
  //console.log(Uuid,name)
   
const Companies = Object.assign([], this.state.AsesorCompanies);
Companies.splice(index, 1);
this.setState({AsesorCompanies:Companies});


var notCompanies = {Uuid:NUuid,name:Nname}
this.setState(state => {
  const NotAsesorCompanies = [...state.NotAsesorCompanies, notCompanies];
  return {
    NotAsesorCompanies
  };
});

}

NotDeleteCompany= (index,Uuid,name, e) =>{
  var Nname = name
  var NUuid = Uuid 

const Companies = Object.assign([], this.state.NotAsesorCompanies);
Companies.splice(index, 1);
this.setState({NotAsesorCompanies:Companies});


var notCompanies = {Uuid:NUuid,name:Nname}
this.setState(state => {
  const AsesorCompanies = [...state.AsesorCompanies, notCompanies];
  return {
    AsesorCompanies
  };
});
}

DialogOpen = async ()=>{
    
  if (this.state.Dialog)
  {
    this.setState( {Dialog : false}) 
  }
  else{
    
    this.setState({Dialog : true}) 
    //await this.fechAsesorNotCompanies(this.state.Uuid);
  }
  
};

Send = async () =>{
  const body = 
  {
    name:this.state.name,
    acceptanceDate:this.state.acceptanceDate,
    address: this.state.acceptanceDate,
    cityUuid: this.state.cityUuid,
    stateUuid: this.state.stateUuid,
    country: this.state.country,
    phone: this.state.phone,
    industrialPark:this.state.industrialPark,
    rh:this.state.rh,
    advisorsUuid:this.state.AsesorCompanies,

        technicalDetails:{
         delegate:this.state.delegate,
         manager:this.state.manager,
         product:this.state.product,
         operationStart: this.state.operationStart,
         clients:this.state.clients,
         productionManager:this.state.productionManager,
         contract:this.state.contract,         
    },
        benefits:{
          christmasBox: this.state.chrismasBox,
          holidayBonus:this.state.holidayBonus,
          savingFund:this.state.savingFund,
          utilities:this.state.utilities,
          vouchers:this.state.vouchers,
          attendanceBonus:this.state.attendanceBonus,
          lunchBonus:this.state.lunchBonus,
          transportBonus:this.state.transportBonus,
          other:this.state.other
    },
  }
  console.log(body)
  }


  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container className={classes.Grid} >
          <div className={classes.AvatarDiv}>
            <TextField
              id="standard-read-only-input"
              label="Nombre de la Empresa"
              className={classes.textFieldAvatar}
              margin="normal"
              name="name"
              onChange={this.onChange}
              value = {this.state.name}
              
              />
              <TextField
              id="standard-read-only-input"
              label="Telefono de la empresa"
              className={classes.textFieldAvatar}
              margin="normal"
              name="phone"
              onChange={this.onChange}
              value = {this.state.phone}
              
              />
            </div>
          <div><Typography variant="h6" gutterBottom>Asesores</Typography></div>
          <div className={classes.Asesores}>

            {this.state.AsesorCompanies.length > 0 ? (
              <Paper>
               <Table size="small" aria-label="a dense table" className={classes.TableCompany}>
                 <TableHead>
                    <TableRow>
                      <TableCell>Asesores</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Telefono</TableCell>
                    </TableRow>
                  </TableHead>
                 <TableBody className={classes.TableCompany}>
               {this.state.AsesorCompanies.map(Asesor => {
               return (
                       <React.Fragment>
                           <TableRow key={Asesor.Uuid}>
                               <TableCell component="th" scope="row">{Asesor.name}</TableCell>
                               <TableCell component="th" scope="row">{Asesor.name}</TableCell>
                               <TableCell component="th" scope="row">{Asesor.name}</TableCell>
                           </TableRow>
                      </React.Fragment>      
                       );
                     })}
               </TableBody>
               </Table>
               </Paper>
               ) : (
                 <div className={classes.load} >
                   <CircularProgress /> 
                 </div>
               )}
            </div>
          <div className={classes.btn}>
                  <Button variant="contained" color="primary" onClick={this.DialogOpen} > Agregar Asesores</Button>
              </div>
          <div className={classes.Asesores} >
            <TextField
                        id="standard-read-only-input"
                        label="Lista de delegados / Comicionados Sindicales"
                        className={classes.textField} 
                        margin="normal"
                        name="delegate"
                        multiline
                        rows = "5"
                        onChange={this.onChange}
                        value = {this.state.delegate}
                        variant = 'outlined'
                        />
            </div>
          <div><Typography variant="h6" gutterBottom>Personal Administrativo</Typography></div> 
          <div className={classes.Administracion}>
             <Grid container>
             <Grid item xs={3} className={classes.Grid} container direction="row" justify="flex-start" alignItems="flex-start">
             <TextField
                        id="standard-read-only-input"
                        label="Nombre"
                        className={classes.textField} 
                        margin="normal"
                        name="name"
                        onChange={this.onChangeRH}
                        value = {this.state.rh.name}
                         
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Nombre"
                        
                        className={classes.textField} 
                        margin="normal"
                        name="name"
                        onChange={this.onChangeGeneral}
                        value = {this.state.manager.name}
                         
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Nombre"
                        
                        className={classes.textField} 
                        margin="normal"
                        name="name"
                        onChange={this.onChangeProduccion}
                        value = {this.state.productionManager.name}
                         
                        />
             </Grid>
             <Grid item xs={3} className={classes.Grid} container direction="row" justify="flex-start" alignItems="flex-start">
             <div className={classes.Select}>
             <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Cargo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.rh.charge}
                  onChange={this.onChangeRHSelec('charge')}
                  >
                  <MenuItem value={72468}>Administrativo</MenuItem>
                  <MenuItem value={48235}>General</MenuItem>
                  <MenuItem value={81493}>Asesor</MenuItem>
                  <MenuItem value={23648}>RH</MenuItem>
                  </Select>
                </FormControl>
             </div>
             <div className={classes.Select}>
             <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Cargo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.manager.charge}
                  onChange={this.onChangeGeneralSelec('charge')}
                  >
                  <MenuItem value={72468}>Administrativo</MenuItem>
                  <MenuItem value={48235}>General</MenuItem>
                  <MenuItem value={81493}>Asesor</MenuItem>
                  <MenuItem value={23648}>RH</MenuItem>
                  </Select>
                </FormControl>
             </div>
             <div className={classes.Select}>
             <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Cargo</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.productionManager.charge}
                  onChange={this.onChangeProduccionSelec('charge')}
                  >
                  <MenuItem value={72468}>Administrativo</MenuItem>
                  <MenuItem value={48235}>General</MenuItem>
                  <MenuItem value={81493}>Asesor</MenuItem>
                  <MenuItem value={23648}>RH</MenuItem>
                  </Select>
                </FormControl>
             </div>
             </Grid>
             <Grid item xs={3} className={classes.Grid} container direction="row" justify="flex-start" alignItems="flex-start">
             <TextField
                        id="standard-read-only-input"
                        label="Telefono"
                        className={classes.textField} 
                        margin="normal"
                        name="phone"
                        onChange={this.onChangeRH}
                        value = {this.state.rh.phone}
                         
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Telefono"
                        
                        className={classes.textField} 
                        margin="normal"
                        name="phone"
                        onChange={this.onChangeGeneral}
                        value = {this.state.manager.phone}
                         
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Telefono"
                        
                        className={classes.textField} 
                        margin="normal"
                        name="phone"
                        onChange={this.onChangeProduccion}
                        value = {this.state.productionManager.Phone}
                         />
             </Grid>
             <Grid item xs={3} className={classes.Grid} container direction="row" justify="flex-start" alignItems="flex-start">
             <TextField
                        id="standard-read-only-input"
                        label="Email"
                        className={classes.textField} 
                        margin="normal"
                        name="email"
                        onChange={this.onChangeRH}
                        value = {this.state.rh.email}
                         
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Email"
                        
                        className={classes.textField} 
                        margin="normal"
                        name="email"
                        onChange={this.onChangeGeneral}
                        value = {this.state.manager.email}
                         
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Email"
                        
                        className={classes.textField} 
                        margin="normal"
                        name="email"
                        onChange={this.onChangeProduccion}
                        value = {this.state.productionManager.email}
                         />
             </Grid>
            </Grid>
          </div> 
          <div><Typography variant="h6" gutterBottom>Datos generales</Typography></div>
          <div className={classes.DatosDiv}>
            <Grid container className={classes.Grid}>
              <Grid item xs={6} className={classes.Grid} container direction="column" justify="flex-start" alignItems="flex-start">
                          


                        <div className={classes.Select} >
                        {this.state.Estados.length > 0 ? ( 
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">Estados</InputLabel>
                              <Select
                              labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.stateUuid}
                                name='stateUuid'
                                onChange={this.handleChangeState}
                                >
                                {this.state.Estados.map((Estado,index) => {
                                  return (
                                <MenuItem value={Estado.id} key={Estado.Uuid}>{Estado.name}</MenuItem>
                                    
                              );
                            })}
                              </Select>
                            </FormControl>
                            ) : (

                              
                                  <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Estados</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    >
                              <MenuItem value={0}>_</MenuItem>
                              </Select>
                              </FormControl>
                            
                            )}
                        </div>



                        <div className={classes.Select} >
                        {this.state.Ciudades.length > 0 ? ( 
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">Municipio</InputLabel>
                              <Select
                              labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.cityUuid}
                                onChange={this.handleChangeCity}
                                >
                                {this.state.Ciudades.map((ciudad,index) => {
                                  return (
                                <MenuItem value={ciudad.id} key={ciudad.Uuid}>{ciudad.name}</MenuItem>
                                    
                              );
                            })}
                              </Select>
                            </FormControl>
                            ) : (

                              
                                  <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Municipios</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    >
                              <MenuItem value={10}></MenuItem>
                              </Select>
                              </FormControl>
                              
                            )}

                        </div>
                        <TextField
                        id="standard-read-only-input"
                        label="Direccion"
                        className={classes.textField}
                        margin="normal"
                        name="address"
                        onChange={this.onChange}
                        value = {this.state.address}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Pais de origen"
                        className={classes.textField}
                        margin="normal"
                        name="country"
                        onChange={this.onChange}
                        value = {this.state.country}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Año de inicio"
                        className={classes.textField}
                        margin="normal"
                        name="operationStart"
                        onChange={this.onChange}
                        value = {this.state.operationStart}
                        />
                      </Grid>
              <Grid item xs={6} className={classes.Grid} container direction="column" justify="flex-start"alignItems="flex-start">
                      <TextField
                        id="standard-read-only-input"
                        label="Producto que elabora"
                        className={classes.textField} 
                        margin="normal"
                        name="product"
                        onChange={this.onChange}
                        value = {this.state.product}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Principales Clientes"
                        className={classes.textField}
                        margin="normal"
                        name="clients"
                        onChange={this.onChange}
                        value = {this.state.clients}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Fecha de vigencia del contrato"
                        className={classes.textField}
                        margin="normal"
                        name="contract"
                        onChange={this.onChange}
                        value = {this.state.contract}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Parque industrial"
                        className={classes.textField}
                        margin="normal"
                        name="industrialPark"
                        onChange={this.onChange}
                        value = {this.state.industrialPark}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Año de afiliacion"
                        className={classes.textField}
                        margin="normal"
                        name="acceptanceDate"
                        onChange={this.onChange}
                        value = {this.state.acceptanceDate}
                        />
                      </Grid>
            </Grid>
          </div>
          <div><Typography variant="h6" gutterBottom>Datos Contractuales</Typography></div>
          <div className={classes.ContratoDiv}>
          <Grid container className={classes.Grid}>
            <Grid item xs={6} className={classes.Grid} container direction="column" justify="flex-start" alignItems="flex-start">
                          <TextField
                        id="standard-read-only-input"
                        label="Aguinaldo"
                        className={classes.textFieldPerson} 
                        margin="normal"
                        name="chrismasBox"
                        onChange={this.onChange}
                        value = {this.state.chrismasBox}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Prima Vacacional"
                        className={classes.textFieldPerson}
                        margin="normal"
                        name="holidayBonus"
                        onChange={this.onChange}
                        value = {this.state.holidayBonus}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Utilidades"
                        className={classes.textFieldPerson}
                        margin="normal"
                        name="utilities"
                        onChange={this.onChange}
                        value = {this.state.utilities}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Servicio de Comedor"
                        className={classes.textFieldPerson}
                        margin="normal"
                        name="lunchBonus"
                        onChange={this.onChange}
                        value = {this.state.lunchBonus}
                        />
                        
                      </Grid>
            <Grid item xs={6} className={classes.Grid} container direction="column" justify="flex-start" alignItems="flex-start">
                        <TextField
                        id="standard-read-only-input"
                        label="Bono por Asistencia"
                        className={classes.textFieldPerson} 
                        margin="normal"
                        name="attendanceBonus"
                        onChange={this.onChange}
                        value = {this.state.attendanceBonus}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Fondo de Ahorro"
                        className={classes.textFieldPerson}
                        margin="normal"
                        name="savingFund"
                        onChange={this.onChange}
                        value = {this.state.savingFund}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Vales de despensa"
                        className={classes.textFieldPerson}
                        margin="normal"
                        name="vouchers"
                        onChange={this.onChange}
                        value = {this.state.vouchers}
                        />
                        <TextField
                        id="standard-read-only-input"
                        label="Servicio de Transporte"
                        className={classes.textFieldPerson}
                        margin="normal"
                        name="transportBonus"
                        onChange={this.onChange}
                        value = {this.state.transportBonus}
                        
                        />
                      </Grid>            
          </Grid>
          </div>
          <div className={classes.Asesores} >
            <TextField
                        id="standard-read-only-input"
                        label="Listado de beneficios adicionales"
                        className={classes.textField} 
                        margin="normal"
                        name="other"
                        multiline
                        rows = "5"
                        onChange={this.onChange}
                        value = {this.state.other}
                        variant = 'outlined'
                        />
            </div>
          <div className={classes.div}>
            <Link className={classes.Link} to="/Empresas">
              <Button variant="contained" color="secondary" className={classes.btnEnd}>
                cancelar
              </Button>
            </Link>
              <Button variant="contained" color="primary" className={classes.btnEnd} onClick={this.Send} >
                  Editar
              </Button>
            </div>
        </Grid>

        <Dialog open={this.state.Dialog} onClose={this.DialogOpen} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Agregar/Eliminar empresas</DialogTitle>
                <DialogContent>
                  <Grid container>
                  <Grid item xs={6}  container direction="column" justify="flex-start" alignItems="center">
                  <Typography variant="button">
                            Eliminar
                  </Typography>
                   {this.state.AsesorCompanies.length > 0 ? ( 
                     <div>
                          {this.state.AsesorCompanies.map((companie,index) => {
                            return (
                        <React.Fragment>
                          <Grid container>

                          <Grid item xs={8} container direction="column" justify="center" alignItems="center">
                          <Typography variant="button" className={classes.text}>
                            {companie.name}
                          </Typography>
                          </Grid>

                          <Grid item xs={4} container direction="column" justify="center" alignItems="center">
                          <Button
                              color="secondary"
                              
                              onClick={this.DeleteCompany.bind(this,index,companie.Uuid,companie.name)}
                            ><DeleteIcon /></Button>
                         </Grid>
                          </Grid>
                         
                        </React.Fragment>      
                        );
                      })}
                    </div>
                      ) : (
                        <div className={classes.load} >
                          <CircularProgress /> 
                        </div>
                      )}
                  </Grid>

                    <Grid item xs={6} container direction="column" justify="flex-start" alignItems="center">
                    <Typography variant="button">
                            Agregar
                  </Typography>
                    {this.state.NotAsesorCompanies.length > 0 ? ( 
                     <div>
                          {this.state.NotAsesorCompanies.map((companie,index) => {
                            return (
                              <React.Fragment>
                              <Grid container>
    
                              <Grid item xs={8}container direction="column" justify="center" alignItems="center">
                              <Typography variant="button">
                                {companie.name}
                              </Typography>
                              </Grid>
    
                              <Grid item xs={4} container direction="column" justify="center" alignItems="center">
                              <Button
                                  color="primary"
                                  
                                  onClick={this.NotDeleteCompany.bind(this,index,companie.Uuid,companie.name)}
                                ><AddIcon /></Button>
                             </Grid>
                              </Grid>
                             
                            </React.Fragment>           
                        );
                      })}
                    </div>
                      ) : (
                        <div className={classes.load} >
                          <CircularProgress /> 
                        </div>
                      )}
                    </Grid>



                  </Grid>                  
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.DialogOpen} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.SaveChanges} color="primary">
                    Guardar
                  </Button>
                </DialogActions>
              </Dialog>
      </Paper>   
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CompanieDetails));
