import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Paper, Grid, TextField } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");
const styles = (theme) => ({
  formControl: {
    margin: "10px",
  },
  Skeleton: {
    width: "100%",
    height: "20px",
    borderRadius: "10px 10px 10px 10px",
  },
  Container: {
    width: "100%",
    padding: "10px",
  },
  TextField: {
    width: "100%",
  },
});
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  changeDate = (acceptanceDate) => {
    this.props.SendToparent(
      "employeData",
      "entry",
      moment(new Date(acceptanceDate)).toISOString()
    );
  };
  Send = (ev) => {
    this.props.SendToparent("employeData", ev.target.name, ev.target.value);
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper>
        <Grid container row spacing={1} className={classes.Container}>
          <TextField
            id="outlined-basic"
            label="Numero de empleado"
            variant="outlined"
            value={this.props.ParentState.payroll}
            onChange={this.Send}
            name="payroll"
            className={classes.TextField}
            margin="dense"
            error={
              this.props.errors
                ? this.props.ParentState.payroll !== ""
                  ? false
                  : true
                : false
            }
          />
          <TextField
            id="outlined-basic"
            label="Puesto de Trabajo"
            variant="outlined"
            value={this.props.ParentState.job}
            onChange={this.Send}
            name="job"
            className={classes.TextField}
            margin="dense"
            error={
              this.props.errors
                ? this.props.ParentState.job !== ""
                  ? false
                  : true
                : false
            }
          />
          <TextField
            id="outlined-basic"
            label="Salario Diario"
            variant="outlined"
            value={this.props.ParentState.daySalary}
            onChange={this.Send}
            name="daySalary"
            className={classes.TextField}
            margin="dense"
            error={
              this.props.errors
                ? this.props.ParentState.daySalary !== ""
                  ? false
                  : true
                : false
            }
          />
          <DatePicker
            label="Fecha de Entrada a la empresa"
            value={this.props.ParentState.entry}
            onChange={this.changeDate}
            disableFuture
            openTo="year"
            inputVariant="outlined"
            format="ll"
            className={classes.TextField}
          />
          <TextField
            id="outlined-basic"
            label="Ciudad"
            variant="outlined"
            value={this.props.ParentState.City}
            onChange={this.Send}
            name="City"
            className={classes.TextField}
            margin="dense"
            error={
              this.props.errors
                ? this.props.ParentState.City !== ""
                  ? false
                  : true
                : false
            }
          />
          <TextField
            id="outlined-basic"
            label="Dirección"
            variant="outlined"
            value={this.props.ParentState.address}
            onChange={this.Send}
            name="address"
            className={classes.TextField}
            margin="dense"
            error={
              this.props.errors
                ? this.props.ParentState.address !== ""
                  ? false
                  : true
                : false
            }
          />
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    Uuid: state.login.user.userUuid,
    userType: state.login.user.userType,
    Companies: state.login.user.CompaniesList,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(index));
