import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
const Types = [
  "Publico",
  "Afiliados",
  "Asesores",
  "Delegados",
  "Miembros del Comite",
];
const styles = (theme) => ({
  Table: {
    width: "100%",
  },

  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
function SelectType(props) {
  return (
    <Grid container className={props.classes.Table}>
      <FormControl className={props.classes.Table}>
        <InputLabel>Seleccionar Participantes</InputLabel>
        <Select
          value={props.ParentState.audience}
          onChange={(ev) => {
            props.ParentSet(ev.target.value, "audience");
          }}
        >
          {Types.map((type) => {
            return (
              <MenuItem value={type} key={1}>
                {type}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default withStyles(styles)(SelectType);
