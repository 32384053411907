import ShowAll from "./ShowAll";
import Create from "./Create";
import Delete from "./Delete";
import Galleries from "./Galleries/Galleries";
import DeleteGalleries from "./Galleries/DeleteGalleries";
import CreateGalleries from "./Galleries/CreateGalleries";
import GalleriesElements from "./Galleries/GalleriesElements";
import GallerierDeleteElements from "./Galleries/GalleriesDeleteElement";
import GalleriesCreateElements from "./Galleries/GalleriesCreateElement";
import INDETShow from "./INDET/Show";
import INDETShowAll from "./INDET/ShowAll";
import INDETCreate from "./INDET/Create";
import INDETDelete from "./INDET/Delete";
import INDETUpdate from "./INDET/Update";
import SportEventDisiplineCreate from "./SportEventDisciplines/Create";
import SportEventDisiplineUpdate from "./SportEventDisciplines/Update";
import SportEventDisiplineDelete from "./SportEventDisciplines/Delete";
import SportEventDisiplineShowAll from "./SportEventDisciplines/ShowAll";
import DisiplinesShowAll from "./DisiplinesType/ShowAll";
import DisiplinesCreate from "./DisiplinesType/Create";
import DisiplinesDelete from "./DisiplinesType/Delete";
import DisiplinesUpdate from "./DisiplinesType/Update";
import DocumentsTypeCreate from "./SportEventDisciplineDocumentTypes/Create";
import DocumentsTypeDelete from "./SportEventDisciplineDocumentTypes/Delete";
import DocumentsTypeUpdate from "./SportEventDisciplineDocumentTypes/Update";
import DocumentsTypeShowAll from "./SportEventDisciplineDocumentTypes/ShowAll";
import DocumentsCreate from "./SportEventDisciplineDocuments/Create";
import DocumentsDelete from "./SportEventDisciplineDocuments/Delete";
import DocumentsUpdate from "./SportEventDisciplineDocuments/Update";
import DocumentsShowAll from "./SportEventDisciplineDocuments/ShowAll";
import SportBulletinsCreate from "./Comunicados/Create";
import SportBulletinsDelete from "./Comunicados/Delete";
import SportBulletinsUpdate from "./Comunicados/Update";
import SportBulletinsShowAll from "./Comunicados/ShowAll";
const SportEvents = {
  ShowAll,
  Create,
  Delete,
  Galleries,
  DeleteGalleries,
  CreateGalleries,
  GalleriesElements,
  GallerierDeleteElements,
  GalleriesCreateElements,
  INDETShow,
  INDETShowAll,
  INDETCreate,
  INDETDelete,
  INDETUpdate,
  SportEventDisiplineCreate,
  SportEventDisiplineUpdate,
  SportEventDisiplineDelete,
  SportEventDisiplineShowAll,
  DisiplinesShowAll,
  DisiplinesCreate,
  DisiplinesDelete,
  DisiplinesUpdate,
  DocumentsTypeCreate,
  DocumentsTypeDelete,
  DocumentsTypeUpdate,
  DocumentsTypeShowAll,
  DocumentsCreate,
  DocumentsDelete,
  DocumentsUpdate,
  DocumentsShowAll,
  SportBulletinsCreate,
  SportBulletinsDelete,
  SportBulletinsUpdate,
  SportBulletinsShowAll,
};

export default SportEvents;
