import axios from "axios";
const Report = async (state, number) => {
  const re = await axios
    .post(`https://api.sitimm.org/api/reports/show`, {
      params: {
        industrialPark: state,
      },
      reportNumber: number,
    })
    .then((res) => {
      return res;
    });
  return re;
};

export default Report;
