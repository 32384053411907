import React from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import { Chart } from "react-google-charts";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  text: {
    width: "100%",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    marginBottom: "8px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Paper: {
    width: "100%",
    minHeight: "250px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
  },
  margin: {
    marginTop: "12px",
    marginBottom: "12px",
  },
});
function Graphics({ classes, women, men, both, mean, mode }) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      xs={12}
      spacing={1}
      className={classes.margin}
    >
      <Typography
        align="Center"
        variant="h5"
        component="p"
        className={classes.Subtitle}
      >
        Gráficas comparativas
      </Typography>

      {mean && (
        <Grid
          //Promedio
          item
          xs={12}
          direction="row"
          container
          //justifyContent="center"
          //alignItems="center"
        >
          <Typography
            align="Center"
            variant="subtitle"
            component="p"
            className={classes.Subtitle}
          >
            Promedio
          </Typography>
          <Grid
            item
            xs={4}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            {" "}
            <Chart
              width={"350px"}
              height={"300px"}
              chartType="Bar"
              //loader={<CircularProgress />}
              data={[
                ["Salario", "Todos", "Mujeres", "Hombres"],
                ["Pesos", both.meanSalary, women.meanSalary, men.meanSalary],
              ]}
              options={{
                colors: ["#e7a433", "#bf3b36", "#2391f5"],
                chart: {
                  title: "Promedio de Salario",
                  subtitle: "Diferencia de promedio de salario",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Chart
              width={"350px"}
              height={"300px"}
              chartType="Bar"
              //loader={<CircularProgress />}
              data={[
                ["Edad", "Todos", "Mujeres", "Hombres"],
                ["Años", both.meanAge, women.meanAge, men.meanAge],
              ]}
              options={{
                colors: ["#e7a433", "#bf3b36", "#2391f5"],
                chart: {
                  title: "Promedio de Edad",
                  subtitle: "Diferencia de promedio de Edad",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Chart
              width={"350px"}
              height={"300px"}
              chartType="Bar"
              //loader={<CircularProgress />}
              data={[
                ["Antigüedad", "Todos", "Mujeres", "Hombres"],
                [
                  "Años",
                  both.meanAntiquity,
                  women.meanAntiquity,
                  men.meanAntiquity,
                ],
              ]}
              options={{
                colors: ["#e7a433", "#bf3b36", "#2391f5"],
                chart: {
                  title: "Promedio de antigüedad",
                  subtitle: "Diferencia de promedio de antigüedad",
                },
              }}
            />
          </Grid>
        </Grid>
      )}
      {mode && (
        <Grid
          //Promedio
          item
          xs={12}
          direction="row"
          container
          //justifyContent="center"
          //alignItems="center"
        >
          <Typography
            align="Center"
            variant="subtitle"
            component="p"
            className={classes.Subtitle}
          >
            Moda
          </Typography>
          <Grid
            item
            xs={4}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            {" "}
            <Chart
              width={"350px"}
              height={"300px"}
              chartType="Bar"
              //loader={<CircularProgress />}
              data={[
                ["Salario", "Todos", "Mujeres", "Hombres"],
                [
                  "Pesos",
                  both.modeSalary[0],
                  women.modeSalary[0],
                  men.modeSalary[0],
                ],
              ]}
              options={{
                colors: ["#e7a433", "#bf3b36", "#2391f5"],
                chart: {
                  title: "Promedio de Salario",
                  subtitle: "Diferencia de promedio de salario",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Chart
              width={"350px"}
              height={"300px"}
              chartType="Bar"
              //loader={<CircularProgress />}
              data={[
                ["Edad", "Todos", "Mujeres", "Hombres"],
                ["Años", both.modeAge[0], women.modeAge[0], men.modeAge[0]],
              ]}
              options={{
                colors: ["#e7a433", "#bf3b36", "#2391f5"],
                chart: {
                  title: "Promedio de Edad",
                  subtitle: "Diferencia de promedio de Edad",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="flex-start"
            alignItems="flex-start"
          >
            <Chart
              width={"350px"}
              height={"300px"}
              chartType="Bar"
              //loader={<CircularProgress />}
              data={[
                ["Antigüedad", "Todos", "Mujeres", "Hombres"],
                [
                  "Años",
                  both.modeAntiquity[0],
                  women.modeAntiquity[0],
                  men.modeAntiquity[0],
                ],
              ]}
              options={{
                colors: ["#e7a433", "#bf3b36", "#2391f5"],
                chart: {
                  title: "Promedio de antigüedad",
                  subtitle: "Diferencia de promedio de antigüedad",
                },
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
export default withStyles(styles)(Graphics);
