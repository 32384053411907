import Create from "./create";
import showAll from "./showAll";

const Voting = {
  Create,
  showAll,
};

export default Voting;

export { Create, showAll };
