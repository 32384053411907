import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
//import toastType from "../../../../API/CallUtils/tostyNotification";
import GetList from "../../../../API/CTM/events";
import Skeleton from "@material-ui/lab/Skeleton";
import MUIDataTable from "mui-datatables";
import "moment/locale/es";
import moment from "moment";
moment.locale("es");

const styles = (theme) => ({
  paper: {
    //height: "85vh",
    width: "90%",
    padding: "1%",
  },
  candidate: {
    width: "100%",
    minHeight: "200px",
    padding: "5px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  ImgPlaceholder: {
    width: "100%",
    height: "185px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  Remp: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    justifyContent: "end",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  ImgProp: {
    borderRadius: "8px",
    maxWidth: "100%",
    maxHeight: "185px",
    objectFit: "contain",
    marginTop: "10px",
  },
  Text: {
    width: "100%",
    marginTop: "20px",
  },
  GridDialog: {
    //marginLeft: "10px",
    padding: "1%",
  },
  Select: {
    maxWidth: "100%",
  },
  full: { width: "100%", marginTop: "20px", marginBottom: "20px" },
  Skeleton: {
    width: "100%",
    height: "100%",
    borderRadius: "10px 10px 10px 10px",
  },
  TableArea: {
    marginTop: "12px",
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [],
      AlterList: [],
      ConectionState: "onLoad",
    };
  }
  componentDidMount = async () => {
    try {
      let list = await GetList.ShowList(this.props.eventId);
      if (list.status === "success") {
        console.log("entra a la asignación", list);
        let gestList = list.data.participants.alternativeParticipants.map(
          (x) => {
            return [
              x.name,
              x.phone,
              x.email,
              x.Company ? x.Company.name : "",
              x.needTransport ? "Solicita" : "Llega por sus medios",
              x.BusStop ? x.BusStop.name : "Sin parada asignada",
              x.Campus ? x.Campus.name : "Sin cede asignada",
              x.confirmation ? "Confirmado" : "Sin Confirmar",
              moment(x.createdAt.split("T")[0]).format("ll"),
            ];
          }
        );
        let UserList = list.data.participants.participants.map((x) => {
          return [
            x.User.UserProfile.name,
            x.User.UserProfile.mobilePhone,
            x.User.email,
            x.Company ? x.Company.name : "",
            x.needTransport ? "Solicita" : "Llega por sus medios",
            x.BusStop ? x.BusStop.name : "Sin parada asignada",
            x.Campus ? x.Campus.name : "Sin cede asignada",
            x.confirmation ? "Confirmado" : "Sin Confirmar",
            moment(x.createdAt.split("T")[0]).format("llll"),
          ];
        });
        console.log(gestList);
        this.setState({
          List: UserList,
          AlterList: gestList,
          ConectionState: "onSuccess",
        });
        //.Company ? companie.Company.name
      } else {
        console.log(list);
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const columns = [
      "Nombre",
      "Teléfono",
      "Email",
      "Empresa",
      "Requiere Transporte",
      "Parada Solicitada",
      "Cede Solicitada",
      "Confirmación",
      "Fecha de registro",
    ];

    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 15, 50, 100],
    };
    const { classes } = this.props;
    let Used = <></>;
    switch (this.state.ConectionState) {
      case "onLoad":
        Used = <Skeleton className={classes.Skeleton} />;
        break;
      case "onSuccess":
        Used = (
          <Grid container className={classes.RootGrid}>
            <Grid
              item
              xs={12}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              {this.state.List.length > 0 ? (
                <Paper className={classes.TableArea}>
                  <MUIDataTable
                    title={"Participantes Registrados"}
                    data={this.state.List}
                    columns={columns}
                    options={options}
                    selectableRows="none"
                  />
                </Paper>
              ) : (
                <Paper className={classes.full}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    align="center"
                    className={classes.AddCandidate}
                  >
                    Sin participantes Registrados
                  </Typography>
                </Paper>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.Grid}
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
            >
              {this.state.AlterList.length > 0 ? (
                <Paper className={classes.TableArea}>
                  <MUIDataTable
                    title={"Participantes Invitados"}
                    data={this.state.AlterList}
                    columns={columns}
                    options={options}
                    selectableRows="none"
                  />
                </Paper>
              ) : (
                <Paper className={classes.full}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    align="center"
                    className={classes.AddCandidate}
                  >
                    Sin Invitados Registrados
                  </Typography>
                </Paper>
              )}
            </Grid>
          </Grid>
        );
        break;
      case "onError":
        Used = (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Error
              </Typography>
            </Grid>
          </Paper>
        );
        break;
      case "onNull":
        Used = (
          <Paper className={classes.FullPaper}>
            <Grid
              container
              className={classes.Full}
              alignContent="center"
              justify="center"
            >
              <Typography variant="h6" gutterBottom align="center">
                Sin participantes Registrados
              </Typography>
            </Grid>
          </Paper>
        );
        break;
      default:
        Used = (
          <Skeleton
            Name="Información del Asesor"
            className={classes.Skeleton}
          />
        );
    }
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Lista de personas registrados
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.GridD}>
            {Used}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
