import base from "../baseurl";
import token from "../getTk";

const Show = async () => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const status = await api
    .post("civilstates/showall", {
      json: {},
    })
    .json();
  //console.log(status);
  return status;
};

export default Show;
