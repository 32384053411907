import React, { Component } from "react";
import { withStyles, Paper, Grid, Button, IconButton } from "@material-ui/core";
import FilterOption from "./FilterOption";
import SelectSex from "./SelectSex";
import SelectState from "../../Companies/Create/SelectState";
import SelectCity from "../../Companies/Create/selectCity";
import SelectColony from "./SelectColony";
import Operations from "./Operations";
import Values from "./Values";
import StatisticCall from "../../../API/Statistic";
import Places from "../../../API/CallUtils";
import toasType from "../../../API/CallUtils/tostyNotification";
import CircularProgress from "@material-ui/core/CircularProgress";
import Raw from "./rawInfo";
import MUIDataTable from "mui-datatables";
import Results from "./Results";
import HasGraphics from "./HasGraphics";
import Graphics from "./Graphics";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#2980b9",
  },
  MainGrid: {},
  Paper: {
    width: "100%",
    padding: "12px",
    height: "100%",
  },
  block: {
    width: "100%",
  },
  Grid: {
    maxHeight: "100%",
    overflow: "auto",
  },
  Table: {
    marginTop: "12px",
    maxHeight: "100%",
    overflow: "auto",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});

class EmployeesStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salary: {
        min: 0,
        max: 300,
      },
      age: {
        min: 0,
        max: 25,
      },
      antiquity: {
        min: 0,
        max: 10,
      },
      antiquityCheck: true,
      ageCheck: true,
      salaryCheck: true,
      sex: "HOMBRE",
      stateUuid: "",
      ListOfStates: [],
      cityUuid: "",
      ListOfCities: [],
      ColonyList: [],
      Colony: "",
      stdDeviation: false,
      mode: false,
      mean: false,
      OptionSalary: false,
      OptionAntiquity: false,
      OptionAge: false,
      onLoad: false,
      raw: false,
      CompaniesResults: [],
      UsersResults: [],
      hasResult: false,
      womenResults: {
        meanAge: 0,
        meanAntiquity: 0,
        meanSalary: 0,
        modeAge: [],
        modeAntiquity: [],
        modeSalary: [],
        stdDeviationAge: 0,
        stdDeviationAntiquity: 0,
        stdDeviationSalary: 0,
      },
      menResults: {
        meanAge: 0,
        meanAntiquity: 0,
        meanSalary: 0,
        modeAge: [],
        modeAntiquity: [],
        modeSalary: [],
        stdDeviationAge: 0,
        stdDeviationAntiquity: 0,
        stdDeviationSalary: 0,
      },
      allResults: {
        meanAge: 0,
        meanAntiquity: 0,
        meanSalary: 0,
        modeAge: [],
        modeAntiquity: [],
        modeSalary: [],
        stdDeviationAge: 0,
        stdDeviationAntiquity: 0,
        stdDeviationSalary: 0,
      },
      hasGraphics: false,
    };
  }
  componentDidMount = async () => {
    await this.ShowAllStates();
  };
  SendTo = (name, type, value) => {
    let temp = this.state[name];
    temp[type] = value;
    console.log(name, type, value, temp);
    this.setState({ [name]: temp });
  };
  ResiveFromChild = (place, value) => {
    this.setState({
      hasResult: false,
      [place]: value,
    });
  };
  getCities = async (uuid) => {
    try {
      const ShowCitys = await Places.Citys(uuid);
      this.setState({ ListOfCities: ShowCitys });
    } catch (e) {
      console.log(e);
    }
  };
  ShowAllStates = async () => {
    try {
      const ShowStates = await Places.States();
      this.setState({ ListOfStates: ShowStates });
    } catch (e) {
      console.log(e);
    }
  };
  getLocalPlaces = async (Uuid) => {
    try {
      const colonyList = await Places.localplaces(Uuid);
      //console.log(colonysList)
      this.setState({ ColonyList: colonyList });
    } catch (e) {
      console.log(e);
    }
  };
  Send = async () => {
    this.setState({ onLoad: true });
    try {
      const create = await StatisticCall.Employees(this.state);
      console.log(create);
      if (create && create.employees) {
        switch (this.state.sex) {
          case "AMBOS":
            this.setState({
              allResults: create.employees.statistics.both.statistics,
              menResults: create.employees.statistics.men.statistics,
              womenResults: create.employees.statistics.women.statistics,
            });
            break;
          case "HOMBRE":
            this.setState({
              menResults: create.employees.statistics.men.statistics,
            });
            break;
          case "MUJER":
            this.setState({
              womenResults: create.employees.statistics.women.statistics,
            });
            break;
          default:
            toasType.error("No hay sexo seleccionado");
            break;
        }
        if (create.employees.companies.length > 0) {
          let data = [];
          create.employees.companies.forEach((x) => {
            data.push([x.name, x.industrialPark]);
          });
          this.setState({
            CompaniesResults: data,
            onLoad: false,
            hasResult: true,
          });
        }
        if (this.state.raw && create.employees.raw) {
          let dataUser = create.employees.raw.map((x) => {
            return [
              x.User.UserProfile.name,
              x.Company.name,
              x.User.email,
              x.payroll,
              x.job,
              x.daySalary,
              x.entry,
              x.address,
              x.Localplace ? x.Localplace.name : "",
              x.City ? x.City.name : "",
              x.Company.industrialPark,
              x.User.UserProfile.nss,
              x.User.UserProfile.dateOfBirth,
              x.User.UserProfile.otherMail,
              x.User.UserProfile.mobilePhone,
            ];
          });
          this.setState({ UsersResults: dataUser });
        }
        toasType.info(
          `${create.employees.noOfEmployees} Personas cumplen con el criterio`
        );
      } else {
        toasType.error("No fue posible traer la información");
        this.setState({ onLoad: false });
      }
    } catch (e) {
      toasType.error("No fue posible traer la información");
      console.log(e);
      this.setState({ onLoad: false });
    }
  };

  render() {
    const { classes } = this.props;
    const columns = ["Empresa", "parque industrial"];
    const columnsUsers = [
      "Nombre",
      "Empresa",
      "rfc",
      "Num nomina",
      "Area de trabajo",
      "Salario",
      "Fecha de Afiliación",
      "Dirección",
      "Colonia",
      "Ciudad",
      "Parque Industrial",
      "Seguro Social",
      "Fecha de nacimiento",
      "email",
      "Teléfono",
    ];

    const options = {
      textLabels: {
        body: {
          noMatch: "Estableciendo conexión",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
          next: "Siguiente pagina",
          previous: "Pagina anterior",
          rowsPerPage: "Columnas por pagina:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tablas",
        },
        filter: {
          all: "Todos",
          title: "FILTROS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Mostrar Columnas",
          titleAria: "Mostrar/Ocultar Columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionadas",
          delete: "Borrar",
          deleteAria: "Borrar Filas seleccionadas",
        },
      },
      filterType: "textField",
      page: 0,
      selectableRows: "none",
      print: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 50, 100, 200],
    };
    return (
      <Grid
        container
        direction="row"
        className={classes.MainGrid}
        xs={12}
        spacing={1}
      >
        <Grid
          item
          xs={3}
          className={classes.Grid}
          container
          direction="column"
          justify="flex-start"
        >
          <Paper className={classes.Paper}>
            <FilterOption
              label={"Antigüedad"}
              name={"antiquity"}
              value={this.state.antiquity}
              SendTo={this.SendTo}
              ResiveFromChild={this.ResiveFromChild}
              enable={this.state.antiquityCheck}
            />
            <FilterOption
              label={"Edad"}
              name={"age"}
              value={this.state.age}
              SendTo={this.SendTo}
              ResiveFromChild={this.ResiveFromChild}
              enable={this.state.ageCheck}
            />
            <FilterOption
              label={"Salarios"}
              name={"salary"}
              value={this.state.salary}
              SendTo={this.SendTo}
              ResiveFromChild={this.ResiveFromChild}
              enable={this.state.salaryCheck}
            />
            <SelectSex
              value={this.state.sex}
              ParentSet={this.ResiveFromChild}
            />
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
            >
              <Grid
                item
                xs={this.state.stateUuid ? 10 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <SelectState
                  SelectedState={this.state.stateUuid}
                  ListOfStates={this.state.ListOfStates}
                  sentoParent={this.ResiveFromChild}
                  getCities={this.getCities}
                />
              </Grid>
              {this.state.stateUuid && (
                <Grid
                  container
                  item
                  xs={2}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({
                        stateUuid: "",
                        cityUuid: "",
                        Colony: "",
                      });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
            >
              <Grid
                item
                xs={this.state.cityUuid ? 10 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <SelectCity
                  hasState={this.state.stateUuid}
                  CitySelected={this.state.cityUuid}
                  ListOfCities={this.state.ListOfCities}
                  sentoParent={this.ResiveFromChild}
                  getLocalPlaces={this.getLocalPlaces}
                />
              </Grid>
              {this.state.cityUuid && (
                <Grid
                  container
                  item
                  xs={2}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ cityUuid: "", Colony: "" });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
            >
              <Grid
                item
                xs={this.state.Colony ? 10 : 12}
                className={classes.Grid}
                container
                direction="row"
                justify="flex-start"
              >
                <SelectColony
                  value={this.state.Colony}
                  List={this.state.ColonyList}
                  sentoParent={this.ResiveFromChild}
                />
              </Grid>
              {this.state.Colony && (
                <Grid
                  container
                  item
                  xs={2}
                  className={classes.Grid}
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    color="Secondary"
                    onClick={() => {
                      this.setState({ Colony: "" });
                    }}
                    size="large"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Operations
              sentoParent={this.ResiveFromChild}
              mean={this.state.mean}
              mode={this.state.mode}
              stdDeviation={this.state.stdDeviation}
            />
            <Values
              sentoParent={this.ResiveFromChild}
              OptionSalary={this.state.OptionSalary}
              OptionAntiquity={this.state.OptionAntiquity}
              OptionAge={this.state.OptionAge}
            />
            <Raw sentoParent={this.ResiveFromChild} raw={this.state.raw} />
            <HasGraphics
              sex={this.state.sex}
              hasGraphics={this.state.hasGraphics}
              sentoParent={this.ResiveFromChild}
            />
            {this.state.onLoad ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.block}
                disabled
              >
                <CircularProgress />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={this.Send}
                className={classes.block}
                disabled={this.state.onLoad}
              >
                {"Buscar"}
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid item xs={9} className={classes.Grid} container direction="row">
          <Paper className={classes.Paper}>
            {!this.state.hasResult && (
              <Button className={classes.AddCandidate}>Sin información</Button>
            )}

            {this.state.hasResult &&
              (this.state.sex === "HOMBRE" || this.state.sex === "AMBOS") && (
                <Results
                  label="Hombres"
                  value={this.state.menResults}
                  mean={this.state.mean}
                  mode={this.state.mode}
                  stdDeviation={this.state.stdDeviation}
                />
              )}
            {this.state.hasResult &&
              (this.state.sex === "MUJER" || this.state.sex === "AMBOS") && (
                <Results
                  label="mujeres"
                  value={this.state.womenResults}
                  mean={this.state.mean}
                  mode={this.state.mode}
                  stdDeviation={this.state.stdDeviation}
                />
              )}
            {this.state.hasResult && this.state.sex === "AMBOS" && (
              <Results
                label="ambos"
                value={this.state.allResults}
                mean={this.state.mean}
                mode={this.state.mode}
                stdDeviation={this.state.stdDeviation}
              />
            )}
            {this.state.hasResult && this.state.sex === "AMBOS" && (
              <Graphics
                mean={this.state.mean}
                mode={this.state.mode}
                women={this.state.womenResults}
                men={this.state.menResults}
                both={this.state.allResults}
              />
            )}
            {this.state.hasResult && (
              <MUIDataTable
                title={"Empresas"}
                data={this.state.CompaniesResults}
                columns={columns}
                options={options}
                selectableRows="none"
                className={classes.Table}
              />
            )}
            {this.state.hasResult && this.state.raw && (
              <MUIDataTable
                title={"Usuarios"}
                data={this.state.UsersResults}
                columns={columnsUsers}
                options={options}
                selectableRows="none"
                className={classes.Table}
              />
            )}
          </Paper>
        </Grid>

        {this.state.hasResult && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
          ></Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(EmployeesStatistic);
