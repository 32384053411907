import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import toastType from "../../../API/CallUtils/tostyNotification";
import Api from "../../../API/CompaniesFormsQuestions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Types from "../Selects/SelectQuestionType";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogCreateAnswer from "../Dialogs/DialogNewAnswer";
import AddBoxIcon from "@material-ui/icons/Add";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
  margin: {
    margin: "10px",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      question: "",
      answers: [],
      DialogCreateAnswer: false,
    };
  }
  componentDidMount = async () => {};
  validations = () => {
    if (this.state.question.length > 0 && this.state.type) {
      if (this.state.type === 1 || this.state.type === 2) {
        return true;
      } else {
        if (this.state.answers.length > 1) {
          return true;
        } else {
          toastType.error(
            "Las preguntas de opción multiple deben tener al menos 2 opciones "
          );
        }
      }
    } else {
      toastType.error("Falta la pregunta o el tipo de pregunta");
    }
  };
  create = async () => {
    try {
      if (this.validations()) {
        this.setState({
          Oncreate: true,
        });
        const Deli = await Api.Create(this.state);
        if (Deli.status === "success") {
          this.setState({
            Oncreate: false,
          });
          toastType.success("Se creado con éxito");
          await this.props.Refresh("CreateDialog");
        } else {
          this.setState({
            Oncreate: false,
          });
          toastType.error("SSE No fue Posible Eliminar el elemento");
        }
      }
    } catch (e) {
      this.setState({
        Oncreate: false,
      });
      toastType.error("CSE No fue posible Borrar el evento");
      console.log(e);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (value, name) => {
    this.setState({ [name]: value });
  };
  deleteAnswer = (index) => {
    let value = this.state.answers.filter((item, j) => index !== j);
    this.setState({ answers: value });
  };
  Dialog = (a) => {
    console.log(a);
    this.setState({ [a]: !this.state[a] });
  };
  NewAnswer = (Value) => {
    this.setState({ answers: [...this.state.answers, Value] });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Nueva pregunta </DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            <Types
              value={this.state.type}
              ParentName={"type"}
              Select={this.handleChange}
            />
            <TextField
              label="Pregunta"
              size="small"
              name="question"
              onChange={this.onChange}
              fullWidth
              value={this.state.question}
              className={classes.margin}
            />
            {this.state.type &&
              this.state.question.length > 0 &&
              !(this.state.type === 1) &&
              !(this.state.type === 2) &&
              (this.state.answers.length > 0 ? (
                <div className={classes.Table}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Respuesta</TableCell>
                        <TableCell>Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.answers.map((Article, index) => {
                        return (
                          <React.Fragment>
                            <TableRow key={Article.uuid}>
                              <TableCell component="th" scope="row">
                                {Article}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={this.deleteAnswer.bind(this, index)}
                                  className={classes.btn}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.Margin}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.margin}
                      onClick={this.Dialog.bind(this, "DialogCreateAnswer")}
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                  {this.state.DialogCreateAnswer && (
                    <DialogCreateAnswer
                      DialogOpen={this.state.DialogCreateAnswer}
                      DialogClose={this.Dialog.bind(this, "DialogCreateAnswer")}
                      Send={this.NewAnswer}
                    />
                  )}
                </div>
              ) : (
                <Paper className={classes.NoItemPaper}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.Dialog.bind(this, "DialogCreateAnswer")}
                    className={classes.AddCandidate}
                  >
                    {"Agregar opciones "} <AddBoxIcon />
                  </Button>
                  {this.state.DialogCreateAnswer && (
                    <DialogCreateAnswer
                      DialogOpen={this.state.DialogCreateAnswer}
                      DialogClose={this.Dialog.bind(this, "DialogCreateAnswer")}
                      Send={this.NewAnswer}
                    />
                  )}
                </Paper>
              ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.question.length > 0
            ? [
                !this.state.Oncreate ? (
                  <Button color="Primary" onClick={this.create}>
                    <AddIcon />
                  </Button>
                ) : (
                  <Button color="Primary" disabled>
                    <CircularProgress />
                  </Button>
                ),
              ]
            : [
                <Button color="Primary" disabled>
                  <AddIcon />
                </Button>,
              ]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
