import base from "../baseurl";
import token from "../getTk";

const Employees = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log("llego", state);
  const status = await api
    .post("statistics/employees", {
      timeout: false,
      json: {
        queryParams: {
          localPlaceUuid: state.Colony,
          cityUuid: state.cityUuid,
          antiquity: state.antiquityCheck
            ? state.antiquity
            : { min: 0, max: 100 },
          salary: state.salaryCheck ? state.salary : { min: 0, max: 10000 },
          age: state.ageCheck ? state.age : { min: 0, max: 100 },
          sex: state.sex,
        },
        operations: {
          mean: state.mean,
          mode: state.mode,
          stdDeviation: state.stdDeviation,
        },
        values: {
          salary: state.salary,
          age: state.age,
          antiquity: state.antiquity,
        },
        raw: state.raw,
      },
    })
    .json();

  return status.data;
};

export default Employees;
