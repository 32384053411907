import base from "../baseurl";
import token from "../getTk";

const showAll = async (Uuid) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });

  const showAllWS = await api
    .post("candidate-worksheets/showall", {
      json: {
        electionUuid: Uuid,
      },
    })
    .json();
  //console.log(Create);
  return showAllWS;
};

export default showAll;
