import base from "../baseurl";
import token from "../getTk";

const showAll = async (id) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  console.log(id);
  const Create = await api
    .post("personal-documents/showall", {
      json: {
        id,
      },
      timeout: false,
    })
    .json();
  return Create;
};

export default showAll;
