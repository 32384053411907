import base from "../../baseurl";
import token from "../../getTk";

const Update = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const Update = await api
    .post("frequentqas/update", {
      json: {
        frequentQAUuid: state.Uuid,
        question: state.Question,
        answer: state.answer,
        links: Array.isArray(state.url)?state.url : state.url.split(/\r?\n|,/),
        typeUuid: state.type,
      },
    })
    .json();

  return Update;
};

export default Update;
