import axios from "axios";
const Report = async (companyId, year) => {
  const re = await axios
    .post(`https://api.sitimm.org/api/reports/show`, {
      reportNumber: 7,
      params: {
        companyId,
        year,
      },
    })
    .then((res) => {
      console.log(res);
      console.log(res.data);
      return res;
    });
  return re;
};

export default Report;
