import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import OnLoadSelect from "../../../Components/StatesForComponets/OnLoadSelect";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  Title: {
    width: "90%",
  },
  CloseBtn: {
    //width: "10%",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { idQuestion: 1 };
  }
  handleChange = (ev) => {
    this.setState({ idQuestion: ev.target.value });
  };
  componentDidMount = async () => {
    //this.fetchlist();
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        TransitionComponent={Transition}
        maxWidth="xl"
        //fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.Title}>Agregar pregunta</Typography>
        </DialogTitle>
        <DialogContent className={classes.paper}>
          <Paper>
            {this.props.Questions.length > 0 ? (
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Lista de preguntas
                </InputLabel>
                <Select
                  value={this.state.idQuestion}
                  onChange={this.handleChange}
                >
                  {this.props.Questions.map((Question, index) => {
                    return (
                      <MenuItem value={Question.id} key={Question.id}>
                        {Question.content}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <OnLoadSelect />
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          <Button
            onClick={() => {
              this.props.Send(this.state.idQuestion);
              this.props.DialogClose();
            }}
            color="primary"
          >
            <Button color="Primary" onClick={this.create}>
              <AddIcon />
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
