import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "400px",
  },
});

class rejectionNotesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    // console.log(this.props.info);
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Notas de rechazo</DialogTitle>
        <DialogContent className={classes.paper}>
          <div className={classes.paper}>
            <TextField
              margin="normal"
              size="small"
              name="name"
              multiline
              rows={5}
              value={this.props.notes}
              onChange={(e) => {
                this.props.onChange(e.target.value);
              }}
              fullWidth
              variant={"outlined"}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              this.props.send(
                this.props.info.id,
                this.props.info.fullName,
                this.props.info.ScholarshipId,
                this.props.info.birthDay,
                3
              );
            }}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(rejectionNotesDialog);
