import base from "../../baseurl";
import token from "../../getTk";

const Create = async (state) => {
  const tokenA = token.get();
  const api = base.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("jwt", `${tokenA}`);
        },
      ],
    },
  });
  const create = await api
    .post("bulletins-general/create", {
      timeout: false,
      json: {
        title: state.NewTitle,
        description: state.NewDescrip,
        pdf: state.NewPdfCont,
        img: state.NewPdfImg,
        links: state.links.toString(),
        isSportBulletin: true,
      },
    })
    .json();
  console.log(create);

  return create;
};

export default Create;
