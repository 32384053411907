import Create from "./Create";
import Update from "./Update";
import showAll from "./showAll";
import Delete from "./Delete";
import ShowAllEventTypes from "./ShowAllEvenType";
import ShowList from "./ShowList";
import UpdateEnable from "./UpdateEnable";
import BusStopList from "./BusStopList";
import CampusShowAll from "./CampusShowAll";

const events = {
  Update,
  showAll,
  Create,
  Delete,
  ShowAllEventTypes,
  ShowList,
  UpdateEnable,
  BusStopList,
  CampusShowAll,
};

export default events;

export {
  Create,
  Update,
  showAll,
  Delete,
  ShowAllEventTypes,
  ShowList,
  UpdateEnable,
  BusStopList,
  CampusShowAll,
};
