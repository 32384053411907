import React from "react";
import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Api from "../../../API/CompaniesCampaigs";
import toastType from "../../../API/CallUtils/tostyNotification";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const styles = (theme) => ({
  paper: {
    width: "100%",
    minHeight: "100px",
    minWidth: "500px",
  },
  Title: {
    width: "90%",
  },
  CloseBtn: {
    //width: "10%",
  },
  AddCandidate: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(99, 159, 191,0.2)",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  Margin: {
    marginTop: "1%",
    padding: "5px",
  },
  text: {
    width: "80%",
  },
  Subtitle: {
    alignItems: "center",
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  Card: {
    margin: "10px",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Select: null,
      Oncreate: false,
      ListOfAnswers: [],
    };
  }
  componentDidMount = async () => {
    console.log("result 2");
    this.fetchlist();
  };
  fetchlist = async () => {
    try {
      this.setState({
        onLoad: true,
      });

      const Deli = await Api.ShowResults(this.props.EventId);
      console.log(
        Deli.data.entries.length > 0 ? Deli.data.entries[0].results : "nada"
      );
      if (Deli.status === "success") {
        this.setState({
          onLoad: false,
          onError: false,
          ListOfAnswers:
            Deli.data.entries.length > 0 ? Deli.data.entries[0].results : [],
        });
      } else {
        toastType.error("Error");
        this.setState({
          onError: true,
          onLoad: false,
          ErrorCase: "Error en el servidor",
        });
      }
    } catch (error) {
      toastType.error("Error");
      console.log(error);
      this.setState({
        onErrorFetch: true,
        onLoad: false,
        ErrorCase: "Error en la llamada de los recursos",
      });
    }
  };
  TypeAnswer = (ans, index) => {
    const { classes } = this.props;
    switch (ans.type) {
      case 1:
        return (
          <Card className={classes.Card}>
            <CardContent>
              <Typography>
                {" "}
                {index + 1}
                {".  "}
                {ans.question}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Respuestas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ans.answers &&
                    ans.answers.length > 0 &&
                    ans.answers.map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {x}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      case 2:
        return (
          <Card className={classes.Card}>
            <CardContent>
              <Typography>
                {" "}
                {index + 1}
                {".  "}
                {ans.question}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Respuestas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ans.answers &&
                    ans.answers.length > 0 &&
                    ans.answers.map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {x}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      case 3:
        return (
          <Card className={classes.Card}>
            <CardContent>
              <Typography>
                {" "}
                {index + 1}
                {".  "}
                {ans.question}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Respuesta</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ans.answers &&
                    ans.answers.length > 0 &&
                    ans.answers.map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {x.answer}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {x.amount}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      case 4:
        return (
          <Card className={classes.Card}>
            <CardContent>
              <Typography>
                {" "}
                {index + 1}
                {".  "}
                {ans.question}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Respuesta</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ans.answers &&
                    ans.answers.length > 0 &&
                    ans.answers.map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {x.answer}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {x.amount}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      case 5:
        return (
          <Card className={classes.Card}>
            <CardContent>
              <Typography>
                {" "}
                {index + 1}
                {".  "}
                {ans.question}
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Respuesta</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ans.answers &&
                    ans.answers.length > 0 &&
                    ans.answers.map((x, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {x.answer}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {x.amount}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        );
      default:
        return <p>Error</p>;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        TransitionComponent={Transition}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Typography className={classes.Title}>
            Resultados de las encuestas
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.paper}>
          {this.state.ListOfAnswers &&
            this.state.ListOfAnswers.length > 0 &&
            this.state.ListOfAnswers.map((x, index) => {
              return this.TypeAnswer(x, index);
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
