import React, { Component } from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
const styles = (theme) => ({
  Initial: {
    width: "100%",
  },
  Paper: {
    width: "100%",
    minHeight: "200px",
    borderRadius: "0px 0px 10px 10px",
    padding: "1%",
    marginTop: "1%",
  },
  Subtitle: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  marginL: {
    marginLeft: "5px",
    width: "28%",
  },
});

class TablePadron extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    console.log("Objeto con estadística ", this.props.info.statistics);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.Initial}>
        <Grid container className={classes.Initial} spacing={1}>
          <Grid container item xs={12} className={classes.Initial}>
            <Typography
              align="Center"
              variant="h5"
              component="p"
              className={classes.Subtitle}
            >
              Estadística
            </Typography>
            <Paper className={classes.Paper}>
              <Grid
                container
                item
                xs={12}
                className={classes.Initial}
                spacing={1}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid container item xs={10}>
                  <TextField
                    label="Trabajadores"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.statistics.noOfEmployees
                        ? Math.round(this.props.info.statistics.noOfEmployees)
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={10}>
                  <TextField
                    label="Sueldo Promedio"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.statistics.meanWage
                        ? Math.round(this.props.info.statistics.meanWage)
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={10}>
                  <TextField
                    label="Edad Promedio"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.statistics.meanAge
                        ? Math.round(this.props.info.statistics.meanAge)
                        : "Sin información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={10}>
                  <TextField
                    label="Salario mas Alto"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.statistics.maxWage
                        ? Math.round(this.props.info.statistics.maxWage)
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
                <Grid container item xs={10}>
                  <TextField
                    label="Salario mas Bajo"
                    margin="normal"
                    size="small"
                    fullWidth
                    value={
                      this.props.info.statistics.minWage
                        ? Math.round(this.props.info.statistics.minWage)
                        : "Sin Información"
                    }
                    //variant="outlined"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TablePadron);
