import base from "../baseurl";
import token from "../getTk"

const Take = async (Uuid,Asesor) => {
  const tokenA = token.get()
  const api = base.extend({
    hooks: {
        beforeRequest: [
            request => {
                request.headers.set('jwt', `${tokenA}`);
            }
        ]
    }
  });
    const Take = await api.post("consultations/update",{json: {
      updateType:1,
      consultationUuid:Uuid,
      payload:{
        advisorUuid:Asesor
      }
    }}).json();
    console.log(Take)
    return Take;
    
  };
  
  export default  Take ;