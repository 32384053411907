import React from "react";
import {
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  paper: {
    width: "100%",
    //padding: "1%",
  },
  Subtitle: {
    padding: "5px",
    width: "100%",
    borderRadius: "3px 3px 3px 3px",
    backgroundColor: "#ad1d21",
    color: "rgb(247, 244, 244)",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  AddCandidate: {
    minWidth: "300px",
    width: "100%",
    height: "250px",
    backgroundColor: "rgb(99, 159, 191,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(30, 160, 230)",
    "&:hover": {
      backgroundColor: "rgb(99, 159, 191,0.5)",
    },
  },
  input: { display: "none" },
});

class addCandidateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ProcessEvent: "Select" };
  }
  componentDidMount = async () => {};

  readFile = async (event) => {
    const file = event.target.files[0];
    if (file.size / 1024 / 1024 <= 10) {
      const readerDone = await this.handleFile(file);
      console.log(readerDone.data);
      this.converFile(file);
      const indexFile = this.state.indexFile;
      let indexData = {
        no_nomina: indexFile[0],
        nombre: indexFile[1],
        apellidoP: indexFile[2],
        apellidoM: indexFile[3],
        puesto: indexFile[4],
        salario_tab: indexFile[5],
        fecha_ingr: indexFile[6],
        rfc: indexFile[7],
        nss: indexFile[8],
        fecha_naci: indexFile[9],
        municipio: indexFile[10],
        calle: indexFile[11],
        colonia: indexFile[12],
        sexo: indexFile[13],
        headers: indexFile[14],
        startData: indexFile[15],
      };
      this.getInfo(readerDone, indexData);
    } else {
      alert(
        "El tamaño de archivo es demasiado grande, por favor revise que solo tenga los datos necesarios."
      );
    }
  };

  render() {
    const { classes } = this.props;
    var Selected = <></>;
    switch (this.state.ProcessEvent) {
      case "Select":
        Selected = (
          <>
            <input
              type="file"
              id="files"
              accept=".csv, .xlsx, .xls"
              onChange={this.readFile.bind(this)}
              required
              className={classes.input}
            />
            <label htmlFor="files">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.AddCandidate}
              >
                Subir archivo
              </Button>
            </label>
          </>
        );
        break;
      case "Census":
        Selected = <Button />;
        break;
      case "Details":
        Selected = <Button />;
        break;
      default:
        Selected = <Button />;
        break;
    }

    return (
      <Dialog
        open={this.props.DialogOpen}
        onClose={this.props.DialogClose}
        //maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <Typography align="Center" variant="h5" className={classes.Subtitle}>
            {this.props.name}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.paper}>{Selected}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.DialogClose} color="secondary">
            <CloseIcon />
          </Button>
          {this.state.ProcessEvent !== "Select"
            ? [
                <Button
                  onClick={() => {
                    this.setState({ ProcessEvent: "Select" });
                  }}
                  color="primary"
                >
                  <ArrowBackIcon />
                </Button>,
              ]
            : [<></>]}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(addCandidateDialog);
