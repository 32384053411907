import React, { Component } from "react";
import PropTypes from "prop-types";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { connect } from "react-redux";
import { authorization } from "../../Accions/actions";
import {
  Avatar,
  Button,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import Api from "../../API/Users/Login";
import toastType from "../../API/CallUtils/tostyNotification";
import Permissions from "../../API/Permissions/Show";
import { FullScreenLoading } from "../../Utils/Loadings";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  paperImg: {
    padding: theme.spacing(4),
    margin: "auto",
    maxWidth: 500,
  },
  Grid: {
    marginTop: theme.spacing(2),
  },
  loading: {
    margin: "70px",
    padding: "4px",
    display: "flex",
    alignItems: "center",
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      pass: "",
      onLoad: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit = async (event) => {
    this.setState({ onLoad: true });
    event.preventDefault();
    const login = await Api(this.state);
    console.log(login);
    if (login.status === "success") {
      await Permissions(login.data.userId, login.data.accessToken);
      this.props.authorization(login);
    } else {
      login.data
        ? toastType.error(login.data.message)
        : toastType.error("Error de origen");
      this.setState({ onLoad: false });
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, pass } = this.state;
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {!!this.state.onLoad ? (
            <div className={classes.loading}>
              <FullScreenLoading />
            </div>
          ) : (
            <form className={classes.form} onSubmit={this.onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Correo Electrónico</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={this.onChange}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  name="pass"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={pass}
                  onChange={this.onChange}
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recuérdate"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onSubmit={this.onSubmit}
              >
                Sign in
              </Button>
            </form>
          )}
        </Paper>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    authorization: (data) => dispatch(authorization(data)),
  };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Login));
